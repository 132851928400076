import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getFurnaceName } from "../../configuration";
import { cosy_preperties } from "../../interfaces/IConvectionSection";
import { RootState } from "../../reducers/rootReducer";
import { addDefaultConvectionSectionData, addUpdateComponents, updateConvectionSectionSignature, updateDefaultConvectionSectionData } from "../../slices/convectionSectionSlice";
import EditableCell from "../editableCell";

export const CONVECTION_SECTION_DASHBOARD_ID = "_CONVECTION_SECTION";

interface IConvectionSectionProps { }

const mapStateToProps = (state: RootState) => {
    return {
        current_convectionsection_id: state.convectionsection.current_convectionsection_id,
        convectionsections: state.convectionsection.convectionsections,
        defaultConvectionSectionDatas: state.convectionsection.defaultConvectionSectionDatas,
        token: state.authState.token,
        furnaces: state.furnace.furnaces,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addDefaultConvectionSectionData: (convectionsectiondata: any[]) => dispatch(addDefaultConvectionSectionData(convectionsectiondata)),
        updateDefaultConvectionSectionData: (convectionsectiondata: any[]) => dispatch(updateDefaultConvectionSectionData(convectionsectiondata)),
        addUpdateComponents: (convectionid: string, components: any[]) => dispatch(addUpdateComponents({ convectionid, components })),
        updateSignature: (convectionSection_id: string) => dispatch(updateConvectionSectionSignature({ convectionSection_id })),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IConvectionSectionReduxProps = PropsFromRedux & IConvectionSectionProps;

type IConvectionSectionState = {
    tagNames: string[];
};

class ConvectionSection extends Component<IConvectionSectionReduxProps, IConvectionSectionState> {

    /**
     *
     */
    constructor(props) {
        super(props);

        this.state = {
            tagNames: [
                "Boil Feed Water",
                "High Pressure Stream",
                "Feed",
                "Dilution Steam",
                "Fuel Gas",
                "Cracked Gas after Quenching"
            ],
        }

    }

    tableHighlight = (e) => {
        var elems = document.querySelectorAll(".selected");

        // remove all other selections
        elems.forEach(el => {
            el.classList.remove("selected");
        });

        // set selection highlight
        if (e.target.parentElement && e.target.parentElement.parentElement) {
            e.target.parentElement.parentElement.classList.add('selected');
        }
    };

    /**
   * Returns default component value for selected column/row
   * @param compKey {string} component name
   */
    getDefaultValue = (compKey, defaultdata, currentdata) => {

        if (currentdata.asset_name === "") {
            return (
                <td></td>
            );
        }
        else {
            return (
                <td>
                    {defaultdata[compKey]['design']}
                </td>
            );
        }

    };

    /**
   * Handle change of user input fields
   * @param mockE {any} value  
   * @param compKey {string} index of component - row
   */

    handleTableValuesChange = async (mockE: { target: { id: string, name: string, value: number } }, compKey, currentdata) => {
        let czCopy = JSON.parse(JSON.stringify(currentdata.components[0]));
        czCopy[compKey]['design'] = mockE.target.value;
        this.props.addUpdateComponents(currentdata.id, [czCopy]);
        this.props.updateSignature(currentdata.id)
    };

    /**
    * Returns editable cell of component value for selected column/row
    * @param compKey {string} component name
    * @param convectiondata {object} components data
    */
    getCurrentValue = (compKey, convectiondata) => {

        if (convectiondata.asset_name === "" || convectiondata.components.length === 0) {
            return (
                <td></td>
            );
        }
        else {

            return (
                <EditableCell
                    key={`cz-${compKey}`}
                    cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleTableValuesChange(mockE, compKey, convectiondata)}
                    cellData={{
                        id: `czin-${compKey}`,
                        value: convectiondata.components[0][compKey]['design'],
                        type: "number",
                    }}
                />
            );
        }

    };

    getSelectedFurnace(furnace_id) {
        return getFurnaceName(furnace_id);
    }

    render() {

        let csIndex = this.props.convectionsections.findIndex(x => x.id === this.props.current_convectionsection_id);
        let convectiondata = this.props.convectionsections[csIndex];

        let defaultdata = this.props.defaultConvectionSectionDatas.find(x => x.asset_name === convectiondata.asset_name);

        return (
            <div className="convection-section-dashboard">

                <div className="convection-section">
                    <table id="convection-section-table" className="convection-section-dashboard">
                        <thead>
                            <tr>
                                <th rowSpan={2}>Process Media ( Furnace : {this.getSelectedFurnace(!!this.props.convectionsections && this.props.convectionsections.find(f => f.id === this.props.current_convectionsection_id)?.furnace_id)}) </th>
                                <th rowSpan={2}>Process Value</th>
                                <th rowSpan={2}>Unit</th>
                                <th rowSpan={2}>Default</th>
                                <th>{this.props.convectionsections.find(f => f.id === this.props.current_convectionsection_id)?.name}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cosy_preperties.map((comp, i) => {
                                return (
                                    <tr key={`comp-${i}`} onFocus={this.tableHighlight}>
                                        {i % 2 === 0 ?
                                            <td rowSpan={i === cosy_preperties.length - 1 ? 1 : 2}>{this.state.tagNames[i / 2]}</td> : null}
                                        <td>{i % 2 === 0 && i !== cosy_preperties.length - 1 ? "Temperature" : "Pressure"}</td>
                                        <td>{i % 2 === 0 && i !== cosy_preperties.length - 1 ? `(${defaultdata[comp]["tag_unit"]})` : `(${defaultdata[comp]["tag_unit"]})`}</td>
                                        {this.getDefaultValue(comp, defaultdata, convectiondata)}
                                        {this.getCurrentValue(comp, convectiondata)}
                                        {/*{scenarios.map((s, j) => getScenarioValue(comp, j))} */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};


export default connector(ConvectionSection);
