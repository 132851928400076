import { MenuItem, Select, Checkbox, ListItemText, ListItemIcon, FormControl } from "@material-ui/core"
import React, { Component } from "react"
import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../reducers/rootReducer"
import { setCoils, setCurrentCoil, setMultipleCurrentCoil, toggleCoilDropdown } from "../../slices/coilSlice"
import { setCoilgroups } from '../../slices/coilGroupSlice'
import { REACT_APP_APIM_URL } from "../../utilities/GlobalConstants"
import store from "../..";
import { getCameraByCoilgroup, getCoilgroups } from "../../configuration"

interface ICoilSelector {
    data: string[],
    showMultiCoilSelector?: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        coil: state.coil,
        currCamera: state.camera.name,
        currCoilGroup: state.coilGroup.name,
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentCoil: (name: string) => dispatch(setCurrentCoil(name)),
        setCoil: (name: string[]) => dispatch(setCoils(name)),
        setMultipleCurrentCoil: (name: string[]) => dispatch(setMultipleCurrentCoil(name)),
        toggleCoilDropdown: (name: boolean) => dispatch(toggleCoilDropdown(name)),
        setCoilgroups: (values: string[]) => dispatch(setCoilgroups(values)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICoilSelectorReduxProps = PropsFromRedux & ICoilSelector;

type ICoilSelectorState = {
    coil: string[],
    currCoil: string,
    currCamera: string,
    selectedCoils: string[]
    isAllSelected: boolean,
    currCoilGroup: string,
    coilDropdownDisabled: boolean
}
class CoilSelector extends Component<ICoilSelectorReduxProps, ICoilSelectorState>{

    constructor(props) {
        super(props);

        this.state = {
            coil: this.props.coil.coils,
            currCoil: this.props.coil.currentCoil,
            currCamera: this.props.currCamera,
            selectedCoils: this.props.coil.multipleCurrentCoil || [],
            isAllSelected: false,
            currCoilGroup: this.props.currCoilGroup,
            coilDropdownDisabled: this.props.coil.coilDropdownDisabled
        }
    }
    
    fetchCoilsData = () => {
        fetch(`${REACT_APP_APIM_URL}/thermalimage/getCameraCoilConfig`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + store.getState().authState.token,
                "Access-Control-Allow-Origin": "*"
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    let mappedArray: any[] = [];
                    if(this.props.showMultiCoilSelector)
                    {
                        let selectedCoilgroup = this.props.currCoilGroup;
                        let cameraForSelectedCoilgroup = "";
                        if(selectedCoilgroup !== "ALL")
                        {
                            let cameraData = getCameraByCoilgroup(selectedCoilgroup);
                            cameraForSelectedCoilgroup = cameraData[0].display_name;
                            const result = data.filter(d => d.camera === cameraForSelectedCoilgroup)
                            for (const d of result[0]?.coils) {
                                if (d.includes("OUT"))
                                    mappedArray.push(d);
                            }
                        }
                        else
                        {
                            const result = data
                            for(let i = 0 ; i < result.length; i++){
                                for (const d of result[i]?.coils) {
                                    if (d.includes("OUT"))
                                        mappedArray.push(d);
                                }
                            }
                        }
                        
                    }
                    else
                    {
                        const result = data.filter(d => d.camera === this.props.currCamera)
                        for (const d of result[0]?.coils) {
                            if (d.includes("OUT"))
                                mappedArray.push(d);
                        }
                    }
                    this.props.setCoil(mappedArray)
                    this.setState({
                        coil: mappedArray
                    });
                    let coilGroups = getCoilgroups(this.props.currentFurnaceId);
                    coilGroups = coilGroups && coilGroups.filter((cg,i, cgs) => cgs.indexOf(cg) === i)
                    const allCoilGroups: any[] = [];
                    coilGroups.forEach(x => {
                        allCoilGroups.push(x.name)
                    });
                    this.props.setCoilgroups(allCoilGroups)
                },
                (error) => console.log("error", error)
            )
    }

    componentDidMount(): void {
        this.props.toggleCoilDropdown(false)
        this.fetchCoilsData()
        const isAllSelectedVal =
        this.state.coil.length > 0 && this.state.selectedCoils.length === this.state.coil.length;
        this.setState({isAllSelected : isAllSelectedVal}) 
        this.setState({
            currCoil: this.props.coil.currentCoil
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                coil: this.props.data.filter(x => x.includes("OUT")),
            });
        }

        if (this.state.currCoil !== this.props.coil.currentCoil) {
            this.setState({
                currCoil: this.props.coil.currentCoil
            })
        }

        if(prevProps.currCoilGroup !== this.props.currCoilGroup)
        {
            this.fetchCoilsData()
            this.setState({
                selectedCoils: []
            });
        }

        if(this.state.coilDropdownDisabled !== this.props.coil.coilDropdownDisabled)
        {
            this.setState({
                coilDropdownDisabled: this.props.coil.coilDropdownDisabled
            })
        }
    }

    handleSelection = (e) => {
        if(this.props.showMultiCoilSelector){

            const newVal: Array<string> = e.target.value;
            if(newVal.length <= 12 && newVal.length > 0)
            {
                this.props.toggleCoilDropdown(true)
                var currSelectedCoilGroup = this.props.currCoilGroup;
                const newValArray: Array<string> = [];
                for(var i = 0; i<newVal.length; i++)
                {
                    if(newVal[i].includes("CG-"+currSelectedCoilGroup))
                    {
                        newValArray.push(newVal[i])
                    }
                }
                
                if (e.target.value.includes("all"))
                { 
                    this.setState({
                        selectedCoils: this.state.selectedCoils.length === this.state.coil.length ? [] : this.state.coil
                    });
                    
                    
                    const isAllSelectedVal =
                    this.state.coil.length > 0 && this.state.selectedCoils.length === this.state.coil.length;
                    this.props.setMultipleCurrentCoil(this.state.coil);
                    this.setState({isAllSelected : !isAllSelectedVal}) 
                    return;
                }
                this.setState({
                    selectedCoils: newValArray
                });
                this.props.setMultipleCurrentCoil(newValArray);
            }
            else
            {
                this.setState({
                    selectedCoils: []
                });
                this.props.toggleCoilDropdown(false)
                this.setState({isAllSelected : false}) 
                this.props.setMultipleCurrentCoil([]);
            }
        }
        else{
            const newVal: string = e.target.value;
            this.props.setMultipleCurrentCoil([newVal]);
            this.props.setCurrentCoil(newVal);
        }
    }
    render(): React.ReactNode {
        return (
            <div className="control-header element">
                <div className="container-typography">
                    <b>Coil: </b>
                </div>
                <FormControl style={{width: 500}}>
                {this.props.showMultiCoilSelector && <Select multiple value={this.state.selectedCoils} disabled={this.state.coilDropdownDisabled} onChange={(event) => this.handleSelection(event)} 
                renderValue={(p: string[] | any) => p.join(", ")}>
                <MenuItem
                    disabled={this.state.coilDropdownDisabled}
                    value="all"
                    >
                    <ListItemIcon>
                        <Checkbox
                        style ={{
                            color: "#435591",
                        }}
                        checked={this.state.isAllSelected}
                        indeterminate={
                            this.state.selectedCoils.length > 0 && this.state.selectedCoils.length < this.state.coil.length
                        }
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="Select All"
                    />
                    </MenuItem>
                    {this.state.coil?.map((coil, i) => (
                        <MenuItem key={`COIL-SELECT-${coil}`} disabled={this.state.coilDropdownDisabled} value={coil}>
                            <Checkbox
                                style ={{
                                    color: "#435591",
                                }}
                                checked={this.state?.selectedCoils.findIndex((item) => item === coil) >= 0
                                }
                            />
                            <ListItemText primary={coil} />
                        </MenuItem>
                    ))}
                </Select>}

                {!this.props.showMultiCoilSelector && <Select autoWidth value={this.state.currCoil} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.state.coil?.map((coil, i) => (
                        <MenuItem key={`COIL-SELECT-${coil}`} value={coil}>
                            {coil}
                        </MenuItem>
                    ))}
                </Select>}
                </FormControl>
            </div>
        )
    }
}

export default connector(CoilSelector)