import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const TermCondition = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const pageName = "Term & Conditions"
        if (pageName !== undefined) {
            dispatch({ type: "UPDATE_PAGE_TITLE", payload: { value: pageName } });
        }
    }, [dispatch]);

    return (
        <div className="terms-conditions">
            <div className="row">
                <div className="col-12 col-md-10 offset-md-1"><h1 className="header">Terms &#038; Conditions</h1>
                    <div className="post-content">
                        <h3>The use of the Linde Virtual Academy website(s) provided by Linde GmbH, hereinafter “LVA Website”, is subject to the following terms and conditions, hereinafter “Terms and Conditions”:</h3>
                        <h4><strong>1. Your acknowledgment of the terms and conditions of use</strong></h4>
                        <p>By accessing the LVA Website you, the user, acknowledge and confirm that you have read, understood, and agree to the following terms and conditions of use. Furthermore, you agree to be bound by all applicable laws as well as any other applicable rules and conditions. If you do not understand or agree with these terms and conditions you must stop accessing and using the LVA Website.</p>
                        <h4><strong>2. Scope of right to use</strong></h4>
                        <p><strong>2.1</strong> All text footage, pictures, graphics, software, applications, sound samples, animations, videos, corporate logos and brands, the layout of the LVA Website, as well as other content contained in the LVA Website, hereinafter jointly referred to as “LVA Website Content”, are protected by trademark and copyright law and other laws for the protection of intellectual property and may be subject to third parties’ rights.</p>
                        <p><strong>2.2</strong> Linde GmbH does not grant any license rights or other rights regarding the use of the intellectual property rights in the LVA Website Content, unless otherwise specified herein. The copying, distributing, reproducing, forwarding, otherwise using of these intellectual property rights in the LVA Website or the LVA Website Content including the making available to third parties is not permitted without the prior written consent of Linde GmbH.</p>
                        <p><strong>2.3</strong> The user is only permitted to view and use the LVA Website Content for his own personal, non-commercial use as part of his use of the LVA Website. The user is also permitted to produce a single copy of the LVA Website for his personal, non-commercial use, on the condition that the content is not altered in the course of copying and all references to intellectual property rights are retained in the copy, or inserted if only extracts from the LVA Website are used.</p>
                        <p><strong>2.4</strong> The content of press releases and other documents classified as public may be used in public communications save the condition that the permission granted hereunder does not apply to those parts of public documents which are protected by trademark or copyright law, or other legal regulations. Any use of such material is made in the sole responsibility of the user.</p>
                        <p><strong>2.5</strong> Access to or use of the LVA Website or the LVA Website Content from countries, where this access or this use is unlawful, is not permitted. In this case, and if the user wants to enter into business relations with a Linde company, the user should contact representatives of such Linde company in the respective country.</p>
                        <p><strong>2.6</strong> Any other use of the LVA Website as well as the LVA Website Content is prohibited unless specifically permitted by these Terms and Conditions.</p>
                        <h4><strong>3. Restrictions of Use</strong></h4>
                        <p><strong>3.1</strong> The user must in particular not, without the prior written consent of Linde GmbH,</p>
                        <ul><li>use the LVA Website or the LVA Website Content for any fraudulent or unlawful purpose;</li>
                            <li>use any registered or unregistered trademarks or signs on the LVA Website or in the LVA Website Content without the prior written consent of the respective owner;</li>
                            <li>translate, modify, adapt, reverse engineer, decompile or disassemble the LVA Website or LVA Website Content in whole or in part;</li><li>remove any notice of copyright, trademark, or other proprietary rights from the LVA Website Content;</li>
                            <li>frame or otherwise embed LVA Website Content in your or a third party website;</li>
                            <li>mirror any part of the LVA Website;</li>
                            <li>create a database by systematically storing and downloading all or any LVA Website Content; or</li>
                            <li>use any robot, spider, site search application or retrieval application or other manual or automatic device to retrieve, index, &#8220;scrape,&#8221; &#8220;data mine&#8221; or in any way reproduce or circumvent the navigational structure or presentation of the LVA Website or the LVA Website Content.</li>
                        </ul>
                        <p><strong>3.2</strong> Linde GmbH reserves the right to deny access to the LVA Website at any time.</p>
                        <h4><strong>4. Privacy</strong></h4>
                        <p>All personal data is collected, stored, processed and used in accordance with applicable data protection laws, the LVA Website Privacy Policy and the LVA Website Cookie Policy.</p>
                        <h4><strong>5. No Warranties</strong></h4>
                        <p><strong>5.1</strong> Linde GmbH does not give any express or implied warranties, nor does it make any representations, nor does it assume any responsibility that</p>
                        <ul><li>the LVA Website or the LVA Website Content are available without interruption;</li>
                            <li>the use of the LVA Website or the LVA Website Content will not infringe any third party rights;</li>
                            <li>the LVA Website as well as the LVA Website Content are free from errors or are complete, accurate or up-to-date at all times;</li>
                            <li>other web pages accessed by hyperlinks from the LVA Website, irrespective of whether these belong to affiliated companies of Linde GmbH or third parties, do not infringe third party rights, or are free from errors, or that applicable data protection provisions are complied with; or that</li>
                            <li>the LVA Website is free from viruses, bugs, malware, or similar malicious programs.</li>
                        </ul>
                        <p><strong>5.2</strong> Any forward-looking statements made on the LVA Website are based on the beliefs and judgement of the management of Linde GmbH at the time of their publication and are therefore subject to risks and uncertainties. No assurance is given that these forward-looking statements will prove to be accurate and complete. Linde GmbH is under no obligation towards the user to update such forward-looking statements and accepts no liability whatsoever for these statements.</p>
                        <h4><strong>6. Limitation of Liability</strong></h4>
                        <p><strong>6.1</strong> Linde GmbH does not accept any liability for:</p>
                        <ul><li>defects in rights regarding the LVA Website or LVA Website Content;</li>
                            <li>any loss or damage arising as a result of inhibited access or non-accessibility of the LVA Website as well as LVA Website Content;</li>
                            <li>any loss or damage, which may arise from the use of the LVA Website or of any of the LVA Website Content; or</li>
                            <li>the availability of the content of third party websites or any loss or damage resulting from their use, regardless of the purpose of use,</li>
                        </ul>
                        <p>whereas Linde GmbH does not limit its liability under statutory law for the violation of life, body or health caused by willful intent or gross negligence of Linde GmbH or its representatives.</p>
                        <p><strong>6.2</strong> The LVA Website and the LVA Website Content are operated and managed by Linde GmbH and / or other Linde companies. Save the provisions herein, the LVA Website and the LVA Website Content take into account the requirements of the country, in which the Linde company in charge is based. Linde GmbH accepts no responsibility if (i)the LVA Website is accessed, or (ii) if the LVA Website Content is used or downloaded from the LVA Website, in places outside the country concerned. When the user accesses the LVA Website from places outside the country concerned, the user is solely responsible for complying with the regulations that apply in accordance with their respective national laws.</p>
                        <h4><strong>7. Amendments to the LVA Website or the LVA Website Content or Terms and Conditions</strong>
                        </h4><p><strong>7.1</strong> Linde GmbH reserves the right, at any time and without prior notice, to make amendments, or additions, to the LVA Website or the LVA Website Content.</p>
                        <p><strong>7.2</strong> Linde GmbH reserves the right to change these terms and conditions of use from time to time at its sole discretion without any obligation to inform the user. The user agrees that his continued use of the LVA Website constitutes his consent to respective changes of the terms and conditions as applicable.</p>
                        <h4><strong>8. Violation of these Terms and Conditions</strong></h4>
                        <p><strong>8.1</strong> Linde GmbH reserves the right to deny the user’s access to the LVA Website in whole or in part at any time in case of the user’s non-compliance with these Terms and Conditions or the user’s infringement of the rights of Linde GmbH in the LVA Website or the LVA Website Content or any third parties’ rights.</p>
                        <p><strong>8.2</strong> Furthermore, the user herewith indemnifies and holds harmless Linde GmbH from and against all claims and damages arising out of or in connection with (i) the use of, or activities in connection with, the LVA Website and LVA Website Content, or (ii) any violation of these Terms and Conditions by the user.</p>
                        <h4><strong>9. Applicable Law</strong></h4>
                        <p>These Terms and Conditions and all questions and disputes relating to the use of the LVA Website or the LVA Website Content are exclusively subject to the laws of the Federal Republic of Germany excluding the provisions of international private law and the United Nations Convention on Contracts for the International Sales of Goods (CISG).</p>
                        <h4><strong>10. Alternative Dispute Resolution for Consumer Disputes</strong></h4>
                        <p>Linde GmbH does not commit to participate in alternative dispute resolution procedures in front of an alternative dispute resolution entity to resolve disputes with consumers.</p>
                        <p>Pullach, December 2020</p>
                    </div>
                </div>
            </div>
        </div>
    )
}