/**
 *  NOTE: business logic
 *  1) prompt may be required until feedstock is saved 
 *  2) may be save all functionality required
 * 
 */

import React, { Component } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { getFeedstockTypesAvailable } from "../../configuration";
import { FEEDSTOCK_TYPES, IFeedstock } from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import { getFeedstockById, saveNewFeedStock } from "../../services/feedstockServices";
import { modifyFeedstocks, setCurrentFeedstockId, setNewSelectedFeedstockId, updateSignature } from "../../slices/feedstockSlice";
import { Prompt, RouteComponentProps, withRouter } from "react-router-dom";
import { updateModifiedFeedstockInDbForScenario } from "../../slices/scenarioSimulatorSlice";
import { updateModifiedFeedstockInPP } from "../../slices/productPredictorSlice";

interface ISaveFeedstockProps extends RouteComponentProps {
    feedstock: IFeedstock;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        feedstocks: state.feedstock.feedstocks,
        new_selected_feedstock_id: state.feedstock.new_selected_feedstock_id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateSignature: (feedstock_id: string) => dispatch(updateSignature({ feedstock_id })),
        modifyFeedstocks: (newFeedstock: IFeedstock) => dispatch(modifyFeedstocks(newFeedstock)),
        updateModifiedFeedstockInPP: (feedstock_id: string) => dispatch(updateModifiedFeedstockInPP(feedstock_id)),
        updateModifiedFeedstockInDbForScenario: (feedstock_id: string) => dispatch(updateModifiedFeedstockInDbForScenario(feedstock_id)),
        setCurrentFeedstockId: (newFeedstockId: string) => dispatch(setCurrentFeedstockId(newFeedstockId)),
        setNewSelectedFeedstockId: (newFeedstockId: string) => dispatch(setNewSelectedFeedstockId(newFeedstockId))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ISaveFeedstockReduxProps = PropsFromRedux & ISaveFeedstockProps;

type ISaveFeedstockState = {
    showDialog: boolean;
    name: string;
    type: FEEDSTOCK_TYPES;
    message: string;
    location: string;
    signature: string;
    feedstock: IFeedstock;
    showLoading: boolean;
}

class SaveFeedstock extends Component<ISaveFeedstockReduxProps, ISaveFeedstockState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            name: "",
            type: getFeedstockTypesAvailable()[0],
            message: "",
            location: "",
            signature: this.props.feedstock.signature,
            feedstock: this.props.feedstock,
            showLoading: false,
        }
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
            name: "",
            message: ""
        })
    }

    componentDidMount() {
        // TO BE USED IN FEATURE 
        // if (this.props.feedstock.is_display_leave_popup) {
        //     const newFeedstock: IFeedstock = {
        //         ...this.props.feedstock,
        //         is_display_leave_popup: false
        //     }
        //     this.props.modifyFeedstocks(newFeedstock)
        // }
    }

    async handleSaveNewFeedstock() {
        if (this.props.feedstock.signature !== "") {
            this.setState({
                showLoading: true,
            })
            try {
                let save = await saveNewFeedStock(this.props.feedstock);
                if (save.status) {
                    let getNewSavedUpdatedById = await getFeedstockById(this.props.feedstock.id);
                    if (getNewSavedUpdatedById.status) {
                        this.setState({
                            message: save.message,
                            signature: this.props.feedstock.signature,
                            showLoading: false,
                        })
                        let feedstock = getNewSavedUpdatedById.data[0] as IFeedstock;

                        const newFeedstock: IFeedstock = {
                            ...feedstock,
                            is_display_leave_popup: false,
                            is_saved: true,
                        }

                        this.props.modifyFeedstocks(newFeedstock)
                        this.props.updateModifiedFeedstockInDbForScenario(newFeedstock.id)
                        this.props.updateModifiedFeedstockInPP(newFeedstock.id)

                        setTimeout(() => {
                            this.toggleShowDialog();
                            if (!!this.state.location && this.state.location !== "") {
                                this.props.history.push(this.state.location)
                            }
                        }, 0);
                    } else {
                        this.setState({
                            message: "Oops... somthing went to wrong!",
                            showLoading: false,
                        })
                    }
                } else {
                    this.setState({
                        message: "Oops... somthing went to wrong!",
                        showLoading: false,
                    })
                }
            } catch (error) {
                this.setState({
                    message: "Oops... somthing went to wrong!",
                    showLoading: false,
                })
            }
        } else {
            this.setState({
                message: "Please Add component value in feedstock!",
                showLoading: false,
            })
        }

    }

    handleTransition = location => {
        if (this.props.feedstock.signature !== this.state.signature) {
            this.setState({
                location: location.pathname + location.search
            })
            this.toggleShowDialog();
            return false
        } else {
            return true
        }
    };

    handleCancelFeedstockRedirection() {
        this.toggleShowDialog();
        setTimeout(() => {
            this.setState({
                location: "",
            })
            if (this.props.feedstock.is_display_leave_popup) {
                const newFeedstock: IFeedstock = {
                    ...this.props.feedstock,
                }

                this.props.modifyFeedstocks(newFeedstock)
            }
        }, 0);
    }

    async handleLeaveFeedstock() {
        this.toggleShowDialog();
        let getNewSavedUpdatedById = await getFeedstockById(this.props.feedstock.id);
        let feedstock = getNewSavedUpdatedById.data.length > 0 ? getNewSavedUpdatedById.data[0] as IFeedstock : this.state.feedstock;
        const newFeedstock: IFeedstock = {
            ...feedstock,
            is_display_leave_popup: false,
            is_saved: true
        }
        await this.props.modifyFeedstocks(newFeedstock)
        this.setState({
            signature: this.props.feedstock.signature,
            feedstock: this.props.feedstock
        })
        if (this.props.new_selected_feedstock_id !== "") {
            await this.props.setCurrentFeedstockId(this.props.new_selected_feedstock_id)
            setTimeout(async () => {
                this.props.setNewSelectedFeedstockId("");
            }, 10);
        }
        setTimeout(async () => {
            if (!!this.state.location && this.state.location !== "") {
                this.props.history.push(this.state.location)
            }
        }, 0);
    }

    hendalClickOnSavePopup() {
        if (this.props.feedstock.is_display_leave_popup) {
            const newFeedstock: IFeedstock = {
                ...this.props.feedstock,
                is_display_leave_popup: false,
            }
            this.props.modifyFeedstocks(newFeedstock)
        }
        this.toggleShowDialog();
    }

    hendalClickOnCancelbButtonInSavePopup() {
        if (this.state.signature !== this.props.feedstock.signature) {
            const newFeedstock: IFeedstock = {
                ...this.props.feedstock,
                is_display_leave_popup: true,
            }
            this.props.modifyFeedstocks(newFeedstock)
        }
        this.toggleShowDialog();
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    id="saveButton"
                    className={this.props.feedstock.is_display_leave_popup === true ? "button red" : "button"}
                    onClick={() => this.hendalClickOnSavePopup()}
                >
                    Save
                </button>
                <button
                    id="saveFeedstockButtonHidden"
                    type="button"
                    style={{ display: "none" }}
                    onClick={() => this.toggleShowDialog()}
                ></button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    {this.props.feedstock.is_display_leave_popup === false && (
                        <>
                            <DialogTitle id="form-dialog-title">Save this feedstock</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to save this feedstock?
                                </DialogContentText>
                                {!!this.state.message && this.state.message !== "" && (
                                    <Typography variant="body1" component="p" color="secondary">
                                        {this.state.message}
                                    </Typography>
                                )}
                            </DialogContent>


                            <DialogActions>
                                <button className="button" onClick={() => this.hendalClickOnCancelbButtonInSavePopup()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.handleSaveNewFeedstock()} disabled={this.state.showLoading}>
                                    Save {this.state.showLoading === true && (
                                        <Fade
                                            in={this.state.showLoading}
                                            style={{
                                                transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                            }}
                                            unmountOnExit
                                        >
                                            <CircularProgress size={11} />
                                        </Fade>
                                    )}
                                </button>
                            </DialogActions>
                        </>
                    )}
                    {this.props.feedstock.is_display_leave_popup === true && (
                        <>
                            <DialogTitle id="form-dialog-title1">Leave Page</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Do you want to save ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button className="button" onClick={() => this.handleCancelFeedstockRedirection()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.handleSaveNewFeedstock()} >
                                    Yes
                                </button>
                                <button className="button" onClick={() => this.handleLeaveFeedstock()} >
                                    No
                                </button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
                <Prompt when={this.props.feedstock.is_display_leave_popup} message={this.handleTransition} />
            </div >
        );
    }
};

export default connector(withRouter(SaveFeedstock));