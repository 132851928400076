import { ScenariosI } from "../interfaces/scenarios";
import pjson from "../../package.json";

export const graphLayout = {
  type: "scatter",
  mode: "lines",
  margin: {
    l: 30,
    r: 10,
    t: 70,
    b: 80,
  },
  shapes: [
    {
      // grenzwert-linie
      type: "line",
      xref: "paper",
      x0: 0,
      y0: 3.5,
      x1: 1,
      y1: 3.5,
      line: {
        color: "rgb(255, 0, 0)",
        width: 1,
        dash: "dot",
      },
    },
  ],
  showlegend: true,
  textposition: "outside",
  xaxis: {
    tickangle: 20,
    tickformat: "%d.%m - %H:%M",
  },
  legend: { orientation: "v" },
};

export const SPEC_TYPE_VALUES: {} = {
  1: "COT",
  3: "P/E ratio",
  5: "Conversion",
};

export const KEY_COMPONENT_VALUES: {} = {
  C2H6: "Ethane",
  C3H8: "Propane",
  IBUTA: "i-Butane",
  NBUTA: "n-Butane",
  ICS: "i-Pentane",
  NC5: "n-Pentane",
  NC10: "n-Dekan",
  NC15: "n-Pentadekan",
  NC20: "n-Eikosan",
};

export const DCS_VALUES = [
  {
    FROM_DB: false,
    FLOW_HC: 46560,
    COP: 0.075,
    CIT: 620.0,
    DILUT: 0.3,
    SPEC_TYPE: 1,
    KEY_COMPONENT: "NBUTA",
    SPEC: "850",
    TMT: "1090",
    LAVAL_RATIO: "0.9",
    P_XOVER: "0.3",
    DELTA_T: "10",
    RUN_LENGTH_MAX: 60,
    IS_UPDATE_LVF: true,
  },
];

export const USER_VALUES: Array<ScenariosI> = [
  {
    FROM_DB: false,
    id: 1,
    IS_UPDATE_LVF: true,
    ALIAS: "ALIAS",
    FLOW_HC: 46560,
    COP: 0.075,
    CIT: 620.0,
    DILUT: 0.3,
    SPEC_TYPE: 1,
    KEY_COMPONENT: "NBUTA",
    SPEC: "850",
    TMT: 1090,
    LAVAL_RATIO: 0.9,
    P_XOVER: 0.3,
    DELTA_T: "10",
    RUN_LENGTH_MAX: 60,
    CALCULATIONS: [],
    RUN_LENGTH_FINAL: null,
  },
];

export const USER_ROLES_WHITELIST = [
  "ADMIN",
  "SUPERVISOR",
  "ENGINEER",
  "OPERATOR",
];

export const USER_ROLES_BLACKLIST = ["OPERATOR"];

export enum AUTH_TYPE {
  AZURE_AD = "AzureAD",
  PING_ID = "PingID"
}

// process.env.NODE_ENV === "development" means only local development, build versions from Azure use NODE_ENV === "production"

// export const DOCKER_DEPLOYMENT = process.env.NODE_ENV === "development" ? false : process.env.REACT_APP_DOCKER_DEPLOYMENT;

export const METADATA_SERVICE_VERSION = "";

export const SCENARIO_SERVICE_VERSION = "";

export const COMPUTE_SERVICE_VERSION = "";

export const RESULT_SERVICE_VERSION = "";

export const USER_GUIDE_URL =
  process.env.NODE_ENV === "development"
    ? "https://lvf-demo-user-documentation.azurewebsites.net/"
    : process.env.REACT_APP_USER_GUIDE_URL;

export const REACT_APP_APIM_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:5000"
    : process.env.REACT_APP_API_URL;

export const REACT_APP_APIM_URL_METADATA =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:5002/metadata"
    : `${REACT_APP_APIM_URL}/metadata`;

export const REACT_APP_APIM_URL_COMPUTE =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:5000/compute"
    : `${REACT_APP_APIM_URL}/compute`;

export const REACT_APP_APIM_URL_SCENARIO =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:5003/scenario"
    : `${REACT_APP_APIM_URL}/scenario`;

export const REACT_APP_APIM_URL_RESULT =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:5001/communicator"
    : process.env.REACT_APP_API_URL_RESULT;

export const REACT_APP_VERSION =
  process.env.NODE_ENV === "development"
    ? `v${pjson.version} (Development build)`
    : `v${pjson.version} (${process.env.REACT_APP_VERSION})`;

export const REACT_APP_LOGGING =
  process.env.NODE_ENV === "development"
    ? `TRUE`
    : `${process.env.REACT_APP_LOGGING}`;

export const CLIENT_ID =
  process.env.NODE_ENV === "development"
    ? "bb55ee90-170e-41a2-ac08-504e0b52d627"
    : process.env.REACT_APP_CLIENT_ID;

export const RTA_TIME_FREQ =
  process.env.NODE_ENV === "development"
    ? 15
    : process.env.REACT_APP_RTA_TIME_FREQ;

export const TI_TIME_FREQ =
  process.env.NODE_ENV === "development"
    ? 15
    : process.env.REACT_APP_TI_TIME_FREQ;

export const TI_MAX_TEMP =
  process.env.NODE_ENV === "development"
    ? 1150
    : process.env.REACT_APP_TI_MAX_TEMP;

export const TI_MIN_TEMP =
  process.env.NODE_ENV === "development"
    ? 725
    : process.env.REACT_APP_TI_MIN_TEMP;

export const TI_GALLERY_MAX_TEMP =
  process.env.NODE_ENV === "development"
    ? 1100
    : process.env.REACT_APP_TI_GALLERY_MAX_TEMP;

export const TI_GALLERY_MIN_TEMP =
  process.env.NODE_ENV === "development"
    ? 800
    : process.env.REACT_APP_TI_GALLERY_MIN_TEMP;

export const TI_MIN_UPDATED_TEMP = process.env.NODE_ENV === "development" ? 600 : process.env.REACT_APP_TI_MIN_UPDATED_TEMP;

export const TI_MAX_UPDATED_TEMP = process.env.NODE_ENV === "development" ? 1300 : process.env.REACT_APP_TI_MAX_UPDATED_TEMP;

export const TI_SHOW_POLYNOMIAL = false;

export const TI_API_CALL_INTERVAL =
  process.env.NODE_ENV === "development"
    ? 300000
    : process.env.REACT_APP_TI_API_CALL_INTERVAL;

export const AUTHENTICATION_TYPE: string = process.env.NODE_ENV === "development" ? AUTH_TYPE.AZURE_AD : ((process.env.REACT_APP_AUTHENTICATION_TYPE === undefined) ? AUTH_TYPE.AZURE_AD : process.env.REACT_APP_AUTHENTICATION_TYPE);
// export const AUTHENTICATION_TYPE: string = process.env.NODE_ENV === "development" ? AUTH_TYPE.PING_ID : ((process.env.REACT_APP_AUTHENTICATION_TYPE === undefined) ? AUTH_TYPE.AZURE_AD : process.env.REACT_APP_AUTHENTICATION_TYPE);

export const OIDC_AUTHORITY_URL = process.env.NODE_ENV === "development" ? "https://login.microsoftonline.com/f9be925e-f94e-4850-a10d-b459d98a27ca" : process.env.REACT_APP_OIDC_AUTHORITY_URL;

export const OIDC_AUTHORIZATION_ENDPOINT = process.env.NODE_ENV === "development" ? "https://login.microsoftonline.com/f9be925e-f94e-4850-a10d-b459d98a27ca/oauth2/v2.0/authorize" : process.env.REACT_APP_AUTHORIZATION_ENDPOINT;

export const OIDC_TOKEN_ENDPOINT = process.env.NODE_ENV === "development" ? "https://login.microsoftonline.com/f9be925e-f94e-4850-a10d-b459d98a27ca/oauth2/v2.0/token" : process.env.REACT_APP_OIDC_TOKEN_ENDPOINT;

export const OIDC_ISSUER = process.env.NODE_ENV === "development" ? "https://login.microsoftonline.com" : process.env.REACT_APP_OIDC_ISSUER;
