import React from 'react';
import './App.scss';
import Start from './components/start';

function App() {
	return (
		<div className="App">
			<Start />
		</div>
	);
}

export default App;
