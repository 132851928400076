import { REACT_APP_APIM_URL_RESULT } from "../utilities/GlobalConstants";
import store from "..";

/**
 * 
 * @returns 
 */
export async function getAllRolesData() {

    const token = store.getState().authState.token;

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let check = await fetch(`${REACT_APP_APIM_URL_RESULT}/user/getroles`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = data;
                return repsonce;
            })
        .catch(err => {
            repsonce.message = err;
            return repsonce;
        });

    return check
}

/**
 * 
 * @returns 
 */
export async function getAllUsersFromAd() {

    const token = store.getState().authState.token;

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let check = await fetch(`${REACT_APP_APIM_URL_RESULT}/user/getadusers`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = data[0].users;
                return repsonce;
            })
        .catch(err => {
            repsonce.message = err;
            return repsonce;
        });

    return check
}


/**
 * get all user roles from db 
 * @returns 
 */
export async function getAllUserRolesFromDB() {

    const token = store.getState().authState.token;

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let check = await fetch(`${REACT_APP_APIM_URL_RESULT}/user/getusers`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = data;
                return repsonce;
            })
        .catch(err => {
            repsonce.message = err;
            return repsonce;
        });

    return check
}


/**
 * get all user roles from db 
 * @returns 
 */
export async function getUserById(id: number) {
    const token = store.getState().authState.token;

    const repsonce = {
        status: false,
        message: "",
        data: {}
    }
    let check = await fetch(`${REACT_APP_APIM_URL_RESULT}/user/getuserbyid/${id}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = data;
                return repsonce;
            })
        .catch(err => {
            repsonce.message = err;
            return repsonce;
        });

    return check
}


/**
 * save user  
 * @returns 
 */
export async function saveUser(data: any) {
    const token = store.getState().authState.token;

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let check = await fetch(`${REACT_APP_APIM_URL_RESULT}/user/saveuser`, {
        method: "POST",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = data;
                return repsonce;
            })
        .catch(err => {
            repsonce.message = err;
            return repsonce;
        });

    return check
}


/**
 * delete user  
 * @returns 
 */
export async function deleteUserById(idObj: any) {

    const token = store.getState().authState.token;

    const response = {
        status: false,
        message: "",
        id: idObj.id <= 0 ? 0 : idObj.id
    }

    let check = await fetch(`${REACT_APP_APIM_URL_RESULT}/user/deleteuser`, {
        method: "DELETE",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(idObj),
    })
        .then((res) => res.json())
        .then(
            () => {
                response.status = true;
                response.message = "success";
                return response;
            })
        .catch(err => {
            response.message = err;
            return response;
        });

    return check
}