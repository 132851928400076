import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IFeedstock } from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import { loadFeedstockData } from "../../services/feedstockServices";
import { loadAllFeedStockData } from "../../slices/feedstockSlice";
import LoadAutocomplete from "../common/LoadAutocomplete"


interface ILoadFeedstockProps {
}

const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadAllFeedStockData: (data: any) => dispatch(loadAllFeedStockData(data))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ILoadFeedstockReduxProps = PropsFromRedux & ILoadFeedstockProps;

type ILoadFeedstockState = {
    showDialog: boolean;
    selected: any[];
    allFeedStockData: any[];
    isAllSelected: boolean;
    showLoading: boolean;
}

class LoadFeedstock extends Component<ILoadFeedstockReduxProps, ILoadFeedstockState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            selected: [],
            allFeedStockData: [],
            isAllSelected: false,
            showLoading: false
        }
    }

    async componentDidMount() {
        await this.loadFeedstockDataInit();
    }

    async loadFeedstockDataInit() {
        this.setState({
            showLoading: true
        })
        let setAllFeedStockData = await loadFeedstockData();
        // TO BE USED IN FUTURE
        // let setAll = [{
        //     ...getDefaultFeedstock(),
        //     id: "-1",
        //     name: "All"
        // }];

        if (!!setAllFeedStockData && !!setAllFeedStockData.data && setAllFeedStockData.data.length > 0) {
            setAllFeedStockData.data.forEach((feedStockValue: IFeedstock) => {
                feedStockValue['is_saved'] = true;
                feedStockValue['is_display_leave_popup'] = false;
            })
        }
        if (setAllFeedStockData.status === true) {
            // TO BE USED IN FUTURE
            // let setAllFeedStockDataNew = [...setAll, ...setAllFeedStockData.data]
            let setAllFeedStockDataNew = [...setAllFeedStockData.data]
            this.setState({
                showLoading: false,
                allFeedStockData: setAllFeedStockDataNew
            })
        } else {
            this.setState({
                showLoading: false
            })
        }
    }


    toggleShowDialog = async () => {
        await this.setState({
            showDialog: !this.state.showDialog,
        })

        if (this.state.showDialog) {
            await this.loadFeedstockDataInit();
        }
    }

    loadFeedstock = async () => {
        let isAllSelected = this.state.selected.some(el => el.name.toLowerCase() === "all");
        if (isAllSelected) {
            let removeAllFromArray = this.state.allFeedStockData.filter(feedstock => feedstock.name.toLowerCase() !== "all");
            let filterData = removeAllFromArray.filter(feedstock => {
                return this.props.feedstocks.filter(el => {
                    return el.id === feedstock.id;
                }).length === 0
            });
            this.props.loadAllFeedStockData(filterData)
            this.setState({
                selected: []
            });
        } else {
            this.props.loadAllFeedStockData(this.state.selected);
            this.setState({
                selected: []
            });
        }
        this.toggleShowDialog()
    }

    selectedDataSet = (valueData) => {
        this.setState({
            selected: valueData
        })
    }

    render() {
        return (
            <>
                <LoadAutocomplete
                    showDialog={this.state.showDialog}
                    selected={this.state.selected}
                    allData={this.state.allFeedStockData}
                    isAllSelected={this.state.isAllSelected}
                    showLoading={this.state.showLoading}
                    componentName={'Feedstock'}
                    disableData={this.props.feedstocks}
                    toggleShowDialog={this.toggleShowDialog}
                    loadFeedstock={this.loadFeedstock}
                    selectedDataSet={this.selectedDataSet}

                ></LoadAutocomplete>
            </>
        );
    }

};

export default connector(LoadFeedstock);