import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import ChartSwitcher from "./chartSwitcher";
import OverviewTable from "../coil/overviewTable";
import SettingsTable from "../coil/settingsTable";
import Timechart from "../timechart";
import { RootState } from "../../reducers/rootReducer";
import Loader from "react-spinners/ClipLoader";
import {
  generateCoilDynamicsData,
  generateDetailTimechartData,
} from "../../utilities/coilModelShapers";
import SummaryTable from "./summaryTable";
import { CoilObjectI, ToFixedI } from "../../interfaces/coil";
import PlantSelector from "../common/PlantSelector";
import { getNumberOfPlants } from "../../configuration";
import FurnaceSelector from "../common/FurnaceSelector";
import ScenarioRunLengthSelector from "../common/ScenarioRunLengthSelector";
import { MODE } from "../../interfaces/IScenario";
import FireboxSelector from "../common/FireboxSelector";
import { isRTA } from "../../utilities/helperFunctions";

export const COIL_DYNAMICS_DASHBOARD_ID = "_COIL_DYNAMICS";

interface ICoilDynamicsProps { }

const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios,
    currentScenarioFireboxId: state.scenarioSimulator.currentScenarioFireboxId,
    currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA
  };
};

const mapDispatchToProps = () => {
  return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICoilDynamicsReduxProps = PropsFromRedux & ICoilDynamicsProps;

const CoilDynamics: React.SFC<ICoilDynamicsReduxProps> = ({ scenarios, currentScenarioFireboxId, currentScenarioIsRTA }) => {
  const { referencePositionIndex } = useSelector((state: RootState) => state.coilState);
  const selectedScenario: number = 0;

  /**
   * Data used for primary chart
   */

  const [data, setData] = useState({
    id: 0,
    name: "",
    data: [],
  } as CoilObjectI);

  /**
   * Data for secondary bottom chart
   */

  const [detailData, setDetailData] = useState({
    id: 0,
    name: "",
    data: [],
  } as CoilObjectI);
  const [currentView, setCurrentView] = React.useState('MAX');
  /**
   * Get new data whenever scenario data or selected scenario changes
   */

  useEffect(() => {
    setData(generateCoilDynamicsData(scenarios, selectedScenario, currentScenarioFireboxId, currentView));
    setDetailData(generateDetailTimechartData("COKETHICKNESS", scenarios, selectedScenario, referencePositionIndex, false,
      currentScenarioFireboxId, currentView));
  }, [scenarios, selectedScenario, referencePositionIndex, currentView, currentScenarioFireboxId]);
  const [firstLoad, setFirstLoad] = useState(true);

  const dispatch = useDispatch();
  /**
   * Resets reference line on first load
   */

  const [filtereddata, setFilteredData] = useState([]);
  useEffect(() => {
    if (data.data.length > 0 && firstLoad) {
      dispatch({
        type: "UPDATE_REFERENCE_POSITION",
        payload: {
          value:
            (data.data.length - 1) *
            parseFloat(
              scenarios ? String(scenarios[selectedScenario]?.INTERVAL) : "1"
            ),
        },
      });
      dispatch({
        type: "UPDATE_REFERENCE_POSITION_INDEX",
        payload: { value: data.data.length - 1 },
      });
      setFirstLoad(false);
    }
    if (currentScenarioIsRTA)
      filterData(data.data, currentView);
  }, [firstLoad, dispatch, scenarios, data, selectedScenario, currentView, currentScenarioIsRTA]);

  /**
   * Empty handler, no need to do any extra tasks on scenario change
   */
  const filterData = (data, day) => {
    let filter_data = data;
    let days = scenarios && scenarios[0]?.Days;
    let day1Index = days?.lastIndexOf('1') === -1 ? 0 : days && days.lastIndexOf('1') + 1;
    let day5Index = days?.lastIndexOf('5') === -1 ? day1Index : days && days.lastIndexOf('5') + 1;
    if ((day === "1" && !days?.includes('1')) || (day === "5" && !days?.includes('5'))) {
      day = 'MAX'
      setCurrentView('MAX');
    }
    filter_data = day === "1" ? filter_data?.slice(0, day1Index) : (day === "5" ? filter_data?.slice(day1Index, day5Index) : filter_data?.slice(day5Index))
    setFilteredData(filter_data)
  }
  const setDayView = (newDayView) => {
    setCurrentView(newDayView);
    filterData(data.data, newDayView);
  };
  const handleScenarioChange = (newId) => {
    dispatch({
      type: "UPDATE_REFERENCE_POSITION",
      payload: {
        value:
          (data.data.length - 1) *
          parseFloat(
            scenarios ? String(scenarios[selectedScenario]?.INTERVAL) : "1"
          ),
      },
    });
    dispatch({
      type: "UPDATE_REFERENCE_POSITION_INDEX",
      payload: { value: data.data.length - 1 },
    });
  };

  const [keys, setKeys] = useState(currentScenarioIsRTA ? ["ETHYLENE", "FORECAST"] : ["ETHYLENE"]);
  const [name, setName] = useState("Ethylene Content");
  const [nameLong, setNameLong] = useState("Ethylene Content");
  const [unit, setUnit] = useState("(%)");
  const [tofixed, setToFixed] = useState(2);
  const [y_axis_adjustment, setYAxisAdjustment] = useState([2, false]);
  const [loader, setLoader] = React.useState(false)
  const setLoading = (val) => {
    setLoader(val);
  }
  /**
   * Resets reference line on first load
   */

  useEffect(() => {
    if (data.data.length > 0 && firstLoad) {
      dispatch({
        type: "UPDATE_REFERENCE_POSITION",
        payload: {
          value:
            (data.data.length - 1) *
            parseFloat(
              scenarios ? String(scenarios[selectedScenario]?.INTERVAL) : "1"
            ),
        },
      });
      dispatch({
        type: "UPDATE_REFERENCE_POSITION_INDEX",
        payload: { value: data.data.length - 1 },
      });
      setFirstLoad(false);
    }
  }, [data, dispatch, firstLoad, scenarios, selectedScenario]);

  /**
   * Whenever new option is selected, assign those values to relevant variables which
   * then refreshes other components with correct data
   * @param newMenuItem {object} Object with new selected menu item keys, name, name_long and unit data
   */

  const handleMenuItemChange = (newMenuItem) => {
    currentScenarioIsRTA ? setKeys([...newMenuItem.keys, "FORECAST"]) : setKeys(newMenuItem.keys);
    setName(newMenuItem.name);
    setNameLong(newMenuItem.name_long);
    setUnit(`(${newMenuItem.unit})`);
    setToFixed(newMenuItem.tofixed);
    setYAxisAdjustment(newMenuItem.y_axis_adjustment);
  };

  /**
   * Define decimal point for overview table
   * mention key and decimal point to display
   */
  let ToFixedOverViewTable: ToFixedI = {
    runtime: currentScenarioIsRTA && currentView === "1" ? 2 : 1,
    [keys[0]]: tofixed,
  };

  if (keys[0] === "COKETHICKNESS") {
    return (
      <React.Fragment>
        {scenarios && scenarios?.length === 0 && !isRTA ? <div>
          <p>Please run scenario simulator.</p>
        </div> :
          <Box display="flex" flexDirection="row">
            <Box mx={1} width="25%">
              <Box display="flex" flexDirection="column">
                <Box mx={1}>
                  <PlantSelector disabled={getNumberOfPlants() === 1} />
                  <FurnaceSelector disabled={false} setLoading={(val) => setLoading(val)} />
                  {currentScenarioIsRTA || (scenarios && scenarios[selectedScenario].mode === MODE.HYBRID) ? <FireboxSelector setLoading={(val) => setLoading(val)} /> : <></>}
                  <ScenarioRunLengthSelector
                    disabled={false}
                    onScenarioChange={(newId) => handleScenarioChange(newId)}
                  />
                  <ChartSwitcher
                    onChange={(newMenuItem) => handleMenuItemChange(newMenuItem)}
                    defaultSelection="Coke Thickness"
                  />
                </Box>
                <Box mt={2}>
                  {" "}
                  {!loader ?
                    <OverviewTable
                      currentView={currentView}
                      data={currentScenarioIsRTA ? filtereddata : data.data}
                      keys={["runtime", `DESIGN`, ...keys]}
                      headers={["Run Length (Days)", `DCS ${unit}`, `LVF ${unit}`]}
                      id="overview-table-one-third"
                      className="l-table-overview-cot-cd"
                      ToFixed={ToFixedOverViewTable}
                    /> : <></>}
                </Box>
                <Box mt={2}>
                  <SummaryTable data={currentScenarioIsRTA ? filtereddata : data.data} />
                </Box>
                <Box mt={2}>
                  <SettingsTable />
                </Box>
              </Box>
            </Box>
            <Box mx={1} width="65%" bgcolor="lindeLightGrey">
              <Box display="flex" flexDirection="column">
                <Box mx={1}>
                  {loader ?
                    <Box width="100%">
                      <div className="loader-grid-parent">
                        <div className="loader-grid">
                          <Loader />
                          <p> Loading...</p>
                        </div>
                      </div>
                    </Box>
                    :
                    <Timechart
                      data={currentScenarioIsRTA ? filtereddata : data.data}
                      keys={currentScenarioIsRTA ? [...keys, "FORECAST"] : keys}
                      labels={["Run Length (Days)", `${name} ${unit}`]}
                      key={data.id + "TC0"}
                      scenarioId={data.id}
                      height={375}
                      referenceLine
                      finalRunlength={
                        scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL
                      }
                      ToFixed={tofixed}
                      limitRange={true}
                      y_axis_adjustment_data={y_axis_adjustment}
                      currentView={currentView}
                      dayViewChange={(newVal) => setDayView(newVal)}
                    />
                  }
                </Box>
                <Box mx={1}>
                  {!loader ?
                    <Timechart
                      data={detailData.data}
                      keys={["COKETHICKNESS"]}
                      labels={["Coil Length (%)", `${name} ${unit}`]}
                      key={data.id + "TC1"}
                      scenarioId={data.id}
                      height={375}
                      ToFixed={tofixed}
                      hideChartTooltip={true}
                    /> : <></>}
                </Box>
              </Box>
            </Box>
            <Box mx={1} width="15%">
              {!loader ?
                <OverviewTable
                  data={detailData.data}
                  id="overview-table-full"
                  keys={["runtime", "COKETHICKNESS"]}
                  headers={["Coil Length (%)", `${name} ${unit}`]}
                  disableHighlight={true}
                  className="l-table-detaildata"
                  ToFixed={ToFixedOverViewTable}
                /> : <></>}
            </Box>
          </Box>
        }
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {scenarios && scenarios.length === 0 && !isRTA ? <div>
          <p>Please run scenario simulaor.</p>
        </div> :
          <>
            {loader ? <div className="loader-grid-parent loader-overlay">
              <div className="loader-grid">
                <Loader />
              </div>
            </div> : null}
            <Box display="flex" className={loader ? "chartGridContainerLoader" : ""}>
              <Box mx={2} flexShrink={2}>
                <Box mb={2} width="100%">
                  <PlantSelector disabled={getNumberOfPlants() === 1} />
                  <FurnaceSelector disabled={false} setLoading={(val) => setLoading(val)} />
                  {currentScenarioIsRTA || (scenarios && scenarios[selectedScenario].mode === MODE.HYBRID) ? <FireboxSelector setLoading={(val) => setLoading(val)} /> : <></>}
                  <ScenarioRunLengthSelector
                    disabled={false}
                    onScenarioChange={(newId) => handleScenarioChange(newId)}
                  />
                  <ChartSwitcher
                    onChange={(newMenuItem) => handleMenuItemChange(newMenuItem)}
                    defaultSelection={nameLong}
                  />
                </Box>
                <Box width="100%">


                  <OverviewTable
                    data={currentScenarioIsRTA ? filtereddata : data.data}
                    keys={["runtime", `DESIGN`, ...keys]}
                    headers={["Run Length (Days)", `DCS ${unit}`, `LVF ${unit}`]}
                    id="overview-table-full"
                    className="l-table-overview-cot-cd"
                    ToFixed={ToFixedOverViewTable}
                  />

                </Box>
              </Box>
              <Box width="100%" flexShrink={1}>
                <Box>

                  <Timechart
                    data={currentScenarioIsRTA ? filtereddata : data.data}
                    keys={currentScenarioIsRTA ? [...keys, "FORECAST"] : keys}
                    labels={["Run Length (Days)", `${name} ${unit}`]}
                    key={data.id}
                    scenarioId={data.id}
                    height={525}
                    referenceLine
                    finalRunlength={scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL}
                    limitRange={true}
                    ToFixed={tofixed}
                    y_axis_adjustment_data={y_axis_adjustment}
                    currentView={currentView}
                    dayViewChange={(newVal) => setDayView(newVal)}
                  />

                </Box>
                <Box mx={2}>
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{
                      paddingTop: 5,
                      paddingLeft: 50,
                      paddingRight: 5,
                      paddingBottom: 5,
                    }}
                  >
                    <Grid xs={6} item>
                      <Grid item={true}>

                        <SummaryTable data={currentScenarioIsRTA ? filtereddata : data.data} />

                      </Grid>
                    </Grid>
                    <Grid xs={4} item={true}>

                      <SettingsTable />

                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </>
        }
      </React.Fragment>
    );
  }
};

export default connector(CoilDynamics);
