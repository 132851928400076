import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdminUser, IUserRolesResponse, IAdUsersResponse, ICurrentUserRoleState } from "../interfaces/IadminPanel";

  
export type IAdminPanelSlice = {
    adminPanelUsers: IAdminUser[],
    userRolesResponse: IUserRolesResponse,
    adUsersResponse: IAdUsersResponse
    currentUserRoleState: ICurrentUserRoleState,
}

const initialState: IAdminPanelSlice = {
    adminPanelUsers: [],
    userRolesResponse: {
        status: false,
        message: "",
        data: []
    },
    adUsersResponse: {
        status: false,
        message: "",
        data: []
    },
    currentUserRoleState: {
        roleIndex: 0,
        role: "",
        displayName: ""
    },
}

export const adminPanelSlice = createSlice({
    name: 'adminPanelSlice',
    initialState: initialState,
    reducers: {
        addCurrentUserRole: (state, action: PayloadAction<ICurrentUserRoleState>) => {
            // state.adminPanelUsers.push(action.payload);
            state.currentUserRoleState = action.payload
        },
        resetCurrentUserRole: (state) => {
            // state.adminPanelUsers.push(action.payload);
            state.currentUserRoleState = initialState.currentUserRoleState
        }, 
        addUser: (state, action: PayloadAction<IAdminUser>) => {
            state.adminPanelUsers.push(action.payload);
        },
        editUser: (state, action: PayloadAction<IAdminUser>) => {
            let idx: number = state.adminPanelUsers.findIndex(x => x.id === action.payload.id);
            state.adminPanelUsers[idx] = action.payload
        },
        deleteUser: (state, action: PayloadAction<number>) => {
            state.adminPanelUsers = state.adminPanelUsers.filter(x => x.id !== action.payload);
        },
        loadAllUsers: (state, action: PayloadAction<IAdminUser[]>) => {
            state.adminPanelUsers = action.payload
        },
        loadAllRoles: (state, action: PayloadAction<IUserRolesResponse>) => {
            state.userRolesResponse = action.payload
        },
        loadAllAdUsers: (state, action: PayloadAction<IAdUsersResponse>) => {
            state.adUsersResponse = action.payload
        }
    }
})

export const {
    addCurrentUserRole,
    resetCurrentUserRole,
    addUser,
    editUser,
    deleteUser,
    loadAllUsers,
    loadAllRoles,
    loadAllAdUsers
} = adminPanelSlice.actions


export default adminPanelSlice.reducer
