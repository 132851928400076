import React, { Component } from "react";
import { MenuItem, Select, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { getDefaultFeedstock, IFeedstock } from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import { setCurrentFeedstockId, setNewSelectedFeedstockId } from "../../slices/feedstockSlice";

interface IFeedstockSelectorProps {
    disabled?: boolean;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFeedstockId: state.feedstock.current_feedstock_id,
        feedstocks: state.feedstock.feedstocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentFeedstockId: (newFeedstockId: string) => dispatch(setCurrentFeedstockId(newFeedstockId)),
        setNewSelectedFeedstockId: (newFeedstockId: string) => dispatch(setNewSelectedFeedstockId(newFeedstockId))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFeedstockSelectorReduxProps = PropsFromRedux & IFeedstockSelectorProps;

class FeedstockSelector extends Component<IFeedstockSelectorReduxProps, {}>{

    getSelectedFeedstock(): IFeedstock {
        const feedstock_index = this.props.feedstocks.findIndex(feedstock => feedstock.id === this.props.currentFeedstockId)
        if (feedstock_index === -1) {
            return {
                ...getDefaultFeedstock(),
                id: "-1",
                name: "Please select feedstock",
            }
        } else {
            return this.props.feedstocks[feedstock_index]
        }
    }

    handleSelection(event) {
        const feedstock_index = this.props.feedstocks.findIndex(feedstock => feedstock.id === this.props.currentFeedstockId)
        if (feedstock_index > -1) {
            if (this.props.feedstocks[feedstock_index].is_display_leave_popup) {
                document.getElementById("saveFeedstockButtonHidden")?.click()
                const new_feedstock_id = event.target.value;
                this.props.setNewSelectedFeedstockId(new_feedstock_id);
            } else {
                const new_feedstock_id = event.target.value;
                this.props.setCurrentFeedstockId(new_feedstock_id);
            }
        } else {
            const new_feedstock_id = event.target.value;
            this.props.setCurrentFeedstockId(new_feedstock_id);
        }
    }

    render() {
        return (
            <div className="control-header element">
                <div className="container-typography">
                    <Typography>Feedstock: </Typography>
                </div>
                <Select disabled={this.props.feedstocks.length === 0} autoWidth value={this.props.feedstocks.length === 0 ? "No feedstock defined" : this.getSelectedFeedstock().id} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.props.feedstocks.length === 0 ?
                        <MenuItem key={`FEEDSTOCK-SELECT-NONE`} value={"No feedstock defined"}>
                            {"No feedstock defined"}
                        </MenuItem> : <MenuItem key={`FEEDSTOCK-SELECT-NONE`} value={"-1"}>
                            {"Please select feedstock"}
                        </MenuItem>}
                    {this.props.feedstocks.map((feedstock, i) => (
                        <MenuItem key={`FEEDSTOCK-SELECT-${feedstock.name}`} value={feedstock.id}>
                            {feedstock.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }

};

export default connector(FeedstockSelector);