export type AppState = {
  pageTitle: string;
};

const initState: AppState = {
  pageTitle: "Home",
};

/**
 * App state reducer handles state management for dynamic page title
 */

const appStateReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_PAGE_TITLE":
      return {
        ...state,
        pageTitle: action.payload.value,
      };
    case "RESET_APP_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};
export default appStateReducer;
