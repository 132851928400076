import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ICameraStatusSlice = {
    furnaceName: string,
    cameraName: string,
    cameraAllStatus: {}
}

const initialState: ICameraStatusSlice = {
    furnaceName: "",
    cameraName: "",
    cameraAllStatus: {}
}

export const CameraStatusSlice = createSlice({
    name: "CameraStatusSlice",
    initialState: initialState,
    reducers: {
        setFurnaceNameForCameraStatus: (state, action: PayloadAction<string>) => {
            state.furnaceName = action.payload
        },
        setCameraNameForCameraStatus: (state, action: PayloadAction<string>) => {
            state.cameraName = action.payload
        },
        setAllCameraStatus: (state, action: PayloadAction<{}>) => {
            state.cameraAllStatus = action.payload
        },
        resetCameraStatusSlice: (state) => {
            state.furnaceName = "";
            state.cameraName = "";
        },
    }
});

export const {
    setFurnaceNameForCameraStatus,
    setCameraNameForCameraStatus,
    setAllCameraStatus,
    resetCameraStatusSlice
} = CameraStatusSlice.actions


export default CameraStatusSlice.reducer
