import React, { Component } from "react";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { getFeedstockTypesAvailable } from "../../configuration";
import { FEEDSTOCK_TYPES, IFeedstock } from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import { checkFeedStockName } from "../../services/feedstockServices";
import { modifyFeedstocks } from "../../slices/feedstockSlice";
import { getSignature } from "../../utilities/helperFunctions";


interface IModifyFeedstockProps {
    feedstock: IFeedstock;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        feedstocks: state.feedstock.feedstocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        modifyFeedstocks: (newFeedstock: IFeedstock) => dispatch(modifyFeedstocks(newFeedstock)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IModifyFeedstockReduxProps = PropsFromRedux & IModifyFeedstockProps;

type IModifyFeedstockState = {
    showDialog: boolean;
    name: string;
    type: FEEDSTOCK_TYPES;
    isPublic: boolean;
    isNameAvailable: boolean;
    nameErrorMessage: string;
    showLoading: boolean;
}


class ModifyFeedstock extends Component<IModifyFeedstockReduxProps, IModifyFeedstockState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            name: this.props.feedstock.name,
            type: this.props.feedstock.type,
            isPublic: this.props.feedstock.is_public ? this.props.feedstock.is_public : false,
            isNameAvailable: false,
            nameErrorMessage: "",
            showLoading: false,
        }
    }

    toggleShowDialog() {
        let getName = this.props.feedstock.is_public === true ? this.props.feedstock.name.replace('(PUBLIC)', '') : this.props.feedstock.name;
        this.setState({
            showDialog: !this.state.showDialog,
            name: getName,
            type: this.props.feedstock.type,
            isPublic: this.props.feedstock.is_public ? this.props.feedstock.is_public : false,
            isNameAvailable: false,
            nameErrorMessage: ""
        })
    }

    handleNameInput(event) {
        this.setState({
            name: event.target.value.toUpperCase()
        })
    }

    handleChangeIsPublic(event) {
        this.setState({
            isPublic: !this.state.isPublic
        })
    }


    async handleModifyNewFeedstock() {
        if (!!this.state.name?.trim()) {
            this.setState({
                showLoading: true,
            })

            let setName = this.state.isPublic === true ? this.state.name.trim() + "(PUBLIC)" : this.state.name.trim();

            const newFeedstock: IFeedstock = {
                id: this.props.feedstock.id,
                name: setName,
                unit: this.props.feedstock.unit,
                type: this.props.feedstock.type,
                feedstock: this.props.feedstock.feedstock,
                feed_type_id: this.props.feedstock.feed_type_id,
                status: this.props.feedstock.status,
                signature: this.props.feedstock.signature,
                error_message: "",
                is_public: this.state.isPublic,
                is_display_leave_popup: true,
                is_saved: false
            }

            try {
                let isNameInRedux = this.props.feedstocks.some(feedstock => feedstock.name === newFeedstock.name);
                if (isNameInRedux) {
                    this.setState({
                        isNameAvailable: true,
                        nameErrorMessage: "Name already exist!",
                        showLoading: false,
                    })
                } else {
                    let isNameAvailable = await checkFeedStockName(newFeedstock);

                    if (isNameAvailable.status) {
                        this.setState({
                            isNameAvailable: true,
                            nameErrorMessage: !!isNameAvailable.message ? isNameAvailable.message : "Name already exist!",
                            showLoading: false,
                        })
                    } else {
                        newFeedstock.signature = getSignature(newFeedstock);
                        this.props.modifyFeedstocks(newFeedstock);
                        this.toggleShowDialog();
                        this.setState({
                            showLoading: false,
                        })
                    }
                }

            } catch (error) {
                // Api error hendaling 
                this.setState({
                    isNameAvailable: true,
                    nameErrorMessage: "Oops somthing went to wrong!",
                    showLoading: false,
                })
            }

        } else {
            this.setState({
                isNameAvailable: true,
                nameErrorMessage: "Please enter a name!"
            })
        }
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                >
                    Rename
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Modify this feedstock</DialogTitle>
                    <DialogContent className="hight-220">
                        <Typography component={'span'} variant={'body2'}>
                            To modify this feedstock, please provide the following information:
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Name*"
                                        type="text"
                                        fullWidth
                                        defaultValue={this.state.name}
                                        value={this.state.name}
                                        onChange={(event) => this.handleNameInput(event)}
                                        helperText={this.state.nameErrorMessage}
                                        error={this.state.isNameAvailable}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        id={"type"}
                                        select
                                        label={"Type"}
                                        defaultValue={this.state.type}
                                        disabled={true}
                                    >
                                        {getFeedstockTypesAvailable().map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.isPublic}
                                                onChange={this.handleChangeIsPublic.bind(this)}
                                                name="isPublic"
                                                value={this.state.isPublic}
                                                color="primary"
                                                disabled={true}
                                            />
                                        }
                                        label="Is Public?"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.handleModifyNewFeedstock()} >
                            Modify
                        </button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
};

export default connector(ModifyFeedstock);