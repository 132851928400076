import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getNumberOfPlants } from "../../configuration";
import { SCENARIO_STATUS } from "../../interfaces/IScenario";
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { RootState } from "../../reducers/rootReducer";
import { updateExportableAll } from "../../slices/scenarioSimulatorSlice";
import { loadScenarioRunlengths } from "../../utilities/loadScenarioRunlengths";
import FurnaceSelector from "../common/FurnaceSelector";
import PlantSelector from "../common/PlantSelector";
import AddScenarioRunlength from "./addScenarioRunlength";
import LoadScenarioRunlength from "./loadScenarioRunlength";
import ScenarioRunlengthCard from "./scenarioRunlengthCard";
import ImportSession from "../ImportExport/ImportSession";
import ExportSession from "../ImportExport/ExportSession";
import { isRTA } from "../../utilities/helperFunctions";

export const SCENARIO_SIMULATOR_DASHBOARD_ID = "_SCENARIO_SIMULATOR";
interface IScenarioSimulatorOverviewProps { }

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths,
        yieldPredictions: state.productPredictor.productPredictorScenarios,
        feedstocks: state.feedstock.feedstocks,
        fuelGases: state.fuelGas.fuelGases,
        token: state.authState.token,
        accessibleFeatures: state.features.accessibleFeatures
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateExportableAll: (furnaceId, plantId, exportable) => dispatch(updateExportableAll({ furnace_id: furnaceId, plant_id: plantId, exportable: exportable }))
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IScenarioSimulatorOverviewReduxProps = PropsFromRedux & IScenarioSimulatorOverviewProps;

type IScenarioSimulatorOverviewState = {
    allExportable: boolean,
    chkAllExportableState: boolean,
    currScenarioRunlength: IScenarioRunlength[],
    importFeature: boolean,
    exportFeature: boolean,
    isScenariosExportable: boolean,
    isYieldPredExportable:boolean
}

class ScenarioSimulatorOverview extends Component<IScenarioSimulatorOverviewReduxProps, IScenarioSimulatorOverviewState> {

    componentDidMount() {
        loadScenarioRunlengths();
    }

    allScenarioExport() {
        this.setState({ allExportable: !this.state.allExportable, chkAllExportableState: true }, async () => {
            await this.props.updateExportableAll(this.props.currentFurnaceId, this.props.currentPlantId, this.state.allExportable)
            // this.updateAllExportable()
        })
    }
    constructor(props) {
        super(props);
        const sce = this.props.scenarioRunlengths
            .filter(scenarioRunlength =>
                scenarioRunlength.furnaceId === this.props.currentFurnaceId &&
                scenarioRunlength.plantId === this.props.currentPlantId)
        const allExportable = this.props.feedstocks.length > 0 && sce && sce.every(x => x.isExportable === true) ? true : false;


        this.state = {
            allExportable: allExportable,
            chkAllExportableState: false,
            currScenarioRunlength: this.props.scenarioRunlengths,
            importFeature: this.props.accessibleFeatures.includes('IMPORT'),
            exportFeature: this.props.accessibleFeatures.includes('TEST_IMPORT_EXPORT') || this.props.accessibleFeatures.includes('EXPORT'),
            isScenariosExportable:this.enableScenarioExport(),
            isYieldPredExportable:this.enableYieldPredExport(),
        }
    }

    componentDidUpdate(PrevProps) {
        if ((PrevProps.currentFurnaceId !== this.props.currentFurnaceId) 
        || (PrevProps.currentPlantId !== this.props.currentPlantId) 
        || (PrevProps.scenarioRunlengths !== this.props.scenarioRunlengths )
        || (PrevProps.yieldPredictions !== this.props.yieldPredictions)) {
            this.updateAllExportable();
            this.setState({
                currScenarioRunlength: this.props.scenarioRunlengths
                    .filter(scenarioRunlength =>
                        scenarioRunlength.furnaceId === this.props.currentFurnaceId &&
                        scenarioRunlength.plantId === this.props.currentPlantId
                    ),
                    isScenariosExportable:this.enableScenarioExport(),
                    isYieldPredExportable:this.enableYieldPredExport(),
            })
        }
    }

    updateAllExportable() {
        const sce = this.props.scenarioRunlengths
            .filter(scenarioRunlength =>
                scenarioRunlength.furnaceId === this.props.currentFurnaceId &&
                scenarioRunlength.plantId === this.props.currentPlantId
            );
        const allExportable = this.props.feedstocks.length > 0 && sce && sce.every(x => x.isExportable === true) ? true : false;
        this.setState({
            allExportable: allExportable,
            chkAllExportableState: false,
            isScenariosExportable:this.enableScenarioExport(),
            isYieldPredExportable:this.enableYieldPredExport(),
        })
    }
    checkExportableState() {
        const sce = this.props.scenarioRunlengths
            .filter(scenarioRunlength =>
                scenarioRunlength.furnaceId === this.props.currentFurnaceId &&
                scenarioRunlength.plantId === this.props.currentPlantId
            );
        return sce && sce.every(x => x.isExportable === true) ? false : ((sce.every(x => x.isExportable === false) ? false : true));
    }

    enableScenarioExport(props=this.props) {
       
        let allowScenarioExport = false;
     
        const sce = props.scenarioRunlengths
        let isScenarioAvailable = sce !== undefined && sce.length > 0 ;
        let isFeedstocksAvailable = props.feedstocks !== undefined && props.feedstocks.length > 0;
        let isFuelGasesAvailable = props.fuelGases !== undefined && props.fuelGases.length > 0;

        
        if (isScenarioAvailable && isFeedstocksAvailable && isFuelGasesAvailable) {
            for (const ScenarioData of sce) {
                if (ScenarioData.isExportable === true) {
                    allowScenarioExport = true;
                    //break;
                }
            }
        }
        return allowScenarioExport 
    }

    
    enableYieldPredExport() {

        let allowYieldPredExport = false;
        let isYieldPredictorAvailable = this.props.yieldPredictions !== undefined && this.props.yieldPredictions.length > 0;
        if(isYieldPredictorAvailable){
            for(const yieldPredScenarioData of this.props.yieldPredictions){
              if(yieldPredScenarioData.status==="COMPUTED" && yieldPredScenarioData.output['products'].length >0 && yieldPredScenarioData.isExportable)
                allowYieldPredExport=true;
                //break;
            }
        }
  
        return allowYieldPredExport;
    }


    render() {

        return (
            <div className="scenario-simulator-overview">
                <div className="control-header">
                    <PlantSelector disabled={getNumberOfPlants() === 1} />
                    <FurnaceSelector disabled={false} />
                    <AddScenarioRunlength />
                    <LoadScenarioRunlength />
                    {this.state.importFeature ? <ImportSession /> :<></>}
                    {this.state.exportFeature ?
                        <>
                            <ExportSession 
                            exportData={{
                            isScenariosExportable: this.state.isScenariosExportable,
                            isYieldPredExportable: this.state.isYieldPredExportable,
                            enableScenarioExport: this.enableScenarioExport,
                            enableYieldPredExport: this.enableYieldPredExport
                            }} 
                            />
                        </>
                        : <></>}
                </div>
                <div className="scenario-runlength-list">
                    {this.state.currScenarioRunlength.length > 0 && this.state.exportFeature ?
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" paddingRight={4.6}>
                            <FormControlLabel labelPlacement="start" control={<Checkbox
                                color="primary"
                                checked={this.state.allExportable === true}
                                disabled={!(this.props.scenarioRunlengths.filter(scenarioRunlength =>
                                    scenarioRunlength.furnaceId === this.props.currentFurnaceId &&
                                    scenarioRunlength.plantId === this.props.currentPlantId).some(y => y.scenarios[0]?.status === SCENARIO_STATUS.COMPUTED))}
                                indeterminate={this.checkExportableState()}
                                onChange={this.allScenarioExport.bind(this)}
                            />} label="Select All (For Export)" />
                        </Box> : null}
                    {isRTA()
                        ? this.props.scenarioRunlengths.filter(scenarioRunlength => scenarioRunlength.furnaceId === this.props.currentFurnaceId && scenarioRunlength.plantId === this.props.currentPlantId && !scenarioRunlength.name.includes("Realtime Advisor")).map((scenarioRunlength, index) => <ScenarioRunlengthCard key={`RunLengthCard-${index}`} scenarioRunlength={scenarioRunlength} chkAllExportableState={this.state.chkAllExportableState} allExportable={this.state.allExportable} updateAllExportable={() => { this.updateAllExportable() }} />)
                        : this.props.scenarioRunlengths.filter(scenarioRunlength => scenarioRunlength.furnaceId === this.props.currentFurnaceId && scenarioRunlength.plantId === this.props.currentPlantId).map((scenarioRunlength, index) => <ScenarioRunlengthCard key={`RunLengthCard-${index}`} scenarioRunlength={scenarioRunlength} chkAllExportableState={this.state.chkAllExportableState} allExportable={this.state.allExportable} updateAllExportable={() => { this.updateAllExportable() }} />)}
                </div>
            </div>
        )
    }
}

export default connector(ScenarioSimulatorOverview);