import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { getNumberOfPlants } from "../../configuration";
import { RootState } from "../../reducers/rootReducer";
import { setmolPerc } from "../../slices/crackedGasSlice";
import { REACT_APP_APIM_URL_METADATA } from "../../utilities/GlobalConstants";
import { isRTA } from "../../utilities/helperFunctions";
import FurnaceSelector from "../common/FurnaceSelector";
import PlantSelector from "../common/PlantSelector";
import RunLengthSelector from "../common/RunLengthSelector";
import ScenarioRunLengthSelector from "../common/ScenarioRunLengthSelector";
import Loader from "../loader";
import CGModeSelector from "./cgModeSelector";
import FireboxSelector from "../common/FireboxSelector";
import { MODE } from "../../interfaces/IScenario";

export const CRACKED_GAS_DASHBOARD_ID = "_CRACKED_GAS"

interface ICrackedGasProps { }

const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios,
    currentScenarioRunLengthId: state.scenarioSimulator.currentScenarioRunLengthId,
    scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths,
    currentRunLengthId: state.scenarioSimulator.currentRunLengthId,
    current_asset_name: state.furnace.current_asset_name,
    cgMode: state.crackedGas.cgMode,
    molPerc: state.crackedGas.molPerc,
    token: state.authState.token,
    accessibleFeatures: state.features.accessibleFeatures,
    currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA,
    currentScenarioFireboxId: state.scenarioSimulator.currentScenarioFireboxId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setmolPerc: (molPerc: string) => dispatch(setmolPerc(molPerc)),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICrackedGasReduxProps = PropsFromRedux & ICrackedGasProps;


const CrackedGas: React.SFC<ICrackedGasReduxProps> = ({ scenarios, currentScenarioRunLengthId, scenarioRunlengths, currentRunLengthId, current_asset_name, cgMode, molPerc, token, accessibleFeatures, currentScenarioIsRTA, currentScenarioFireboxId }) => {
  type ana2I = {
    value: number[];
    name: string[];
  };

  const [compData, setCompData] = useState<any[]>([]);
  const [compDataLong, setCompDataLong] = useState<any[]>([]);
  const [designData, setDesignData] = useState<any[]>([]);
  const [designDataLong, setDesignDataLong] = useState<any[]>([]);
  const [ana2, setAna2] = useState({} as ana2I);
  const [ana2Long, setAna2Long] = useState({} as ana2I);
  const [anaFireboxCombined, setAnaFireboxCombined] = useState({} as ana2I);
  const [ana2LongFireboxCombined, setAna2LongFireboxCombined] = useState({} as ana2I);
  const [loading, setLoading] = useState(false);
  const [flowrate, setFlowrate] = useState(false);
  const scenario_runlength_index = scenarioRunlengths.findIndex(scenarioRunlength => scenarioRunlength.id === currentScenarioRunLengthId)
  const scenario = (scenario_runlength_index === -1 ? scenarioRunlengths[0]?.scenarios[0] : scenarioRunlengths[scenario_runlength_index]?.scenarios[0]);
  const selectedRun = currentRunLengthId;
  // const [CalculatedANA2Val, setCalculatedANA2Val] = useState(0);
  // const [CalculatedANA2LongVal, setCalculatedANA2LongVal] = useState(0);


  useEffect(() => {
    runFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetches data for all required fields for both modes (short and detailed)
   */

  const runFetch = async () => {
    setLoading(true);
    try {
      const compFetch = await fetch(`${REACT_APP_APIM_URL_METADATA}/components/CGSHORT`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const compJson = await compFetch.json();
      setCompData(compJson);
    } catch (error) {
      console.log(error);
    }

    try {
      const compFetch = await fetch(`${REACT_APP_APIM_URL_METADATA}/components/CG`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const compJson = await compFetch.json();
      setCompDataLong(compJson);
    } catch (error) {
      console.log(error);
    }

    try {
      const designInput = {
        AssetNames: current_asset_name,
      };
      const designFetch = await fetch(`${REACT_APP_APIM_URL_METADATA}/components/design/short`, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(designInput),
      });
      const designJson = await designFetch.json();
      setDesignData(designJson);
    } catch (error) {
      console.log(error);
    }

    try {
      const designInput = {
        AssetNames: current_asset_name,
      };
      const designFetch = await fetch(`${REACT_APP_APIM_URL_METADATA}/components/design/detailed`, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(designInput),
      });
      const designJson = await designFetch.json();
      setDesignDataLong(designJson);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  /**
   * Get design data for passed component based n selected mode
   * @param cg {string} - name of design component
   */

  const getMolVal = () => molPerc === "MOL %" ? "mol-frac" : "mass-frac"

  const getDesign = (cg) => {
    if (designData.length === 0 || designDataLong.length === 0) return "-"
    const molVal = getMolVal()
    if (cgMode === "short") {
      if (Object.keys(designData).indexOf(current_asset_name) === -1) return "N/A";
      const dIdx = designData[current_asset_name].SPECIES.findIndex((d) => d === cg);
      if (dIdx > -1) {
        return designData[current_asset_name][molVal][dIdx];
      } else {
        return "-";
      }
    } else {
      if (Object.keys(designData).indexOf(current_asset_name) === -1) return "N/A";
      const dIdx = designDataLong[current_asset_name].SPECIES.findIndex((d) => d === cg);
      if (dIdx > -1) {
        return designDataLong[current_asset_name][molVal][dIdx];
      } else {
        return "-";
      }
    }
  };

  /**
   * Gets new data from already fetched data from feedstock
   * whenever scenarios, selected scenario or selected run is changed
   */

  useEffect(() => {
    updateAll()
    // getUpdatedData()
    // handleRadioChange({target:{value: molPerc}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarios, currentScenarioRunLengthId, selectedRun, compData, compDataLong, currentScenarioFireboxId]);

  const updateAll = async () => {
    await getUpdatedData();
    await handleRadioChange({ target: { value: molPerc } });
  }


  /*
   * Recalculates data on change of input
   */

  const getUpdatedData = () => {
    const idx = selectedRun;
    if (scenario) {


      if (scenario.mode === MODE.HYBRID && scenario.firebox[0]["CALCULATIONS"] && scenario.firebox[0]["CALCULATIONS"].length > 0
        && scenario.firebox[1]["CALCULATIONS"] && scenario.firebox[1]["CALCULATIONS"].length > 0) {

        if (!scenario.firebox[currentScenarioFireboxId]["CALCULATIONS"][idx]) return;
        const calc = scenario.firebox[currentScenarioFireboxId]["CALCULATIONS"][idx]["output_dict"];
        if ("ERROR_CODE" in calc) return;
        const ANA2 = calc.SHORT_FRACTION.map((fraction, i) => {
          return {
            value: fraction,
            name: calc.SHORT_SPECIES[i],
          };
        });
        const ANA2_LONG = calc.ANA2_FRACTION.map((fraction, i) => {
          return {
            value: fraction,
            name: calc.ANA2_FRACTION_COMP[i],
          };
        });
        setAna2(ANA2);
        setAna2Long(ANA2_LONG);

        if (!scenario.firebox[0]["CALCULATIONS"][idx] || !scenario.firebox[1]["CALCULATIONS"][idx]) return;
        const calc1 = scenario.firebox[0]["CALCULATIONS"][idx]["output_dict"];
        const calc2 = scenario.firebox[1]["CALCULATIONS"][idx]["output_dict"];
        const f1: number = Number(scenario.firebox[0].FLOW_HC);
        const f2: number = Number(scenario.firebox[1].FLOW_HC);
        if ("ERROR_CODE" in calc1) return;
        if ("ERROR_CODE" in calc2) return;

        const ANACombined = calc1.SHORT_FRACTION.map((fraction, i) => {
          return {
            value: calc1.SHORT_FRACTION[0].map((fraction, i) =>
              Number(((f1 * fraction) + (f2 * calc2.SHORT_FRACTION[0][i])) / (f1 + f2))
            ),
            name: calc1.SHORT_SPECIES[i]
          }
        });

        const ANA2_LONG_Combined = calc1.ANA2_FRACTION.map((fraction, i) => {
          return {
            value: calc1.ANA2_FRACTION[0].map((fraction, i) =>
              Number((f1 * fraction + f2 * calc2.ANA2_FRACTION[0][i])) / (f1 + f2)
            ),
            name: calc1.ANA2_FRACTION_COMP[i],
          };
        });

        setAnaFireboxCombined(ANACombined);
        setAna2LongFireboxCombined(ANA2_LONG_Combined);
      }
      else {
        if (!scenario["CALCULATIONS"][idx] && !scenario.firebox[0].CALCULATIONS) return;
        const calc = scenario.mode === MODE.FULL ? scenario.firebox[0]["CALCULATIONS"][idx]["output_dict"] : scenario["CALCULATIONS"][idx]["output_dict"];
        if ("ERROR_CODE" in calc) return;
        const ANA2 = calc.SHORT_FRACTION.map((fraction, i) => {
          return {
            value: fraction,
            name: calc.SHORT_SPECIES[i],
          };
        });
        const ANA2_LONG = calc.ANA2_FRACTION.map((fraction, i) => {
          return {
            value: fraction,
            name: calc.ANA2_FRACTION_COMP[i],
          };
        });
        setAna2(ANA2);
        setAna2Long(ANA2_LONG);
      }
    }
  }

  /**
   * Finds selected ana2 value for selected component name based on selected mode
   */

  const getAna2 = (cg, combinedFirebox = false) => {
    if (cgMode === "short") {
      const source = combinedFirebox ? anaFireboxCombined : ana2
      if (cg === "Pyrolysis Oil") {
        cg = "OEL";
      }
      if (!source[0] || !("name" in source[0]) || !source[0].value) return;
      const idx = source[0].name.findIndex((n) => n === cg);
      if (idx >= 0) {
        const val = source[0].value[idx] ? Number(source[0].value[idx])?.toFixed(3) : 0
        return !flowrate ? val :
          (combinedFirebox ? (
            (Number(val) * 0.01 * Number(scenarioRunlengths[scenario_runlength_index].scenarios[0].firebox[0].FLOW_HC))
            + (Number(val) * 0.01 * Number(scenarioRunlengths[scenario_runlength_index].scenarios[0].firebox[1].FLOW_HC))
          )?.toFixed(3)
            : (Number(val) * 0.01 * Number(scenarioRunlengths[scenario_runlength_index].scenarios[0].firebox[0].FLOW_HC))?.toFixed(3)
          )
      } else {
        return "-";
      }
    } else {
      const sourceLong = combinedFirebox ? ana2LongFireboxCombined : ana2Long
      if (!("name" in sourceLong[0]) || !sourceLong[0].value) return;
      const idx = sourceLong[0].name.findIndex((n) => n === cg);
      if (idx >= 0) {
        return !flowrate ?
          sourceLong[0].value[idx]?.toFixed(3)
          :
          (
            combinedFirebox
              ? (
                (sourceLong[0].value[idx]?.toFixed(3) * 0.01 * scenarioRunlengths[scenario_runlength_index].scenarios[0].firebox[0].FLOW_HC)
                + (sourceLong[0].value[idx]?.toFixed(3) * 0.01 * scenarioRunlengths[scenario_runlength_index].scenarios[0].firebox[1].FLOW_HC)
              )?.toFixed(3)
              :
              ((sourceLong[0].value[idx]?.toFixed(3) * 0.01 * scenarioRunlengths[scenario_runlength_index].scenarios[0].firebox[currentScenarioFireboxId].FLOW_HC)?.toFixed(3)));
      } else {
        return "-";
      }
    }
  };

  /**
   * Handles conversion of units whenever Mol % - Wt % is switched
   * Also makes sure other columns will have correctly set values
   * @param e {any} event
   */

  const handleRadioChange = async (e) => {
    const frac = e.target.value;
    const calcIdx = selectedRun;
    if (scenario) {
      setFlowrate(false);

      if ((scenario.mode && scenario.firebox[0].CALCULATIONS && !scenario.firebox[0].CALCULATIONS[calcIdx]) && !scenario["CALCULATIONS"][calcIdx]) return;
      if (("ERROR_CODE" in scenario.firebox[0].CALCULATIONS[calcIdx]["output_dict"])) return;

      if (scenario.mode === MODE.HYBRID) {
        if (!scenario.firebox[0]["CALCULATIONS"][calcIdx] || !scenario.firebox[1]["CALCULATIONS"][calcIdx]) return;
        if ("ERROR_CODE" in scenario.firebox[0]["CALCULATIONS"][calcIdx]["output_dict"] ||
          "ERROR_CODE" in scenario.firebox[1]["CALCULATIONS"][calcIdx]["output_dict"]) return;
      }
      else {
        if ((scenario.mode && !scenario.firebox[0]["CALCULATIONS"][calcIdx]) || (!('mode' in scenario) && !scenario["CALCULATIONS"][calcIdx])) return;
        if ((scenario.mode && "ERROR_CODE" in scenario.firebox[0]["CALCULATIONS"][calcIdx]["output_dict"]) || (!('mode' in scenario) && ("ERROR_CODE" in scenario["CALCULATIONS"][calcIdx]["output_dict"]))) return;
      }

      dispatch(setmolPerc(frac));
      if (compData.length > 0 && compDataLong.length > 0) {
        if (frac === "MOL %") {
          setLoading(true);
          let massFrac
          let massANA2
          if (scenario.mode === MODE.HYBRID) {
            massFrac = scenario.firebox[currentScenarioFireboxId]["CALCULATIONS"] ? scenario.firebox[currentScenarioFireboxId]["CALCULATIONS"][calcIdx]["output_dict"].SHORT_FRACTION[0] : scenario["CALCULATIONS"][calcIdx]["output_dict"].SHORT_FRACTION[0];
            massANA2 = scenario.firebox[currentScenarioFireboxId]["CALCULATIONS"] ? scenario.firebox[currentScenarioFireboxId]["CALCULATIONS"][calcIdx]["output_dict"].ANA2_FRACTION[0] : scenario["CALCULATIONS"][calcIdx]["output_dict"].ANA2_FRACTION[0];
          }
          else {
            massFrac = scenario.firebox[0]["CALCULATIONS"] ? scenario.firebox[0]["CALCULATIONS"][calcIdx]["output_dict"].SHORT_FRACTION[0] : scenario["CALCULATIONS"][calcIdx]["output_dict"].SHORT_FRACTION[0];
            massANA2 = scenario.firebox[0]["CALCULATIONS"] ? scenario.firebox[0]["CALCULATIONS"][calcIdx]["output_dict"].ANA2_FRACTION[0] : scenario["CALCULATIONS"][calcIdx]["output_dict"].ANA2_FRACTION[0];
          }
          const convInput = {
            convert: [
              {
                scenario: "short",
                mw: compData.map((cd) => cd.MW),
                "mass-frac": massFrac,
              },
              {
                scenario: "long",
                mw: compDataLong.map((cd) => cd.MW),
                "mass-frac": massANA2,
              },
            ],
          }
          const convFetch = await fetch(`${REACT_APP_APIM_URL_METADATA}/cgconversion`, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(convInput),
          });


          const convJson = await convFetch.json();
          if ("short" in convJson) {
            const ana2Copy = JSON.parse(JSON.stringify(ana2))
            ana2Copy[0].value = convJson.short
            const ana2LongCopy = JSON.parse(JSON.stringify(ana2Long))
            ana2LongCopy[0].value = convJson.long
            setAna2(ana2Copy);
            setAna2Long(ana2LongCopy);
          } else {
          }
          // combined one for hybrid          
          if (scenario.mode === MODE.HYBRID) {

            if (!scenario.firebox[0]["CALCULATIONS"][calcIdx] || !scenario.firebox[1]["CALCULATIONS"][calcIdx]) return;
            const calc1 = scenario.firebox[0]["CALCULATIONS"][calcIdx]["output_dict"];
            const calc2 = scenario.firebox[1]["CALCULATIONS"][calcIdx]["output_dict"];
            const f1: number = Number(scenario.firebox[0].FLOW_HC);
            const f2: number = Number(scenario.firebox[1].FLOW_HC);
            if ("ERROR_CODE" in calc1) return;
            if ("ERROR_CODE" in calc2) return;

            const ANACombined = calc1.SHORT_FRACTION.map((fraction, i) => {
              return {
                value: calc1.SHORT_FRACTION[0].map((fraction, i) =>
                  Number(((f1 * fraction) + (f2 * calc2.SHORT_FRACTION[0][i])) / (f1 + f2))
                ),
                name: calc1.SHORT_SPECIES[i]
              }
            });

            const ANA2_LONG_Combined = calc1.ANA2_FRACTION.map((fraction, i) => {
              return {
                value: calc1.ANA2_FRACTION[0].map((fraction, i) =>
                  Number((f1 * fraction + f2 * calc2.ANA2_FRACTION[0][i])) / (f1 + f2)
                ),
                name: calc1.ANA2_FRACTION_COMP[i],
              };
            });

            setAnaFireboxCombined(ANACombined);
            setAna2LongFireboxCombined(ANA2_LONG_Combined);

            const convInputCombined = {
              convert: [
                {
                  scenario: "short",
                  mw: compData.map((cd) => cd.MW),
                  "mass-frac": ANACombined[0].value,
                },
                {
                  scenario: "long",
                  mw: compDataLong.map((cd) => cd.MW),
                  "mass-frac": ANA2_LONG_Combined[0].value,
                },
              ],
            }
            const convFetchCombined = await fetch(`${REACT_APP_APIM_URL_METADATA}/cgconversion`, {
              method: "POST",
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body: JSON.stringify(convInputCombined),
            });
            const convJsonCombined = await convFetchCombined.json();
            if ("short" in convJsonCombined) {
              const ana2Copy = JSON.parse(JSON.stringify(ana2))
              ana2Copy[0].value = convJsonCombined.short
              const ana2LongCopy = JSON.parse(JSON.stringify(ana2Long))
              ana2LongCopy[0].value = convJsonCombined.long
              setAnaFireboxCombined(ana2Copy);
              setAna2LongFireboxCombined(ana2LongCopy);
            } else {
            }
          }


          setLoading(false);
        } else if (frac === "Flow rates (kg/h)") {
          getUpdatedData();
          setFlowrate(true);
        } else {
          getUpdatedData()
        }
      }
    }
  };


  const dispatch = useDispatch()

  const isVisibleCGModeSelector = () => {

    if (accessibleFeatures.length > 0) {
      let isVisible = accessibleFeatures.find(x => x === "CRACKED_GAS_MODE")
      if (isVisible === undefined) {
        return true;
      }
      else {
        return false;
      }
    }

    return true;
  }
  const designBoxSize = currentScenarioIsRTA ? 2 : 2;
  return (
    <React.Fragment>
      {loading ? <Loader /> : null}
      {scenario || isRTA() ?
        <div style={{ maxWidth: "1350px", margin: "0 auto" }}>
          <Grid container direction="row" spacing={5}>
            <Grid item xs={designBoxSize}>
              <PlantSelector disabled={getNumberOfPlants() === 1} />
            </Grid>
            <Grid item xs={3}>
              <FurnaceSelector disabled={false} setLoading={(val) => setLoading(val)} />
            </Grid>
            <Grid item xs={3}>
              <ScenarioRunLengthSelector disabled={false} onScenarioChange={null} />
            </Grid>
            {(currentScenarioIsRTA || (scenarios && scenarios[0] && scenarios[0].mode === MODE.HYBRID)) && <Grid item xs={designBoxSize}>
              <FireboxSelector setLoading={(val) => setLoading(val)} />
            </Grid>
            }
            <Grid item xs={designBoxSize}>
              <RunLengthSelector disabled={false} setLoading={(val) => setLoading(val)} />
            </Grid>
            {!isVisibleCGModeSelector() &&
              <Grid item xs={3}>
                <CGModeSelector disabled={false} />
              </Grid>
            }
          </Grid>
          <Table className="l-table">
            <TableHead>
              <TableRow>
                <TableCell className="bold">Components</TableCell>
                <TableCell className="bold">
                  <FormControl>
                    <RadioGroup row value={molPerc} onChange={(e) => handleRadioChange(e)}>
                      <FormControlLabel value="MOL %" control={<Radio color="primary" />} label="MOL %" />
                      <FormControlLabel value="Wt %" control={<Radio color="primary" />} label="Wt %" />
                      <FormControlLabel value="Flow rates (kg/h)" control={<Radio color="primary" />} label="Flow rates (kg/h)" />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
                <TableCell className="bold">DCS</TableCell>
                <TableCell className="bold">
                  {scenario?.name} {scenario.mode === MODE.HYBRID ? '(Firebox ' + (currentScenarioFireboxId + 1) + ')' : ''}
                </TableCell>
                {scenario.mode === MODE.HYBRID && <TableCell className="bold">
                  {scenario?.name} (Combined)
                </TableCell>}
              </TableRow>
              <TableRow>
                <TableCell className="bold">Names</TableCell>
                <TableCell className="bold">Molecular Weight</TableCell>
                <TableCell className="bold">{molPerc}</TableCell>
                <TableCell className="bold">{molPerc}</TableCell>
                {scenario.mode === MODE.HYBRID && <TableCell className="bold">{molPerc}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {cgMode === "short"
                ? compData &&
                compData.map((cd, i) => (
                  <TableRow key={cd.CompName} style={i % 2 ? { background: "rgba(210, 225, 235, 0.8)" } : { background: "#f1f6f9" }}>
                    <TableCell>{cd.CompName}</TableCell>
                    <TableCell>{cd.MW}</TableCell>
                    <TableCell>{getDesign(cd.CompName)}</TableCell>
                    <TableCell>{getAna2(cd.CompName)}</TableCell>
                    {scenario.mode === MODE.HYBRID && <TableCell>{getAna2(cd.CompName, true)}</TableCell>}
                  </TableRow>
                ))
                : compDataLong &&
                compDataLong.map((cd, i) => (
                  <TableRow key={cd.CompName} style={i % 2 ? { background: "rgba(210, 225, 235, 0.8)" } : { background: "#f1f6f9" }}>
                    <TableCell>{cd.CompName}</TableCell>
                    <TableCell>{cd.MW}</TableCell>
                    <TableCell>{getDesign(cd.CompName)}</TableCell>
                    <TableCell>{getAna2(cd.CompName)}</TableCell>
                    {scenario.mode === MODE.HYBRID && <TableCell>{getAna2(cd.CompName, true)}</TableCell>}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        :
        <div>
          <p>Please run scenario simulaor.</p>
        </div>
      }
    </React.Fragment>
  );
};

export default connector(CrackedGas);
