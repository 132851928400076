import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import AddConvectionSection from "./addConvectionSection";
import CloneConvectionSection from "./cloneConvectionSection";
import ConvectionSection from "./ConvectionSection";
import ConvectionsectionSelector from "./convectionsectionSelector";
import DeleteConvectionSection from "./deleteConvectionSection";
import LoadConvectionSection from "./loadConvectionSection";
import RenameConvectionSection from "./renameConvectionSection";
import SaveConvectionSection from "./saveConvectionSection";

interface IConvectionSectionOverviewProps { }

const mapStateToProps = (state: RootState) => {
    return {
        current_convectionsection_id: state.convectionsection.current_convectionsection_id,
        convectionsections: state.convectionsection.convectionsections
    };
};

export const CONVECTION_SECTION_DASHBOARD_ID = "_CONVECTION_SECTION";

const mapDispatchToProps = dispatch => {
    return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IConvectionSectionOverviewReduxProps = PropsFromRedux & IConvectionSectionOverviewProps;

class ConvectionSectionOverview extends Component<IConvectionSectionOverviewReduxProps, {}> {

    render() {

        let csIndex = this.props.convectionsections.findIndex(x => x.id === this.props.current_convectionsection_id);
        let convectiondata = this.props.convectionsections[csIndex];

        return (
            <div className="feedstock-overview">
                <div className="control-header">
                    <ConvectionsectionSelector />
                    <AddConvectionSection convectionsection={convectiondata} />
                    <LoadConvectionSection />
                    {this.props.convectionsections.length === 0 ? <div /> : <SaveConvectionSection convectionsection={convectiondata} />}
                    {this.props.convectionsections.length === 0 ? <div /> : <RenameConvectionSection convectionsection={convectiondata} />}
                    {this.props.convectionsections.length === 0 ? <div /> : <CloneConvectionSection convectionsection={convectiondata} />}
                    {this.props.convectionsections.length === 0 ? <div /> : <DeleteConvectionSection convectionsection={convectiondata} />}
                </div>
                <div className="feedstock-dashboard">
                    {this.props.convectionsections.length === 0 ? <div /> :
                        <ConvectionSection />
                    }
                </div>
            </div>
        )
    }
}

export default connector(ConvectionSectionOverview);