import React, { Component } from "react";
import { MenuItem, Select, Typography } from "@material-ui/core";

import { connect, ConnectedProps } from "react-redux";
import { IScenario } from "../../interfaces/IScenario";
import { RootState } from "../../reducers/rootReducer";
import { setCurrentRunLengthId } from "../../slices/scenarioSimulatorSlice";

interface IRunLengthSelectorProps {
  disabled?: boolean;
  setLoading?: (val) => void
}

const mapStateToProps = (state: RootState) => {
  return {
    currentScenarioRunLengthId: state.scenarioSimulator.currentScenarioRunLengthId,
    currentRunLengthId: state.scenarioSimulator.currentRunLengthId,
    currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
    currentPlantId: state.scenarioSimulator.currentPlantId,
    scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths,
    currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA

  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentRunLengthId: (newRunLengthId: number) => dispatch(setCurrentRunLengthId(newRunLengthId))
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFurnaceSelectorReduxProps = PropsFromRedux & IRunLengthSelectorProps;

class RunLengthSelector extends Component<IFurnaceSelectorReduxProps, {}>{

  getRuns = () => {

    let scenario: IScenario | undefined = this.props.scenarioRunlengths.find(scenario => scenario.id === this.props.currentScenarioRunLengthId)?.scenarios[0];
    let days = scenario?.Days;
    let day1Index = days?.lastIndexOf('1') === -1 ? 0 : days && days.lastIndexOf('1') + 1;
    let day5Index = days?.lastIndexOf('5') === -1 ? day1Index : days && days.lastIndexOf('5') + 1;
    let runlength = this.props.currentScenarioIsRTA ? scenario?.firebox[0]?.CALCULATIONS.slice(day5Index) : scenario?.firebox[0]?.CALCULATIONS;
    return runlength?.map((s, i) => {
      const finalRunLength = scenario?.RUN_LENGTH_FINAL

      const runLength = s.output_dict.RUNLENGTH[0][0];
      console.log(i)
      console.log(runlength)
      if (finalRunLength && runLength <= finalRunLength) {
        return (
          <MenuItem key={`RUN-SELECT-${i}`} value={i}>
            {runLength % 1 === 0 ? runLength : runLength.toFixed(2)}
          </MenuItem>
        ) 
      } else if (!finalRunLength) {
        return (
          <MenuItem key={`RUN-SELECT-${i}`} value={i}>
            {this.props.currentScenarioIsRTA ? runLength.toFixed(2) : runLength}
          </MenuItem>
        )
      } else {
        return null
      }
    });
  }


  handleSelection = (event) => {
    this.props.setLoading && this.props.setLoading(true) //set loader true
    const new_runlength_id = event.target.value;
    this.props.setCurrentRunLengthId(new_runlength_id);
    this.props.setLoading && this.props.setLoading(false) //set loader false
  }

  render() {
    return (
      <div className="control-header element">
        <div className="container-typography">
          <Typography>Select Runlength</Typography>
        </div>
        <Select disabled={this.props.disabled} autoWidth value={this.props.currentRunLengthId || 0} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
          {this.getRuns()}
        </Select>
      </div>
    );
  }

};

export default connector(RunLengthSelector);