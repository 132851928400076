export type authState = {
  token: string;
  expiration: string;
};

const initState: authState = {
  token: "",
  expiration: "",
};

/**
 * Auth state reducer handles state management for authentication token and its expiration date
 */

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_TOKEN":
      return {
        ...state,
        token: action.payload.value,
      };
    case "ADD_EXPIRATION":
      return {
        ...state,
        expiration: action.payload.value,
      };
    case "RESET_AUTH_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export default AuthReducer;
