import { MenuItem, Select, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import { setCurrentProductPredictorScenarioId } from '../../slices/productPredictorSlice'

interface IProductPredictorSelectorProps {

}

const mapStateToProps = (state: RootState) => {
    return {
        productPredictorScenarios: state.productPredictor.productPredictorScenarios,
        currentProductPredictorScenarioId: state.productPredictor.currentProductPredictorScenarioId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentProductPredictorScenarioId: (id: string) => dispatch(setCurrentProductPredictorScenarioId(id))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProductPredictorSelectorReduxProps = IProductPredictorSelectorProps & PropsFromRedux;

type IProductPredictorSelectorState = {
    showDialog: boolean;
}

class ProductPredictorSelector extends Component<IProductPredictorSelectorReduxProps, IProductPredictorSelectorState> {

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false
        }
    }
    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog
        })
    }
    ProductPredictorSelectorScenario() {

    }
    handleSelection(event) {
        
        this.props.setCurrentProductPredictorScenarioId(event.target.value)
    }

    getSelectedPPScenario() {
        return this.props.productPredictorScenarios.filter(pp => pp.id === this.props.currentProductPredictorScenarioId)[0];
    }
    render() {
        return (

            <div className="control-header element">
                <div className="container-typography">
                    <Typography>Scenario: </Typography>
                </div>
                <Select disabled={this.props.productPredictorScenarios.length === 0} autoWidth value={this.props.productPredictorScenarios.length === 0 ? "No Scenario defined" : this.getSelectedPPScenario().id} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.props.productPredictorScenarios.length === 0 ?
                        <MenuItem key={`PRODUCT-PREDICTOR-SCENARIO-SELECT-NONE`} value={"No feedstock defined"}>
                            {"No Scenario defined"}
                        </MenuItem> : <div />}
                    {this.props.productPredictorScenarios.map((ppScenario, i) => (
                        <MenuItem key={`PPScenario-SELECT-${ppScenario.name}`} value={ppScenario.id}>
                            {ppScenario.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        )
    }
}

export default connector(ProductPredictorSelector)