import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fade, ListItemText, TextField } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" style={{ color: `rgb(0, 85, 145)` }} />;

interface ILoadAutocompleteProps {
    showDialog: boolean;
    selected: any[];
    allData: any[];
    isAllSelected: boolean;
    showLoading: boolean;
    componentName: string;
    disableData: any[];
    toggleShowDialog: () => void;
    loadFeedstock: () => void;
    selectedDataSet: (valueData) => void;
}

const mapStateToProps = (state: RootState) => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ILoadAutocompleteReduxProps = PropsFromRedux & ILoadAutocompleteProps;

type ILoadAutocompleteState = {
}


class LoadAutocomplete extends Component<ILoadAutocompleteReduxProps, ILoadAutocompleteState>{
    // constructor(props) {
    //     super(props);
    // }

    dateConvert = (time) => {
        let dateTime = new Date(time);
        return dateTime.toLocaleString()
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    className="button"
                    onClick={() => this.props.toggleShowDialog()}
                >
                    Load
                </button>
                <Dialog open={this.props.showDialog} aria-labelledby="form-dialog-title" disableEnforceFocus={true}>
                    <DialogTitle id="form-dialog-title">Load {!!this.props.componentName ? this.props.componentName : ""}</DialogTitle>
                    {this.props.showLoading === true && (
                        <div className="loading-dashboard">
                            <Fade
                                in={this.props.showLoading}
                                style={{
                                    transitionDelay: this.props.showLoading ? '800ms' : '0ms',
                                }}
                                unmountOnExit
                            >
                                <CircularProgress />
                            </Fade>
                        </div>
                    )}

                    {this.props.showLoading === false && (
                        <>
                            <DialogContent>
                                <div style={{ maxHeight: 300 }}>
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={this.props.allData}
                                        disableCloseOnSelect
                                        limitTags={4}
                                        ListboxProps={{ position: "bottom-start" }}
                                        getOptionLabel={(option) => option.name}
                                        getOptionDisabled={(option) => {
                                            return this.props.disableData.some(elem => elem.id === option.id)
                                        }}
                                        renderOption={(option, { selected }) => {
                                            const selectFeedStockIndex = this.props.selected.findIndex(
                                                feedstock => feedstock.name.toLowerCase() === "all"
                                            );
                                            if (selectFeedStockIndex > -1) {
                                                selected = true;
                                            }
                                            return (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                        value={option.name}
                                                    />
                                                    <ListItemText
                                                        primary={option.name}
                                                        secondary={!!option.updated_at ? this.dateConvert(option.updated_at) : (!!option.ts ? this.dateConvert(option.ts) : "")}
                                                    />
                                                    {/* {option.name} */}
                                                </React.Fragment>

                                            )
                                        }}
                                        style={{ width: 500 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                style={{ maxHeight: "100px" }}
                                                variant="outlined"
                                                label={`Select ${this.props.componentName}`}
                                                placeholder="Search"
                                            />
                                        )}
                                        onChange={(event, valueData) => {
                                            this.props.selectedDataSet(valueData)

                                        }}

                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <button className="button" onClick={() => this.props.toggleShowDialog()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.props.loadFeedstock()} >
                                    Load
                                </button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            </div >
        );
    }
}

export default connector(LoadAutocomplete);