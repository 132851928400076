import { MenuItem, Select, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import { setCurrentAggregateFunction } from '../../slices/aggregateFunctionSlice'
import { setCoils, setCurrentCoil } from '../../slices/coilSlice'
import { setImage } from '../../slices/thermalImageSlice'

interface IAggregateFunctionSelctorProps {
}

const mapStateToProps = (state: RootState) => {
    return {
        aggregateFunction: state.aggregateFunction
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentAggregateFunction: (name: string) => dispatch(setCurrentAggregateFunction(name)),
        setCoils: (values: string[]) => dispatch(setCoils(values)),
        setCurrentCoil: (name: string) => dispatch(setCurrentCoil(name)),
        setCurrSelectedImage: (name: string) => dispatch(setImage(name))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IAggregateFunctionSelectorReduxProps = PropsFromRedux & IAggregateFunctionSelctorProps;

type IAggregateFunctionSelectorState = {
    aggregateFunction: string[],
    currAggregateFunction: string
}

class AggregateFunctionSelector extends Component<IAggregateFunctionSelectorReduxProps, IAggregateFunctionSelectorState>
{

    constructor(props) {
        super(props)
        this.state = {
            aggregateFunction: this.props.aggregateFunction.aggregateFunctions,
            currAggregateFunction: this.props.aggregateFunction.name
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if ((prevProps.parent.parents !== this.props.parent.parents) || (this.state.currAggregateFunction !== this.props.parent.name)) {
    //         this.setState({
    //             parent: this.props.parent.parents,
    //         });
    //         this.props.setCoils([])
    //         this.props.setCurrentCoil('')
    //         // this.props.setCurrSelectedImage('')
    //     }       
    // }

    handleSelection = (e) => {
        const newVal = e.target.value
        this.setState({ currAggregateFunction: newVal });
        this.props.setCurrentAggregateFunction(newVal);
    }

    render(): React.ReactNode {
        return (
            <div className="control-header element">
                <div className="container-typography">
                    <Typography><b>Aggregation function: </b></Typography>
                </div>
                <Select autoWidth value={this.state.currAggregateFunction} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.state.aggregateFunction?.map((aggregateFunction, i) => (
                        <MenuItem key={`COILGROUP-SELECT-${aggregateFunction}`} value={aggregateFunction}>
                            {aggregateFunction}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        )
    }
}

export default connector(AggregateFunctionSelector);