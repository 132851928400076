import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  FEEDSTOCK_TYPES,
  getDefaultFeedstock,
  IFeedstock,
} from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import AddFeedstock from "./addFeedstock";
import DiscreteCompositionFeedstock from "./discreteCompositionFeedstock";
import DetailedPionaFeedstock from "./detailedPionaFeedstock";
import ShortPionaFeedstock from "./shortPionaFeedstock";
import FeedstockSelector from "./feedstockSelector";
import DeleteFeedstock from "./deleteFeedstock";
import HeavyFeedstock from "./heavyFeedstock";
import SaveFeedstock from "./saveFeedstock";
import ModifyFeedstock from "./modifyFeedstock";
import { loadAllFeedStockData } from "../../slices/feedstockSlice";
import { CircularProgress, Fade } from "@material-ui/core";
import LoadFeedstock from "./loadFeedstock";
import CloneFeedstock from "./cloneFeedstock";

export const FEEDSTOCK_OVERVIEW_DASHBOARD_ID = "_FEEDSTOCK";

interface IFeedstockOverviewProps {}

const mapStateToProps = (state: RootState) => {
  return {
    current_feedstock_id: state.feedstock.current_feedstock_id,
    feedstocks: state.feedstock.feedstocks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllFeedStockData: (data: any) => dispatch(loadAllFeedStockData(data)),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFeedstockOverviewReduxProps = PropsFromRedux & IFeedstockOverviewProps;

type IFeedstockOverviewState = {
  showLoading: boolean;
};

class FeedstockOverview extends Component<
  IFeedstockOverviewReduxProps,
  IFeedstockOverviewState
> {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
    };
  }

  getCurrentFeedstock(): IFeedstock {
    const feedstock = this.props.feedstocks.find(
      (feedstock) => feedstock.id === this.props.current_feedstock_id
    );
    return feedstock !== undefined ? feedstock : getDefaultFeedstock();
  }

  currentFeedstockType() {
    return this.getCurrentFeedstock().type;
  }

  renderFeedstockDashboardView() {
    switch (this.getCurrentFeedstock()?.type) {
      case FEEDSTOCK_TYPES.DISCRETE_COMPOSITION: {
        return (
          <DiscreteCompositionFeedstock
            feedstock={this.getCurrentFeedstock()}
          />
        );
      }
      case FEEDSTOCK_TYPES.DETAILED_PIONA: {
        return (
          <DetailedPionaFeedstock feedstock={this.getCurrentFeedstock()} />
        );
      }
      case FEEDSTOCK_TYPES.SHORT_PIONA: {
        return <ShortPionaFeedstock feedstock={this.getCurrentFeedstock()} />;
      }
      case FEEDSTOCK_TYPES.HEAVY_FEED: {
        return <HeavyFeedstock feedstock={this.getCurrentFeedstock()} />;
      }
    }
  }

  render() {
    return (
      <div className="feedstock-overview">
        {this.state.showLoading === true && (
          <div className="loading-dashboard">
            <Fade
              in={this.state.showLoading}
              style={{
                transitionDelay: this.state.showLoading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </div>
        )}

        {this.state.showLoading === false && (
          <>
            <div className="control-header">
              <FeedstockSelector />
              <AddFeedstock feedstock={this.getCurrentFeedstock()} />
              <LoadFeedstock />
              {this.props.feedstocks.length > 0 &&
              this.props.current_feedstock_id !== "-1" ? (
                <SaveFeedstock feedstock={this.getCurrentFeedstock()} />
              ) : (
                <div />
              )}
              {this.props.feedstocks.length > 0 &&
              this.props.current_feedstock_id !== "-1" ? (
                <ModifyFeedstock feedstock={this.getCurrentFeedstock()} />
              ) : (
                <div />
              )}
              {this.props.feedstocks.length > 0 &&
              this.props.current_feedstock_id !== "-1" ? (
                <CloneFeedstock feedstock={this.getCurrentFeedstock()} />
              ) : (
                <div />
              )}
              {this.props.feedstocks.length > 0 &&
              this.props.current_feedstock_id !== "-1" ? (
                <DeleteFeedstock feedstock={this.getCurrentFeedstock()} />
              ) : (
                <div />
              )}
            </div>

            <div className="feedstock-dashboard">
              {this.props.feedstocks.length === 0 ? (
                <div />
              ) : this.props.current_feedstock_id === "-1" ? (
                <div />
              ) : (
                <div>
                  {/* <div className="control-header">
                                    <label>Type: {this.currentFeedstockType()}</label>
                                </div> */}
                  {this.renderFeedstockDashboardView()}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connector(FeedstockOverview);
