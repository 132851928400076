import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type INotificationConfigurationSlice = {
    notificationConfiguration: Array<any>
}

const initialState: INotificationConfigurationSlice = {
    notificationConfiguration: []
}

export const notificationConfigurationSlice = createSlice({
    name: 'notificationConfigurationSlice',
    initialState: initialState,
    reducers: {
        setNotificationConfiguration: (state, action: PayloadAction<any[]>) => {
            state.notificationConfiguration = action.payload;
        },
        resetNotificationConfiguration: (state) => {
            state.notificationConfiguration = initialState.notificationConfiguration
        }
    }
})

export const {
    setNotificationConfiguration,
    resetNotificationConfiguration
} = notificationConfigurationSlice.actions


export default notificationConfigurationSlice.reducer