import { v4 as uuidv4 } from 'uuid';

export interface IConvectionSection {
  id: string;
  name: string;
  furnace_id: string;
  asset_name: string;
  //cgMode: string;
  //dataDes: string;
  components: any[];
  signature?: string;
  is_public?: boolean;
  is_display_leave_popup?: boolean;
  is_saved?: boolean;
}

export function getDefaultConvectionSection(): IConvectionSection {
  return {
    id: uuidv4(),
    name: "default",
    furnace_id: "",
    asset_name: "",
    //cgMode: "",
    //dataDes: "",
    components: [],
  }
}

export const cosy_preperties: string[] = [
  "BFW_TEMPERATURE",
  "BFW_PRESSURE",
  "HP_STEAM_TEMPERATURE",
  "HP_STEAM_PRESSURE",
  "FEED_TEMPERATURE",
  "FEED_PRESSURE",
  "DILUTION_STEAM_TEMPERATURE",
  "DILUTION_STEAM_PRESSURE",
  "FUELGAS_TEMPERATURE",
  "FUELGAS_PRESSURE",
  "CG_PRESSURE_AT_HEADER",
];
