import { IConvectionSection } from "../interfaces/IConvectionSection";
import { REACT_APP_APIM_URL_SCENARIO } from "../utilities/GlobalConstants";
import store from "..";

/**
 *
 * @param newConvectionSection 
 * @returns 
 */
export async function checkConvectionsectionName(newConvectionSection: IConvectionSection) {
    // Refresh token before calling compute Initialization
    const repsonce = {
        status: false,
        message: "",
    }
    const token = store.getState().authState.token;

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/convectionsection/${newConvectionSection.name}/${newConvectionSection.is_public}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                if (data.status) {
                    data.message = "Name already exist!"
                }
                return data
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check;
}

/**
 * 
 * @returns 
 */
export async function loadConvectionsectionData() {

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    const token = store.getState().authState.token;

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/convectionsection`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = !!data ? data : [];
                return repsonce;
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce;
        });

    return check
}

/**
 * 
 * @param newConvectionSection 
 * @returns 
 */
export async function saveNewConvectionSection(newConvectionSection: IConvectionSection) {

    const repsonce = {
        status: false,
        message: "",
    }
    const token = store.getState().authState.token;

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/convectionsection`, {
        method: "POST",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(newConvectionSection),
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "This convectionsection save sucessfully!"
                return repsonce
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check;
}

/**
 * 
 * @param convectionSectionId 
 * @returns 
 */
export async function deleteConvectionSectionById(convectionSectionId: string) {

    const repsonce = {
        status: false,
        message: "",
    }
    const token = store.getState().authState.token;

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/convectionsection`, {
        method: "DELETE",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
            Id: convectionSectionId
        }),
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "This convectionsection deleted sucessfully!"
                return repsonce
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check
}

/**
 * 
 * @param convectionSectionId 
 * @returns 
 */
export async function getConvectionSectionById(convectionSectionId: string) {
    const token = store.getState().authState.token;

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/convectionsection/${convectionSectionId}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = !!data ? data : [];
                return repsonce;
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce;
        });

    return check

}



