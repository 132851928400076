import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IthermalImageSlice = {
    currSelectedImage: string,
    minUpdatedTemp: number,
    maxUpdatedTemp: number,
}

const initialState: IthermalImageSlice = {
    currSelectedImage: "",
    minUpdatedTemp: 0,
    maxUpdatedTemp: 0,
}

export const thermalImageSlice = createSlice({
    name: "thermalImageSlice",
    initialState: initialState,
    reducers: {
        setImage: (state, action: PayloadAction<string>) => {
            state.currSelectedImage = action.payload
        },
        setTemp: (state, action: PayloadAction<{ minUpdatedTemp, maxUpdatedTemp }>) => {
            state.minUpdatedTemp = action.payload.minUpdatedTemp
            state.maxUpdatedTemp = action.payload.maxUpdatedTemp
        },
        resetThermalImage: (state) => {
            state.currSelectedImage = ""
            state.minUpdatedTemp = 0
            state.maxUpdatedTemp = 0
        }
    }
});

export const {
    setImage,
    setTemp,
    resetThermalImage

} = thermalImageSlice.actions


export default thermalImageSlice.reducer
