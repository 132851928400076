import { REACT_APP_APIM_URL_SCENARIO } from "../utilities/GlobalConstants";
import store from "..";
import { IProductPredictorScenario } from "../interfaces/IProductPredictor";


/**
 * 
 * @returns 
 */
export async function loadProductPredictorData() {
    const token = store.getState().authState.token;

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/productpredictor`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = !!data ? data : [];
                return repsonce;
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce;
        });

    return check
}

/**
 *
 * @param newProductPredictor 
 * @returns 
 */
export async function checkProductPredictorName(newProductPredictor: IProductPredictorScenario) {
    // Refresh token before calling compute Initialization
    const repsonce = {
        status: false,
        message: "",
    }
    const token = store.getState().authState.token;

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/productpredictor/${newProductPredictor.name}/${newProductPredictor.is_public}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                if (data.status) {
                    data.message = "Name already exist!"
                }
                return data
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check;
}


/**
 * 
 * @param newProductPredictor 
 * @returns 
 */
export async function saveNewProductPredictor(newProductPredictor: IProductPredictorScenario) {

    const repsonce = {
        status: false,
        message: "",
    }
    const token = store.getState().authState.token;

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/productpredictor`, {
        method: "POST",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(newProductPredictor),
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "This feedstock save sucessfully!"
                return repsonce
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check;
}


/**
 * 
 * @param productPredictorId 
 * @returns 
 */
export async function getProductPredictorById(productPredictorId: string) {
    const token = store.getState().authState.token;

    const repsonce = {
        status: false,
        message: "",
        data: []
    }

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/productpredictor/${productPredictorId}`, {
        method: "GET",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "success";
                repsonce.data = !!data ? data : [];
                return repsonce;
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce;
        });

    return check

}


/**
 * 
 * @param productPredictorId 
 * @returns 
 */
export async function deleteProductPredictorIdByID(productPredictorId: string) {

    const repsonce = {
        status: false,
        message: "",
    }
    const token = store.getState().authState.token;

    let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/productpredictor`, {
        method: "DELETE",
        headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
            Id: productPredictorId
        }),
    })
        .then((res) => res.json())
        .then(
            (data) => {
                repsonce.status = true;
                repsonce.message = "This feedstock deleted sucessfully!"
                return repsonce
            })
        .catch(err => {
            repsonce.message = "Oops... somthing went to wrong!";
            return repsonce
        });

    return check
}