import * as React from "react";
import { useEffect, useState } from "react";
import { getEventDetails,isPingIDAuth } from "../../utilities/helperFunctions";
import { REACT_APP_APIM_URL } from "../../utilities/GlobalConstants";
import { authProvider } from "../authProvider";
import Loader from "../loader";
import { Link } from "react-router-dom";
import { setCurrentFurnaceId } from "../../slices/scenarioSimulatorSlice";
import { setFurnaceId, setFurnaceOpMode } from "../../slices/furnaceSlice";
import { useDispatch } from "react-redux";
import { getCameraByCameraId, getFurnaceByFurnaceId } from "../../configuration";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { setCameraNameForCameraStatus, setFurnaceNameForCameraStatus } from "../../slices/cameraStatusSlice";
import { setCurrentCamera } from "../../slices/cameraSlice";
import "../../assets/styles/_notification_log.scss";
import { useAuth } from "react-oidc-context";
 
export const NOTIFICATION_LOG_DASHBOARD = "_NOTIFICATION_LOG";
 
export interface NotificationProps {
}
 
const NotificationLog: React.SFC<NotificationProps> = () => {
  const [notificationHistory, setNotificationHistory] = useState([])
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
 
  const dispatch = useDispatch()
 
  const columns: GridColDef<(typeof notificationHistory)[number]>[] = [
  { field: 'event_type', headerName: 'EVENT TYPE', width:150,
  renderCell: (params) => <Link onClick={() => onRedirect(params.row['furnace_id'],params.row['operating_mode'],params)} to={params.row['event_type'] === 'Camera Event' ? `/camera-status` : '/multi-camera'}>{params.row['event_type']}</Link>
  },
  { field: 'rule_name', headerName: 'NAME OF EVENT',width:150},
  { field: 'timestamp', headerName: 'DATE/TIMESTAMP',width:150, type:'dateTime',valueFormatter:(params) => new Date(params).toLocaleString()},
  { field: 'furnace_id', headerName: 'FURNACE',valueGetter: (value, row) => getFurnaceByFurnaceId(row['furnace_id']),width:100},
  { field: 'camera_id', headerName: 'CAMERA',width:100},
  { field: 'operating_mode', headerName: 'OPERATING MODE',width:150},
  { field: 'equipment_level', headerName: 'EQUIPMENT LEVEL',width:150},
 
  { field: 'rule_severity', headerName: 'SEVERITY',width:150,
    renderCell: (params: GridRenderCellParams) => {
      let color = 'medium-severity'
      if(params.value === 'High'){
        color = 'high-severity'
      }      
      return (
        <div className={color}>
          {params.value}
        </div>
      );
    }
  },
 
  { field: 'rule_category', headerName: 'CATEGORY',width:150},
  { field: 'rule_description', headerName: 'EVENT TEXT',valueGetter: (value, row) => getEventDetails(row['rule_id'], getFurnaceByFurnaceId(row['furnace_id']), row['coilgroup_name'], row['coilgroup_name_max'], row['coilgroup_name_min'], row['camera_id'],row['camera_temperature']),width:250},
  { field: 'threshold', headerName: 'THRESHOLD',width:150},
  { field: 'value', headerName: 'VALUE',width:150}
  ]
 
  useEffect(() => {
    getNotificationLog()
  },[])
 
  const getNotificationLog = async () => {
    setLoading(true)
    let accessToken  = '';
    if(isPingIDAuth()){
      accessToken = auth.user?.access_token === undefined ? '' : auth.user?.access_token;
    }
    else{
       accessToken = (await authProvider.getAccessToken()).accessToken;
    }
   
   
    fetch(`${REACT_APP_APIM_URL}/thermalimage/getNotificationLog/history`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Origin": "*",
        }
      })
        .then((res) => res.json())
        .then(async (data) => {
            setNotificationHistory(data)
            setLoading(false)
   
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
        });
    }
 
    const onRedirect = (furnace_id,op_mode,params) => {
      dispatch(setCurrentFurnaceId(furnace_id))
      dispatch(setFurnaceId(furnace_id))
      dispatch(setFurnaceOpMode(op_mode))
      let furnaceName = getFurnaceByFurnaceId(parseInt(furnace_id));
      dispatch(setFurnaceNameForCameraStatus(furnaceName));
      let cameraName = getCameraByCameraId((params.row['camera_id']));
      dispatch(setCameraNameForCameraStatus(cameraName));
      dispatch(setCurrentCamera(cameraName));    
    }
 
   
  return (  
    <React.Fragment>
        {loading ? <Loader /> :
        <DataGrid
        className="notification-log"
        columns={columns}
        rows={notificationHistory}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 30,
            },
          },
        }}
      />
        }
    </React.Fragment>
  );
};
 
export default (NotificationLog);
