import React from "react";
import { useSelector } from "react-redux";
import { Grid, Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/linde-logo.png";
import logo_path from "../../assets/customer_specific_data/logo_header.jpg";
import DrawerMenu from "./drawerMenu";
import Notification from "./notification";
import { RootState } from "../../reducers/rootReducer";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../authProvider";
import TerminationNotification from "./terminationnotification";
import { useAuth } from "react-oidc-context";
import { isPingIDAuth } from "../../utilities/helperFunctions";

const Header: React.SFC = () => {
  const { pageTitle } = useSelector((state: RootState) => state.appState);

  const AzureADAuth = () => {
    return(
      <AzureAD provider={authProvider} forceLogin={false}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        if (authenticationState === "Authenticated") {
          return <DrawerMenu />;
        } else {
          return <div></div>;
        }
      }}
    </AzureAD>
    )
  }
  
  const PingIDAuth = () => {
    const auth = useAuth();

    console.log("In a Header Component: ")
    console.log(auth)

    if (auth.isAuthenticated){
      return <DrawerMenu />;
    }
    else{
      return <div></div>;
    }
  }

  return (
    <header>
      <Grid container justifyContent="space-between" alignItems="center" className="header-content">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Box>
               {/* Ping ID change: Will add condition for authentication type */}
              {isPingIDAuth() ? PingIDAuth() : AzureADAuth()}
            </Box>
            <Box>
              <NavLink className="header-link" to="/">
                <h1>Linde Virtual Furnace</h1>
              </NavLink>
            </Box>
            <Box ml={4}>
              <h2 className="header-link">{pageTitle}</h2>
            </Box>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <TerminationNotification />
            </Box>
            <Box mr={1}>
              <AzureAD provider={authProvider} forceLogin={false}>
                {({ login, logout, authenticationState, error, accountInfo }) => {
                  if (authenticationState === "Authenticated") {
                    return <Notification/>;
                  } else {
                    return <div></div>;
                  }
                }}
              </AzureAD>
            </Box>
            <Box>
              <NavLink className="logo-link" to="/">
                {logo_path && <img src={logo_path} className="logo-customer" alt="Linde, Making our world more productive" />}
                <img src={logo} className="logo" alt="Linde, Making our world more productive" />
              </NavLink>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;
