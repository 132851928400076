import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getDefaultPlantReferenceId, getFeedsbyType } from "../../configuration";
import equal from 'fast-deep-equal';
import { IDiscreteCompositionComponent, UNIT } from "../../interfaces/IComponent";
import { IFeedstock } from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import { modifyFeedstocks, toggleUnit, updateComponent, updateFeedtype, updateSignature } from "../../slices/feedstockSlice";
import EditableCell from "../editableCell";

interface IDiscreteCompositionFeedstockProps {
    feedstock: IFeedstock;
}

const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleUnit: (feedstock_id: string, unit_type: UNIT) => dispatch(toggleUnit({ feedstock_id, unit_type })),
        updateComponent: (feedstock_id: string, component_id: string, new_value: number) => dispatch(updateComponent({ feedstock_id, component_id, new_value })),
        updateFeedtype: (feedstock_id: string, feedtype_id: string) => dispatch(updateFeedtype({ feedstock_id, feedtype_id })),
        updateSignature: (feedstock_id: string) => dispatch(updateSignature({ feedstock_id })),
        modifyFeedstocks: (newFeedstock: IFeedstock) => dispatch(modifyFeedstocks(newFeedstock)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IDiscreteCompositionFeedstockReduxProps = PropsFromRedux & IDiscreteCompositionFeedstockProps;

type IDiscreteCompositionFeedstockState = {

}


export const getFeedtype = (components) => {

    let c2: any[] = getFeedsbyType(getDefaultPlantReferenceId(), "2");
    let c3: any[] = getFeedsbyType(getDefaultPlantReferenceId(), "3");

    let c2_val = 0;
    let c3_val = 0;
    components.forEach(c => {
        if (c2.includes(c.name)) {
            c2_val += Number(c.value);
        }
        else if (c3.includes(c.name)) {
            c3_val += Number(c.value);
        }
    });
    let feedtype = '2';
    if (c2_val >= 50) {
        feedtype = '2';
    }
    else if (c3_val >= 50 || c2_val + c3_val >= 50) {
        feedtype = '3';
    }
    else {
        feedtype = '4';
    }

    return feedtype;
}


class DiscreteCompositionFeedstock extends Component<IDiscreteCompositionFeedstockReduxProps, IDiscreteCompositionFeedstockState>{

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    handleComponentUpdate(mockE: { target: { id: string, name: string, value: number } }) {
        this.props.updateComponent(this.props.feedstock.id, mockE.target.id, mockE.target.value);
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.feedstock.feedstock.components, prevProps.feedstock.feedstock.components) && prevProps.feedstock.id === this.props.feedstock.id) {
            this.props.updateSignature(this.props.feedstock.id);
        }
        if (prevProps.feedstock.id === this.props.feedstock.id && this.props.feedstock.unit !== prevProps.feedstock.unit) {
            this.props.updateSignature(this.props.feedstock.id);
        }
        this.defineFeedtype();
    }

    defineFeedtype(feedstockId: string = '') {

        let c2: any[] = getFeedsbyType(getDefaultPlantReferenceId(), "2");
        let c3: any[] = getFeedsbyType(getDefaultPlantReferenceId(), "3");

        let components = this.props.feedstocks.filter(f => f.id === this.props.feedstock.id)[0].feedstock.components;
        let c2_val = 0;
        let c3_val = 0;
        components.forEach(c => {
            if (c2.includes(c.name)) {
                c2_val += Number(c.value);
            }
            else if (c3.includes(c.name)) {
                c3_val += Number(c.value);
            }
        });
        let feedtype = '2';
        if (c2_val >= 50) {
            feedtype = '2';
        }
        else if (c3_val >= 50 || c2_val + c3_val >= 50) {
            feedtype = '3';
        }
        else {
            feedtype = '4';
        }

        feedstockId !== '' ? this.props.updateFeedtype(feedstockId, feedtype) : this.props.updateFeedtype(this.props.feedstock.id, feedtype);
    }

    render() {
        return (
            <div className="container">
                <div className="feedstock">
                    <label key="radion_mol" className="radio-buttons__item" title="radio_mol">
                        <input className={"radio-buttons__input_MOL"} name="filter" type="radio" value="mol" onChange={() => this.props.toggleUnit(this.props.feedstock.id, UNIT.MOL)} checked={this.props.feedstock.unit === UNIT.MOL} /> MOL %
                    </label>
                    &emsp;
                    <label key="radion_wt" className="radio-buttons__item" title="radio_wt">
                        <input className={"radio-buttons__input_wt"} name="filter" type="radio" onChange={() => this.props.toggleUnit(this.props.feedstock.id, UNIT.WT)} value="wt" checked={this.props.feedstock.unit === UNIT.WT} /> Wt %
                    </label>
                    <div className="feedstock-table-wrapper">
                        <table id="feedstock-table">
                            <thead>
                                <tr>
                                    <th colSpan={2}>Components</th>
                                    <th>Values</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Components</td>
                                    <td>Molecular Weight</td>
                                    <td>{this.props.feedstock.unit}</td>
                                </tr>
                                {(this.props.feedstock.feedstock.components as IDiscreteCompositionComponent[]).map((component, i) => {
                                    return (
                                        <tr key={component.id} id={"tr - ".concat(component.id)} onFocus={() => (true)}>
                                            <td id={"Components - ".concat(component.id)}>{component.name}</td>
                                            <td id={"Molecular Weight - ".concat(component.id)}>{component.molecular_weight}</td>

                                            <EditableCell
                                                key={"Value - ".concat(component.id)}
                                                cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleComponentUpdate(mockE)}
                                                cellData={{
                                                    type: "'Manual Correction'",
                                                    value: component["value"],
                                                    id: component.id,
                                                }}
                                            />
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

};

export default connector(DiscreteCompositionFeedstock);