import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import {
  IHeavyComponent,
  HeavyFeedMethod,
  HEAVYFEEDCOMPONENTUNIT,
} from "../../interfaces/IComponent";
import { IFeedstock, IHeavyFeedstock } from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import {
  toggleHeavyFeedMethod,
  updateSignature,
  updateComponent,
  toggleUnitHeavyFeed,
} from "../../slices/feedstockSlice";
import EditableCell from "../editableCell";
import equal from "fast-deep-equal";
import { getTemperatureUnit } from '../../configuration';

interface IHeavyFeedstockProps {
  feedstock: IFeedstock;
}

const mapStateToProps = (state: RootState) => {
  return {
    feedstocks: state.feedstock.feedstocks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleUnit: (feedstock_id: string, unit_type: HEAVYFEEDCOMPONENTUNIT) =>
      dispatch(toggleUnitHeavyFeed({ feedstock_id, unit_type })),
    updateComponent: (
      feedstock_id: string,
      component_id: string,
      new_value: number,
      field_name: string
    ) =>
      dispatch(
        updateComponent({ feedstock_id, component_id, new_value, field_name })
      ),
    updateMethod: (
      feedstock_id: string,
      component_id: string,
      new_value: number,
      field_name: string,
      IsMethodChange: boolean = true,
      method: string
    ) =>
      dispatch(
        updateComponent({
          feedstock_id,
          component_id,
          new_value,
          field_name,
          IsMethodChange,
          method,
        })
      ),
    toggleMethod: (feedstock_id: string, method_name: HeavyFeedMethod) => {
      dispatch(toggleHeavyFeedMethod({ feedstock_id, method_name }));
    },
    updateSignature: (feedstock_id: string) =>
      dispatch(updateSignature({ feedstock_id })),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IHeavyFeedstockReduxProps = PropsFromRedux & IHeavyFeedstockProps;
type IHeavyFeedstockState = {
  showDialog: boolean;
  warningDialog: boolean;
  nextMethod: HeavyFeedMethod;
  refresh: boolean;
};

class HeavyFeedstock extends Component<
  IHeavyFeedstockReduxProps,
  IHeavyFeedstockState
> {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      warningDialog: false,
      nextMethod: this.getCurrentMethodName(),
      refresh: false,
    };
  }

  getCurrentFeedstockMethod() {
    return (this.props.feedstock.feedstock as IHeavyFeedstock).method
      .distribution;
  }

  getCurrentMethodName(): HeavyFeedMethod {
    return HeavyFeedMethod[
      (this.props.feedstock.feedstock as IHeavyFeedstock).method.id
    ];
  }

  setTotalValue(att: string) {
    const total = (
      this.props.feedstocks.filter(
        (feed) => feed.id === this.props.feedstock.id
      )[0].feedstock.components as any
    )
      .map((c) => Number(c[att]))
      .reduce((a, b) => a + b);
    return total.toFixed(4);
  }
  handleComponentUpdate(mockE: {
    target: { id: string; name: string; value: number };
  }) {
    this.props.updateComponent(
      this.props.feedstock.id,
      mockE.target.id,
      mockE.target.value,
      mockE.target.name
    );
  }

  componentDidUpdate(prevProps) {
    if (
      (!equal(
        this.props.feedstock.feedstock.components,
        prevProps.feedstock.feedstock.components
      ) ||
        !equal(
          (this.props.feedstock.feedstock as IHeavyFeedstock).method,
          (prevProps.feedstock.feedstock as IHeavyFeedstock).method
        )) &&
      prevProps.feedstock.id === this.props.feedstock.id
    ) {
      this.props.updateSignature(this.props.feedstock.id);
    }
  }

  handleMethodValueUpdate(mockE: {
    target: { id: string; name: string; value: number };
  }) {
    this.props.updateMethod(
      this.props.feedstock.id,
      mockE.target.id,
      mockE.target.value,
      mockE.target.name,
      true,
      this.getCurrentMethodName()
    );
    this.setState({
      refresh: !this.state.refresh,
    });
  }

  toggleShowDialog() {
    this.setState({
      showDialog: !this.state.showDialog,
    });
  }

  toggleShowWarningDialog() {
    this.setState({
      warningDialog: true,
    });
  }

  // toggleUnit(feedstock_id: string, unit_type: HEAVYFEEDCOMPONENTUNIT)
  // {

  //     let component = this.props.feedstock.feedstock.components as IHeavyComponent[];

  //     //(unit_type === HEAVYFEEDCOMPONENTUNIT.DENSITY) ? console.log(" Unit value :" + (this.props.feedstock.feedstock.components as IHeavyComponent[])[0].value) : null
  //     if((unit_type === HEAVYFEEDCOMPONENTUNIT.HCRATIO) || (unit_type === HEAVYFEEDCOMPONENTUNIT.REFRECTIVEINDEX))
  //     {
  //         component[1].value = 0
  //         this.props.toggleUnit(feedstock_id,unit_type)
  //     }
  //     // console.log(" Unit value :" + (this.props.feedstock.feedstock.components as IHeavyComponent[])[1].value)
  //     // //const st = this.props.feedstock.feedstock.components
  //     // component[1].value = 0
  //     // console.log(component[1])
  //     // //(this.props.feedstock.feedstock.components as IHeavyComponent[])[1]

  // }

  chnageMethod(feedstock_id: string, component_id: HeavyFeedMethod) {
    this.props.toggleMethod(feedstock_id, component_id);
    if (this.state.showDialog) this.toggleShowDialog();
  }

  toggleMethod(feedstock_id: string, component_id: HeavyFeedMethod) {
    this.setState({
      nextMethod: component_id,
    });
    const totalTemp = (
      this.props.feedstock.feedstock as IHeavyFeedstock
    ).method?.distribution
      .map((mm, i) => Number(mm.value))
      .reduce((a, b) => a + b);
    if (totalTemp > 0) this.toggleShowDialog();
    else this.chnageMethod(feedstock_id, component_id);
  }

  // componentWillUnmount() {
  //     this.toggleShowWarningDialog();
  //     console.log("Component will unmount : " + this.state.warningDialog)
  //     return
  // }

  renderLabels(index: number) {
    return (
      <>
        {index === 0 ? (
          <>
            <label
              key="h/c_ratio"
              className="radio-buttons__item"
              title="h/c_ratio"
            >
              <input
                className={"radio-buttons__input_h/c_ratio"}
                name="filter"
                type="radio"
                onChange={() =>
                  this.props.toggleUnit(
                    this.props.feedstock.id,
                    HEAVYFEEDCOMPONENTUNIT.HCRATIO
                  )
                }
                value="wt"
                checked={
                  (this.props.feedstock.feedstock as IHeavyFeedstock).hcunit ===
                  HEAVYFEEDCOMPONENTUNIT.HCRATIO
                }
              />{" "}
              H/C Ratio
            </label>
            &emsp;
            <label
              key="refrective_index"
              className="radio-buttons__item"
              title="refrective_index"
            >
              <input
                className={"radio-buttons__input_refrective_index"}
                name="filter"
                type="radio"
                value="vol"
                onChange={() =>
                  this.props.toggleUnit(
                    this.props.feedstock.id,
                    HEAVYFEEDCOMPONENTUNIT.REFRECTIVEINDEX
                  )
                } /*checked={this.props.feedstock.unit === UNIT.VOL}*/
                checked={
                  (this.props.feedstock.feedstock as IHeavyFeedstock).hcunit ===
                  HEAVYFEEDCOMPONENTUNIT.REFRECTIVEINDEX
                }
              />{" "}
              Refractive Index
            </label>
            &emsp;
            <label
              key="unknown"
              className="radio-buttons__item"
              title="unknown"
            >
              <input
                className={"radio-buttons__input_unknown"}
                name="filter"
                type="radio"
                value="vol"
                onChange={() =>
                  this.props.toggleUnit(
                    this.props.feedstock.id,
                    HEAVYFEEDCOMPONENTUNIT.UNKNOWN
                  )
                } /*checked={this.props.feedstock.unit === UNIT.VOL}*/
                checked={
                  (this.props.feedstock.feedstock as IHeavyFeedstock).hcunit ===
                  HEAVYFEEDCOMPONENTUNIT.UNKNOWN
                }
              />{" "}
              Unknown
            </label>
          </>
        ) : null}

        {index === 2 ? (
          <>
            <label
              key="density"
              className="radio-buttons__item"
              title="density"
            >
              <input
                className={"radio-buttons__input_h/c_ratio"}
                name="filter1"
                type="radio"
                onChange={() =>
                  this.props.toggleUnit(
                    this.props.feedstock.id,
                    HEAVYFEEDCOMPONENTUNIT.DENSITY
                  )
                }
                value="wt" /*checked={this.props.feedstock.unit === UNIT.WT}*/
                checked={
                  (this.props.feedstock.feedstock as IHeavyFeedstock)
                    .denunit === HEAVYFEEDCOMPONENTUNIT.DENSITY
                }
              />{" "}
              Density
            </label>
            &emsp;
            <label
              key="specific_gravity"
              className="radio-buttons__item"
              title="specific_gravity"
            >
              <input
                className={"radio-buttons__input_refrective_index"}
                name="filter1"
                type="radio"
                value="vol"
                onChange={() =>
                  this.props.toggleUnit(
                    this.props.feedstock.id,
                    HEAVYFEEDCOMPONENTUNIT.SPGRAVITY
                  )
                } /*checked={this.props.feedstock.unit === UNIT.VOL}*/
                checked={
                  (this.props.feedstock.feedstock as IHeavyFeedstock)
                    .denunit === HEAVYFEEDCOMPONENTUNIT.SPGRAVITY
                }
              />{" "}
              Specific Gravity (15.5/15.5)
            </label>
          </>
        ) : null}

        {index === 4 ? (
          <>
            <label
              key="d_gasoil"
              className="radio-buttons__item"
              title="d_gasoil"
            >
              <input
                className={"radio-buttons__input_h/c_ratio"}
                name="filter2"
                type="radio"
                onChange={() =>
                  this.props.toggleUnit(
                    this.props.feedstock.id,
                    HEAVYFEEDCOMPONENTUNIT.DESULPGASOIL
                  )
                }
                value="wt" /*checked={this.props.feedstock.unit === UNIT.WT}*/
                checked={
                  (this.props.feedstock.feedstock as IHeavyFeedstock)
                    .pinaunit === HEAVYFEEDCOMPONENTUNIT.DESULPGASOIL
                }
              />{" "}
              Desulphurised Gasoil
            </label>
            &emsp;
            <label
              key="h_gasoil"
              className="radio-buttons__item"
              title="h_gasoil"
            >
              <input
                className={"radio-buttons__input_refrective_index"}
                name="filter2"
                type="radio"
                value="vol"
                onChange={() =>
                  this.props.toggleUnit(
                    this.props.feedstock.id,
                    HEAVYFEEDCOMPONENTUNIT.HYDROGASOIL
                  )
                } /*checked={this.props.feedstock.unit === UNIT.VOL}*/
                checked={
                  (this.props.feedstock.feedstock as IHeavyFeedstock)
                    .pinaunit === HEAVYFEEDCOMPONENTUNIT.HYDROGASOIL
                }
              />{" "}
              Hydrotreated Gasoil
            </label>
            &emsp;
            <label
              key="straightrun"
              className="radio-buttons__item"
              title="straightrun"
            >
              <input
                className={"radio-buttons__input_unknown"}
                name="filter2"
                type="radio"
                value="vol"
                onChange={() =>
                  this.props.toggleUnit(
                    this.props.feedstock.id,
                    HEAVYFEEDCOMPONENTUNIT.STRAIGHTRUN
                  )
                } /*checked={this.props.feedstock.unit === UNIT.VOL}*/
                checked={
                  (this.props.feedstock.feedstock as IHeavyFeedstock)
                    .pinaunit === HEAVYFEEDCOMPONENTUNIT.STRAIGHTRUN
                }
              />{" "}
              Straight Run
            </label>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  renderTableHead(index: number) {
    return (
      <tr>
        <th></th>
        <th>Compostion ( Wt.-% )</th>
      </tr>
    );
  }

  renderTable(x: IHeavyComponent[], index: number) {
    return (
      <>
        {/* <br/>  */}
        <table id="feedstock-table">
          {index === 4 ? (
            <thead>
              <tr>
                <th></th>
                <th>Compostion ( WT.-% )</th>
              </tr>
            </thead>
          ) : null}
          {/* <thead>
                    {(index === 4) ? this.renderTableHead(index):null}
                </thead> */}
          <tbody>
            {x.map((component, i) => {
              return (
                <tr
                  key={component.id}
                  id={"tr - ".concat(component.id)}
                  onFocus={() => true}
                >
                  {/* <td id={"name - ".concat(component.id)}>{component.name}</td> */}
                  {(this.props.feedstock.feedstock as IHeavyFeedstock)
                    .denunit === HEAVYFEEDCOMPONENTUNIT.DENSITY &&
                  component.id === "valued" ? (
                    <td id={"name - ".concat(component.id)}>
                      {component.name + " ( kg/l )"}
                    </td>
                  ) : (
                    <td id={"name - ".concat(component.id)}>
                      {component.name}
                    </td>
                  )}

                  {component.id === "temperatured" ? (
                    <EditableCell
                      key={component.id + ""}
                      cellUpdate={(mockE: {
                        target: { id: string; name: string; value: number };
                      }) => this.handleComponentUpdate(mockE)}
                      cellData={{
                        type: "Mannual Modification",
                        value: component.value, //(((this.props.feedstock.feedstock as IHeavyFeedstock).denunit) === HEAVYFEEDCOMPONENTUNIT.DENSITY) && component.id === "temperatured" ? 0: component.value,
                        id: "" + component.id,
                        disabled:
                          (this.props.feedstock.feedstock as IHeavyFeedstock)
                            .denunit === HEAVYFEEDCOMPONENTUNIT.DENSITY &&
                          component.id === "temperatured"
                            ? true
                            : false,
                      }}
                    />
                  ) : component.id === "temperatureh" ? (
                    <EditableCell
                      key={component.id + ""}
                      cellUpdate={(mockE: {
                        target: { id: string; name: string; value: number };
                      }) => this.handleComponentUpdate(mockE)}
                      cellData={{
                        type: "Mannual Modification",
                        value: component.value, //(((this.props.feedstock.feedstock as IHeavyFeedstock).hcunit) !== HEAVYFEEDCOMPONENTUNIT.UNKNOWN) && component.id === "temperatureh" ? 0: component.value,
                        id: "" + component.id,
                        disabled:
                          (this.props.feedstock.feedstock as IHeavyFeedstock)
                            .hcunit !== HEAVYFEEDCOMPONENTUNIT.HCRATIO &&
                          component.id === "temperatureh"
                            ? false
                            : true,
                      }}
                    />
                  ) : (
                    <EditableCell
                      key={component.id + ""}
                      cellUpdate={(mockE: {
                        target: { id: string; name: string; value: number };
                      }) => this.handleComponentUpdate(mockE)}
                      cellData={{
                        type: "Mannual Modification",
                        value: component.value,
                        id: "" + component.id,
                      }}
                    />
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </>
    );
  }

  renderComponent() {
    if (this.props.feedstock.feedstock.components.length > 0) {
      return (
        // <div>
        //  {this.renderLabels(0)}
        //  {this.renderTable((this.props.feedstock.feedstock.components as IHeavyComponent[]).slice(0,2),0)}
        //  {this.renderLabels(2)}
        //  {this.renderTable((this.props.feedstock.feedstock.components as IHeavyComponent[]).slice(2,4),2)}
        //  {this.renderLabels(4)}
        //  {this.renderTable((this.props.feedstock.feedstock.components as IHeavyComponent[]).slice(4,8),4)}
        //  {this.renderTable((this.props.feedstock.feedstock.components as IHeavyComponent[]).slice(8,9),8)}
        // </div>

        (this.props.feedstock.feedstock.components as IHeavyComponent[]).map(
          (component, i) => {
            // console.log("================", this.props);

            return (
              <div key={i} className="feedstock">
                {i === 0
                  ? this.renderLabels(i)
                  : i === 2
                  ? this.renderLabels(i)
                  : i === 4
                  ? this.renderLabels(i)
                  : null}

                {/* <div className="feedstock-table-wrapper"> */}
                {i === 0
                  ? this.renderTable(
                      (
                        this.props.feedstock.feedstock
                          .components as IHeavyComponent[]
                      ).slice(0, 2),
                      i
                    )
                  : i === 2
                  ? this.renderTable(
                      (
                        this.props.feedstock.feedstock
                          .components as IHeavyComponent[]
                      ).slice(2, 4),
                      i
                    )
                  : i === 4
                  ? this.renderTable(
                      (
                        this.props.feedstock.feedstock
                          .components as IHeavyComponent[]
                      ).slice(4, 9),
                      i
                    )
                  : i === 8
                  ? this.renderTable(
                      (
                        this.props.feedstock.feedstock
                          .components as IHeavyComponent[]
                      ).slice(9, 10),
                      i
                    )
                  : null}
                {/* </div> */}
              </div>
            );
          }
        )
      );
    }
  }

  render() {
    return (
      <div className="container">
        <Grid container direction="row" spacing={2}>
          {/* Heavy Feed Distribution UI */}
          <Grid item xs={5}>
            {this.renderComponent()}
          </Grid>

          <Grid item xs={2}></Grid>
          {/* Heavy Feed Method UI */}
          <Grid item xs={5}>
            <div className="feedstock">
              <label
                key="radion_simDest"
                className="radio-buttons__item2"
                title="radio_simDest"
              >
                <input
                  className={"radio-buttons__input_simDest"}
                  name="Methodfilter"
                  type="radio"
                  onChange={() =>
                    this.toggleMethod(
                      this.props.feedstock.id,
                      HeavyFeedMethod.simDest
                    )
                  }
                  value="simDest"
                  checked={
                    (this.props.feedstock.feedstock as IHeavyFeedstock).method
                      .id === HeavyFeedMethod.simDest
                  }
                />{" "}
                Sim Distillation
              </label>
              &emsp;
              <label
                key="radion_tbp"
                className="radio-buttons__item2"
                title="radio_tbp"
              >
                <input
                  className={"radio-buttons__input_tdp"}
                  name="Methodfilter"
                  type="radio"
                  onChange={() =>
                    this.toggleMethod(
                      this.props.feedstock.id,
                      HeavyFeedMethod.TBP
                    )
                  }
                  value="TBP"
                  checked={
                    (this.props.feedstock.feedstock as IHeavyFeedstock).method
                      .id === HeavyFeedMethod.TBP
                  }
                />{" "}
                TBP
              </label>
              &emsp;
              <label
                key="radion_astm"
                className="radio-buttons__item2"
                title="radio_astm"
              >
                <input
                  className={"radio-buttons__input_astm"}
                  name="Methodfilter"
                  type="radio"
                  onChange={() =>
                    this.toggleMethod(
                      this.props.feedstock.id,
                      HeavyFeedMethod.ASTM
                    )
                  }
                  value="ASTM"
                  checked={
                    (this.props.feedstock.feedstock as IHeavyFeedstock).method
                      .id === HeavyFeedMethod.ASTM
                  }
                />{" "}
                Astm D86
              </label>
              <div className="feedstock-table-wrapper">
                <table id="feedstock-table">
                  <thead>
                    <tr>
                      {(this.props.feedstock.feedstock as IHeavyFeedstock)
                        .method.id === HeavyFeedMethod.simDest ? (
                        <th>WT. %</th>
                      ) : (
                        <th>Vol. %</th>
                      )}
                      <th>Temperature ( {getTemperatureUnit()} )</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.getCurrentFeedstockMethod().map((mm, i) => {
                      return (
                        <tr
                          key={mm.id}
                          id={"tr - " + mm.id}
                          onFocus={() => true}
                        >
                          <td
                            id={"name - " + mm.id + this.getCurrentMethodName()}
                          >
                            {mm.id}
                          </td>

                          <EditableCell
                            key={this.getCurrentMethodName() + "-" + mm.id + ""}
                            cellUpdate={(mockE: {
                              target: {
                                id: string;
                                name: string;
                                value: number;
                              };
                            }) => this.handleMethodValueUpdate(mockE)}
                            cellData={{
                              type: this.getCurrentMethodName() + "-" + mm.id,
                              value: mm.value,
                              id: this.getCurrentMethodName() + "-" + mm.id,
                            }}
                          />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.toggleShowDialog()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to discard your changes?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="button" onClick={() => this.toggleShowDialog()}>
              Cancel
            </button>
            <button
              className="button"
              onClick={() =>
                this.chnageMethod(
                  this.props.feedstock.id,
                  this.state.nextMethod
                )
              }
            >
              Ok
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.warningDialog}
          onClose={() => this.toggleShowWarningDialog()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please Provide minimum 3 boiling point's value.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="button"
              onClick={() => this.toggleShowWarningDialog()}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => this.toggleShowWarningDialog()}
            >
              Ok
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connector(HeavyFeedstock);

// renderLabels(index: number) {
//     return (
//         <>
//         {
//             (index === 0) ?
//             <>
//                 <label key="h/c_ratio" className="radio-buttons__item" title="h/c_ratio">
//                     <input className={"radio-buttons__input_h/c_ratio"} name="filter" type="radio"
//                                 onChange={() => this.props.toggleUnit(this.props.feedstock.id, HEAVYFEEDCOMPONENTUNIT.HCRATIO)} value="wt"
//                                 checked={(this.props.feedstock.feedstock as IHeavyFeedstock).hcunit === HEAVYFEEDCOMPONENTUNIT.HCRATIO} /> H/C Ratio
//                 </label>
//                 &emsp;
//                 <label key="refrective_index" className="radio-buttons__item" title="refrective_index">
//                     <input className={"radio-buttons__input_refrective_index"} name="filter" type="radio" value="vol"
//                                 onChange={() => this.props.toggleUnit(this.props.feedstock.id, HEAVYFEEDCOMPONENTUNIT.REFRECTIVEINDEX)} /*checked={this.props.feedstock.unit === UNIT.VOL}*/
//                                 checked={(this.props.feedstock.feedstock as IHeavyFeedstock).hcunit === HEAVYFEEDCOMPONENTUNIT.REFRECTIVEINDEX}/> Refractive Index
//                 </label>
//                 &emsp;
//                 <label key="unknown" className="radio-buttons__item" title="unknown">
//                     <input className={"radio-buttons__input_unknown"} name="filter" type="radio" value="vol"
//                                 onChange={() => this.props.toggleUnit(this.props.feedstock.id, HEAVYFEEDCOMPONENTUNIT.UNKNOWN)} /*checked={this.props.feedstock.unit === UNIT.VOL}*/
//                                 checked={(this.props.feedstock.feedstock as IHeavyFeedstock).hcunit === HEAVYFEEDCOMPONENTUNIT.UNKNOWN}/> Unknown
//                 </label>
//             </>
//             : null
//         }

//         {

//             (index === 2) ?
//             <>
//                 <label key="density" className="radio-buttons__item" title="density">
//                     <input className={"radio-buttons__input_h/c_ratio"} name="filter1" type="radio"
//                                 onChange={() => this.props.toggleUnit(this.props.feedstock.id, HEAVYFEEDCOMPONENTUNIT.DENSITY)} value="wt" /*checked={this.props.feedstock.unit === UNIT.WT}*/
//                                 checked={(this.props.feedstock.feedstock as IHeavyFeedstock).denunit === HEAVYFEEDCOMPONENTUNIT.DENSITY}/> Density
//                 </label>
//                 &emsp;
//                 <label key="specific_gravity" className="radio-buttons__item" title="specific_gravity">
//                     <input className={"radio-buttons__input_refrective_index"} name="filter1" type="radio" value="vol"
//                                     onChange={() => this.props.toggleUnit(this.props.feedstock.id, HEAVYFEEDCOMPONENTUNIT.SPGRAVITY)} /*checked={this.props.feedstock.unit === UNIT.VOL}*/
//                                     checked={(this.props.feedstock.feedstock as IHeavyFeedstock).denunit === HEAVYFEEDCOMPONENTUNIT.SPGRAVITY}/> Specific Gravity (15.5/15.5)
//                 </label>
//             </>
//             : null
//         }

//         {
//             (index === 4) ?
//             <>
//                 <label key="d_gasoil" className="radio-buttons__item" title="d_gasoil">
//                     <input className={"radio-buttons__input_h/c_ratio"} name="filter2" type="radio"
//                                 onChange={() => this.props.toggleUnit(this.props.feedstock.id, HEAVYFEEDCOMPONENTUNIT.DESULPGASOIL)} value="wt" /*checked={this.props.feedstock.unit === UNIT.WT}*/
//                                 checked={(this.props.feedstock.feedstock as IHeavyFeedstock).pinaunit === HEAVYFEEDCOMPONENTUNIT.DESULPGASOIL}/> Desulphurised Gasoil
//                 </label>
//                 &emsp;
//                 <label key="h_gasoil" className="radio-buttons__item" title="h_gasoil">
//                     <input className={"radio-buttons__input_refrective_index"} name="filter2" type="radio" value="vol"
//                                     onChange={() => this.props.toggleUnit(this.props.feedstock.id, HEAVYFEEDCOMPONENTUNIT.HYDROGASOIL)} /*checked={this.props.feedstock.unit === UNIT.VOL}*/
//                                     checked={(this.props.feedstock.feedstock as IHeavyFeedstock).pinaunit === HEAVYFEEDCOMPONENTUNIT.HYDROGASOIL}/> Hydrotreated Gasoil
//                 </label>
//                 &emsp;
//                 <label key="straightrun" className="radio-buttons__item" title="straightrun">
//                     <input className={"radio-buttons__input_unknown"} name="filter2" type="radio" value="vol"
//                                 onChange={() => this.props.toggleUnit(this.props.feedstock.id, HEAVYFEEDCOMPONENTUNIT.STRAIGHTRUN)} /*checked={this.props.feedstock.unit === UNIT.VOL}*/
//                                 checked={(this.props.feedstock.feedstock as IHeavyFeedstock).pinaunit === HEAVYFEEDCOMPONENTUNIT.STRAIGHTRUN}/> Straight Run
//                 </label>
//             </>
//             : <></>
//         }

//         {/* Sulfur Cotent */}
//         {/* {
//             (index === 8) ?
//             <>
//                 <label key="sulfur_content" className="radio-buttons__item" title="sulfur_content">
//                     <input className={"radio-buttons__input_h/c_ratio"} name="filter3" type="radio"
//                                 onChange={() => this.props.toggleUnit(this.props.feedstock.id, HEAVYFEEDCOMPONENTUNIT.SULFURCONT)} value="wt"
//                                 checked={(this.props.feedstock.feedstock as IHeavyFeedstock).sulfurunit === HEAVYFEEDCOMPONENTUNIT.SULFURCONT}/> Sulfur content ( WT.-% )
//                 </label>
//             </>
//             : <></>
//         } */}
//     </>
//     )
// }