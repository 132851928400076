import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IDetailedPionaComponent, UNIT } from '../../interfaces/IComponent';
import { IFeedstock } from '../../interfaces/IFeedstock';
import { RootState } from '../../reducers/rootReducer';
import { toggleUnit, updateComponent, updateSignature } from '../../slices/feedstockSlice';
import EditableCell from '../editableCell';
import equal from 'fast-deep-equal';

interface IDetailedPionaFeedstockProps {
    feedstock: IFeedstock;
}

const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleUnit: (feedstock_id: string, unit_type: UNIT) => dispatch(toggleUnit({ feedstock_id, unit_type })),
        updateComponent: (feedstock_id: string, component_id: string, new_value: number, field_name: string) => dispatch(updateComponent({ feedstock_id, component_id, new_value, field_name })),
        updateSignature: (feedstock_id: string) => dispatch(updateSignature({ feedstock_id })),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IDetailedPionaFeedstockReduxProps = PropsFromRedux & IDetailedPionaFeedstockProps;
type IDetailedPionaFeedstockState = {
}

class DetailedPionaFeedstock extends Component<IDetailedPionaFeedstockReduxProps, IDetailedPionaFeedstockState> {

    setTotalValue(att: string) {

        const total = (this.props.feedstocks.filter((feed) => feed.id === this.props.feedstock.id)[0]
            .feedstock.components as any).map((c) => Number(c[att])).reduce((a, b) => a + b);
        return total.toFixed(4);
    }
    handleComponentUpdate(mockE: { target: { id: string, name: string, value: number } }) {
        this.props.updateComponent(this.props.feedstock.id, mockE.target.id, mockE.target.value, mockE.target.name);
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.feedstock.feedstock.components, prevProps.feedstock.feedstock.components) && prevProps.feedstock.id === this.props.feedstock.id) {
            this.props.updateSignature(this.props.feedstock.id);
        }
        if (prevProps.feedstock.id === this.props.feedstock.id && this.props.feedstock.unit !== prevProps.feedstock.unit) {
            this.props.updateSignature(this.props.feedstock.id);
        }
    }

    render() {
        return (
            <div className="container">
                <div className="feedstock">
                    <label key="radion_wt" className="radio-buttons__item" title="radio_wt">
                        <input className={"radio-buttons__input_wt"} name="filter" type="radio" onChange={() => this.props.toggleUnit(this.props.feedstock.id, UNIT.WT)} value="wt" checked={this.props.feedstock.unit === UNIT.WT} /> Wt %
                    </label>
                    &emsp;
                    <label key="radion_vol" className="radio-buttons__item" title="radio_vol">
                        <input className={"radio-buttons__input_VOL"} name="filter" type="radio" value="vol" onChange={() => this.props.toggleUnit(this.props.feedstock.id, UNIT.VOL)} checked={this.props.feedstock.unit === UNIT.VOL} /> VOL %
                    </label>
                    <div className="feedstock-table-wrapper">
                        <table id="feedstock-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>n-Paraffins</th>
                                    <th>I-Paraffins</th>
                                    <th>Olefins</th>
                                    <th>Naphthenes</th>
                                    <th>Aromatics</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(this.props.feedstock.feedstock.components as IDetailedPionaComponent[]).map((component, i) => {
                                    return (
                                        <tr key={component.id} id={"tr - ".concat(component.id)} onFocus={() => (true)}>
                                            <td id={"name - ".concat(component.id)}>{component.name}</td>

                                            <EditableCell
                                                key={component.id + 'N_Paraffins'}
                                                cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleComponentUpdate(mockE)}
                                                cellData={{
                                                    type: "N_Paraffins",
                                                    value: component.N_Paraffins,
                                                    id: "" + component.id
                                                }}
                                            />
                                            <EditableCell
                                                key={component.id + 'I_Paraffins'}
                                                cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleComponentUpdate(mockE)}
                                                cellData={{
                                                    type: "I_Paraffins",
                                                    value: ["C2", "C3"].indexOf(component.name) > -1 ? '' : component.I_Paraffins,
                                                    id: "" + component.id,
                                                    disabled: ["C2", "C3"].indexOf(component.name) > -1 ? true : false
                                                }}
                                            />
                                            <EditableCell
                                                key={component.id + 'Olefins'}
                                                cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleComponentUpdate(mockE)}
                                                cellData={{
                                                    type: "Olefins",
                                                    value: component.Olefins,
                                                    id: "" + component.id,
                                                }}
                                            />
                                            <EditableCell
                                                key={component.id + 'Naphthenes'}
                                                cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleComponentUpdate(mockE)}
                                                cellData={{
                                                    type: "Naphthenes",
                                                    value: ["C2", "C3", "C4"].indexOf(component.name) > -1 ? '' : component["Naphthenes"],
                                                    id: "" + component.id,
                                                    disabled: ["C2", "C3", "C4"].indexOf(component.name) > -1 ? true : false
                                                }}
                                            />
                                            <EditableCell
                                                key={component.id + 'Aromatics'}
                                                cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleComponentUpdate(mockE)}
                                                cellData={{
                                                    type: "Aromatics",
                                                    value: ["C2", "C3", "C4", "C5"].indexOf(component.name) > -1 ? '' : component.Aromatics,
                                                    id: "" + component.id,
                                                    disabled: ["C2", "C3", "C4", "C5"].indexOf(component.name) > -1 ? true : false
                                                }}
                                            />
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td>Total</td>
                                    <td className="disableCell">{this.setTotalValue("N_Paraffins")}</td>
                                    <td className="disableCell">{this.setTotalValue("I_Paraffins")}</td>
                                    <td className="disableCell">{this.setTotalValue("Olefins")}</td>
                                    <td className="disableCell">{this.setTotalValue("Naphthenes")}</td>
                                    <td className="disableCell">{this.setTotalValue("Aromatics")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default connector(DetailedPionaFeedstock);