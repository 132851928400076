import store from "..";
import { CoilObjectI, COTDataI } from "../interfaces/coil";
import { IScenario } from "../interfaces/IScenario";

/**
 * Generates data for primary timechart
 *
 * @param key {string} Key to look for in output_dict
 * @param scenarios {object} Scenarios object from Redux
 * @param selectedScenario {number} Index of currently selected scenario from Redux
 */

export const generateTimechartData = (
  key: string,
  scenarios: Array<IScenario> | undefined,
  selectedScenario: number,
  runLengthFinal: number | null | undefined,
  fireboxIndex: number = 0,
  DayView?: string | "MAX"

) => {
  const dataObj: CoilObjectI = {
    id: selectedScenario || 0,
    name: scenarios?.[selectedScenario]?.name,
    data: [],

  };

  if (scenarios !== undefined) {
    if (scenarios.length > 0) {
      let currentScenarioIsRTA = scenarios[selectedScenario].currentScenarioIsRTA;
      fireboxIndex = currentScenarioIsRTA ? fireboxIndex : 0;
      scenarios[selectedScenario]?.firebox[fireboxIndex]?.["CALCULATIONS"].filter(value => Object.keys(value).length !== 0).forEach((calculation) => {
        if (!calculation || !calculation["output_dict"]) {
          return;
        }
        if ("ERROR_CODE" in calculation["output_dict"]) {
          return;
        }
        let fixedDecimal = DayView === "1" ? 2 : 1;
        const tempCotDataObj: COTDataI = {
          runtime: currentScenarioIsRTA ? Number(Number(Number(calculation["output_dict"]["RUNLENGTH"][0][0]).toFixed(fixedDecimal))) : calculation["output_dict"]["RUNLENGTH"][0][0],
        };
        if (key === "LR") {
          if (!scenarios?.[selectedScenario]["P_XOVER"]) {
            tempCotDataObj[key] = 0;
          } else {
            tempCotDataObj[key] =
              Number(calculation["output_dict"]["CIP"][0][0] + 1.013) /
              (Number(scenarios[selectedScenario]["P_XOVER"]) + 1.013);
          }
        } else {
          if (key === "CIP") {
            tempCotDataObj[key] = calculation["output_dict"][key][0][0];
          } else {
            tempCotDataObj[key] = calculation["output_dict"][key][0][0];
          }
        }

        dataObj.data.push(tempCotDataObj);
      });
    }
  }

  // const finalLenght = scenarios[selectedScenario].RUN_LENGTH_FINAL;
  // if (finalLenght) {
  //   const indexOfLast = dataObj.data.findIndex((d) => d.runtime >= finalLenght);
  //   if (indexOfLast >= 0) {
  //     dataObj.data = dataObj.data.slice(0, indexOfLast + 1);
  //   }
  // }
  const finalLenght = Number(
    scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL?.toFixed(1)
  );
  if (finalLenght) {
    dataObj.data = dataObj.data.filter((d) => {
      return d.runtime <= finalLenght;
    });
  }
  return dataObj;
}

/**
 *
 * @param key {string} Key to look for in output_dict
 * @param scenarios {object} Scenarios object from Redux
 * @param selectedScenario {number} Index of currently selected scenario from Redux
 * @param position {number} Reference position from the primary chart
 * @param lr {optional boolean} = Laval Ratio, if it is passed, then there is different calculation
 */

export const generateDetailTimechartData = (
  key: string,
  scenarios: Array<IScenario> | undefined,
  selectedScenario: number,
  position: number,
  lr?: boolean,
  selectedFirebox: number = 0,
  DayView?: string | "MAX"
) => {
  const dataObj: CoilObjectI = {
    id: selectedScenario || 0,
    name: scenarios?.[selectedScenario]?.name,
    data: [],
  };

  if (scenarios !== undefined) {
    if (scenarios.length > 0) {
      let currentScenarioIsRTA = scenarios[selectedScenario].currentScenarioIsRTA;
      const calcsLength = scenarios
        ? scenarios[selectedScenario]?.firebox[selectedFirebox]?.["CALCULATIONS"]?.length
        : 0;
      position = position >= calcsLength || position < 0 ? 0 : position;
      if (
        calcsLength > 0 && scenarios[selectedScenario]?.firebox[selectedFirebox]?.["CALCULATIONS"]?.[position]["output_dict"] &&
        !("ERROR_CODE" in scenarios?.[selectedScenario]?.firebox[selectedFirebox]?.["CALCULATIONS"]?.[position]?.["output_dict"])
      ) {
        if (position >= calcsLength) position = calcsLength - 1;
        const values =
          scenarios?.[selectedScenario]?.firebox[selectedFirebox]?.["CALCULATIONS"]?.[position]["output_dict"][
          key
          ][0];
        const stepPercentage = 100 / (values.length - 1);
        values.forEach((value, i) => {
          let fixedDecimal = 1;
          if (key === "TWALLOUTER_MAX" && i === 0) {
            // B C      
            //  0 826.8   770.85  770.85
            //  3.1 787.9   787.9  
            //  6.3 805.5   805.5  
            let b2 = Math.round(1 * stepPercentage * 100) / 100; // 3.1 
            let b3 = Math.round(2 * stepPercentage * 100) / 100; // 6.3
            let C2 = values[1];
            let c3 = values[2];
            let dValue: any = C2 - b2 / (b3 - b2) * (c3 - C2);

            const tempCotDataObj = {
              runtime: currentScenarioIsRTA ? Number((Math.round(i * stepPercentage * 100) / 100).toFixed(fixedDecimal)) : Math.round(i * stepPercentage * 100) / 100,
              [key]: dValue, //keep it here if selected LR on CIP needs different calculation      =C2-B2/(B3-B2)*(C3-C2)
            };
            dataObj.data.push(tempCotDataObj);
          }
          else {
            const tempCotDataObj: COTDataI = {
              runtime: currentScenarioIsRTA ? Number((Math.round(i * stepPercentage * 100) / 100).toFixed(fixedDecimal)) : Math.round(i * stepPercentage * 100) / 100,
              [key]: lr ? value : key === "PCG" ? value : value, //keep it here if selected LR on CIP needs different calculation
            };
            dataObj.data.push(tempCotDataObj);
          }
        });
      }
    }
  }
  return dataObj;
};

/**
 * Generate Coil Dynamics data for all relevant keys
 *
 * @param scenarios {object} Scenarios from Redux
 * @param selectedScenario {number} Selected scenario id from Redux
 */

export const generateCoilDynamicsData = (
  scenarios: Array<IScenario> | undefined,
  selectedScenario: number,
  selectedFirebox: number,
  DayView?: string | "MAX"  
) => {
  const dataObj: { id: number; name: string; data: any[] } = {
    id: selectedScenario || 0,
    name: scenarios ? scenarios[selectedScenario]?.name : "",
    data: [],
  };

  if (scenarios !== undefined) {
    if (scenarios.length > 0) {
      let currentScenarioIsRTA = scenarios[selectedScenario].currentScenarioIsRTA;
      scenarios?.[selectedScenario]?.firebox[selectedFirebox]?.["CALCULATIONS"].forEach((calculation, i) => {
        let isCosy: boolean = false;
        if (calculation["output_cosy_dict"]) {
          isCosy = true;
          if ("ERROR_CODE" in calculation["output_cosy_dict"]) return;
        }
        if (calculation["output_dict"]) {
          if ("ERROR_CODE" in calculation["output_dict"]) return;
          const COKETHICKNESS = calculation["output_dict"]?.["COKETHICKNESS"][0];

          const feedstocks = store.getState().feedstock.feedstocks;
          const feedstock_index = feedstocks.findIndex((feedstock) => feedstock.id === scenarios?.[selectedScenario].firebox[selectedFirebox].feedstock_id);

          const C2H6_FEED = feedstocks[feedstock_index]?.feedstock?.components.find(
            (component) => component.name === "Ethane"
          )?.value; // Ethane
          const C3H8_FEED = feedstocks[feedstock_index]?.feedstock?.components.find(
            (component) => component.name === "Propane"
          )?.value; // Propane
          const C4H10_FEED = feedstocks[feedstock_index]?.feedstock?.components.find(
            (component) => component.name === "n-Butane"
          )?.value; // n-Butane

          const C2H4_CG = calculation["output_dict"]["ANA2_FRACTION"][0][6]; // Ethylene
          const C2H6_CG = calculation["output_dict"]["ANA2_FRACTION"][0][7]; // Ethane
          const C3H6_CG = calculation["output_dict"]["ANA2_FRACTION"][0][10]; // Propylene
          const C3H8_CG = calculation["output_dict"]["ANA2_FRACTION"][0][11]; // Propane
          const C4H10_CG = calculation["output_dict"]["ANA2_FRACTION"][0][22]; // n-Butane

          const ABSORBED_HEAT_ANA =
            calculation["output_dict"]["ABSORBED_HEAT_ANA"][0][0];
          const FEUDIM_RADIANT_EFFICIENCY =
            calculation["output_dict"]["FEUDIM_RADIANT_EFFICIENCY"][0][0];
          const FEUDIM_INSULATION_HEATLOSS =
            calculation["output_dict"]["FEUDIM_INSULATION_HEATLOSS"][0][0];

          let FEUDIM_FIRED_HEAT = isCosy ? calculation["output_cosy_dict"]?.COSY?.PERFORMANCE?.COMBUSTION_DATA?.FIRED_HEAT : (
            (ABSORBED_HEAT_ANA /
              (FEUDIM_RADIANT_EFFICIENCY + FEUDIM_INSULATION_HEATLOSS - 3)) *
            100
          );

          const ratio = (feed, cg) => {
            const result = ((feed - cg) / feed) * 100;
            return result >= 0 ? result : "N/A";
          };
          let fixedDecimal = DayView === "1" ? 2 : 1;
          const tempCdDataObj = {
            runtime: currentScenarioIsRTA && calculation["output_dict"] ? Number(calculation["output_dict"]["RUNLENGTH"][0][0].toFixed(fixedDecimal)) : calculation["output_dict"]["RUNLENGTH"][0][0],
            ETHYLENE: Number(C2H4_CG.toFixed(2)), // instead of ETHYLENE[ETHYLENE.length - 1] by Malte/Mustafa request
            PROPYLENE: Number(C3H6_CG.toFixed(2)), // instead of PROPYLENE[PROPYLENE.length - 1] by Malta/Mustafa request
            COKETHICKNESS: Number(COKETHICKNESS.reduce((a, b) => (a > b ? a : b)).toFixed(1)),
            PE_RATIO: C2H4_CG > 0 ? Number((C3H6_CG / C2H4_CG).toFixed(3)) : "N/A",
            C2_CONVERSION: Number(Number((ratio(C2H6_FEED, C2H6_CG))).toFixed(2)),
            C3_CONVERSION: Number(Number((ratio(C3H8_FEED, C3H8_CG))).toFixed(2)),
            C4_CONVERSION: Number(Number((ratio(C4H10_FEED, C4H10_CG))).toFixed(2)),
            ABSORBED_HEAT_ANA,
            FEUDIM_FIRED_HEAT,
          };

          dataObj.data.push(tempCdDataObj);
        }
      });
    }
  }

  const finalLenght = scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL;
  if (finalLenght) {
    dataObj.data = dataObj.data.filter((d) => {
      return d.runtime <= finalLenght;
    });
  }

  return dataObj;
};
