import { useMemo } from "react";
import { useDropzone } from 'react-dropzone';
//import refRecord_v010820210004ie from './refImport_v010820210004ie';
//import refRecord_v010820210004ie from './refImport_v010820210004ie';
import CSS from 'csstype';


const baseStyle: CSS.Properties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2',
  borderRadius: '2',
  borderColor: '#000000',
  borderStyle: 'dashed',
  backgroundColor: '#dbeefd',
  color: '#000000',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const activeStyle = {
  borderColor: 'blue',
  borderStyle: 'solid',

};

const acceptStyle = {
  borderColor: 'green'
};

const rejectStyle = {
  borderColor: 'red'
};

// interface SettingsIn {
//   Input: {
//     AIR_ENTHALPY: [[number]];
//     AMBIENT_PRESSURE: [Array<number>];
//     AMBIENT_TEMPERATURE: [Array<number>];
//     CIPMAX: [Array<number>];
//     CIT: [Array<number>];
//     COP: [Array<number>];
//     COT: [Array<number>]
//     DANA1_VALUE: [Array<number>];
//     DILUT: [Array<number>];
//     DP_TRL: [Array<number>];
//     DSCA1_VALUE: [Array<number>]
//     EXCESS: [Array<number>];
//     FLOW_HC: [Array<number>];
//     FROM_DB: true
//     FUEL_ENTHALPY: [Array<number>];
//     HUMIDITY: [Array<number>];
//     INPUT_COMPONENT_MASS: [Array<number>];
//     INPUT_COMPONENT_NAMES: [Array<number>];
//     Interval: [Array<number>];
//     KEY_COMPONENT: [Array<number>];
//     LAVAL_RATIO: [Array<number>];
//     POINTS: [Array<number>];
//     P_XOVER: [Array<number>];
//     SPEC: [Array<number>];
//     SPEC_TYPE: [Array<number>];
//     TMTMAX: [Array<number>];
//     VOLFRACTION: [Array<number>];
//     WIND_VELOCITY: [Array<number>];
//     userRunlength: [Array<number>];
//     userTMT: [Array<number>];
//   }
// }

// type LVF_Asset_type = {
//   AssetName: string;
//   Description: string;
//   Id: number;

// }

// type LVF_Input_type = {
//   AIR_ENTHALPY: [[number]];
//   AMBIENT_PRESSURE: [Array<number>];
//   AMBIENT_TEMPERATURE: [Array<number>];
//   CIPMAX: [Array<number>];
//   CIT: [Array<number>];
//   COP: [Array<number>];
//   COT: [Array<number>]
//   DANA1_VALUE: [Array<number>];
//   DILUT: [Array<number>];
//   DP_TRL: [Array<number>];
//   DSCA1_VALUE: [Array<number>]
//   EXCESS: [Array<number>];
//   FLOW_HC: [Array<number>];
//   FROM_DB: true
//   FUEL_ENTHALPY: [Array<number>];
//   HUMIDITY: [Array<number>];
//   INPUT_COMPONENT_MASS: [Array<number>];
//   INPUT_COMPONENT_NAMES: [Array<number>];
//   Interval: [Array<number>];
//   KEY_COMPONENT: [Array<number>];
//   LAVAL_RATIO: [Array<number>];
//   POINTS: [Array<number>];
//   P_XOVER: [Array<number>];
//   SPEC: [Array<number>];
//   SPEC_TYPE: [Array<number>];
//   TMTMAX: [Array<number>];
//   VOLFRACTION: [Array<number>];
//   WIND_VELOCITY: [Array<number>];
//   userRunlength: [Array<number>];
//   userTMT: [Array<number>];
// }

interface Session {
  Version: string,
  Session: object,
}

// interface Scenarios {
//   scenarios: {
//     Scenario: string,
//     Plant_Id: number,
//     Furnace_Id: number,
//     Feedstock_Ref: string,
//     FuelGas_Ref: string,
//     Convection_Ref: string,
//     Config_Ref: string,
//     CIT: number,
//     COP: number,
//     DILUT: number,
//     FLOW_HC: number,
//     SPEC: number,
//     TMTMAX: number,
//     INTERVAL: number,
//     LAVAL_RATIO: number,
//     P_XOVER: number,
//     RUNLENGTH_MAX: number
//   }
// }


//type Import_Schema= (LVF_Asset_type & LVF_Input_type) & (LVF_Asset_type & SettingsIn);
type Import_Schema = Session;

interface verifyType { required?: string, isValid: boolean, msg: string }

type InputDefinitionType = Record<string | symbol | number, verifyType>
let InputDefinition: InputDefinitionType = {}

var printJsonError = function (error, explicit) {
  //throw new Error(`[${explicit ? 'EXPLICIT' : 'INEXPLICIT'}] ${error.name}: ${error.message}`);
  console.warn(Error(`[${explicit ? 'EXPLICIT' : 'INEXPLICIT'}] ${error.name}: ${error.message}`));
}

const maxLength = 200;
const nameLengthValidator = (file) => {

  if (file.name.length > maxLength) {
    return {
      code: "name-too-large",
      message: `Name is larger then ${maxLength} characters`
    };
  }

  return null;
}


const jsonValidator = (file) => {

  if (file.type === "application/json") {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      //store result into your state array.
      try {
        // let jsonImport = JSON.parse(e.target.result)
      }
      catch (e) {
        if (e instanceof SyntaxError) {
          printJsonError(e, true);
        } else {
          printJsonError(e, false);
        }
      }

    };

    //reader.readAsDataURL(file);
    reader.readAsText(file);
    return null;

  }
  else
    return {
      code: "wrong-json",
      message: 'Json has not proper structure'
    };

}

const InputValidator = (file) => {

  let ret1 = nameLengthValidator(file);
  let ret2 = ret1 != null ? jsonValidator(file) : ret1

  return ret2;

}

const getProperty = <T extends unknown, K extends keyof T>(obj: T, key: K): T[K] => {
  return obj[key]
}

const getType = (value) => Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
const isPositive = value => typeof value === 'number' && value >= 0;
const isBool = value => typeof value === 'boolean';
// const isNull = (value) => value === null;
const isObject = objValue => objValue && typeof objValue === 'object' && objValue.constructor === Object;
const isArray = value => value && Array.isArray(value)
const isAlphabeticStr = value => typeof value === 'string' && value.length > 3 && !/[^a-z\-\/]/i.test(value);
const isAlphaNumStr = value => typeof value === 'string' && value.length > 3 && !/[^0-9a-z\-\/]/i.test(value) && /[a-z]/i.test(value[0]);


const areObjectsEqual = (objects) => {
  let elemType = getType(objects[0]);
  let sameProps = false;
  if (elemType === 'object') {
    // const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
    // const union = new Set(allKeys);
    sameProps = true;

  }
  else if (elemType === 'array' || elemType === 'number' || elemType === 'string')
    sameProps = true


  return { elemType, sameProps, };

}

const resolve = (path, obj, refRecord, separator = '.') => {
  var properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}


const checkRecordCond = (def_obj: InputDefinitionType, test_obj: Map<string, unknown>, obj: any, path: string, refRecord: any): boolean => {

  let flag = false;
  let msg = '';
  let key = path
  let value = resolve(path, obj, refRecord)
  let condition = refRecord[key]['required'] != null ? refRecord[key]['required'] : '';


  switch (condition) {

    case 'isPositive':
      flag = isPositive(value);
      msg = flag ? `${key} is not positive or a number` : '';
      break;

    case 'isBool':
      flag = isBool(value);
      msg = flag ? `${key} is not a Boolean (true/false)` : '';
      break;

    case 'isArray':
      flag = isArray(value);
      msg = flag ? `${key} is not an Array` : '';
      break;

    case 'isAlphabeticStr':
      flag = isAlphabeticStr(value);
      msg = flag ? `${key} should only be an alphabetic string` : '';
      break;

    case 'isAlphaNumStr':
      flag = isAlphaNumStr(value)
      msg = flag ? `${key} should only consist of both letters and numbers` : '';
      break;

    case 'isObject':
      flag = isObject(value)
      msg = flag ? `${key} entity should be a valid object of type "{ key: value, ... }"` : '';
      break;

    case '':
      flag = true;
      break;
  }
  InputDefinition[path]['isValid'] = flag;
  InputDefinition[path]['msg'] = msg;

  return flag;

}

//initRefSchema();
const checkRecordKey = (def_obj: InputDefinitionType, test_obj: Map<string, unknown>, key: string, refRecord: any): boolean => {

  let allkeys = Object.keys(refRecord);
  let valid_ = allkeys.some((refkey) => refkey === key);
  let msg_ = valid_ ? '' : `Key ${key} is not recognized`;

  InputDefinition[key]['isValid'] = valid_;
  InputDefinition[key]['msg'] = msg_;
  return valid_;

}

const checkRecordLength = (def_obj: InputDefinitionType, test_obj: Map<string, unknown>, key: string, refRecord: any): boolean => {
  //Call this function after checkTecordType
  let objLength = test_obj[key]['length']
  let refMaxLength = refRecord[key]['maxLength']
  let valid_ = true;


  if (refMaxLength != null) {
    valid_ = objLength <= refMaxLength;
    let msg_ = valid_ ? '' : `key ${key} has length ${objLength} but the number should not exceed ${refMaxLength}!`

    if (!valid_)
      console.warn(`Warning: key ${key} has not the proper length. Number should not exceed ${refMaxLength}!`);

    InputDefinition[key]['isValid'] = valid_;
    InputDefinition[key]['msg'] = msg_;
  }

  return valid_
}

const checkRecordType = (def_obj: InputDefinitionType, test_obj: Map<string, unknown>, key: string, refRecord: any): boolean => {


  let reqObjKey = test_obj[key]
  let msg_ = '';
  let valid_ = false;

  let objType: any;
  let refType: any;

  if (reqObjKey === undefined) {
    msg_ = `Missing Key "${key}"`;
    return false;
  }
  else {
    objType = getProperty(reqObjKey, 'type')
    refType = getProperty(refRecord[key], 'type')
  }

  if (refType === undefined) {
    msg_ = `Warning: Missing Key "${key}"" in object!`;
    return false;
  }
  else {
    valid_ = objType === refType;
    msg_ = valid_ ? '' : `${key} is not a ${objType}`


  }

  if (InputDefinition[key] === undefined)
    msg_ = `Warning: Missing Key ${key}  or Type: ${objType} does not match!`;


  //InputDefinition[key]={required: refRequiredCond_,isValid: valid_, msg: msg_}; 
  InputDefinition[key]['isValid'] = valid_;
  InputDefinition[key]['msg'] = msg_;

  return valid_;
}

const checkRecordPath = (def_obj: InputDefinitionType, test_obj: Map<string, unknown>, key: string, refRecord: any): boolean => {

  let objPath = test_obj[key]['key']
  let refPath = refRecord[key]['key']

  let valid_ = objPath === refPath;
  let msg_ = valid_ ? '' : `${key} is has not the proper path ${refPath}`

  if (!valid_)
    console.warn(`Warning: ref_type: ${refPath} and  obj_Type: ${objPath} for ${key} are not equal!`);

  InputDefinition[key]['isValid'] = valid_;
  InputDefinition[key]['msg'] = msg_;

  return valid_
}

// const obj_to_map = ((obj) => {
//   return new Map(Object.entries(obj));
// });



const hasImportSchema = (mappedObj: any, obj: any, refRecord: any): mappedObj is Import_Schema => {
  let valid: boolean = false;

  //initRefSchema();
  InputDefinition = {};

  let allObjkeys = Object.keys(mappedObj)
  for (let i = 0; i < allObjkeys.length; i++) {
    let key = allObjkeys[i]

    InputDefinition[key] = { required: '', isValid: false, msg: '' };
    valid = checkRecordKey(InputDefinition, mappedObj, key, refRecord)


  }

  let allRefkeys = Object.keys(refRecord)

  for (let i = 0; i < allRefkeys.length; i++) {
    let key = allRefkeys[i]
    valid = valid ? checkRecordType(InputDefinition, mappedObj, key, refRecord) : valid
    valid = valid ? checkRecordLength(InputDefinition, mappedObj, key, refRecord) : valid
    valid = valid ? checkRecordPath(InputDefinition, mappedObj, key, refRecord) : valid


  }

  return valid
}

let counter = 0;
const createPathRecord = (obj, parent, record0) => { // standalone recursive function
  counter = counter + 1;
  let path_;
  let val_;

  for (let key in obj) {
    if (isObject(obj[key])) {
      path_ = parent + '.' + key + ''

      val_ = { key: key, type: getType(obj[key]), depth: path_.replace(/[^.]/g, "").length }
      record0[path_] = val_;
      //InputDefinition[path_]={required: '',isValid: false, msg: ''}; 
      createPathRecord(obj[key], path_, record0)

    }
    else if (isArray(obj[key])) {
      path_ = parent + '.' + key + ''

      let { elemType, sameProps } = areObjectsEqual(obj[key]);

      val_ = { key: key, type: 'array.' + elemType, depth: path_.replace(/[^.]/g, "").length, length: obj[key].length }
      record0[path_] = val_;

      if (elemType === 'object' && sameProps)
        createPathRecord(obj[key][0], path_, record0)
      else if (!sameProps)
        throw new Error(`Objects in array ${path_} have not the same size!`);


    }
    else {
      path_ = parent + '.' + key + ''
      val_ = { key: key, type: typeof obj[key], depth: path_.replace(/[^.]/g, "").length }
      record0[path_] = val_;

    }

  }

  return record0 || {};
}

const onDrop = (file, props) => {

  if (file[0] && file[0].type === "application/json") {

    const reader = new FileReader();
    reader.onload = (e: any) => {

      try {

        let jsonImport: Import_Schema = JSON.parse(e.target.result)
        let record0: Record<string, { key: string, type: string, depth: number }> = {}

        counter = 0;
        let droppedObjPath = createPathRecord(jsonImport, "root", record0);

        let allowImport: any = {};
        let versions = ['010820210004ie', '291020210001ie']


        if (versions.indexOf(jsonImport['Version']) > -1) {
          const module = "./refImport_v" + jsonImport['Version'];
          import(`${module}`).then(
            ref => {
              allowImport = hasImportSchema(droppedObjPath, jsonImport, ref.refRecord);

            });

        } else throw new Error(`Wrong Version!`);


        props.onDrop(allowImport, jsonImport)

      }
      catch (e) {
        if (e instanceof SyntaxError) {
          printJsonError(e, true);
        } else {
          printJsonError(e, false);
        }
      }

    };

    reader.readAsText(file[0]);
    return null;

  }
  else
    return {
      code: "wrong-json",
      message: 'Json has not proper structure'
    };

}

const DropzoneComponent = (props) => {


  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    disabled: false,
    accept: 'application/json',
    maxFiles: 1,
    onDrop: (file) => onDrop(file, props),
    validator: InputValidator
  });


  const files = acceptedFiles.map(file => {
    return (<li key={file.name}>
      {file.name} - {(file.size / 1024).toFixed(2)} kb
    </li>)
  });

  const DropZoneStyle = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragActive && isDragAccept ? acceptStyle : {}),
    ...(isDragActive && isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div >
      <div {...getRootProps()} style={DropZoneStyle}>
        <input {...getInputProps()} />
        <p >Double click to select a file or drag 'n' drop the Session file here</p>
        <em> (Only one *.json with specific structure will be accepted)</em>
      </div >
      <aside>
        <h4>Selected File:</h4>
        <ul style={{ listStyle: 'none' }}>{files}</ul>
        <br></br>
      </aside>
    </div>
  );
}

export default DropzoneComponent;