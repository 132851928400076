import { Component } from "react";
import { Box, Checkbox, Grid, Paper, Typography, Tooltip } from "@material-ui/core";
import LibraryAddOutlined from '@material-ui/icons/LibraryAddOutlined';
import LibraryAddRounded from '@material-ui/icons/LibraryAddRounded';
import { connect, ConnectedProps } from "react-redux";
import { getScenarioRunlengthFromJson, IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { RootState } from "../../reducers/rootReducer";
import { deleteScenarioRunlength, modifyScenarioRunlength, setFurnaceFeedCombination, setStatusScenarioInRunlength, updateExportable, updateScenarioCaseId } from "../../slices/scenarioSimulatorSlice";
import AddScenario from "./addScenario"
import ScenarioCard from "./scenarioCard"
import { MODE, SCENARIO_PROGRESS, SCENARIO_STATUS } from "../../interfaces/IScenario";
import UpdateRunlength from "./updateRunlength";
import { REACT_APP_APIM_URL_COMPUTE, REACT_APP_APIM_URL_METADATA, REACT_APP_APIM_URL_SCENARIO } from "../../utilities/GlobalConstants";

import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import DeleteScenario from "./deleteScenario";
import { BarLoader } from "react-spinners";
import { loadScenarioRunlengths } from "../../utilities/loadScenarioRunlengths";
import RenameScenarioRunlength from "./renameScenarioRunlength";
import CloneScenarioRunlength from "./cloneScenarioRunlength";
import DeleteScenarioRunlength from "./deleteScenarioRunlength"
import { getScenarioRunlengthById } from "../../services/scenarioSimulatorServices";


const FurnaceIcon = require("../../assets/images/furnace_icon.svg");

interface IScenarioRunlengthCardProps {
    scenarioRunlength: IScenarioRunlength;
    allExportable: boolean;
    chkAllExportableState: boolean;
    key: string;
    updateAllExportable: () => void
}

const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks,
        fuelGases: state.fuelGas.fuelGases,
        convectionSections: state.convectionsection.convectionsections,
        token: state.authState.token,
        accessibleFeatures: state.features.accessibleFeatures,
        scenarioRunlengths: state.scenarioSimulator.loadedScenarioRunlengths,
        scenarioRunlengthsData: state.scenarioSimulator.scenarioRunlengths,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteScenarioRunlength: (scenarioRunlength: IScenarioRunlength) => dispatch(deleteScenarioRunlength(scenarioRunlength)),
        setStatusScenarioInRunlength: (runlength_id: string, scenario_id: string, status: SCENARIO_STATUS) => dispatch(setStatusScenarioInRunlength({ runlength_id, scenario_id, status })),
        updateScenarioCaseId: (runlength_id: string, scenario_id: string, case_id: string) => dispatch(updateScenarioCaseId({ runlength_id, scenario_id, case_id })),
        setFurnaceFeedCombination: (furnacefeed: any[]) => dispatch(setFurnaceFeedCombination({ furnacefeed })),
        updateExportable: (runlength_id: string, scenario_id: string, isExportable: boolean,) => dispatch(updateExportable({ runlength_id, scenario_id, isExportable })),
        modifyScenarioRunlength: (newScenarioRunlength: IScenarioRunlength) => dispatch(modifyScenarioRunlength(newScenarioRunlength)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IScenarioRunlengthCardReduxProps = PropsFromRedux & IScenarioRunlengthCardProps;

type IScenarioRunlengthCardState = {
    saveButtonClickable: boolean,
    isExportable: boolean,
    importExportFeature: boolean
}

/*function StyledPaper(props) {
    const { classes,children } = props;
    return <Paper className={classes.root}>{children}</Paper>;
  }

  const styles = {
    root: { borderRadius: 50, borderColor: "red", padding: 50 },
  };

let Paper2= withStyles(styles)(StyledPaper);*/

class ScenarioRunlengthCard extends Component<IScenarioRunlengthCardReduxProps, IScenarioRunlengthCardState>{

    constructor(props) {
        super(props);
        this.state = {
            saveButtonClickable: true,
            isExportable: this.props.scenarioRunlength?.isExportable ?? false,
            importExportFeature: this.props.accessibleFeatures.includes('IMPORT_EXPORT')
        }
    }
    componentDidMount() {
        this.getFurnaceOperatingCombinations();
    }
    handleDeletion() {
        this.props.deleteScenarioRunlength(this.props.scenarioRunlength);
    }

    componentDidUpdate(PrevProps) {
        if (PrevProps.allExportable !== this.props.allExportable && this.props.chkAllExportableState) {
            this.setState({
                isExportable: this.props.allExportable
            })
        }
    }

    async handleSave() {
        this.setState({
            saveButtonClickable: false
        })

        let scenariodata = JSON.parse(JSON.stringify(this.props.scenarioRunlength));
        // scenariodata.name = scenariodata.name;
        let cases_id = (!!this.props.scenarioRunlength.cases_id && this.props.scenarioRunlength.cases_id > 0) ? this.props.scenarioRunlength.cases_id : 0;

        const input = JSON.stringify(scenariodata)
        let feedstocks = [this.props.feedstocks.find(feedstock => feedstock.id === this.props.scenarioRunlength.scenarios[0].firebox[0].feedstock_id)];
        if (this.props.scenarioRunlength.scenarios[0].mode === MODE.HYBRID) {
            feedstocks.push(this.props.feedstocks.find(feedstock => feedstock.id === this.props.scenarioRunlength.scenarios[0].firebox[1].feedstock_id));
        }
        let scenarioToStore = {
            AssetName: this.props.scenarioRunlength.furnaceId + " @ " + this.props.scenarioRunlength.plantId,
            Description: this.props.scenarioRunlength.scenarios[0].name + " @ " + this.props.scenarioRunlength.name,
            SettingsIn: input,
            SettingsOut: [{
                scenario_id: this.props.scenarioRunlength.scenarios[0].id,
                feedstock: this.props.feedstocks.find(feedstock => feedstock.id === this.props.scenarioRunlength.scenarios[0].firebox[0].feedstock_id),
                fuelGas: this.props.fuelGases.find(fuelGas => fuelGas.id === this.props.scenarioRunlength.scenarios[0].fuelGas_id),
                convectionSection: this.props.convectionSections.find(cs => cs.id === this.props.scenarioRunlength.scenarios[0].convectionsection_id)
            }],
            id: cases_id.toString(),
            name: this.props.scenarioRunlength.name,
            signature: this.props.scenarioRunlength.signature,
            is_delete: false,
            is_public: true,
        };

        const check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/scenarios`, {
            method: "POST",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.token,
            },
            body: JSON.stringify(scenarioToStore),
        })
            .then((res) => {
                return res.json();
            });

        await loadScenarioRunlengths();

        setTimeout(async () => {
            let getIndex = this.props.scenarioRunlengths.findIndex(scenario => scenario.cases_id === check.id)

            if (getIndex > -1) {
                const newScenarioRunlength: IScenarioRunlength = {
                    ...this.props.scenarioRunlengths[getIndex],
                    is_saved: true,
                    is_display_leave_popup: false
                }

                await this.props.modifyScenarioRunlength(newScenarioRunlength);
            } else {

                const newScenarioRunlength101: IScenarioRunlength = {
                    ...this.props.scenarioRunlength,
                    cases_id: check.id,
                }

                let getScenarioDromDbAll = await getScenarioRunlengthById(newScenarioRunlength101)
                if (!!getScenarioDromDbAll.data && getScenarioDromDbAll.data.length > 0) {
                    let getScenarioDromDb = getScenarioRunlengthFromJson(getScenarioDromDbAll.data[0]['SettingsIn']) as IScenarioRunlength;
                    const newScenarioRunlength: IScenarioRunlength = {
                        ...getScenarioDromDb,
                        is_display_leave_popup: false,
                        is_saved: true,
                        signature: getScenarioDromDbAll.data[0]['signature'],
                        ts: getScenarioDromDbAll.data[0]['ts'],
                        cases_id: getScenarioDromDbAll.data[0]['id']
                    }
                    this.props.modifyScenarioRunlength(newScenarioRunlength)
                }
            }

            this.setState({
                saveButtonClickable: true
            })
        }, 3000);
        return await check;
    }


    getFurnaceOperatingCombinations() {
        const combinations: any[] = [];

        fetch(`${REACT_APP_APIM_URL_METADATA}/furnaces/operatingcombination`, {
            method: "GET",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.props.token,
            },
        })
            .then((res) => res.json())
            .then(
                (data) => {
                    // map object to array
                    for (const furnace of Object.entries(data)) {
                        combinations.push(furnace[1]);
                    }
                    let final_combinations = combinations.reduce((com, { furnace_type_id, feed_type_id }) => {
                        const existing = com.find(i => i.furnace_type_id === furnace_type_id)
                        if (existing) { existing.feed_type_id.push(feed_type_id) }
                        else { com.push({ furnace_type_id, feed_type_id: [feed_type_id] }) }
                        return com
                    }, [])
                    this.props.setFurnaceFeedCombination(final_combinations)
                },
                (error) => console.log("error", error)
            );
    }

    async handleStopCalculation(scenario) {

        // Update redux status
        this.props.scenarioRunlength.scenarios.forEach(s => {
            if (s.status === SCENARIO_STATUS.RUNNING && s.id === scenario.id) {
                this.props.setStatusScenarioInRunlength(this.props.scenarioRunlength.id, s.id, SCENARIO_STATUS.STOP_CALCULATION);
                this.props.updateScenarioCaseId(this.props.scenarioRunlength.id, scenario.id, "");
            }
        });

        // Stop backend computaion if started
        scenario.case_id.split(",").forEach(async (caseid) => {

            if (caseid !== "") {
                await fetch(`${REACT_APP_APIM_URL_COMPUTE}/computeStop/${scenario.case_id}`, {
                    // const check = await fetch(`${REACT_APP_APIM_URL_COMPUTE}/compute/computeStop/${scenario.case_id}`, {
                    method: "GET",
                    headers: {
                        Accept: "*/*",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + this.props.token,
                    },
                })
                    .then((res) => {
                        return res.json();
                    });
            }
        })
    }

    getProgressbarText = () => {
        const currSecnario = this.props.scenarioRunlength.scenarios[0];
        const firebox1Length = currSecnario.firebox[0].CALCULATIONS.length - 1;
        const firebox2Length = currSecnario.mode === MODE.HYBRID ? currSecnario.firebox[1].CALCULATIONS.length - 1 : -1;
        const interval = currSecnario.INTERVAL;
        const runlengthMax = currSecnario.RUNLENGTH_MAX;
        const hybridModeLength = Math.min(firebox1Length, firebox2Length) === -1 ? 0 : Math.min(firebox1Length, firebox2Length);

        const text = currSecnario.mode === MODE.HYBRID ?
            (
                (currSecnario.scenario_progress !== SCENARIO_PROGRESS.Looping)
                    ? currSecnario.scenario_progress
                    : (firebox1Length === -1 && firebox2Length === -1 ? "0/" + runlengthMax : (hybridModeLength * interval) + "/" + runlengthMax)
            ) :
            (currSecnario.scenario_progress !== SCENARIO_PROGRESS.Looping
                ? currSecnario.scenario_progress
                : (firebox1Length === -1 ? "0/" + runlengthMax : (firebox1Length * interval) + "/" + runlengthMax)
            );
        return text;
    }
    getProgressbarVal = () => {
        const currentText = this.getProgressbarText()
        let val = 0
        if (currentText.indexOf('/') > -1)
        {
            let numerals : any[] = currentText.split("/")
            val = (numerals[0]/numerals[1]) * 100
        }
        else
        {
            val = 0
        }
        return val;
    }

    exportScenario = () => {
        this.setState({
            isExportable: !this.state.isExportable,
        }, async () => {
            await this.props.updateExportable(this.props.scenarioRunlength.id, this.props.scenarioRunlength.scenarios[0].id, this.state.isExportable);
            this.props.updateAllExportable();
        });
    }
    render() {
        return (

            <div className={"scenario-runlength-card"} key={this.props.key}>

                {this.props.scenarioRunlength.scenarios.find(scenario => scenario.status === SCENARIO_STATUS.RUNNING) !== undefined ?
                    <Paper className="calculating">
                        <div className="right-column">
                            <Box display="flex" flexDirection="row" justifyContent="flex-end" paddingRight={2}>
                                <button
                                    className="button margin-right margin-top button-stop"
                                    onClick={() => this.handleStopCalculation(this.props.scenarioRunlength.scenarios[0])}
                                >
                                    Stop Simulation
                                </button>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="center" paddingRight={2}>
                                <div className="circularProgressbar">
                                    <CircularProgressbar
                                        maxValue={100}
                                        styles={buildStyles({
                                            // Text size
                                            textSize: '16px'
                                        })} value={this.getProgressbarVal()} text={this.getProgressbarText()} />
                                </div>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="center" paddingRight={2} paddingTop={2}>
                                <BarLoader color="#36B0D7" />
                            </Box>
                        </div>
                    </Paper> : <div />
                }
                <Paper className="paper" onClick={() => { }}>
                    <div className="scenario-runlength-content">
                        <img className="furnace-image" alt="complex" src={FurnaceIcon.default} />
                        <div className="right-column">
                            <div className="header">
                                <Grid container justifyContent="center" direction="row" >
                                    <Grid item xs={12} md={10} lg={11} >
                                        <Typography>{this.props.scenarioRunlength.name}</Typography>
                                        {/* {isRTA() ? <Typography>{this.props.scenarioRunlength.type}</Typography> : <></>} */}
                                    </Grid>

                                    {(this.props.scenarioRunlength?.scenarios.length === 0 || this.props.scenarioRunlength?.scenarios?.find(scenario => scenario.status !== SCENARIO_STATUS.RUNNING)) && this.state.importExportFeature ?

                                        <Grid item xs={12} md={2} lg={1} >
                                            <Box
                                                //display="flex" 
                                                //flexDirection="row" 
                                                justifyContent="flex-end"
                                            >   <Tooltip title="Add to selection">
                                                    <Checkbox
                                                        color="primary"
                                                        disabled={!(this.state.saveButtonClickable && this.props.scenarioRunlength.scenarios.length > 0 && this.props.feedstocks.length > 0 && this.props.scenarioRunlength.scenarios[0].status === SCENARIO_STATUS.COMPUTED)}
                                                        checked={this.props.scenarioRunlength.isExportable && ((this.state.saveButtonClickable && this.props.feedstocks.length > 0 && this.props.scenarioRunlength.scenarios.length > 0 && this.props.scenarioRunlength.scenarios[0].status === SCENARIO_STATUS.COMPUTED))}
                                                        onChange={this.exportScenario}
                                                        icon={<LibraryAddOutlined />}
                                                        checkedIcon={<LibraryAddRounded />}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        :
                                        null
                                    }
                                </Grid>

                            </div>
                            <div className="runlength">
                                {this.props.scenarioRunlength.scenarios.map(scenario => (
                                    <ScenarioCard key={scenario.id} scenario={scenario} runlength_id={this.props.scenarioRunlength.id} feedstocks={this.props.feedstocks} />
                                ))}
                                {this.props.scenarioRunlength.scenarios.length === 0 ?
                                    <AddScenario scenarioRunlengthId={this.props.scenarioRunlength.id} /> :
                                    <div />}
                            </div>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end" >
                                <RenameScenarioRunlength scenarioRunlength={this.props.scenarioRunlength} />
                                <CloneScenarioRunlength scenarioRunlength={this.props.scenarioRunlength} />
                                <UpdateRunlength scenarioRunlength={this.props.scenarioRunlength} />
                                <button
                                    className={(this.props.scenarioRunlength.scenarios.length > 0 && this.props.scenarioRunlength.scenarios[0].status === SCENARIO_STATUS.COMPUTED) && this.props.scenarioRunlength.is_saved === false ? "button margin-right red" : "button margin-right"}
                                    onClick={() => this.handleSave()}
                                    disabled={!(this.state.saveButtonClickable && this.props.scenarioRunlength.scenarios.length > 0 && this.props.scenarioRunlength.scenarios[0].status === SCENARIO_STATUS.COMPUTED)}
                                >
                                    Save Runlength
                                </button>
                                <DeleteScenarioRunlength scenarioRunlength={this.props.scenarioRunlength} />
                                <DeleteScenario scenarioRunlength={this.props.scenarioRunlength} />
                            </Box>
                        </div>
                    </div>
                </Paper>

            </div >
        );
    }

};


export default connector(ScenarioRunlengthCard);
