import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.scss";
import { rootReducer } from "./reducers/rootReducer";
import * as serviceWorker from "./serviceWorker.jsx";
import { REACT_APP_LOGGING } from "./utilities/GlobalConstants";
import localforage from 'localforage';
import { AuthProvider } from 'react-oidc-context';
import { oidcAuthConfig } from './components/authProvider';

if (REACT_APP_LOGGING === "FALSE") {
  console.log = () => { };
}

const persistConfig = {
  key: "root",
  blacklist: ["coilState", "exampleState", "errorsState", "feedstockState"],
  storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = (process.env.NODE_ENV !== 'production' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()) || compose;
export const store = createStore(persistedReducer, composeEnhancers);
const persistor = persistStore(store);

function onSigninCallback() {
  window.location.href = "/";
}

export default store;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider {...oidcAuthConfig} onSigninCallback={onSigninCallback}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
