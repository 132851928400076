import React, { Component } from "react";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { getFeedstockTypesAvailable } from "../../configuration";
import { UNIT } from "../../interfaces/IComponent";
import {
  FEEDSTOCK_STATUS,
  FEEDSTOCK_TYPES,
  getFeedstockByType,
  IFeedstock,
} from "../../interfaces/IFeedstock";
import { RootState } from "../../reducers/rootReducer";
import { addFeedstock } from "../../slices/feedstockSlice";
import { checkFeedStockName } from "../../services/feedstockServices";

interface IAddFeedstockProps {
  feedstock: IFeedstock;
}

const mapStateToProps = (state: RootState) => {
  return {
    currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
    currentPlantId: state.scenarioSimulator.currentPlantId,
    feedstocks: state.feedstock.feedstocks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFeedstock: (newFeedstock: IFeedstock) =>
      dispatch(addFeedstock(newFeedstock)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IAddFeedstockReduxProps = PropsFromRedux & IAddFeedstockProps;

type IAddFeedstockState = {
  showDialog: boolean;
  name: string;
  type: FEEDSTOCK_TYPES;
  isPublic: boolean;
  isNameAvailable: boolean;
  nameErrorMessage: string;
  showLoading: boolean;
};

class AddFeedstock extends Component<
  IAddFeedstockReduxProps,
  IAddFeedstockState
> {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      name: "",
      type: getFeedstockTypesAvailable()[0],
      isPublic: true,
      isNameAvailable: false,
      nameErrorMessage: "",
      showLoading: false,
    };
  }

  toggleShowDialog() {
    this.setState({
      showDialog: !this.state.showDialog,
      name: "",
      isNameAvailable: false,
      nameErrorMessage: "",
    });
  }

  handleNameInput(event) {
    this.setState({
      name: event.target.value.toUpperCase(),
    });
  }

  async addNewFeedstock() {
    if (!!this.state.name?.trim()) {
      this.setState({
        showLoading: true,
      });
      // Default feed_type is 2, define feedtype based on feedstock type
      let feed_type = "2";
      if (
        this.state.type === FEEDSTOCK_TYPES.SHORT_PIONA ||
        this.state.type === FEEDSTOCK_TYPES.DETAILED_PIONA
      ) {
        feed_type = "4";
      }
      if (this.state.type === FEEDSTOCK_TYPES.HEAVY_FEED) {
        feed_type = "5";
      }
      let setName =
        this.state.isPublic === true
          ? this.state.name.trim() + "(PUBLIC)"
          : this.state.name.trim();

      const newFeedstock: IFeedstock = {
        id: uuidv4(),
        name: setName,
        unit: UNIT.WT,
        type: this.state.type,
        feedstock: getFeedstockByType(this.state.type),
        feed_type_id: feed_type,
        status: FEEDSTOCK_STATUS.UNCOMPUTED,
        error_message: "",
        signature: "",
        is_public: this.state.isPublic,
        is_display_leave_popup: false,
      };

      try {
        let isNameInRedux = this.props.feedstocks.some(
          (feedstock) => feedstock.name === newFeedstock.name
        );
        if (isNameInRedux) {
          this.setState({
            isNameAvailable: true,
            nameErrorMessage: "Name already exist!",
            showLoading: false,
          });
        } else {
          let isNameAvailable = await checkFeedStockName(newFeedstock);

          if (isNameAvailable.status) {
            this.setState({
              isNameAvailable: true,
              nameErrorMessage: !!isNameAvailable.message
                ? isNameAvailable.message
                : "Name already exist!",
              showLoading: false,
            });
          } else {
            this.props.addFeedstock(newFeedstock);
            this.toggleShowDialog();
            this.setState({
              showLoading: false,
            });
          }
        }
      } catch (error) {
        // Api error hendaling
        this.setState({
          isNameAvailable: true,
          showLoading: false,
          nameErrorMessage: "Oops somthing went to wrong!",
        });
      }
    } else {
      this.setState({
        isNameAvailable: true,
        nameErrorMessage: "Please enter a name!",
        showLoading: false,
      });
    }
  }

  handleTypeChange(event) {
    this.setState({
      type: event.target.value,
    });
  }

  handleChangeIsPublic(event) {
    this.setState({
      isPublic: !this.state.isPublic,
    });
  }

  render() {
    return (
      <div className="control-header element">
        <button
          className="button"
          onClick={() => this.toggleShowDialog()}
          disabled={
            !this.props.feedstock.is_saved &&
            this.props.feedstock.name !== "default"
          }
        >
          Add
        </button>
        <Dialog
          open={this.state.showDialog}
          onClose={() => this.toggleShowDialog()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add new feedstock</DialogTitle>
          <DialogContent className="hight-220">
            <Typography component={"span"} variant={"body2"}>
              To add a new feedstock, please provide the following information:
            </Typography>
            <Grid container spacing={2}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name*"
                    type="text"
                    fullWidth
                    defaultValue={this.state.name}
                    value={this.state.name}
                    onChange={(event) => this.handleNameInput(event)}
                    helperText={this.state.nameErrorMessage}
                    error={this.state.isNameAvailable}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    id={"type"}
                    select
                    label={"Type"}
                    defaultValue={this.state.type}
                    onChange={(event) => this.handleTypeChange(event)}
                  >
                    {getFeedstockTypesAvailable().map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isPublic}
                        onChange={this.handleChangeIsPublic.bind(this)}
                        name="isPublic"
                        value={this.state.isPublic}
                        color="primary"
                      />
                    }
                    label="Is Public?"
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <button className="button" onClick={() => this.toggleShowDialog()}>
              Cancel
            </button>
            <button
              className="button"
              onClick={() => this.addNewFeedstock()}
              disabled={this.state.showLoading}
            >
              Add{" "}
              {this.state.showLoading === true && (
                <Fade
                  in={this.state.showLoading}
                  style={{
                    transitionDelay: this.state.showLoading ? "800ms" : "0ms",
                  }}
                  unmountOnExit
                >
                  <CircularProgress size={11} />
                </Fade>
              )}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connector(AddFeedstock);
