import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { getScenarioInputsForProductPredictorDefinations } from '../../configuration';
import { IProductPredictorGroup, IProductPredictorScenario } from '../../interfaces/IProductPredictor';
import { RootState } from '../../reducers/rootReducer';
import { addGroupConfiguration, setProgress, updateSignature, setIsExportable } from '../../slices/productPredictorSlice';

interface IProductPredictorGroupConfig {
    ok_button_text: string,
    delete_button: boolean,
    toggleShowDialog: () => void,
    currentProductPredictorGroupConfigurationGrp: IProductPredictorGroup,
    currentProductPredictorScenario: IProductPredictorScenario,
    showDialog: boolean,
    currentGroupName: string,
    groupLabelName: string,
    grpMaxFurnace: number,
    configAdded: () => void
}

const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks,
        fuelGases: state.fuelGas.fuelGases,
        convectionsections: state.convectionsection.convectionsections.filter(x => x.furnace_id === state.scenarioSimulator.currentFurnaceId),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addGroupConfiguration: (grpName: string, groupDetails: IProductPredictorGroup) => dispatch(addGroupConfiguration({ groupName: grpName, groupdetails: groupDetails })),
        updateSignature: (obj: IProductPredictorScenario) => dispatch(updateSignature(obj)),
        setProgress: (scenario_id: string) => dispatch(setProgress({ scenario_id: scenario_id, progress: Number(0) })),
        setIsExportable: (scenario_id: string, isTrueOrFalse: boolean) => dispatch(setIsExportable({ scenario_id, isTrueOrFalse }))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProductPredictorGroupConfigFromRedux = PropsFromRedux & IProductPredictorGroupConfig

type IProductPredictorGroupConfigState = {
    fuelGasId: string,
    convectionSectionId: string,
    flowrate: number,
    currentProductPredictorGroupConfigurationGroup: IProductPredictorGroup,
    predictionTime: number,
    furnaceNumber: number,
    pressure: number
}

class ProductPredictorGroupConfig extends Component<IProductPredictorGroupConfigFromRedux, IProductPredictorGroupConfigState> {



    getInitialState() {
        return {
            currentProductPredictorGroupConfigurationGroup: this.props.currentProductPredictorGroupConfigurationGrp,
            flowrate: this.props.currentProductPredictorGroupConfigurationGrp.flowrate,
            fuelGasId: this.props.currentProductPredictorScenario.fuelgas_id,
            convectionSectionId: this.props.currentProductPredictorGroupConfigurationGrp.convectionsection_id,
            predictionTime: this.props.currentProductPredictorScenario.prediction_time,
            furnaceNumber: this.props.currentProductPredictorGroupConfigurationGrp.furnace_number,
            pressure: this.props.currentProductPredictorScenario.pressure
        }
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    toggleShowDialog() {
        this.props.toggleShowDialog();
    }
    handleFlowRateInput(event) {

    }

    handleFuelGasChange(event) {
        this.setState({
            fuelGasId: event.target.value,
        });
    }

    addNewGroupConfiguration() {
        let newObj: IProductPredictorGroup = {
            convectionsection_id: this.state.convectionSectionId,
            name: this.state.currentProductPredictorGroupConfigurationGroup.name,
            flowrate: this.state.flowrate,
            CIT: this.state.currentProductPredictorGroupConfigurationGroup.CIT,
            DILUT: this.state.currentProductPredictorGroupConfigurationGroup.DILUT,
            SPEC: this.state.currentProductPredictorGroupConfigurationGroup.SPEC,
            SPEC_TYPE: this.state.currentProductPredictorGroupConfigurationGroup.SPEC_TYPE,
            KEY_COMPONENT: this.state.currentProductPredictorGroupConfigurationGroup.KEY_COMPONENT,
            furnace_number: this.state.furnaceNumber,
            modified: true
        }
        this.props.addGroupConfiguration(this.props.currentGroupName, newObj);
        this.props.toggleShowDialog();
        this.props.configAdded();
        this.props.updateSignature(this.props.currentProductPredictorScenario)
        this.props.setProgress(this.props.currentProductPredictorScenario?.id)
        this.props.setIsExportable(this.props.currentProductPredictorScenario.id, false)
    }

    deleteGroupConfiguration() {

    }

    handleInput(event, id) {
        const { value } = event.target;
        this.setState(
            prevState => ({
                currentProductPredictorGroupConfigurationGroup: {
                    ...prevState.currentProductPredictorGroupConfigurationGroup,
                    [id]: value
                }
            }));

    }
    handleFurnaceNumberInput(event) {
        this.setState({
            furnaceNumber: event.target.value
        })
    }

    getDefaultScenarioInputs() {
        let scenarioInputs = {};
        getScenarioInputsForProductPredictorDefinations(this.state.currentProductPredictorGroupConfigurationGroup.furnacetype_id!, this.state.currentProductPredictorGroupConfigurationGroup.feedtype_id!).forEach((input) => {
            const id = input.id;
            if (input.type === "SELECT") {
                scenarioInputs[id] = input.selected_value;
            }
            else {
                scenarioInputs[id] = input.design;
            }
        });
        return scenarioInputs;
    }

    componentWillReceiveProps({ currentProductPredictorGroupConfigurationGrp, currentProductPredictorScenario }) {
        this.setState({
            currentProductPredictorGroupConfigurationGroup: currentProductPredictorGroupConfigurationGrp,
            flowrate: currentProductPredictorGroupConfigurationGrp.flowrate,
            furnaceNumber: currentProductPredictorGroupConfigurationGrp.furnace_number,
            predictionTime: this.props.currentProductPredictorScenario.prediction_time,
            convectionSectionId: currentProductPredictorGroupConfigurationGrp.convectionsection_id,
            fuelGasId: this.props.currentProductPredictorScenario.fuelgas_id,
            pressure: this.props.currentProductPredictorScenario.pressure,
        })
    }

    render() {
        const furnaceFieldLabel = "Furnace Number (Max Number " + this.props.grpMaxFurnace + ")"
        return (
            <div>

                <Dialog open={this.props.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.groupLabelName} Furnace / Max Number {this.props.grpMaxFurnace} - Configurator</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please provide the following information:
                        </DialogContentText>
                        <Box display="flex" justifyContent="flex-start" >
                            <Box mr={2}>
                                <TextField
                                    id={"fuelGas"}
                                    select
                                    margin="dense"
                                    SelectProps={{ autoWidth: true }}
                                    error={this.state.fuelGasId === undefined || this.state.fuelGasId === ""}
                                    helperText={this.state.fuelGasId === undefined || this.state.fuelGasId === "" ? "Please define a fuelgas first" : ""}
                                    label={"FuelGas"}
                                    defaultValue={this.state.fuelGasId}
                                    onChange={(event) => this.handleFuelGasChange(event)}
                                    disabled
                                >
                                    {this.props.fuelGases.map((fuelGas) => (
                                        <MenuItem key={fuelGas.id} value={fuelGas.id}>
                                            {fuelGas.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box>
                                <Autocomplete
                                    options={this.props.convectionsections}
                                    id="convectionSection"
                                    value={this.props.convectionsections.filter((c) => c.id === this.state.convectionSectionId)[0]}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            convectionSectionId: newValue?.id ?? ""
                                        });
                                    }}
                                    className="autocomplete"
                                    renderInput={(params) => <TextField {...params} label="Convection Section" />}
                                />

                            </Box>
                        </Box>
                        <TextField
                            margin="dense"
                            id="_FlowHC"
                            label="Fresh Feed HCLO (kg/h)"
                            type="text"
                            fullWidth
                            disabled
                            defaultValue={this.state.flowrate}
                        />
                        <TextField
                            margin="dense"
                            id="_Pressure"
                            label="CGC Suction Pressure (bar g)"
                            type="text"
                            fullWidth
                            disabled
                            defaultValue={this.state.pressure}
                        />
                        {getScenarioInputsForProductPredictorDefinations(this.state.currentProductPredictorGroupConfigurationGroup.furnacetype_id!, this.state.currentProductPredictorGroupConfigurationGroup.feedtype_id!).map((input) => (
                            input.type === "INPUT" ?
                                <TextField
                                    margin="dense"
                                    id={input.id}
                                    label={input.name + " (" + input.unit + ")"}
                                    type="text"
                                    fullWidth
                                    key={input.id}
                                    value={this.state.currentProductPredictorGroupConfigurationGroup[input.id]}
                                    onChange={(event) => this.handleInput(event, input.id)}
                                /> :
                                <TextField
                                    id={input.id}
                                    select
                                    style={{ width: '20%', marginRight: 10 }}
                                    SelectProps={{ autoWidth: true }}
                                    label={input.name}
                                    key={input.id + "-" + input.name}
                                    defaultValue={this.state.currentProductPredictorGroupConfigurationGroup[input.id]}
                                    onChange={(event) => this.handleInput(event, input.id)}
                                >
                                    {input.values.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                        ))}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="_NAME"
                            label={furnaceFieldLabel}
                            type="text"
                            fullWidth
                            value={this.state.furnaceNumber >= 0 ? this.state.furnaceNumber : ''}
                            onChange={(event) => this.handleFurnaceNumberInput(event)}
                        />
                        <TextField
                            margin="dense"
                            id="_NAME"
                            label="Prediction Time (Days)"
                            type="text"
                            disabled
                            fullWidth
                            defaultValue={this.state.predictionTime}
                        />
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} color="primary">
                            Cancel
                        </button>
                        {this.props.delete_button ?
                            <button className="button" onClick={() => this.deleteGroupConfiguration()} color="primary">
                                Delete
                            </button>
                            : <div />}
                        <button className="button" onClick={() => this.addNewGroupConfiguration()} color="primary">
                            {this.props.ok_button_text}
                        </button>
                    </DialogActions>
                </Dialog>
            </div>)
    }
}

export default connector(ProductPredictorGroupConfig)