import { createStyles } from '@material-ui/core/styles';

export const tmtStyles = theme => createStyles({
    table: {
        width: 'auto',
        "& .MuiTableCell-root": {
        border: '3px solid #FFFFFF',
        }
    },
    legend_border:{
        border: '3px solid #FFFFFF'
    },
    cell_spacing:{
        padding: '5px',
        lineHeight: 1.30
    },
    tmt_furnace:{
        fontWeight: 'bold',
        marginRight: '10px',
        marginLeft: '20px',
        color: '#435591'
    },
    tmt_furnace_value:{
        padding: 0, 
        display: "inline"
    },
    fontdetail:{
        fontWeight: 'bold'
    },
    gen_display:{
        display: 'flex'
    },
    tmt_white_bg:{
        background:"#FFFFFF"
    },
    tmt_furn_bg:{
        backgroundColor: '#F45858', 
        width: '20px', 
        height: '4px'
    },
    tmt_bl_bg:{
        background:"#f1f6f9"
    },
    tmt_lbl_bg:{
        color:"#435591"
    },
    tmt_red_bg:{
        backgroundColor: '#ed7925', 
        width: '20px', 
        height: '4px'
    },
    tmt_coil_bg:{
        background: "#d2e1eb"
    },
    tmt_yel_bg:{
        backgroundColor: '#FFF241', 
        width: '20px', 
        height: '4px'
    }
});
