import React, { Component } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducers/rootReducer'
import ProductPredictorGroupConfig from './groupConfiguration'
import { IProductPredictorGroup } from '../../interfaces/IProductPredictor';
import { getScenarioInputsForProductPredictorDefinations } from '../../configuration';
import { compiledFlowHC } from '../../utilities/helperFunctions';
interface IAddGroupConfiguration {
    grpName: string,
    grpLabel: string,
    grpMaxFurnace: number
}

const mapStateToProps = (state: RootState) => {
    return {
        currentProductPredictorScenario: state.productPredictor.productPredictorScenarios.filter(x => x.id === state.productPredictor.currentProductPredictorScenarioId)[0],
        currentProductPredictorScenarioGroups: state.productPredictor.productPredictorScenarios.filter(x => x.id === state.productPredictor.currentProductPredictorScenarioId)[0].groups
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

type IAddGroupConfigurationFromRedux = PropsFromRedux & IAddGroupConfiguration

type IAddGroupConfigurationState = {
    showDialog: boolean,
    currentProductPredictorGroupConfigurationGroup: IProductPredictorGroup,
    isNew: boolean
}

class AddGroupConfiguration extends Component<IAddGroupConfigurationFromRedux, IAddGroupConfigurationState> {

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            currentProductPredictorGroupConfigurationGroup: this.props.currentProductPredictorScenarioGroups.filter(x => x.name === this.props.grpName)[0],
            isNew: !this.props.currentProductPredictorScenarioGroups.filter(x => x.name === this.props.grpName)[0].modified
        }
    }

    getDefaultScenarioInputs() {
        let scenarioInputs = {};
        getScenarioInputsForProductPredictorDefinations(this.state.currentProductPredictorGroupConfigurationGroup.furnacetype_id!, this.state.currentProductPredictorGroupConfigurationGroup.feedtype_id!).forEach((input) => {
            const id = input.id;
            if (input.type === "SELECT") {
                scenarioInputs[id] = input.selected_value;
            }
            else {
                scenarioInputs[id] = input.design;
            }
        });

        return { ...this.props.currentProductPredictorScenarioGroups.filter(x => x.name === this.props.grpName)[0], ...scenarioInputs };
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
            currentProductPredictorGroupConfigurationGroup: !this.props.currentProductPredictorScenarioGroups.filter(x => x.name === this.props.grpName)[0].modified ? this.getDefaultScenarioInputs() : this.props.currentProductPredictorScenarioGroups.filter(x => x.name === this.props.grpName)[0],
        })
    }

    configAdded() {
        this.setState({
            isNew: false
        });
    }

    componentDidUpdate(PrevProps) {
        if (PrevProps.currentProductPredictorScenarioGroups !== this.props.currentProductPredictorScenarioGroups) {
            this.setState({
                isNew: !this.props.currentProductPredictorScenarioGroups.filter(x => x.name === this.props.grpName)[0].modified
            })
        }
    }

    componentWillReceiveProps({ currentProductPredictorScenarioGroups, grpName }) {
        this.setState({
            currentProductPredictorGroupConfigurationGroup: currentProductPredictorScenarioGroups.filter(x => x.name === grpName)[0]
        })
    }

    render() {
        let furnace_number = this.props.currentProductPredictorScenarioGroups.filter(x => x.name === this.props.grpName)[0].furnace_number
        return (
            <div>
                <div className="runlength">

                    <Button
                        variant="contained"
                        color="secondary"
                        className="add-scenario-runlength-button"
                        onClick={() => this.toggleShowDialog()}
                    >
                        {this.state.isNew ? "+" : this.props.grpLabel}
                    </Button>
                    <ProductPredictorGroupConfig
                        ok_button_text={this.state.isNew ? "Add" : "Modify"}
                        delete_button={true}
                        toggleShowDialog={() => this.toggleShowDialog()}
                        configAdded={() => this.configAdded()}
                        currentProductPredictorGroupConfigurationGrp={this.state.currentProductPredictorGroupConfigurationGroup}
                        currentProductPredictorScenario={this.props.currentProductPredictorScenario}
                        currentGroupName={this.props.grpName}
                        groupLabelName={this.props.grpLabel}
                        grpMaxFurnace={this.props.grpMaxFurnace}
                        showDialog={this.state.showDialog}
                    />


                </div>
                <Box mt={2}>
                    <Typography> {furnace_number > 0 ? 'Furnace Number: ' + furnace_number : ''}</Typography>
                </Box>
                <Box mt={2}>
                    <Typography> {furnace_number > 0 ? 'Hydrocarbon Flow Per Furnace: ' + compiledFlowHC(this.props.currentProductPredictorScenario, this.props.currentProductPredictorScenarioGroups, this.props.grpName) : ''}</Typography>
                </Box>
            </div>
        )
    }
}
export default connector(AddGroupConfiguration)