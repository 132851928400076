import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IDiscreteCompositionComponent, UNIT } from "../../interfaces/IComponent";
import { IFuelGas } from "../../interfaces/IFuelGas";
import { RootState } from "../../reducers/rootReducer";
import { deleteFuelGas, toggleUnit, updateComponent, updateFuelGasPressure, updateFuelGasTemperature, updateO2BridgeWall, updateFualGasSignature } from "../../slices/fuelGasSlice";
import { updateDeletedFuelGasId } from "../../slices/scenarioSimulatorSlice";
import EditableCell from "../editableCell";
import { getTemperatureUnit } from '../../configuration';

interface IFuelGasTableProps {
    fuelGas: IFuelGas;
}

const mapStateToProps = (state: RootState) => {
    return {
        accessibleFeatures: state.features.accessibleFeatures
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleUnit: (fuelGas_id: string) => dispatch(toggleUnit(fuelGas_id)),
        updateComponent: (fuelGas_id: string, component_id: string, new_value: number) => dispatch(updateComponent({ fuelGas_id, component_id, new_value })),
        deleteFuelGas: (fuelGas_id: string) => dispatch(deleteFuelGas(fuelGas_id)),
        updateDeletedFuelGasId: (fuelGas_id: string) => dispatch(updateDeletedFuelGasId(fuelGas_id)),
        updateO2BridgeWall: (fuelGas_id: string, o2Bridgewall: number) => dispatch(updateO2BridgeWall({ fuelGas_id, o2Bridgewall })),
        updateFuelGasPressure: (fuelGas_id: string, fuelgasPressure: number) => dispatch(updateFuelGasPressure({ fuelGas_id, fuelgasPressure })),
        updateFuelGasTemperature: (fuelGas_id: string, fuelgasTemperature: number) => dispatch(updateFuelGasTemperature({ fuelGas_id, fuelgasTemperature })),
        updateSignature: (fuelgas_id: string) => dispatch(updateFualGasSignature({ fuelgas_id: fuelgas_id })),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFuelGasTableReduxProps = PropsFromRedux & IFuelGasTableProps;

type IFuelGasTableState = {
    _O2BridgeWall: number,
    _FuelgasPressure: number,
    _FuelgasTemperature: number
}

class FuelGasTable extends Component<IFuelGasTableReduxProps, IFuelGasTableState>{

    constructor(props) {
        super(props);

        this.state = {
            _O2BridgeWall: this.props.fuelGas.O2BridgeWall,
            _FuelgasPressure: this.props.fuelGas.Pressure,
            _FuelgasTemperature: this.props.fuelGas.Temperature
        }
    }
    handleComponentUpdate(mockE: { target: { id: string, name: string, value: number } }) {

        if (!this.props.fuelGas.id.startsWith("_DEFAULT")) {
            this.props.updateComponent(this.props.fuelGas.id, mockE.target.id, mockE.target.value);
            this.props.updateSignature(this.props.fuelGas.id);
        }
    }
    componentDidUpdate(PrevProps) {
        if (PrevProps.fuelGas !== this.props.fuelGas) {
            this.setState({
                _O2BridgeWall: this.props.fuelGas.O2BridgeWall,
                _FuelgasPressure: this.props.fuelGas.Pressure,
                _FuelgasTemperature: this.props.fuelGas.Temperature
            });
        }
    }
    deleteFuelGas() {
        this.props.updateDeletedFuelGasId(this.props.fuelGas.id);
        this.props.deleteFuelGas(this.props.fuelGas.id);
    }
    handleCombustionUpdate(event) {
        this.setState({
            _O2BridgeWall: event.target.value
        }, () => {
            this.props.updateO2BridgeWall(this.props.fuelGas.id, this.state._O2BridgeWall)
        })
    }
    handleCombustionPressureUpdate(event) {
        this.setState({
            _FuelgasPressure: event.target.value
        }, () => {
            this.props.updateFuelGasPressure(this.props.fuelGas.id, this.state._FuelgasPressure)
        })
    }
    handleCombustionTemperatureUpdate(event) {
        this.setState({
            _FuelgasTemperature: event.target.value
        }, () => {
            this.props.updateFuelGasTemperature(this.props.fuelGas.id, this.state._FuelgasTemperature)
        })
    }

    render() {
        return (
            <div className="container">
                <div className="feedstock">

                    <label key="radion_mol" className="radio-buttons__item" title="radio_mol">
                        <input className={"radio-buttons__input_MOL"} name="filter" type="radio" value="mol" onChange={() => this.props.toggleUnit(this.props.fuelGas.id)} checked={this.props.fuelGas.unit === UNIT.MOL} /> MOL %
                    </label>
                    &emsp;
                    <label key="radion_wt" className="radio-buttons__item" title="radio_wt">
                        <input className={"radio-buttons__input_wt"} name="filter" type="radio" onChange={() => this.props.toggleUnit(this.props.fuelGas.id)} value="wt" checked={this.props.fuelGas.unit === UNIT.WT} /> Wt %
                    </label>
                    <div className="feedstock-table-wrapper">
                        <table id="feedstock-table">
                            <thead>
                                <tr>
                                    <th colSpan={2}>Components</th>
                                    <th>Values</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Components</td>
                                    <td>Molecular Weight</td>
                                    <td>{this.props.fuelGas.unit}</td>
                                </tr>
                                {(this.props.fuelGas.components as IDiscreteCompositionComponent[]).map((component, i) => {
                                    return (
                                        <tr key={component.id} id={"tr - ".concat(component.id)} onFocus={() => (true)}>
                                            <td id={"Components - ".concat(component.id)}>{component.name}</td>
                                            <td id={"Molecular Weight - ".concat(component.id)}>{component.molecular_weight}</td>

                                            <EditableCell
                                                key={"Value - ".concat(component.id)}
                                                cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleComponentUpdate(mockE)}
                                                cellData={{
                                                    type: "'Manual Correction'",
                                                    value: component["value"],
                                                    id: component.id,
                                                }}
                                            />
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {this.props.accessibleFeatures.includes('COMBUSTION_DYNAMICS') ?

                        <table id="combustion-dynamics-table" className="com-dynamic-table">
                            <thead>
                                <tr>
                                    <th colSpan={3}>Combustion Parameters</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        Oxygen At Bridgewall
                                    </td>
                                    <td>
                                        Mol %
                                    </td>

                                    <EditableCell
                                        key={"Value - ".concat('_O2BridgeWall')}
                                        ValueInString={true}
                                        cellUpdate={(mockE: { target: { id: string, name: string, value: any } }) => this.handleCombustionUpdate(mockE)}
                                        cellData={{
                                            type: "'Manual Correction'",
                                            value: this.state._O2BridgeWall >= 0 && this.state._O2BridgeWall <= 100 ? this.state._O2BridgeWall : '',
                                            id: '_O2BridgeWall',
                                        }}
                                    />

                                </tr>
                                {!this.props.accessibleFeatures.includes('CONVECTION_SECTION') ?
                                    <>
                                        <tr>
                                            <td>
                                                Fuel Gas Temperature
                                            </td>
                                            <td>
                                                {getTemperatureUnit()}
                                            </td>

                                            <EditableCell
                                                key={"Value - ".concat('_FuelgasTemperature')}
                                                cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleCombustionTemperatureUpdate(mockE)}
                                                cellData={{
                                                    type: "'Manual Correction'",
                                                    value: this.state._FuelgasTemperature >= 0 ? this.state._FuelgasTemperature : 0,
                                                    id: '_FuelgasTemperature',
                                                }}
                                            />

                                        </tr>
                                        <tr>
                                            <td>
                                                Fuel Gas Pressure
                                            </td>
                                            <td>
                                                Bar g
                                            </td>

                                            <EditableCell
                                                key={"Value - ".concat('_FuelgasPressure')}
                                                cellUpdate={(mockE: { target: { id: string, name: string, value: number } }) => this.handleCombustionPressureUpdate(mockE)}
                                                cellData={{
                                                    type: "'Manual Correction'",
                                                    value: this.state._FuelgasPressure >= 0 ? this.state._FuelgasPressure : 0,
                                                    id: '_FuelgasPressure',
                                                }}
                                            />

                                        </tr>
                                    </>
                                    : null}
                            </tbody>
                        </table>
                        : null}

                </div>
            </div>
        );
    }

};

export default connector(FuelGasTable);