import React, { Component } from "react";
import { MenuItem, Select, Typography} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { setConfig, getAssetName, getDefaultFurnaceReferenceId, getDefaultPlantReferenceId, getFurnaceTypeFeedTypeByFurnaceId, getPlantReferenceById, getPlantReferences } from "../../configuration";
import { RootState } from "../../reducers/rootReducer";
import { setAssetName, setFurnaceId } from "../../slices/furnaceSlice";
import { setCurrentFeedTypeId, setCurrentFurnaceId, setCurrentFurnaceTypeId, setCurrentPlantId } from "../../slices/scenarioSimulatorSlice";
import { REACT_APP_APIM_URL_RESULT } from "../../utilities/GlobalConstants";
import { loadConfigPlants } from "../../slices/configurationSlice";
import store from "../..";

interface IPlantSelectorProps {
    disabled?: boolean;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentPlantId: state.scenarioSimulator.currentPlantId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentPlantId: (newPlantId: string) => dispatch(setCurrentPlantId(newPlantId)),
        setCurrentFurnaceId: (newFurnaceId: string) => dispatch(setCurrentFurnaceId(newFurnaceId)),
        setFurnaceId: (newFurnaceId: string) => dispatch(setFurnaceId(newFurnaceId)),
        setAssetName: (assetname: string) => dispatch(setAssetName(assetname)),
        setCurrentFurnaceTypeId: (furnace_type_id: string) => dispatch(setCurrentFurnaceTypeId(furnace_type_id)),
        setCurrentFeedTypeId: (feed_type_id: string) => dispatch(setCurrentFeedTypeId(feed_type_id)),
        loadConfigPlants: (config_plants: []) => dispatch(loadConfigPlants(config_plants))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IPlantSelectorReduxProps = PropsFromRedux & IPlantSelectorProps;

class PlantSelector extends Component<IPlantSelectorReduxProps, {}>{

    getSelectedPlant() {
        return getPlantReferenceById(this.props.currentPlantId);
    }

    getPlants() {
        return getPlantReferences();
    }
    
    async componentDidMount() {
        console.log('in plantconfig')
        //await this.getPlantconfig()
    }

    getPlantconfig = async () => {
        await fetch(`${REACT_APP_APIM_URL_RESULT}/user/getConfigForPlants`, {
          method: "GET",
          headers: {
            Accept: "*/*",
            Connection: "keep-alive",
            "Content-Type": "application/json",
            Authorization: "Bearer " + store.getState().authState.token,
            "Access-Control-Allow-Origin": "*"
          },
        })
          .then((res) => res.json())
          .then(
            (data) => {
              this.props.loadConfigPlants(data)
              return data;
            },
            (error) => console.log("error", error)
          );
    };

    handleSelection(event) {
        const new_plant_id = event.target.value.id;
        const furnace_id = getDefaultFurnaceReferenceId(new_plant_id);
        const assetname = getAssetName(furnace_id);
        this.props.setCurrentPlantId(new_plant_id);
        this.props.setCurrentFurnaceId(furnace_id);
        this.props.setFurnaceId(furnace_id);
        this.props.setAssetName(assetname);

        const value = getFurnaceTypeFeedTypeByFurnaceId(furnace_id);
        this.props.setCurrentFurnaceTypeId(value[0]);
        this.props.setCurrentFeedTypeId(value[1]);

    }

    render() {
        return (
            <div className="control-header element">
                <div className="container-typography">
                    <Typography>Ethylene Unit</Typography>
                </div>
                <Select disabled={this.props.disabled} autoWidth value={this.getSelectedPlant()} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.getPlants().map((plant, i) => (
                        <MenuItem key={`PLANT-SELECT-${plant.name}`} value={plant}>
                            {plant.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }

};

export default connector(PlantSelector);