import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, MenuItem, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { Component } from "react";
import { Autocomplete } from '@material-ui/lab';
import { connect, ConnectedProps } from "react-redux";
import { getFireboxScenarioCommonInputDefinitions, getFireboxScenarioInputDefinitions, getPlantReferenceById, getScenarioInputDefinitions } from "../../configuration";
import { RootState } from "../../reducers/rootReducer";
import { addScenarioToRunlength, deleteScenarioFromRunlength } from "../../slices/scenarioSimulatorSlice";
import { getScenarioFromInput, IScenario, MODE } from "../../interfaces/IScenario";
import { isHybrid } from "../../utilities/helperFunctions";

interface IScenarioDialogProps {
    scenario: any,
    ok_button_text: string,
    showDialog: boolean,
    toggleShowDialog: () => void,
    scenarioRunlengthId: string,
    delete_button: boolean,
    okBtnDisabled: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        current_furnace_type_id: state.scenarioSimulator.current_furnace_type_id,
        current_feed_type_id: state.scenarioSimulator.current_feed_type_id,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        feedstocks: state.feedstock.feedstocks,
        fuelGases: state.fuelGas.fuelGases,
        convectionsections: state.convectionsection.convectionsections.filter(x => x.furnace_id === state.scenarioSimulator.currentFurnaceId),
        furnace_feed_combination: state.scenarioSimulator.furnace_feed_combination,
        accessibleFeatures: state.features.accessibleFeatures,
        scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addScenarioToRunlength: (runlength_id: string, scenario: IScenario) => dispatch(addScenarioToRunlength({ runlength_id, scenario })),
        deleteScenarioFromRunlength: (runlength_id: string, scenario: IScenario) => dispatch(deleteScenarioFromRunlength({ runlength_id, scenario }))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IScenarioDialogReduxProps = PropsFromRedux & IScenarioDialogProps;

type IScenarioDialogState = {
    scenarioInputs: any;
    name: string;
    mode: MODE;
    fuelGasId: string;
    tabValue: number;
    okBtnDisabled: boolean;
    convectionSectionId: string;
    updated_feed_type_id: any;
    isNameAvailable: boolean;
    nameErrorMessage: string;
    showLoading: boolean;
    feedstockId: string;
    hybridModeChecked: boolean;
}

class ScenarioDialog extends Component<IScenarioDialogReduxProps, IScenarioDialogState>{

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        let initialState = {
            name: this.props.scenario.name,
            mode: this.props.scenario.mode,
            fuelGasId: this.props.scenario.fuelGas_id,
            tabValue: 0,
            okBtnDisabled: this.props.okBtnDisabled,
            convectionSectionId: this.props.scenario.convectionsection_id,
            updated_feed_type_id: this.props.current_feed_type_id,
            isNameAvailable: false,
            nameErrorMessage: "",
            showLoading: false,
            scenarioInputs: this.getDefaultScenarioInputs(),
            feedstockId: this.props.scenario.feedstock_id,
            hybridModeChecked: this.props.scenario.mode === MODE.HYBRID ? true : false
        }
        return initialState;
    }

    getDefaultScenarioInputs() {
        const scenario_input_id = getPlantReferenceById(this.props.currentPlantId).id;
        let scenarioInputs = { firebox: [{}, {}, {}] };
        let inputs = getFireboxScenarioInputDefinitions(scenario_input_id, this.props.current_furnace_type_id, this.props.current_feed_type_id);

        getScenarioInputDefinitions(this.props.current_furnace_type_id, this.props.current_feed_type_id).forEach((input) => {


            const id = input.id;
            if (this.props.scenario.mode === MODE.HYBRID) {
                if (inputs.map(x => x.id).includes(id)) {
                    scenarioInputs.firebox[1]['feedstock_id'] = this.props.scenario.firebox[0]['feedstock_id'];
                    scenarioInputs.firebox[1][id] = this.props.scenario.firebox[0][id];
                    scenarioInputs.firebox[2]['feedstock_id'] = this.props.scenario.firebox[1]['feedstock_id'];
                    scenarioInputs.firebox[2][id] = this.props.scenario.firebox[1][id]
                }
                else {
                    scenarioInputs[id] = this.props.scenario[id];
                }
            }
            else {
                if (inputs.map(x => x.id).includes(id)) {
                    scenarioInputs.firebox.forEach((fb, index) => {
                        fb['feedstock_id'] = this.props.scenario.firebox ? this.props.scenario.firebox[0]['feedstock_id'] : this.props.scenario.feedstock_id;
                        fb[id] = this.props.scenario.firebox ? (id === 'FLOW_HC' && index !== 0 ? this.props.scenario.firebox[0][id] / 2 : this.props.scenario.firebox[0][id]) : this.props.scenario.id;
                    })
                }
                else {
                    scenarioInputs[id] = this.props.scenario[id];
                }
            }
        });
        // console.log("scenarioInputs1", scenarioInputs)
        // if (this.props.scenario.mode === MODE.FULL) {
        //     scenarioInputs.firebox[1] = (this.defaultScenario().firebox[0]);
        //     scenarioInputs.firebox[2] = (this.defaultScenario().firebox[1]);
        // }
        // else {
        //     scenarioInputs.firebox[0] = (this.defaultScenario().firebox[0]);
        // }
        // console.log("scenarioInputs", scenarioInputs)
        return scenarioInputs
    }

    defaultScenario() {
        const scenario_input_id = getPlantReferenceById(this.props.currentPlantId).scenario_input_id;
        let scenarioInputs = { firebox: [{}, {}] };
        let inputs = getFireboxScenarioInputDefinitions(scenario_input_id, this.props.current_furnace_type_id, this.props.current_feed_type_id);
        getScenarioInputDefinitions(this.props.current_furnace_type_id, this.props.current_feed_type_id).forEach((input) => {
            const id = input.id;
            if (inputs.map(x => x.id).includes(id)) {
                scenarioInputs.firebox[0]['feedstock_id'] = scenarioInputs.firebox[1]['feedstock_id'] = "";
                scenarioInputs.firebox[0][id] = input.design ?? input.selected_value;
                scenarioInputs.firebox[1][id] = input.design ?? input.selected_value;
            }
            else {
                scenarioInputs[id] = input.design;
            }
        });
        return scenarioInputs;
    }

    handleNameInput(event) {
        this.setState({
            name: event.target.value
        }, () => this.validateFields());
    }

    handleInput(event, id, fireboxIndex) {
        let newScenarioInput = this.state.scenarioInputs;
        if (newScenarioInput[id] !== undefined)
            newScenarioInput[id] = event.target.value;
        else
            newScenarioInput.firebox[fireboxIndex][id] = event.target.value
        this.setState({
            scenarioInputs: newScenarioInput
        })
    }

    handleFuelGasChange(event) {
        this.setState({
            fuelGasId: event.target.value
        }, () => this.validateFields());
    }

    handleFeedstockChange(event, fireboxIndex) {
        let newScenarioInput = this.state.scenarioInputs;
        newScenarioInput.firebox[fireboxIndex].feedstock_id = event.target.value;
        this.setState({
            scenarioInputs: newScenarioInput
        }, () => this.validateFields());
    }

    handleFireboxTabChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        })
    };

    validateFields() {
        let result: boolean = true;
        result = this.state.name && this.state.fuelGasId ? false : true;
        if (!result && this.state.mode === MODE.HYBRID) {
            result = (this.state.scenarioInputs.firebox.slice(1, 3).filter((sce) => sce.feedstock_id).length === 2 ? false : true);
        }
        else if (!result && this.state.mode === MODE.FULL) {
            result = this.state.scenarioInputs.firebox[0].feedstock_id ? false : true;
        }
        this.setState({ okBtnDisabled: result });
    }

    addNewScenarioToRunlength() {

        const newScenario = getScenarioFromInput(this.state.scenarioInputs, this.state.name, this.state.mode, this.props.currentFurnaceId)
        newScenario.id = this.state.scenarioInputs.id;
        newScenario.fuelGas_id = this.state.fuelGasId;
        newScenario.firebox = newScenario.mode === MODE.HYBRID ? this.state.scenarioInputs.firebox.slice(1, 3) : this.state.scenarioInputs.firebox.slice(0, 1);

        newScenario.firebox.forEach((input) => {
            input["CALCULATIONS"] = [];
        })
        newScenario.convectionsection_id = this.state.convectionSectionId;
        this.props.addScenarioToRunlength(this.props.scenarioRunlengthId, newScenario);
        this.toggleShowDialog();
        this.setState({ tabValue: 0 });
    }

    deleteScenario() {
        this.props.deleteScenarioFromRunlength(this.props.scenarioRunlengthId, this.props.scenario)
        this.toggleShowDialog()
    }

    toggleShowDialog() {
        setTimeout(() => {
            this.setState(this.getInitialState());
        }, 200);
        this.props.toggleShowDialog();
    }

    toggleMode(mode: MODE) {
        this.setState({ mode: mode }, () => {
            const ele = document.querySelector("#_NAME") as HTMLInputElement;
            ele.focus();
            this.validateFields();
        })
    }

    form(index, isHybrid = false, filtered_feed, scenarioInputs) {
        if (isHybrid) {
            return (
                <div>
                    <Box display="flex" justifyContent="flex-start" >
                        <Box mr={2}>
                            <TextField
                                id={"feedstock"}
                                select
                                margin="dense"
                                SelectProps={{ autoWidth: true }}
                                error={
                                    (this.state.scenarioInputs.firebox ? (this.state.scenarioInputs.firebox[index].feedstock_id === undefined ||
                                        this.state.scenarioInputs.firebox[index].feedstock_id === "") : (this.state.scenarioInputs.feedstock_id === undefined ||
                                            this.state.scenarioInputs.feedstock_id === ""))
                                }
                                helperText={
                                    (this.state.scenarioInputs.firebox ? (this.state.scenarioInputs.firebox[index].feedstock_id === undefined ||
                                        this.state.scenarioInputs.firebox[index].feedstock_id === "") : (this.state.scenarioInputs.feedstock_id === undefined ||
                                            this.state.scenarioInputs.feedstock_id === ""))
                                        ? "Please define a feedstock first" : ""}
                                label={"Feedstock"}
                                defaultValue={this.state.scenarioInputs.firebox ? this.state.scenarioInputs.firebox[index].feedstock_id : this.state.scenarioInputs.feedstock_id}
                                onChange={(event) => this.handleFeedstockChange(event, index)}
                            >
                                {filtered_feed.map((feedstock) => (
                                    <MenuItem key={feedstock.id} value={feedstock.id}>
                                        {feedstock.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Box>
                    {getFireboxScenarioInputDefinitions(getPlantReferenceById(this.props.currentPlantId).scenario_input_id, this.props.current_furnace_type_id, this.props.current_feed_type_id).map((input) => (
                        input.type === "INPUT" ?
                            <TextField
                                margin="dense"
                                id={input.id}
                                label={input.name + " (" + input.unit + ")"}
                                type="text"
                                fullWidth
                                defaultValue={scenarioInputs.firebox[index][input.id]}
                                onChange={(event) => this.handleInput(event, input.id, index)}
                            /> :
                            <TextField
                                id={input.id}
                                select
                                style={{ width: '48%', marginRight: '2%' }}
                                SelectProps={{ autoWidth: true }}
                                label={input.name}
                                defaultValue={scenarioInputs.firebox[index][input.id]}
                                onChange={(event) => this.handleInput(event, input.id, index)}
                            >
                                {input.values.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>

                    ))}</div>

            )
        }
        else {
            return (<div>
                {getScenarioInputDefinitions(this.props.current_furnace_type_id, this.props.current_feed_type_id).map((input) => (
                    input.type === "INPUT" ?
                        <TextField
                            margin="dense"
                            id={input.id}
                            label={input.name + " (" + input.unit + ")"}
                            type="text"
                            fullWidth
                            defaultValue={scenarioInputs[input.id] ? scenarioInputs[input.id] : scenarioInputs.firebox[index][input.id]}
                            onChange={(event) => this.handleInput(event, input.id, index)}
                        /> :
                        <TextField
                            id={input.id}
                            select
                            style={{ width: '20%', marginRight: 10 }}
                            SelectProps={{ autoWidth: true }}
                            label={input.name}
                            defaultValue={scenarioInputs[input.id] ? scenarioInputs[input.id] : scenarioInputs.firebox[index][input.id]}
                            onChange={(event) => this.handleInput(event, input.id, index)}
                        >
                            {input.values.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                ))}
            </div>
            )
        }
    }

    isVisibleCS = () => {

        if (this.props.accessibleFeatures.length > 0) {
            let isVisible = this.props.accessibleFeatures.find(x => x === "CONVECTION_SECTION")
            if (isVisible === undefined) {
                return true;
            }
            else {
                return false;
            }
        }

        return true;
    }


    handleModeChange = () => {
        this.setState({
            hybridModeChecked: !this.state.hybridModeChecked,
            mode: this.state.hybridModeChecked ? MODE.FULL : MODE.HYBRID
        })

        // this.setState({ mode: mode }, () => {
        //     const ele = document.querySelector("#_NAME") as HTMLInputElement;
        //     ele.focus();
        //     this.validateFields();
        // })
    }
    render() {
        let getcombinaton = this.props.furnace_feed_combination?.filter(x => x.furnace_type_id === this.props.current_furnace_type_id)[0]?.feed_type_id;
        let filtered_feed = this.props.feedstocks.filter(x => (getcombinaton?.includes(parseInt(x.feed_type_id))) && (x.is_saved === true));
        return (
            <div className="control-header element">
                <Dialog open={this.props.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Scenario Configurator</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please provide the following information:
                        </DialogContentText>
                        {isHybrid() &&
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={this.state.hybridModeChecked} onChange={this.handleModeChange} />}
                                label="Hybrid Mode"
                            />}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="_NAME"
                            label="Name"
                            type="text"
                            fullWidth
                            defaultValue={this.state.name}
                            onChange={(event) => this.handleNameInput(event)}
                            error={this.state.name === ""}
                            helperText={this.state.name === "" ? "Please provide a name" : ""}
                        />
                        <Box component="div" display={this.state.mode !== MODE.HYBRID ? "inline" : "none"}>
                            <Box display="flex" justifyContent="flex-start" >
                                <Box mr={2}>
                                    <TextField
                                        id={"feedstock"}
                                        select
                                        margin="dense"
                                        SelectProps={{ autoWidth: true }}
                                        error={
                                            (this.state.scenarioInputs.firebox ? (this.state.scenarioInputs.firebox[0].feedstock_id === undefined ||
                                                this.state.scenarioInputs.firebox[0].feedstock_id === "") : (this.state.scenarioInputs.feedstock_id === undefined ||
                                                    this.state.scenarioInputs.feedstock_id === ""))
                                        }
                                        helperText={
                                            (this.state.scenarioInputs.firebox ? (this.state.scenarioInputs.firebox[0].feedstock_id === undefined ||
                                                this.state.scenarioInputs.firebox[0].feedstock_id === "") : (this.state.scenarioInputs.feedstock_id === undefined ||
                                                    this.state.scenarioInputs.feedstock_id === ""))
                                                ? "Please define a feedstock first" : ""}
                                        label={"Feedstock"}
                                        defaultValue={this.state.scenarioInputs.firebox ? this.state.scenarioInputs.firebox[0].feedstock_id : this.state.scenarioInputs.feedstock_id}
                                        onChange={(event) => this.handleFeedstockChange(event, 0)}
                                    >
                                        {filtered_feed.map((feedstock) => (
                                            <MenuItem key={feedstock.id} value={feedstock.id}>
                                                {feedstock.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>


                                <TextField
                                    id={"fuelGas"}
                                    select
                                    margin="dense"
                                    SelectProps={{ autoWidth: true }}
                                    error={this.state.fuelGasId === undefined || this.state.fuelGasId === ""}
                                    helperText={this.state.fuelGasId === undefined || this.state.fuelGasId === "" ? "Please define a feedstock first" : ""}
                                    label={"Fuel Gas"}
                                    style={{ marginRight: 10 }}
                                    defaultValue={this.state.fuelGasId}
                                    onChange={(event) => this.handleFuelGasChange(event)}
                                >
                                    {this.props.fuelGases.map((fuelGas) => (
                                        <MenuItem key={fuelGas.id} value={fuelGas.id}>
                                            {fuelGas.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {this.isVisibleCS() === false ?
                                    <Autocomplete
                                        options={this.props.convectionsections}
                                        id="convectionSection"
                                        value={this.props.convectionsections.filter((c) => c.id === this.state.convectionSectionId)[0]}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            console.log(newValue);
                                            this.setState({
                                                convectionSectionId: newValue?.id ?? ""
                                            });
                                        }}
                                        className="autocomplete"
                                        renderInput={(params) => <TextField {...params} label="Convection Section" />}
                                    />
                                    : <></>}

                            </Box>
                            {this.form(0, false, filtered_feed, this.state.scenarioInputs)}
                        </Box>
                        <Box component="div" display={this.state.mode === MODE.HYBRID ? "inline" : "none"} mt={8}>
                            <Box className="fireboxDisp firebox1">
                                <span >FIREBOX I</span>
                                {this.form(1, true, filtered_feed, this.state.scenarioInputs)}
                            </Box>
                            <Box className="fireboxDisp">
                                <span>FIREBOX II</span>
                                {this.form(2, true, filtered_feed, this.state.scenarioInputs)}
                            </Box>
                            <Box className="commonConfigLabel">
                                <span>COMMON CONFIGURATION (FIREBOX I AND II)</span>
                            </Box>
                            <Box>
                                <TextField
                                    id={"fuelGas"}
                                    select
                                    margin="dense"
                                    SelectProps={{ autoWidth: true }}
                                    error={this.state.fuelGasId === undefined || this.state.fuelGasId === ""}
                                    helperText={this.state.fuelGasId === undefined || this.state.fuelGasId === "" ? "Please define a feedstock first" : ""}
                                    label={"Fuel Gas"}
                                    style={{ marginRight: 10, minWidth: 150 }}
                                    defaultValue={this.state.fuelGasId}
                                    onChange={(event) => this.handleFuelGasChange(event)}
                                >
                                    {this.props.fuelGases.map((fuelGas) => (
                                        <MenuItem key={fuelGas.id} value={fuelGas.id}>
                                            {fuelGas.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {this.isVisibleCS() === false ?
                                    <Autocomplete
                                        options={this.props.convectionsections}
                                        id="convectionSection"
                                        value={this.props.convectionsections.filter((c) => c.id === this.state.convectionSectionId)[0]}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            console.log(newValue);
                                            this.setState({
                                                convectionSectionId: newValue?.id ?? ""
                                            });
                                        }}
                                        className="autocomplete"
                                        renderInput={(params) => <TextField {...params} label="Convection Section" />}
                                    />
                                    : <></>}
                            </Box>
                            {getFireboxScenarioCommonInputDefinitions(this.props.current_furnace_type_id, this.props.current_feed_type_id).map((input) => (
                                input.type === "INPUT" ?

                                    <Box className={this.state.mode === MODE.HYBRID ? 'fireboxCommonField' : ''}>
                                        <TextField
                                            margin="dense"
                                            id={input.id}
                                            label={input.name + " (" + input.unit + ")"}
                                            type="text"
                                            fullWidth
                                            defaultValue={this.state.scenarioInputs[input.id]}
                                            onChange={(event) => this.handleInput(event, input.id, 0)}
                                        />
                                    </Box> :
                                    <Box>
                                        <TextField
                                            id={input.id}
                                            select
                                            style={{ width: '20%', marginRight: 10 }}
                                            SelectProps={{ autoWidth: true }}
                                            label={input.name}
                                            defaultValue={this.state.scenarioInputs[input.id]}
                                            onChange={(event) => this.handleInput(event, input.id, 0)}
                                        >
                                            {input.values.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                            ))}
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} color="primary">
                            Cancel
                        </button>
                        {this.props.delete_button ?
                            <button className="button" onClick={() => this.deleteScenario()} color="primary">
                                Delete
                            </button>
                            : <div />}
                        <button className="button" onClick={() => this.addNewScenarioToRunlength()} color="primary" disabled={this.state.showLoading}>
                            {this.props.ok_button_text} {this.state.showLoading === true && (
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={11} />
                                </Fade>
                            )}
                        </button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }

};

export default connector(ScenarioDialog);