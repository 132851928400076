import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDefaultProductPredictorGroups, IProductPredictorFeedstockDetail, IProductPredictorGroup, IProductPredictorOutputProduct, IProductPredictorScenario } from "../interfaces/IProductPredictor";
import { CALCULATION_STATUS, SCENARIO_PROGRESS, SCENARIO_STATUS, TERMINATION_REASON } from "../interfaces/IScenario";
import { getSignature } from "../utilities/helperFunctions";

export type IProductPredictorSlice = {
    productPredictorScenarios: IProductPredictorScenario[],
    currentProductPredictorScenarioId: string
}
const initialState: IProductPredictorSlice = {
    productPredictorScenarios: [],
    currentProductPredictorScenarioId: ''
}

export const productPredictorslice = createSlice({
    name: 'productPredictorslice',
    initialState: initialState,
    reducers: {
        AddProductPredictorScenario: (state, action: PayloadAction<IProductPredictorScenario>) => {
            const index = state.productPredictorScenarios.findIndex(p => p.id === action.payload.id);
            state.currentProductPredictorScenarioId = action.payload.id;
            if (index === -1) {
                state.productPredictorScenarios.push(action.payload);
            }
            else {
                state.productPredictorScenarios[index] = action.payload;
            }
        },
        resetProductPredictorScenario: (state) => {
            // state = initialState;  // TO BE USED IN FUTURE
            state.currentProductPredictorScenarioId = '';
            state.productPredictorScenarios = [];
        },
        setCurrentProductPredictorScenarioId: (state, action: PayloadAction<string>) => {
            state.currentProductPredictorScenarioId = action.payload;
        },
        deleteProductPredictorScenario: (state, action: PayloadAction<string>) => {
            let tobeDeleteElementIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload);
            if (tobeDeleteElementIndex > -1)
                state.productPredictorScenarios.splice(tobeDeleteElementIndex, 1)
            if (state.productPredictorScenarios.length > 0)
                state.currentProductPredictorScenarioId = state.productPredictorScenarios[state.productPredictorScenarios.length - 1].id;
        },
        addFeedstockDetails: (state, action: PayloadAction<{ groupIndex: number, feedstockIndex: number, feedstockdetails: IProductPredictorFeedstockDetail }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === state.currentProductPredictorScenarioId)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            let currGrpIndex = action.payload.groupIndex;
            let currFeeedstockIndex = action.payload.feedstockIndex;
            currPPScenario.groups[currGrpIndex].feedstock![currFeeedstockIndex] = action.payload.feedstockdetails;
            //set feedtype based on feedstocks
            //let max_feedtypeid_val = currPPScenario.groups[currGrpIndex].feedstock!.reduce((op, item) => op = Number(op) > Number(item.feedtype_id) ? Number(op) : Number(item.feedtype_id), 0);
            //currPPScenario.groups[currGrpIndex].feedtype_id = max_feedtypeid_val.toString();
            //set total flowrate 
            let new_flowrate = currPPScenario.groups[currGrpIndex].feedstock!.reduce((op, item) => op = Number(op) + (Number(item.flowrate) > 0 ? Number(item.flowrate) : 0), 0);
            currPPScenario.groups[currGrpIndex].flowrate = new_flowrate;
        },
        updateModifiedFeedstockInPP: (state, action: PayloadAction<string>) => {

            state.productPredictorScenarios = state.productPredictorScenarios.map(scenario => {

                let ff: any = []
                scenario.groups.forEach(grp => {
                    ff = grp.feedstock?.map(fs => fs.feedstock_id)
                })

                let fs = new Set(ff?.flat())

                if (fs.has(action.payload)) {
                    let new_PPScenario = scenario;
                    new_PPScenario.status = SCENARIO_STATUS.IS_CHANGED_IN_FEEDSTOCK;
                    new_PPScenario.isExportable = false
                    return new_PPScenario

                }
                else
                    return scenario
            });

        },

        updateDeletedFeedstockIdInPP: (state, action: PayloadAction<string>) => {

            state.productPredictorScenarios = state.productPredictorScenarios.map(scenario => {

                let ff: any = []
                scenario.groups.forEach(grp => {
                    ff = grp.feedstock?.map(fs => fs.feedstock_id)
                })

                let fs = new Set(ff?.flat())

                if (fs.has(action.payload)) {
                    let new_PPScenario = scenario;
                    new_PPScenario.status = SCENARIO_STATUS.DELETED_FEEDSTOCK;
                    new_PPScenario.isExportable = false
                    return new_PPScenario

                }
                else
                    return scenario
            });

        },
        updateModifiedFuelGasInPP: (state, action: PayloadAction<string>) => {

            state.productPredictorScenarios = state.productPredictorScenarios.map(scenario => {

                if (scenario.fuelgas_id === action.payload) {
                    let new_PPScenario = scenario;
                    new_PPScenario.status = SCENARIO_STATUS.IS_CHANGED_IN_FUELGAS;
                    new_PPScenario.isExportable = false
                    return new_PPScenario

                }
                else
                    return scenario
            });

        },

        updateDeletedFuelGasIdInPP: (state, action: PayloadAction<string>) => {

            state.productPredictorScenarios = state.productPredictorScenarios.map(scenario => {

                if (scenario.fuelgas_id === action.payload) {
                    let new_PPScenario = scenario;
                    new_PPScenario.status = SCENARIO_STATUS.DELETED_FUELGAS;
                    new_PPScenario.isExportable = false
                    return new_PPScenario

                }
                else
                    return scenario
            });

        },

        updateModifiedConvectionSectionIdInPP: (state, action: PayloadAction<string>) => {

            state.productPredictorScenarios = state.productPredictorScenarios.map(scenario => {


                let cs = scenario.groups.find(grp => grp.convectionsection_id === action.payload)

                if (cs) {
                    let new_PPScenario = scenario;
                    new_PPScenario.status = SCENARIO_STATUS.IS_CHANGED_IN_CONVECTION_SECTION;
                    new_PPScenario.isExportable = false
                    return new_PPScenario

                }
                else
                    return scenario
            });

        },

        updateDeletedConvectionSectionIdInPP: (state, action: PayloadAction<string>) => {

            state.productPredictorScenarios = state.productPredictorScenarios.map(scenario => {


                let cs = scenario.groups.find(grp => grp.convectionsection_id === action.payload)

                if (cs) {
                    let new_PPScenario = scenario;
                    new_PPScenario.status = SCENARIO_STATUS.DELETED_CONVECTION_SECTION;
                    new_PPScenario.isExportable = false
                    return new_PPScenario

                }
                else
                    return scenario
            });

        },
        addGroupConfiguration: (state, action: PayloadAction<{ groupName: string, groupdetails: IProductPredictorGroup }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === state.currentProductPredictorScenarioId)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            let currGrpName = action.payload.groupName;
            let grpIndex = currPPScenario.groups.findIndex(g => g.name === currGrpName);
            currPPScenario.groups[grpIndex] = { ...currPPScenario.groups.filter(g => g.name === currGrpName)[0], ...action.payload.groupdetails }
        },
        deleteGroupConfiguration: (state, action: PayloadAction<{ groupIndex: string }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === state.currentProductPredictorScenarioId)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            let tobeDeleteElementIndex = action.payload.groupIndex;
            currPPScenario.groups[tobeDeleteElementIndex] = getDefaultProductPredictorGroups()
        },
        setPPScenarioStatus: (state, action: PayloadAction<{ scenario_id: string, status: SCENARIO_STATUS }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload.scenario_id)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            currPPScenario.status = action.payload.status;
        },
        setPPScenarioProgress: (state, action: PayloadAction<{ scenario_id: string, status: SCENARIO_PROGRESS }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload.scenario_id)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            currPPScenario.scenario_progress = action.payload.status;
        },
        setPPScenarioCalculationStatus: (state, action: PayloadAction<{ scenario_id: string, status: CALCULATION_STATUS }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload.scenario_id)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            currPPScenario.calculation_status = action.payload.status;
        },
        setTerminationReason: (state, action: PayloadAction<{ scenario_id: string, reason: TERMINATION_REASON }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload.scenario_id)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            currPPScenario.termination_reason = action.payload.reason;
        },
        resetOutput: (state, action: PayloadAction<{ scenario_id: string }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload.scenario_id)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            currPPScenario.output.products = [];
        },
        updatePPScenarioCaseId: (state, action: PayloadAction<{ scenario_id: string, caseId: string }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload.scenario_id)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            currPPScenario.case_id = action.payload.caseId;
        },
        setPPResult: (state, action: PayloadAction<{ scenario_id: string, response: IProductPredictorOutputProduct[] }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload.scenario_id)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            const products: IProductPredictorOutputProduct[] = [];
            action.payload.response.forEach(res =>
                res.name === "Ethane" || res.name === "Propane"
                    ? products.push({ name: res.name + " Recycle", value: res.value })
                    : products.push({ name: res.name, value: res.value })
            );
            currPPScenario.output.products = products;
        },
        setProgress: (state, action: PayloadAction<{ scenario_id: string, progress: number }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload.scenario_id)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            // if (action.payload.progress > currPPScenario.progress)
            currPPScenario.progress = action.payload.progress;
        },
        loadAllProductPredictorData: (state, action: PayloadAction<IProductPredictorScenario[]>) => {
            if (state.productPredictorScenarios.length === 0 && action.payload.length > 0) {
                state.currentProductPredictorScenarioId = action.payload[0].id
            }
            state.productPredictorScenarios = [...state.productPredictorScenarios, ...action.payload]
        },
        updateSignature: (state, action: PayloadAction<IProductPredictorScenario>) => {
            const index = state.productPredictorScenarios.findIndex(p => p.id === action.payload.id);
            state.productPredictorScenarios[index].signature = getSignature(action.payload);
        },
        setIsExportable: (state, action: PayloadAction<{ scenario_id: string, isTrueOrFalse: boolean }>) => {
            let currPPScenarioIndex = state.productPredictorScenarios.findIndex(x => x.id === action.payload.scenario_id)
            let currPPScenario = state.productPredictorScenarios[currPPScenarioIndex];
            currPPScenario.isExportable = action.payload.isTrueOrFalse;
        },
    }
})

export const {
    AddProductPredictorScenario,
    resetProductPredictorScenario,
    setCurrentProductPredictorScenarioId,
    deleteProductPredictorScenario,
    addFeedstockDetails,
    updateModifiedFeedstockInPP,
    updateDeletedFeedstockIdInPP,
    updateModifiedFuelGasInPP,
    updateDeletedFuelGasIdInPP,
    updateModifiedConvectionSectionIdInPP,
    updateDeletedConvectionSectionIdInPP,
    addGroupConfiguration,
    deleteGroupConfiguration,
    setPPScenarioStatus,
    setPPScenarioProgress,
    setPPScenarioCalculationStatus,
    setTerminationReason,
    resetOutput,
    updatePPScenarioCaseId,
    setPPResult,
    setProgress,
    loadAllProductPredictorData,
    updateSignature,
    setIsExportable,
} = productPredictorslice.actions

export default productPredictorslice.reducer