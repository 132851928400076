import * as React from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { Table, TableRow, TableCell, TableBody } from "@material-ui/core";

export interface CIPSummaryTableProps {
  lr: boolean;
  selectedFirebox: number;
  cip_forecast?: any;
  cipDCSMax: string;
  lrDCSMax: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios,
    currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICIPSummaryReduxProps = PropsFromRedux & CIPSummaryTableProps;


/**
 * CIP specific summary table
 */

const CIPSummaryTable: React.SFC<ICIPSummaryReduxProps> = ({ scenarios, currentScenarioIsRTA, cip_forecast, lr, cipDCSMax, lrDCSMax, selectedFirebox }) => {
  const { referencePositionIndex } = useSelector((state: RootState) => state.coilState);
  const selectedScenario: number = 0;
  const output_dict = currentScenarioIsRTA ? (scenarios?.[selectedScenario]?.firebox[selectedFirebox]["CALCULATIONS"][referencePositionIndex] ? scenarios?.[selectedScenario]?.firebox[selectedFirebox]["CALCULATIONS"][referencePositionIndex]["output_dict"] : undefined) : scenarios?.[selectedScenario]?.firebox[0]["CALCULATIONS"][0]["output_dict"];

  return (
    <Table className="l-table-summary" >
      <TableBody>
        <TableRow>
          <TableCell></TableCell>
          <TableCell className="bold th">DCS</TableCell>
          <TableCell className="bold th">LVF</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="bold td">CIP Forecast (bar g)</TableCell>
          <TableCell className="td">N/A</TableCell>
          <TableCell className="td">{(output_dict && !("ERROR_CODE" in output_dict) && (output_dict["CIP"][0][0] / 10).toFixed(3)) || "N/A"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="bold td">Laval Ratio Forecast (-)</TableCell>
          <TableCell className="td">N/A</TableCell>
          <TableCell className="td">{(output_dict && !("ERROR_CODE" in output_dict) && (output_dict["CIP"][0][0] / (scenarios ? scenarios[selectedScenario]["P_XOVER"] : 0 * 10 + 1.013)).toFixed(3)) || "N/A"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="bold td">CIP Max. Current (bar g)</TableCell>
          <TableCell className="td">{cipDCSMax}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="bold">Laval Ratio Max Current (-)</TableCell>
          <TableCell className="td">{lrDCSMax}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default connector(CIPSummaryTable);
