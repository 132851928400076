import { IFuelGas } from "../interfaces/IFuelGas";
import { REACT_APP_APIM_URL_SCENARIO } from "../utilities/GlobalConstants";
import store from "..";

/**
 *
 * @param newFuelGas
 * @returns
 */
export async function checkFuelgasName(newFuelGas: IFuelGas) {
  // Refresh token before calling compute Initialization
  const repsonce = {
    status: false,
    message: "",
  };
  const token = store.getState().authState.token;

  let check = await fetch(
    `${REACT_APP_APIM_URL_SCENARIO}/fuelgas/${newFuelGas.name}/${newFuelGas.is_public}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        data.message = "Name already exist!";
      }
      return data;
    })
    .catch((err) => {
      repsonce.message = "Oops... somthing went to wrong!";
      return repsonce;
    });

  return check;
}

/**
 *
 * @returns
 */
export async function loadFuelgasData() {
  const token = store.getState().authState.token;

  const repsonce = {
    status: false,
    message: "",
    data: [],
  };

  let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/fuelgas`, {
    method: "GET",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      // data.forEach(element => {
      //     element.O2BridgeWall = element.o2_bridge_wall;
      //     element.Pressure = element.pressure
      //     element.Temperature = element.temperature
      // });
      repsonce.status = true;
      repsonce.message = "success";
      repsonce.data = !!data ? data : [];
      return repsonce;
    })
    .catch((err) => {
      repsonce.message = "Oops... somthing went to wrong!";
      return repsonce;
    });

  return check;
}

/**
 *
 * @param newFuelGas
 * @returns
 */
export async function saveNewFuelGas(newFuelGas: IFuelGas) {
  const repsonce = {
    status: false,
    message: "",
  };
  const token = store.getState().authState.token;

  let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/fuelgas`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(newFuelGas),
  })
    .then((res) => res.json())
    .then((data) => {
      repsonce.status = true;
      repsonce.message = "This fuelgas save sucessfully!";
      return repsonce;
    })
    .catch((err) => {
      repsonce.message = "Oops... somthing went to wrong!";
      return repsonce;
    });

  return check;
}

/**
 *
 * @param fuelGasId
 * @returns
 */
export async function deleteFuelGasById(fuelGasId: string) {
  const repsonce = {
    status: false,
    message: "",
  };
  const token = store.getState().authState.token;

  let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/fuelgas`, {
    method: "DELETE",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      Id: fuelGasId,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      repsonce.status = true;
      repsonce.message = "This fuelgas deleted sucessfully!";
      return repsonce;
    })
    .catch((err) => {
      repsonce.message = "Oops... somthing went to wrong!";
      return repsonce;
    });

  return check;
}

/**
 *
 * @param fuelGasId
 * @returns
 */
export async function getFuelGasById(fuelGasId: string) {
  const token = store.getState().authState.token;
  const repsonce = {
    status: false,
    message: "",
    data: [],
  };

  let check = await fetch(
    `${REACT_APP_APIM_URL_SCENARIO}/fuelgas/${fuelGasId}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((res) => res.json())
    .then((data) => {
      repsonce.status = true;
      repsonce.message = "success";
      repsonce.data = !!data ? data : [];
      return repsonce;
    })
    .catch((err) => {
      repsonce.message = "Oops... somthing went to wrong!";
      return repsonce;
    });

  return check;
}
