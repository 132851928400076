import { MenuItem, Select, InputLabel, FormControl } from '@material-ui/core'
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../reducers/rootReducer'
import { setCurrentCamera,setCurrentCameraId,ICameras } from '../../slices/cameraSlice'
import { setCoils, setCurrentCoil } from '../../slices/coilSlice'
import { setImage } from '../../slices/thermalImageSlice'
import { getCoilgroupByCamera, getFurnaceCameras } from "../../configuration";
import { setCurrentCoilGroup } from '../../slices/coilGroupSlice';

interface ICameraSelctorProps {
    cameraData: ICameras[];
}

const mapStateToProps = (state: RootState) => {
    return {
        camera: state.camera,
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentCameraId: state.camera.id
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentCamera: (name: string) => dispatch(setCurrentCamera(name)),
        setCoils: (values: string[]) => dispatch(setCoils(values)),
        setCurrentCoil: (name: string) => dispatch(setCurrentCoil(name)),
        setCurrSelectedImage: (name: string) => dispatch(setImage(name)),
        setCurrentCameraId: (id: string) => dispatch(setCurrentCameraId(id)),
        setCurrentCoilGroup: (name: string) => dispatch(setCurrentCoilGroup(name))
     }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICameraSelectorReduxProps = PropsFromRedux & ICameraSelctorProps;

type ICameraSelectorState = {
    camera: ICameras[],
    currCamera: string,
    currentCameraId : string
}

class CameraSelector extends Component<ICameraSelectorReduxProps, ICameraSelectorState>
{
    constructor(props) {       
        super(props)
        this.state = {
            camera: this.props.cameraData,
            currCamera: this.props.camera.name,
            currentCameraId : this.props.currentCameraId
        }
    }

    getCameras() {
        let furnaceCameras = getFurnaceCameras(this.props.currentFurnaceId);
        //console.log(this.props.currentCameraId,"sdg")
        //this.props.setCurrentCameraId(furnaceCameras[0].name)
        return furnaceCameras;
    }

    componentDidMount(): void {
        if(this.props.currentCameraId === "")
        {
            let furnaceCameras = getFurnaceCameras(this.props.currentFurnaceId);
            this.props.setCurrentCameraId(furnaceCameras[0].id)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.camera.cameras !== this.props.camera.cameras)) {
            this.setState(() => ({
                camera: this.props.camera.cameras,
            }));
        }
        if((this.state.currCamera !== this.props.camera.name)){
            this.props.setCoils([])
            this.props.setCurrentCoil('')
            // this.props.setCurrSelectedImage('')
        }       
    }

    handleSelection = (e) => {
        const newVal = e.target.value
        this.setState({ currCamera: newVal });
        this.props.setCurrentCamera(newVal);
        this.props.setCurrentCameraId(e.nativeEvent.target.getAttribute("data-cameraid"));
        let coilGroupData = getCoilgroupByCamera(newVal)
        this.props.setCurrentCoilGroup(coilGroupData[0].name)
    }

    render(): React.ReactNode {
        return (
            <div className="control-header element">
                <FormControl className="sel_camera">
                    <InputLabel>Select Camera</InputLabel>
                    <Select autoWidth value={this.state.currCamera} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                        {this.getCameras().map((camera, i) => (
                            <MenuItem key={`CAMERA-SELECT-${i}`} data-cameraid={camera.id} value={camera.display_name}>
                                {camera.display_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="camera_location">
                    <div className="edit_assignment">Camera Location</div>
                    <div className="camera_loc">
                        {this.getCameras().map((camera, i) => (
                            this.state.currCamera === camera.display_name && camera.camera_location                         
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default connector(CameraSelector);