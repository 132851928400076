import * as XLSX from 'xlsx/xlsx.mjs';
import { ROWCOLType } from '../../interfaces/IImportExport';

import { prepareFeedstock, prepareFuelGas, prepareSheetFromAOA, prepareSheetFromObj, getCalcResultFromObj, prepareConvectionSection, prepareCG } from './prepareXSLX_Data';
import { preparePPSheetFromObj } from './prepareXSLX_Data';
import { getTemperatureUnit } from '../../configuration';

// const ConsoleLog = (error) => error !== "" ? console.log("error", error) : null;

export const xlsExporter = async (state, scenarioRunlengths) => {
    var ws_arr: any[] = [];
    let session = await state.exportData.getSessionConfig();
    const isEmpty = Object.keys(session).length === 0;
    if (isEmpty) {
        let msg = "An empty Session can not be exported. Please check whether Feedstock and Fuelgas are loaded and Results from Scenario-Simulator or Yield-Predictor are available."
        state.exportData.setMessage(true, msg)

        return
    }
    else if (session.Session['ErrorTrace'].length > 0) {
        state.exportData.setMessage(session.Session['ErrorTrace'][0]["error"], session.Session['ErrorTrace'][0]["msg"])
        return

    }


    // ws_arr.push(await prepareCOT(state,'COT',['RUNLENGTH','COT'],[ROWCOLType.VarCol,ROWCOLType.VarCol]));

    //TYPES:  CONST_A; VAR_A; CONST_AOA, VAR_AOA
    //Types define what kind of Stucture to read and how to construct a new array for each iteration. 
    //CONST means that the value or field is not varying for one scenario or whole iteration 
    //therfore it is returned only once for the first run
    //An AOA-Type for each row is required by the library
    //CONTEXT: ITEM-NAME
    //The FieldName is mapped to the tag in Redux and supports as a context to define the rows and cols in a sheet

    //COILLENGTH:ROWCOLType.StaticCol map array length ->[0-100] constant for ALL scenarios using the same furnace --ConstA ARRAY
    //RL:ROWCOLType.VarCol one array for each scenario, type is var since runlength can be different VarAOA ==
    //TMTMAX:ROWCOLType:VarSingleValue for each RL/Iteration

    let TMTconfig = {
        sheet: 'TMT',
        tags: ['RUNLENGTH', 'TMTMAX', 'TWALLOUTER_MAX'],
        types: [ROWCOLType.VarCol, ROWCOLType.VarCol, ROWCOLType.VarRow],
        refTag: 'TWALLOUTER_MAX',
        alias: ['RUNLENGTH [d]', 'TMT_MAX ['+ getTemperatureUnit() +']', 'TMT ['+ getTemperatureUnit() +']'],
        transposed: true,
        headers: [
            //[[0, 0], ['plant', 'furnace', 'scenario','','','RUNLENGTH','TMTMAX', ''], true],
            //[[0, 0], ['', '', '','','','RUNLENGTH','TMTMAX', ''], true],
            [[0, 0], ['plant', 'furnace', 'scenario', '', '', '', 'RUNLENGTH', 'TMTMAX', ''], true],
            [[2, 4], ['COILLENGTH %'], true],

        ],
        map: {},
        map2Range: state.exportData.map2Range,
        getXYItemsbyType: state.exportData.getXYItemsbyType
    };

    /*  let COTconfig = {
          sheet: 'COT',
          tags: ['RUNLENGTH', 'COT'],
          alias: ['RUNLENGTH [d]', 'TMT [°C]'],
          types: [ROWCOLType.VarCol, ROWCOLType.VarCol],
          transposed: false,
          headers: [
              [[0, 0], ['plant', 'furnace', 'scenario'], true],
          ],
          map: {},
          map2Range: state.exportData.map2Range,
          getXYItemsbyType:state.exportData.getXYItemsbyType
  
      };*/

    /*let TWALLOUTER_ProfileConfig = {
      sheet: 'TWALLOUTER',
      tags: ['runlength', 'coillength.runtime', 'coillength.TWALLOUTER_MAX'],
      alias: ['RUNLENGTH [d]', "COILLENGTH [%]", 'TMT [°C]'],
      types: [ROWCOLType.VarCol, ROWCOLType.VarCol, ROWCOLType.VarCol],
      refTag: 'coillength.runtime',
      transposed: false,
      headers: [
          [[0, 0], ['plant', 'furnace', 'scenario'], true],
      ],
      map: {},
      map2Range: state.exportData.map2Range,
      getXYItemsbyType: state.exportData.getXYItemsbyType

  };*/


    let COTconfig = {
        sheet: 'COT',
        tags: ['RUNLENGTH', 'COT'],
        types: [ROWCOLType.VarCol, ROWCOLType.VarCol],
        alias: ['RUNLENGTH [d]', 'COT ['+ getTemperatureUnit() +']'],
        transposed: false,
        headers: [
            [[0, 0], ['plant', 'furnace', 'scenario', '', ''], true],
        ],
        map: {},
        map2Range: state.exportData.map2Range,
        getXYItemsbyType: state.exportData.getXYItemsbyType
    };


    let CIPconfig = {
        sheet: 'CIP',
        tags: ['RUNLENGTH', 'CIP'],
        alias: ['RUNLENGTH [d]', 'CIP [bar g]'],
        types: [ROWCOLType.VarCol, ROWCOLType.VarCol],
        transposed: false,
        headers: [
            [[0, 0], ['plant', 'furnace', 'scenario'], true],
        ],
        map: {},
        map2Range: state.exportData.map2Range,
        getXYItemsbyType: state.exportData.getXYItemsbyType

    };

    let CDconfig = {
        sheet: 'CD',
        tags: ['runtime', 'ETHYLENE', 'PROPYLENE', 'COKETHICKNESS',
            'PE_RATIO', 'C2_CONVERSION', 'C3_CONVERSION', 'C4_CONVERSION',
            'ABSORBED_HEAT_ANA', 'FEUDIM_FIRED_HEAT',
        ],
        alias: ['Runlength [d]', 'Ethylene Content [%]', 'Propylene Content [%]', 'Coke Thickness [mm]',
            'P/E RATIO', 'C2 Conversion [%]', 'C3 Conversion [%]', 'C4 Conversion [%]',
            'Q abs [MW]', 'Q fired [MW]'],
        decimal_places: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2], //default value for all numbers is 3
        types: [ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow,
        ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow],
        transposed: false,
        headers: [
            [[0, 0], ['plant', 'furnace', 'scenario', '', 'RUNLENGTH [d]', 'Ethylene Content [%]', 'Propylene Content [%]',
                'Cokethickness [mm]',
                'P/E Ratio', 'C2 Conversion [%]', 'C3 Conversion [%]', 'C4 Conversion [%]',
                'Q abs [MW]', 'Q fired [MW]'], true],

            // [[4, 1],  ['RUNLENGTH [d]'], true],
        ],
        map: {},
        map2Range: state.exportData.map2Range,
        getXYItemsbyType: state.exportData.getXYItemsbyType
    };


    let ConvectionSectionConfig = {
        sheet: 'ConvectionSection',
        tags: ["BFW_TEMPERATURE", "BFW_PRESSURE", "HP_STEAM_TEMPERATURE", "HP_STEAM_PRESSURE", "FEED_TEMPERATURE", "FEED_PRESSURE", "DILUTION_STEAM_TEMPERATURE", "DILUTION_STEAM_PRESSURE", "FUELGAS_TEMPERATURE", "FUELGAS_PRESSURE", "CG_PRESSURE_AT_HEADER"],
        alias: ["Boil Feed Water Temperature", "Boil Feed Water Pressure", "High Pressure Stream Temperature", "High Pressure Stream Pressure", "Feed Temperature", "Feed Pressure", "Dilution Steam Temperature", "Dilution Steam Pressure", "Fuel Gas Temperature", "Fuel Gas Pressure", "Cracked Gas after Quenching"],

        types: [ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow,
        ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow],
        transposed: false,
        headers: [
            [[0, 0], ['plant', 'furnace', 'scenario', '', 'FUELGAS', 'FEEDSTOCK', 'CONVECTIONSECTION', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX'], true],

        ],
        map: {}

    };


    let ScenarioConfigs = {
        sheet: 'Scenarios',
        tags: ['fuelGas_id', 'feedstock_id', 'convectionsection_id', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX'],
        alias: ['FUELGAS', 'FEEDSTOCK', 'CONVECTIONSECTION', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX'],

        types: [ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow,
        ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow, ROWCOLType.VarRow],
        transposed: false,
        headers: [
            [[0, 0], ['plant', 'furnace', 'scenario', '', 'FUELGAS', 'FEEDSTOCK', 'CONVECTIONSECTION', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX'], true],

        ],
        map: {}

    };

    // let TWALLOUTER_ProfileConfig = {
    //     sheet: 'Twallouter',
    //     tags: ['runlength', 'coillength.runtime', 'coillength.TWALLOUTER_MAX'],
    //     alias: ['RUNLENGTH [d]', "COILLENGTH [%]", 'TMT [°C]'],
    //     types: [ROWCOLType.VarCol, ROWCOLType.VarCol, ROWCOLType.VarCol],
    //     refTag: 'coillength.runtime',
    //     transposed: false,
    //     headers: [
    //         [[0, 0], ['plant', 'furnace', 'scenario'], true],
    //     ],
    //     map: {},
    //     map2Range: state.exportData.map2Range,
    //     getXYItemsbyType: state.exportData.getXYItemsbyType

    // };

    let Cokethickness_ProfileConfig = {
        sheet: 'Cokethickness',
        tags: ['runlength', 'coillength.runtime', 'coillength.COKETHICKNESS'],
        alias: ['RUNLENGTH [d]', 'COILLENGTH [%]', 'COKE THICKNESS [mm]'],
        types: [ROWCOLType.VarCol, ROWCOLType.VarCol, ROWCOLType.VarCol],
        refTag: 'coillength.runtime',
        transposed: false,
        //path: ['coillength', 'coillength'],   //is used in prepareFromSheetFromObj to handle e.g [{x:[], y:value},...]
        //display: ['repeated', 'sideheader', 'value'],
        headers: [
            // [[0, 0], ['plant', 'furnace', 'scenario', '', 'Coil length [%]'], true],
            [[0, 0], ['plant', 'furnace', 'scenario'], true],
            // [[4, 1],  ['Coke thickness [mm]'], true],
        ],
        map: {},
        map2Range: state.exportData.map2Range,
        getXYItemsbyType: state.exportData.getXYItemsbyType

    };

    //To Do create header by utilizing plant_config.json

    let YieldPredScenarioConfigs = {
        sheet: 'YieldPred',
        tags: ['fuelGas_id', 'feedstock_id', 'Conv_Ref', 'FGroupLabel', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX', 'HydroCarbon_Flow_Per_Furnace'],
        alias: ['FUELGAS', 'FEEDSTOCK', 'CONVECTIONSECTION', 'FURNACE_GRP', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX', 'HydroCarbon_Flow_Per_Furnace'],

        headers: [
            [[0, 0],
            ['Scenario', 'Pressure', 'PredictionTime', 'Fuelgas_Ref', '',
                'Furnace_Grp', 'Num of Furncaces', 'HydroCarbon_Flow_Per_Furnace', 'ConvectionSection', 'CIT', 'DILUT', 'KEY_COMPONENT', 'SPEC', 'SPEC_TYPE',
                "Component-1", "Component-1-FlowRate", "Component-1-Feedstock",
                "Component-2", "Component-2-FlowRate", "Component-2-Feedstock",
                "Component-3", "Component-3-FlowRate", "Component-3-Feedstock",
                "Component-4", "Component-4-FlowRate", "Component-4-Feedstock"],
                true],
        ],
        map: {},

    };


    /* let CDconfigCokethickness2 = {
         sheet: 'Cokethickness',
         tags: ['runlength', 'runtime', 'COKETHICKNESS'],
         path: ['coillength', 'coillength'],   //is used in prepareFromSheetFromObj to handle e.g [{x:[], y:value},...]
         alias: ['Runlength [d]', 'Coil length [%]', 'Coke thickness max. [mm]'],
         display: ['repeated', 'sideheader', 'value'],
         types: [ROWCOLType.VarCol, ROWCOLType.VarCol],
         transposed: false,  //is used in prepareSheetFromAOA
         headers: [
             [[0, 0], ['plant', 'furnace', 'scenario', '', 'Coil length [%]'], false],
             // [[4, 1],  ['Coke thickness [mm]'], true],
         ],
         map: {}
 
     };*/



    if (state.exportData.isScenariosExportable) {
        ws_arr.push(await prepareCG(state));
        ws_arr.push(await prepareSheetFromAOA(TMTconfig));
        ws_arr.push(await prepareSheetFromAOA(CIPconfig));
        ws_arr.push(await prepareSheetFromAOA(COTconfig));
        /*CalcResults:{
            scenarioName: scenario['Scenario'],
            cot: twallouter_maxProfile,
            cokethickness: thicknessProfile,
            config: [scenarioConfig],
            ...cd_data
        }*/

        let CalcResults = getCalcResultFromObj(session, scenarioRunlengths);
        ws_arr.push(await prepareSheetFromObj(ScenarioConfigs, CalcResults, 'config'))
        //*ws_arr.push(await prepareSheetFromObj(TWALLOUTER_ProfileConfig, CalcResults, 'twallouter'))

        ws_arr.push(await prepareSheetFromObj(CDconfig, CalcResults))
        ws_arr.push(await prepareSheetFromObj(Cokethickness_ProfileConfig, CalcResults, 'cokethickness'))

    }

    ws_arr.push(await prepareConvectionSection(ConvectionSectionConfig, session))

    ws_arr.push(...prepareFeedstock(session))
    ws_arr.push(prepareFuelGas(session, ['Name', 'Molecular_Weight', 'Value']))

    //ws_arr.push(await prepareSheetFromAOA(COTconfig));
    //ws_arr.push(await prepareCD(state));

    //let CalcResults = getCalcResult(scenarioRunlengths);
    //ws_arr.push(await prepareSheetFromObj(Cokethickness_ProfileConfig,CalcResults,'cot'))
    if (state.exportData.isYieldPredExportable)
        ws_arr.push(await preparePPSheetFromObj(YieldPredScenarioConfigs, session, 'YieldPredictor'))




    let error = createXLSX_File(ws_arr, state.fileName);
    if (error)
        state.exportData.setMessage(true, error)

};


function createXLSX_File(ws_arr: any[], fileName) {
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Result",
        //CreatedDate: Date.now()
    };

    console.log("Download here", fileName, ws_arr)


    //  let error_msg="Error during file preparation";
    let error_msg = "";
    for (let ws_obj of ws_arr) {
        //if {} is empty, there is no data therfore ignore it
        if (ws_obj.status === undefined)
            continue;
        //sheetName should not exeed 32 chars
        if (ws_obj.data) {
            try {
                var ws = XLSX.utils.aoa_to_sheet(ws_obj.data);
                XLSX.utils.book_append_sheet(wb, ws, ws_obj.sheetName);
            }
            catch (e) {
                error_msg = JSON.stringify(e);
                break;
            }

        }
        else {
            error_msg = ws_obj.status;
            break;
        }

    }

    if (error_msg === "") {
        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        downloadXLSX(wbout, fileName);
    }

    return error_msg;

}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i = 0; i < s.length; i++)
        view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
}

const downloadXLSX = (wbout, filename) => {
    const fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, `${filename}.xlsx`);
    } else {
        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', `${filename}.xlsx`);
        fakeLink.click();
    }
};

