import React, { Component } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { IFeedstock, FEEDSTOCK_STATUS, IFeedstockComponent } from "../../interfaces/IFeedstock";
import { FUELGAS_STATUS, IFuelGas } from "../../interfaces/IFuelGas";
import { IScenario, CALCULATION_STATUS, TERMINATION_REASON, SCENARIO_PROGRESS, SCENARIO_STATUS, SCENARIO_TYPE, MODE } from "../../interfaces/IScenario";
import { RootState } from "../../reducers/rootReducer";
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { addScenarioRunlength, addScenarioToRunlength, setCurrentRunLengthId, setCurrentScenarioRunLengthId } from "../../slices/scenarioSimulatorSlice";
import { getDefaultConvectionSectionData } from "../../utilities/loadConvectionSection";
import { IConvectionSection } from "../../interfaces/IConvectionSection";
import { updateLoadedConvectionSections, addConvectionSection, addDefaultConvectionSectionData, deleteConvectionSection } from "../../slices/convectionSectionSlice";
import { deleteScenarioRunlength, updateLoadedScenarioRunlengths } from "../../slices/scenarioSimulatorSlice";
import { addFeedstock, updateFeedtype, updateLoadedFeedstocks, setCurrentFeedstockId, deleteFeedstock } from "../../slices/feedstockSlice";
import { addFuelGas, updateLoadedFuelGases, deleteFuelGas } from "../../slices/fuelGasSlice";
// import { getFeedstockTypesAvailable } from "../../configuration";
import store from "../..";
import DropField from "./DropField";
import { FeedstockRef, FuelGasRef } from "./importRef"
import { getFeedtype } from "../feedstocks/discreteCompositionFeedstock";
import { getSignature } from "../../utilities/helperFunctions";


interface IImportSessionProps {
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        currentFeedstockId: state.feedstock.current_feedstock_id,
        scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths,
        furnaces: state.furnace.furnaces,
        //loadedScenarioRunlengths: state.scenarioSimulator.loadedScenarioRunlengths,
        //loadedFeedstocks: state.feedstock.loadedFeedstocks,
        //loadedFuelGases: state.fuelGas.loadedFuelGases,
        feedstocks: state.feedstock.feedstocks,
        fuelGases: state.fuelGas.fuelGases,
        convectionSections: state.convectionsection.loadedConvectionSections,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetSession: () => {

            dispatch({ type: "RESET_COIL_STATE" });
            dispatch({ type: "RESET_ERRORS_STATE" });
            dispatch({ type: "RESET_SCENARIOS_STATE" });
            dispatch({ type: "RESET_TERMINATION_STATE" });
        }

        ,
        addScenarioToRunlength: (runlength_id: string, scenario: IScenario) => dispatch(addScenarioToRunlength({ runlength_id, scenario })),
        addFeedstock: (feedstock: IFeedstock) => dispatch(addFeedstock(feedstock)),
        addFuelGas: (fuelGas: IFuelGas) => dispatch(addFuelGas(fuelGas)),
        addConvectionSection: (ConvectionSection: IConvectionSection) => dispatch(addConvectionSection(ConvectionSection)),
        addDefaultConvectionSectionData: (DefaultConvectionSection: any[]) => dispatch(addDefaultConvectionSectionData(DefaultConvectionSection)),
        addScenarioRunlength: (scenarioRunlength: IScenarioRunlength) => dispatch(addScenarioRunlength(scenarioRunlength)),
        setCurrentScenarioRunLengthId: (newScenarioRunLengthId: string) => dispatch(setCurrentScenarioRunLengthId(newScenarioRunLengthId)),
        setCurrentRunLengthId: (newRunLengthId: number) => dispatch(setCurrentRunLengthId(newRunLengthId)),
        setCurrentFeedstockId: (newFeedstockId: string) => dispatch(setCurrentFeedstockId(newFeedstockId)),
        deleteScenarioRunlength: (scenarioRunlength: IScenarioRunlength) => dispatch(deleteScenarioRunlength(scenarioRunlength)),
        deleteFeedstock: (feedstock_id: string) => dispatch(deleteFeedstock(feedstock_id)),
        deleteFuelGas: (fuelGas_id: string) => dispatch(deleteFuelGas(fuelGas_id)),
        deleteConvectionSection: (convectiosection_id: string) => dispatch(deleteConvectionSection(convectiosection_id)),
        updateFeedtype: (feedstock_id: string, feedtype_id: string) => dispatch(updateFeedtype({ feedstock_id, feedtype_id }))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IImportSessionReduxProps = PropsFromRedux & IImportSessionProps;

type IImportSessionState = {
    showDialog: boolean;
    runlength: any;
    allowImport: boolean;
    importObj: any;
    toggler: boolean;
}


class ImportSession extends Component<IImportSessionReduxProps, IImportSessionState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            runlength: this.props.scenarioRunlengths !== undefined && this.props.scenarioRunlengths.length > 0 ? this.props.scenarioRunlengths[0].id : "",
            allowImport: false,
            importObj: null,
            toggler: false
        }
    }


    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
            runlength: this.props.scenarioRunlengths !== undefined && this.props.scenarioRunlengths.length > 0 ? this.props.scenarioRunlengths[0].id : "",
            allowImport: false,
            importObj: null
        })
    }



    getFurnaceIdByName = (name) => {
        let idx: number = this.props.furnaces.findIndex(f => f.DisplayName === name);
        if (idx < 0)
            throw new Error(`Frunace ${name} was not found!`);
        return idx + 1;
    }

    resetRedux = (data, always = true) => {

        this.props.scenarioRunlengths.forEach(sr => {
            //let del=data[0]['ScenarioIn']['scenarios'].map(x => (x.Plant_Id === sr.plantId && x.scenario === sr.name && x.Furnace_Id === sr.furnaceId) ? true : false);
            let del = data['Scenarios'].map(x => (x.Plant_Id === sr.plantId && x.scenario === sr.name && this.getFurnaceIdByName(x.Furnace_Ref).toString() === sr.furnaceId) ? true : false);
            if (del === true || always === true) {
                this.props.deleteScenarioRunlength(sr);
            }
        });

        this.props.feedstocks.forEach(fs => {
            //let del=data[0]['ScenarioOut'].map(x => x.feedstock.id === fs.id ? true : false);
            let del = data['Feedstocks'].map(x => x.Name === fs.name ? true : false);
            if (del === true || always === true) {
                this.props.deleteFeedstock(fs.id);
            }
        });

        this.props.fuelGases.forEach(fg => {
            //let del=data[0]['ScenarioOut'].map(x => x.fuelGas.id === fg.id? true : false);
            let del = data['FuelGases'].map(x => x.Name === fg.name ? true : false);
            if (del === true || always === true) {
                this.props.deleteFuelGas(fg.id);
            }
        });

        this.props.convectionSections.forEach(cs => {
            //Delete CONDITON has to be Implemented here!!!!
            this.props.deleteConvectionSection(cs.id);
        });

        this.props.resetSession();

    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    getSubstr = (name) => {
        var regex = /\s+\(([0-9]+)(?!.*[0-9])\)/
        var match = name.match(regex)

        return match ? name.substr(0, match['index']) : name
    }

    matchAndModifyName = (name) => {
        var regex = /\(([0-9]+)(?!.*[0-9])\)/     //find # in  "name ... (#)"
        var matches = name.match(regex);
        var num = 1

        if (null != matches) {
            num = parseInt(matches[1]) + 1;
            name = name.substr(0, matches['index'])

        }
        return name + " (" + num.toString() + ")"
    }

    enumerateName = (name, run) => {
        var caseName = this.getSubstr(name)
        var regex = new RegExp(caseName + "\\s+\\(([0-9]+)(?!.*[0-9])\\)")
        var prev_num = 0
        run.forEach(data => {
            var matches = data.name.match(regex);
            if (null != matches) {
                var num = parseInt(matches[1])
                prev_num = num > prev_num ? num : prev_num
            }

        });

        var newName = caseName + " (" + (prev_num + 1).toString() + ")";
        return newName;
    }

    storeSession_v2() {

        let scenarioRunlengths: IScenarioRunlength[] = [];
        let feedstocks: IFeedstock[] = [];
        let fuelGases: IFuelGas[] = [];
        let convectionSections: IConvectionSection[] = [];
        let data = this.state.importObj['Session'];
        let feedType_id = '4'; //Naptha

        // let feedstockTypes = getFeedstockTypesAvailable();
        // this.resetRedux(data);

        feedstocks = feedstocks.concat(store.getState().feedstock.feedstocks);
        data['Feedstocks'] && data['Feedstocks'].forEach(imp => {

            if (!this.isEmpty(imp)) {
                //Check memory if feedstock name already exists
                let feedstock = feedstocks && feedstocks.find(state_fs => state_fs['name'].toUpperCase() === imp['Name'].toUpperCase())
                let feedstockNameExist = feedstock !== undefined
                let newComponents: IFeedstockComponent = [];
                let newFeedstockComp: any = {};

                //Check if DISCRETE_COMPOSITION Components have proper structure
                if (imp['Components'] && imp['Type'] === "DISCRETE_COMPOSITION") {
                    imp['Components'].forEach((el, idx) => {
                        const sameId = (idx + 1) === parseInt(FeedstockRef['components'][idx]['id'])
                        const sameName = el['name'] === FeedstockRef['components'][idx]['name']
                        if (sameId && sameName) {
                            newComponents.push({
                                id: uuidv4(),//FeedstockRef['components'][idx]['id'],
                                name: FeedstockRef['components'][idx]['name'],
                                chemical: FeedstockRef['components'][idx]['chemical'],
                                molecular_weight: el['molecular_Weight'],
                                value: el['value'],
                                feed_type_id: FeedstockRef['components'][idx]['feed_type_id']

                            });
                        }
                        else console.warn(Error(`Component ${el.name} was not found at Index ${idx + 1}!`));
                    });
                    newFeedstockComp = { components: newComponents }
                    feedType_id = getFeedtype(newComponents);
                }
                else if (imp['Components'] && imp['Type'] === "DETAILED_PIONA") {

                    newComponents.push(...imp['Components'])
                    newFeedstockComp = { components: imp['Components'] }
                    feedType_id = '4';

                }

                else if (imp['Components'] && imp['Type'] === "SHORT_PIONA") {

                    newComponents.push(...imp['Components'])
                    newFeedstockComp = { components: imp['Components'], method: imp['Method'] }
                    feedType_id = '4';

                }


                let newFeedstock: IFeedstock = {
                    id: imp['Name'],//uuidv4(),
                    name: imp['Name'],
                    unit: imp['Unit'],
                    type: imp['Type'],
                    feedstock: newFeedstockComp,
                    feed_type_id: feedType_id, //FeedstockRef['feed_type_id'],
                    status: FEEDSTOCK_STATUS.UNCOMPUTED,
                    error_message: "",
                    signature: "",
                };

                newFeedstock.signature = getSignature(newFeedstock)
                const signatureExists = feedstocks.find(item => item.signature === newFeedstock.signature) !== undefined;
                if (!signatureExists) {

                    if (!feedstockNameExist) {
                        //this.props.deleteFeedstock(feedstock.id);
                        this.props.addFeedstock(newFeedstock);
                    }

                    feedstocks.push(newFeedstock);

                    // this.props.updateFeedtype(newFeedstock['id'], feedType_id);

                }


            }
        });


        scenarioRunlengths = scenarioRunlengths.concat(store.getState().scenarioSimulator.scenarioRunlengths);
        scenarioRunlengths && data['Scenarios'] && data['Scenarios'].forEach(imp => {

            if (!this.isEmpty(imp)) {

                let furnace_Id: any = this.getFurnaceIdByName(imp['Furnace_Ref']);
                //check if signature is the same do nothing, if name is already used ask user if to want overwrite,
                // const siteAssetExists = (el) => el['plantId'] === imp['Plant_Id'] && parseInt(el['furnaceId']) == furnace_Id
                const nameExists = el => el['name'] === imp['Scenario']

                // let scenario_is_avail = false
                // let siteAsset_is_avail = scenarioRunlengths.find(siteAssetExists) !== undefined
                let run_name_is_used = scenarioRunlengths.find(nameExists) !== undefined
                /*if (siteAsset_is_avail && run_name_is_used ){
                    let msg= "Scenario " + run['Scenario'] + "for Plant Id: " +  run['Plant_Id'] + "and Asset: " +  run['Furnace_Ref'] +" already exists"
                    console.warn()
                }*/

                let newScenario: IScenario = //Object.assign(imp, 
                {
                    id: uuidv4(),
                    name: imp['Config_Ref'],
                    furnace: furnace_Id,
                    feedstock_id: imp['Feedstock_Ref'],
                    fuelGas_id: imp['FuelGas_Ref'].includes("DEFAULT_") ? '_' + imp['FuelGas_Ref'] : imp['FuelGas_Ref'],
                    convectionsection_id: imp['Convection_Ref'],
                    CIT: imp['CIT'],
                    COP: imp['COP'],
                    DILUT: imp['DILUT'],
                    FLOW_HC: imp['FLOW_HC'],
                    INTERVAL: imp['INTERVAL'],
                    KEY_COMPONENT: imp['KEY_COMPONENT'],
                    LAVAL_RATIO: imp['LAVAL_RATIO'],
                    P_XOVER: imp['P_XOVER'],
                    RUNLENGTH_MAX: imp['RUNLENGTH_MAX'],
                    SPEC: imp['SPEC'],
                    SPEC_TYPE: imp['SPEC_TYPE'],
                    TMTMAX: imp['TMTMAX'],
                    FROM_DB: false,
                    CALCULATIONS: [],
                    RUN_LENGTH_FINAL: 0,
                    NEXT_RESULT_CALCULATION_ID: '',
                    status: SCENARIO_STATUS.UNCOMPUTED,
                    calculation_status: CALCULATION_STATUS.IDLE,
                    last_model_input: {},
                    termination_reason: TERMINATION_REASON.IDLE,
                    case_id: '',
                    scenario_progress: SCENARIO_PROGRESS.VALIDATION,
                    currentScenarioIsRTA: false,
                    mode: MODE.FULL,
                    firebox: [],
                    terminated_firebox: 0
                }//);

                let runlengthdata: IScenarioRunlength | undefined = {
                    id: uuidv4(),
                    furnaceId: furnace_Id,
                    plantId: imp["Plant_Id"],
                    name: imp["Scenario"],
                    scenarios: [newScenario],
                    isExportable: false,
                    signature: getSignature(newScenario),
                    type: SCENARIO_TYPE.Cold_START
                }

                //if runlength name is in use, check also if scenario(signature) is different.
                // const scenarioExists = el => runlengthdata && el['signature'] === runlengthdata['signature']
                runlengthdata.name = run_name_is_used ? this.enumerateName(runlengthdata.name, scenarioRunlengths) : runlengthdata.name
                // scenario_is_avail = run_name_is_used ? scenarioRunlengths.find(scenarioExists) !== undefined : false


                //Add new Scenario, but no replicas
                if (runlengthdata !== undefined) {
                    scenarioRunlengths.push(runlengthdata);
                    this.props.addScenarioRunlength(runlengthdata);
                }



            }
        });


        fuelGases = fuelGases.concat(store.getState().fuelGas.fuelGases);
        data['FuelGases'] && data['FuelGases'].forEach(run => {
            const fuelgas = fuelGases && fuelGases.find(el => el['name'].toUpperCase() === run['Name'].toUpperCase())
            const nameExist = fuelgas !== undefined
            const isDefault = (nameExist && run['Name'].startsWith("DEFAULT_")) ? (fuelgas['id'] === '_' + (run['Name'] !== undefined)) : false

            if (!isDefault) {

                if (run['Components'] !== undefined) {
                    let newComponents: any = [];
                    run['Components'].forEach((el, idx) => {
                        let id = '_' + el['Name'];
                        const sameId = id === FuelGasRef['components'][idx]['id'];
                        const sameName = el['Name'] === FuelGasRef['components'][idx]['name'];
                        if (sameId && sameName)
                            newComponents.push({
                                id: FuelGasRef['components'][idx]['id'],
                                name: FuelGasRef['components'][idx]['name'],
                                chemical: FuelGasRef['components'][idx]['chemical'],
                                molecular_weight: el['Molecular_Weight'],
                                value: el['Value'],
                            });
                        else throw new Error(`Component ${el.name} was not found at Index ${idx + 1}!`);
                    });


                    let newFuelgas: IFuelGas = {
                        id: run['Name'],
                        name: run['Name'],
                        unit: run['Unit'],
                        O2BridgeWall: run['O2BridgeWall'],
                        Temperature: run['Temperature'],
                        Pressure: run['Pressure'],
                        components: newComponents,
                        status: FUELGAS_STATUS.UNCOMPUTED,
                        error_message: "",
                        signature: ""
                    };
                    newFuelgas.signature = getSignature(newFuelgas)

                    if (!nameExist) {
                        fuelGases.push(newFuelgas);
                        this.props.addFuelGas(newFuelgas);
                    }
                    else if (newFuelgas.signature !== fuelgas.signature) {
                        this.props.deleteFuelGas(newFuelgas.id);
                        fuelGases.push(newFuelgas);
                        this.props.addFuelGas(newFuelgas);

                    }

                }
            }

        });

        convectionSections = convectionSections.concat(store.getState().convectionsection.convectionsections);
        data['ConvectionSections'] && data['ConvectionSections'].forEach(run => {
            if (convectionSections && !convectionSections.find(el => el['name'].toUpperCase() === run['Name'].toUpperCase() && el['furnace_id'] === run['Furnace_Id'] && el['asset_name'].toUpperCase() === run['Asset'].toUpperCase())) {
                if (run['Components'] !== undefined) {
                    let newComponents: any = { asset_name: run['Asset'] };
                    run['Components'].forEach(comp => {
                        // if (cosy_preperties.find(comp['Name']) !== undefined) {
                        newComponents = {
                            ...newComponents, ...{
                                [comp['Name']]: {
                                    input_tag: comp['Name'],
                                    tag_unit: comp['Unit'],
                                    design: comp['Value']
                                }
                            }
                        }
                        // }
                    })

                    let newConvectionSection: IConvectionSection = {
                        id: run['Name'],
                        name: run['Name'],
                        furnace_id: run['Furnace_Id'],
                        components: [newComponents],
                        asset_name: run['Asset'],
                    };
                    convectionSections.push(newConvectionSection);
                    this.props.addConvectionSection(newConvectionSection);

                    // If default convection section data is already loaded then get it from redux store
                    let defaultdata = store.getState().convectionsection.defaultConvectionSectionDatas.find(f => f.asset_name === run['Asset']);
                    if (defaultdata === undefined) {
                        // Get default convection section data based on asset name
                        getDefaultConvectionSectionData(run['Asset']).then(data => defaultdata = data);
                    }
                    this.props.addDefaultConvectionSectionData([defaultdata])



                }
            }

        });


        // Update feedStock, fuelGas and convection section to redux store
        store.dispatch(updateLoadedScenarioRunlengths(scenarioRunlengths));
        store.dispatch(updateLoadedFeedstocks(feedstocks));
        store.dispatch(updateLoadedFuelGases(fuelGases));
        store.dispatch(updateLoadedConvectionSections(convectionSections));

        this.toggleShowDialog();

    }


    onDropHandler(validImport, jsonObj) {

        this.setState({
            allowImport: validImport,
            importObj: jsonObj,
            toggler: false
        })
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                >
                    Import Session
                </button>
                <Dialog
                    open={this.state.showDialog}
                    onClose={() => this.toggleShowDialog()}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Import Session</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Drop a Session-File to load:
                        </DialogContentText>
                        <DropField onDrop={(valid, jsnObj) => this.onDropHandler(valid, jsnObj)} />
                        <div className={"load-scenario"}>
                            <TextField
                                id={"scenario-runlength"}
                                select
                                margin="dense"
                                style={{ width: "100%" }}
                                SelectProps={{ autoWidth: true }}
                               // error={this.props.scenarioRunlengths === []}
                               // helperText={this.props.scenarioRunlengths === [] ? "There are no scenario runlengths to load!" : ""}
                                label={"Import Type:"}
                                defaultValue={"Only Config"}
                                disabled={true}
                            >
                                {["Only Config", "Full"].map((value: string, index: number) => (

                                    <MenuItem key={index} value={value}>
                                        {value}
                                    </MenuItem>

                                ))}
                            </TextField>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.storeSession_v2()//this.loadSession()
                        }
                            disabled={!this.state.allowImport}>
                            Import
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

};

export default connector(ImportSession);