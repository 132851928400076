import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IFeedstock } from "../../interfaces/IFeedstock";
import { SCENARIO_STATUS } from "../../interfaces/IScenario";
import {
  getScenarioRunlengthFromJson,
  IScenarioRunlength,
} from "../../interfaces/IScenarioRunlength";
import { RootState } from "../../reducers/rootReducer";
import {
  modifyFeedstocks,
  normalizeFeedstock,
} from "../../slices/feedstockSlice";
import {
  resetCalculations,
  setStatusScenarioInRunlength,
  addCalculation,
  updateNextResultCalculationId,
  modifyScenarioRunlength,
} from "../../slices/scenarioSimulatorSlice";
import { getFeedstockById } from "../../services/feedstockServices";
import { init_calculations } from "../../utilities/scenarioSimulationCalculator";
import { getFuelGasById } from "../../services/fuelgasServices";
import { modifyFuelGas } from "../../slices/fuelGasSlice";
import { IFuelGas } from "../../interfaces/IFuelGas";
import { getConvectionSectionById } from "../../services/convectionsectionServices";
import { IConvectionSection } from "../../interfaces/IConvectionSection";
import { modifyConvectionSection } from "../../slices/convectionSectionSlice";
import { getScenarioRunlengthById } from "../../services/scenarioSimulatorServices";

interface IUpdateRunlengthProps {
  scenarioRunlength: IScenarioRunlength;
}

const mapStateToProps = (state: RootState) => {
  return {
    feedstocks: state.feedstock.feedstocks,
    fuelGases: state.fuelGas.fuelGases,
    convectionsections: state.convectionsection.convectionsections,
    token: state.authState.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStatusScenarioInRunlength: (
      runlength_id: string,
      scenario_id: string,
      status: SCENARIO_STATUS
    ) =>
      dispatch(
        setStatusScenarioInRunlength({ runlength_id, scenario_id, status })
      ),
    resetCalculations: (runlength_id: string, scenario_id: string) =>
      dispatch(resetCalculations({ runlength_id, scenario_id })),
    normalizeFeedstock: (feedstock_id: string) =>
      dispatch(normalizeFeedstock(feedstock_id)),
    addCalculation: (
      runlength_id: string,
      scenario_id: string,
      result: any,
      rta: boolean = false
    ) => dispatch(addCalculation({ runlength_id, scenario_id, result, rta })),
    updateNextResultCalculationId: (
      runlength_id: string,
      scenario_id: string,
      calculation_id: any
    ) =>
      dispatch(
        updateNextResultCalculationId({
          runlength_id,
          scenario_id,
          calculation_id,
        })
      ),
    modifyFeedstocks: (newFeedstock: IFeedstock) =>
      dispatch(modifyFeedstocks(newFeedstock)),
    modifyFuelGas: (newFuelgas: IFuelGas) =>
      dispatch(modifyFuelGas(newFuelgas)),
    modifyConvectionSection: (convectionsectiondata: IConvectionSection) =>
      dispatch(modifyConvectionSection(convectionsectiondata)),
    modifyScenarioRunlength: (newScenarioRunlength: IScenarioRunlength) =>
      dispatch(modifyScenarioRunlength(newScenarioRunlength)),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IUpdateRunlengthReduxProps = PropsFromRedux & IUpdateRunlengthProps;

type IUpdateRunlengthState = {
  showDialog: boolean;
  getFeedStockUpdatedDateFromDb: string;
  showLoading: boolean;
  getFuelGasUpdatedDateFromDb: string;
  getconvectionsectionsUpdatedDateFromDb: string;
  getScenarioRunlengthDateFromDb: string;
};

class UpdateRunlength extends Component<
  IUpdateRunlengthReduxProps,
  IUpdateRunlengthState
> {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      getFeedStockUpdatedDateFromDb: "",
      showLoading: false,
      getFuelGasUpdatedDateFromDb: "",
      getconvectionsectionsUpdatedDateFromDb: "",
      getScenarioRunlengthDateFromDb: "",
    };
  }

  toggleShowDialog = () => {
    this.setState({
      showDialog: !this.state.showDialog,
    });
  };

  checkUpdateAnyUpdate = async (
    scenarios,
    feedstocks,
    fuelGases,
    convectionsections,
    runlength_id: string,
    token: string
  ) => {
    this.setState({
      showLoading: true,
    });
    try {
      // var getScenarioDromDb;
      if (
        !!this.props.scenarioRunlength.cases_id &&
        this.props.scenarioRunlength.cases_id > 0 &&
        this.props.scenarioRunlength.is_public
      ) {
        let getScenarioDromDbAll = await getScenarioRunlengthById(
          this.props.scenarioRunlength
        );
        // getScenarioDromDb = getScenarioRunlengthFromJson(
        //   getScenarioDromDbAll.data[0]["SettingsIn"]
        // ) as IScenarioRunlength;
        let reduxDate =
          !!this.props.scenarioRunlength.ts && this.props.scenarioRunlength.ts
            ? new Date(this.props.scenarioRunlength.ts)
            : null;

        if (
          getScenarioDromDbAll.data[0]["signature"] !==
            this.props.scenarioRunlength.signature &&
          reduxDate !== null &&
          new Date(getScenarioDromDbAll.data[0]["ts"]).getTime() !==
            reduxDate.getTime()
        ) {
          this.toggleShowDialog();
          this.setState({
            getScenarioRunlengthDateFromDb: new Date(
              getScenarioDromDbAll.data[0]["ts"]
            ).toLocaleString(),
            getFeedStockUpdatedDateFromDb: "",
            getFuelGasUpdatedDateFromDb: "",
            getconvectionsectionsUpdatedDateFromDb: "",
            showLoading: false,
          });

          return false;
        }
      }
      if (
        (scenarios[0].firebox[0]?.feedstock_id !== "" &&
          scenarios[0].fuelGas_id !== "") ||
        scenarios[0].convectionsection_id !== ""
      ) {
        let getFeedstockFromDbById,
          getFuelgasFromDbById,
          getConvectionSectionFromDbById;
        let feedstock_index,
          fuel_gas_index,
          convectionsection_index = -1;
        if (scenarios[0].firebox[0].feedstock_id !== "") {
          getFeedstockFromDbById = await getFeedstockById(
            scenarios[0].firebox[0].feedstock_id
          );
          feedstock_index = feedstocks.findIndex(
            (feedstock) => feedstock.id === scenarios[0].firebox[0].feedstock_id
          );
        }
        if (!scenarios[0].fuelGas_id.startsWith("_DEFAULT")) {
          getFuelgasFromDbById = await getFuelGasById(scenarios[0].fuelGas_id);
          fuel_gas_index = fuelGases.findIndex(
            (fuelgas) => fuelgas.id === scenarios[0].fuelGas_id
          );
        }
        if (scenarios[0].convectionsection_id !== "") {
          getConvectionSectionFromDbById = await getConvectionSectionById(
            scenarios[0].convectionsection_id
          );
          convectionsection_index = convectionsections.findIndex(
            (convectionsection) =>
              convectionsection.id === scenarios[0].convectionsection_id
          );
        }
        if (
          ((feedstock_index > -1 && feedstocks[feedstock_index].is_public) ||
            (fuel_gas_index > -1 && fuelGases[fuel_gas_index].is_public) ||
            (convectionsection_index > -1 &&
              convectionsections[convectionsection_index].is_public)) &&
          !scenarios[0].fuelGas_id.startsWith("_DEFAULT")
        ) {
          if (
            getFeedstockFromDbById.data[0]["signature"] ===
              feedstocks[feedstock_index].signature &&
            getFuelgasFromDbById.data[0]["signature"] ===
              fuelGases[fuel_gas_index].signature &&
            getConvectionSectionFromDbById === undefined
          ) {
            this.setState({
              showLoading: false,
            });
            init_calculations(
              scenarios,
              feedstocks,
              fuelGases,
              convectionsections,
              runlength_id,
              token
            );
            return false;
          } else if (
            getFeedstockFromDbById.data[0]["signature"] ===
              feedstocks[feedstock_index].signature &&
            getFuelgasFromDbById.data[0]["signature"] ===
              fuelGases[fuel_gas_index].signature &&
            getConvectionSectionFromDbById !== undefined &&
            getConvectionSectionFromDbById.data[0]["signature"] ===
              convectionsections[convectionsection_index].signature
          ) {
            this.setState({
              showLoading: false,
            });
            init_calculations(
              scenarios,
              feedstocks,
              fuelGases,
              convectionsections,
              runlength_id,
              token
            );
            return false;
          } else if (
            getFeedstockFromDbById.data[0]["signature"] !==
            feedstocks[feedstock_index].signature
          ) {
            this.toggleShowDialog();
            this.setState({
              getFeedStockUpdatedDateFromDb: new Date(
                getFeedstockFromDbById.data[0]["updated_at"]
              ).toLocaleString(),
              getFuelGasUpdatedDateFromDb: "",
              getconvectionsectionsUpdatedDateFromDb: "",
              getScenarioRunlengthDateFromDb: "",
              showLoading: false,
            });
            return false;
          } else if (
            getFuelgasFromDbById.data[0]["signature"] !==
            fuelGases[fuel_gas_index].signature
          ) {
            this.toggleShowDialog();
            this.setState({
              getFeedStockUpdatedDateFromDb: "",
              getFuelGasUpdatedDateFromDb: new Date(
                getFeedstockFromDbById.data[0]["updated_at"]
              ).toLocaleString(),
              getconvectionsectionsUpdatedDateFromDb: "",
              getScenarioRunlengthDateFromDb: "",
              showLoading: false,
            });
            return false;
          } else if (
            getConvectionSectionFromDbById.data[0]["signature"] !==
            convectionsections[convectionsection_index].signature
          ) {
            this.toggleShowDialog();
            this.setState({
              getFeedStockUpdatedDateFromDb: "",
              getFuelGasUpdatedDateFromDb: "",
              getconvectionsectionsUpdatedDateFromDb: new Date(
                getConvectionSectionFromDbById.data[0]["updated_at"]
              ).toLocaleString(),
              getScenarioRunlengthDateFromDb: "",
              showLoading: false,
            });
            return false;
          } else {
            this.setState({
              showLoading: false,
            });
            init_calculations(
              scenarios,
              feedstocks,
              fuelGases,
              convectionsections,
              runlength_id,
              token
            );
            return false;
          }
        }

        if (scenarios[0].fuelGas_id.startsWith("_DEFAULT")) {
          this.setState({
            showLoading: false,
          });
          init_calculations(
            scenarios,
            feedstocks,
            fuelGases,
            convectionsections,
            runlength_id,
            token
          );
        } else {
          this.setState({
            showLoading: false,
          });
          init_calculations(
            scenarios,
            feedstocks,
            fuelGases,
            convectionsections,
            runlength_id,
            token
          );
        }

        // TO BE USED IN FUTURE
        // if (scenarios[0].feedstock_id !== "" && scenarios[0].fuelGas_id.startsWith("_DEFAULT") || scenarios[0].convectionsection_id !== "") {
        //     this.setState({
        //         showLoading: false,
        //     })
        //     init_calculations(scenarios, feedstocks, fuelGases, convectionsections, runlength_id, token)
        // }
      } else if (
        (scenarios[0].feedstock_id !== "" && scenarios[0].fuelGas_id !== "") ||
        scenarios[0].convectionsection_id !== ""
      ) {
        var getFeedstockFromDbById,
          getFuelgasFromDbById,
          getConvectionSectionFromDbById;
        var feedstock_index,
          fuel_gas_index,
          convectionsection_index = -1;
        if (scenarios[0].feedstock_id !== "") {
          getFeedstockFromDbById = await getFeedstockById(
            scenarios[0].firebox[0].feedstock_id
          );
          feedstock_index = feedstocks.findIndex(
            (feedstock) => feedstock.id === scenarios[0].feedstock_id
          );
        }
        if (!scenarios[0].fuelGas_id.startsWith("_DEFAULT")) {
          getFuelgasFromDbById = await getFuelGasById(scenarios[0].fuelGas_id);
          fuel_gas_index = fuelGases.findIndex(
            (fuelgas) => fuelgas.id === scenarios[0].fuelGas_id
          );
        }
        if (scenarios[0].convectionsection_id !== "") {
          getConvectionSectionFromDbById = await getConvectionSectionById(
            scenarios[0].convectionsection_id
          );
          convectionsection_index = convectionsections.findIndex(
            (convectionsection) =>
              convectionsection.id === scenarios[0].convectionsection_id
          );
        }
        if (
          ((feedstock_index > -1 && feedstocks[feedstock_index].is_public) ||
            (fuel_gas_index > -1 && fuelGases[fuel_gas_index].is_public) ||
            (convectionsection_index > -1 &&
              convectionsections[convectionsection_index].is_public)) &&
          !scenarios[0].fuelGas_id.startsWith("_DEFAULT")
        ) {
          if (
            getFeedstockFromDbById.data[0]["signature"] ===
              feedstocks[feedstock_index].signature &&
            getFuelgasFromDbById.data[0]["signature"] ===
              fuelGases[fuel_gas_index].signature &&
            getConvectionSectionFromDbById === undefined
          ) {
            this.setState({
              showLoading: false,
            });
            init_calculations(
              scenarios,
              feedstocks,
              fuelGases,
              convectionsections,
              runlength_id,
              token
            );
            return false;
          } else if (
            getFeedstockFromDbById.data[0]["signature"] ===
              feedstocks[feedstock_index].signature &&
            getFuelgasFromDbById.data[0]["signature"] ===
              fuelGases[fuel_gas_index].signature &&
            getConvectionSectionFromDbById !== undefined &&
            getConvectionSectionFromDbById.data[0]["signature"] ===
              convectionsections[convectionsection_index].signature
          ) {
            this.setState({
              showLoading: false,
            });
            init_calculations(
              scenarios,
              feedstocks,
              fuelGases,
              convectionsections,
              runlength_id,
              token
            );
            return false;
          } else if (
            getFeedstockFromDbById.data[0]["signature"] !==
            feedstocks[feedstock_index].signature
          ) {
            this.toggleShowDialog();
            this.setState({
              getFeedStockUpdatedDateFromDb: new Date(
                getFeedstockFromDbById.data[0]["updated_at"]
              ).toLocaleString(),
              getFuelGasUpdatedDateFromDb: "",
              getconvectionsectionsUpdatedDateFromDb: "",
              getScenarioRunlengthDateFromDb: "",
              showLoading: false,
            });
            return false;
          } else if (
            getFuelgasFromDbById.data[0]["signature"] !==
            fuelGases[fuel_gas_index].signature
          ) {
            this.toggleShowDialog();
            this.setState({
              getFeedStockUpdatedDateFromDb: "",
              getFuelGasUpdatedDateFromDb: new Date(
                getFeedstockFromDbById.data[0]["updated_at"]
              ).toLocaleString(),
              getconvectionsectionsUpdatedDateFromDb: "",
              getScenarioRunlengthDateFromDb: "",
              showLoading: false,
            });
            return false;
          } else if (
            getConvectionSectionFromDbById.data[0]["signature"] !==
            convectionsections[convectionsection_index].signature
          ) {
            this.toggleShowDialog();
            this.setState({
              getFeedStockUpdatedDateFromDb: "",
              getFuelGasUpdatedDateFromDb: "",
              getconvectionsectionsUpdatedDateFromDb: new Date(
                getConvectionSectionFromDbById.data[0]["updated_at"]
              ).toLocaleString(),
              getScenarioRunlengthDateFromDb: "",
              showLoading: false,
            });
            return false;
          } else {
            this.setState({
              showLoading: false,
            });
            init_calculations(
              scenarios,
              feedstocks,
              fuelGases,
              convectionsections,
              runlength_id,
              token
            );
            return false;
          }
        }

        if (scenarios[0].fuelGas_id.startsWith("_DEFAULT")) {
          this.setState({
            showLoading: false,
          });
          init_calculations(
            scenarios,
            feedstocks,
            fuelGases,
            convectionsections,
            runlength_id,
            token
          );
        } else {
          this.setState({
            showLoading: false,
          });
          init_calculations(
            scenarios,
            feedstocks,
            fuelGases,
            convectionsections,
            runlength_id,
            token
          );
        }

        // TO BE USED IN FUTURE
        // if (scenarios[0].feedstock_id !== "" && scenarios[0].fuelGas_id.startsWith("_DEFAULT") || scenarios[0].convectionsection_id !== "") {
        //     this.setState({
        //         showLoading: false,
        //     })
        //     init_calculations(scenarios, feedstocks, fuelGases, convectionsections, runlength_id, token)
        // }
      } else {
        this.setState({
          showLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        showLoading: false,
      });
    }
  };

  handleContinue = async (
    scenarios,
    feedstocks,
    fuelGases,
    convectionsections,
    runlength_id: string,
    token: string
  ) => {
    this.toggleShowDialog();
    let getFuelgasFromDbById,
      getFeedstockFromDbById,
      getConvectionSectionFromDbById,
      getScenarioDromDb;
    if (
      !!this.props.scenarioRunlength.cases_id &&
      this.props.scenarioRunlength.cases_id > 0 &&
      this.state.getScenarioRunlengthDateFromDb !== ""
    ) {
      let getScenarioDromDbAll = await getScenarioRunlengthById(
        this.props.scenarioRunlength
      );
      if (!!getScenarioDromDbAll.data && getScenarioDromDbAll.data.length > 0) {
        getScenarioDromDb = getScenarioRunlengthFromJson(
          getScenarioDromDbAll.data[0]["SettingsIn"]
        ) as IScenarioRunlength;
        const newScenarioRunlength: IScenarioRunlength = {
          ...getScenarioDromDb,
          is_display_leave_popup: false,
          is_saved: true,
          signature: getScenarioDromDbAll.data[0]["signature"],
          ts: getScenarioDromDbAll.data[0]["ts"],
          cases_id: getScenarioDromDbAll.data[0]["id"],
        };
        this.props.modifyScenarioRunlength(newScenarioRunlength);
      }
    }

    if (
      scenarios[0].feedstock_id !== "" &&
      this.state.getFeedStockUpdatedDateFromDb !== ""
    ) {
      getFeedstockFromDbById = await getFeedstockById(
        scenarios[0].feedstock_id
      );
      if (
        !!getFeedstockFromDbById.data &&
        getFeedstockFromDbById.data.length > 0
      ) {
        this.props.modifyFeedstocks(getFeedstockFromDbById.data[0]);
      }
    }

    if (
      !scenarios[0].fuelGas_id.startsWith("_DEFAULT") &&
      this.state.getFuelGasUpdatedDateFromDb !== ""
    ) {
      getFuelgasFromDbById = await getFuelGasById(scenarios[0].fuelGas_id);
      if (!!getFuelgasFromDbById.data && getFuelgasFromDbById.data.length > 0) {
        this.props.modifyFuelGas(getFuelgasFromDbById.data[0]);
      }
    }

    if (
      scenarios[0].convectionsection_id !== "" &&
      this.state.getconvectionsectionsUpdatedDateFromDb !== ""
    ) {
      getConvectionSectionFromDbById = await getConvectionSectionById(
        scenarios[0].convectionsection_id
      );
      if (
        !!getConvectionSectionFromDbById.data &&
        getConvectionSectionFromDbById.data.length > 0
      ) {
        this.props.modifyConvectionSection(
          getConvectionSectionFromDbById.data[0]
        );
      }
    }

    this.checkUpdateAnyUpdate(
      scenarios,
      feedstocks,
      fuelGases,
      convectionsections,
      runlength_id,
      token
    );
  };

  render() {
    return (
      <>
        <button
          className="button margin-right"
          id="updateRunlength"
          onClick={async () =>
            this.checkUpdateAnyUpdate(
              this.props.scenarioRunlength.scenarios,
              this.props.feedstocks,
              this.props.fuelGases,
              this.props.convectionsections,
              this.props.scenarioRunlength.id,
              this.props.token
            )
          }
          disabled={this.state.showLoading}
        >
          Update Runlength{" "}
          {this.state.showLoading === true && (
            <Fade
              in={this.state.showLoading}
              style={{
                transitionDelay: this.state.showLoading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress size={11} />
            </Fade>
          )}
        </button>

        <Dialog
          open={this.state.showDialog}
          aria-labelledby="form-dialog-title"
          disableEnforceFocus={true}
          key={this.props.scenarioRunlength.id}
        >
          <DialogTitle id="form-dialog-title">Update Runlength</DialogTitle>
          <DialogContent>
            <Grid container item spacing={1}>
              {!!this.state.getFeedStockUpdatedDateFromDb &&
                this.state.getFeedStockUpdatedDateFromDb !== "" && (
                  <>
                    Feedstock has been changed on &nbsp;
                    <strong>#{this.state.getFeedStockUpdatedDateFromDb}</strong>
                    .
                  </>
                )}
              {!!this.state.getFuelGasUpdatedDateFromDb &&
                this.state.getFuelGasUpdatedDateFromDb !== "" && (
                  <>
                    Fuelgas has been changed on &nbsp;
                    <strong>#{this.state.getFuelGasUpdatedDateFromDb}</strong>.
                  </>
                )}
              {!!this.state.getconvectionsectionsUpdatedDateFromDb &&
                this.state.getconvectionsectionsUpdatedDateFromDb !== "" && (
                  <>
                    Convectionsection has been changed on &nbsp;
                    <strong>
                      #{this.state.getconvectionsectionsUpdatedDateFromDb}
                    </strong>
                    .
                  </>
                )}
              {!!this.state.getScenarioRunlengthDateFromDb &&
                this.state.getScenarioRunlengthDateFromDb !== "" && (
                  <>
                    Scenario runlength has been changed on &nbsp;
                    <strong>
                      #{this.state.getScenarioRunlengthDateFromDb}
                    </strong>
                    .
                  </>
                )}
              Do you want to continue?
            </Grid>
          </DialogContent>
          <DialogActions>
            <button className="button" onClick={() => this.toggleShowDialog()}>
              No
            </button>
            <button
              className="button"
              onClick={() =>
                this.handleContinue(
                  this.props.scenarioRunlength.scenarios,
                  this.props.feedstocks,
                  this.props.fuelGases,
                  this.props.convectionsections,
                  this.props.scenarioRunlength.id,
                  this.props.token
                )
              }
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connector(UpdateRunlength);
