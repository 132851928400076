import store from "..";
import { IConvectionSection } from "../interfaces/IConvectionSection";
import { IFeedstock } from "../interfaces/IFeedstock";
import { IFuelGas } from "../interfaces/IFuelGas";
import { getScenarioRunlengthFromJson, IScenarioRunlength } from "../interfaces/IScenarioRunlength";
import { updateLoadedConvectionSections } from "../slices/convectionSectionSlice";
import { updateLoadedFeedstocks } from "../slices/feedstockSlice";
import { updateLoadedFuelGases } from "../slices/fuelGasSlice";
import { updateLoadedScenarioRunlengths } from "../slices/scenarioSimulatorSlice";
import { REACT_APP_APIM_URL_SCENARIO } from "./GlobalConstants";

/**
 * Load scenario run length
 */
export async function loadScenarioRunlengths() {
  const repsonce = {
    status: false,
    message: "",
  }

  const token = store.getState().authState.token;
  let check = await fetch(`${REACT_APP_APIM_URL_SCENARIO}/scenarios`, {
    method: "GET",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then(
      (data) => {
        if (data.status || data.message) {
          throw Error(data.message);
        } else {
          const scenarioRunlengths: IScenarioRunlength[] = [];
          data.forEach(runlength => {
            let runlengthdata: IScenarioRunlength | undefined = getScenarioRunlengthFromJson(runlength.SettingsIn);
            if (runlengthdata !== undefined) {
              runlengthdata.cases_id = runlength.id > 0 ? runlength.id : 0;
              runlengthdata.ts = runlength.ts;
              scenarioRunlengths.push(runlengthdata);
            }

          });
          const feedstocks: IFeedstock[] = [];
          const fuelGases: IFuelGas[] = [];
          const convectionSections: IConvectionSection[] = [];

          // Collect feedStock, fuelGas and convection section details
          // Ignore if undefined
          data.forEach(runlength => {
            if (runlength.SettingsOut[0].feedstock !== undefined) {
              feedstocks.push(runlength.SettingsOut[0].feedstock);
            }

            if (runlength.SettingsOut[0].fuelGas !== undefined) {
              fuelGases.push(runlength.SettingsOut[0].fuelGas);
            }

            if (runlength.SettingsOut[0].convectionSection !== undefined) {
              convectionSections.push(runlength.SettingsOut[0].convectionSection);
            }

          });

          // Update feedStock, fuelGas and convection section to redux store
          store.dispatch(updateLoadedScenarioRunlengths(scenarioRunlengths));
          store.dispatch(updateLoadedFeedstocks(feedstocks));
          store.dispatch(updateLoadedFuelGases(fuelGases));
          store.dispatch(updateLoadedConvectionSections(convectionSections));

          repsonce.status = true;
          repsonce.message = "success";
          return repsonce;
        }
      }
    ).catch((error) => {

      error = {
        message: "The server could not process your request. Please try after sometime.",
      };
    });

  return check;
};
