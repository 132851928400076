import { CoilObjectI } from "../interfaces/coil";
export type COILState = {
  referencePosition: number;
  referencePositionIndex: number;
  scenarios: Array<CoilObjectI>;
  activeScenario: CoilObjectI;
};

const initState: COILState = {
  referencePosition: 0,
  referencePositionIndex: 0,
  scenarios: [],
  activeScenario: {
    id: 0,
    data: [],
  },
};

/**
 * Coil state reducer handles state management for Coil dashboards, mainly keeps track on position of reference line
 */

const COSReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_REFERENCE_POSITION":
      return {
        ...state,
        referencePosition: action.payload.value,
      };
    case "UPDATE_REFERENCE_POSITION_INDEX":
      return {
        ...state,
        referencePositionIndex: action.payload.value,
      };
    case "UPDATE_COT_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "CHANGE_SELECTED_SCENARIO":
      return {
        ...state,
        activeScenario: { ...state.scenarios[action.payload.value] },
        selectedScenario: action.payload.value,
      };
    case "UPDATE_ACTIVE_SCENARIO":
      return {
        ...state,
        activeScenario: {
          data: action.payload.value,
        },
      };
    case "RESET_COIL_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export default COSReducer;
