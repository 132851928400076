import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { IScenario, SCENARIO_STATUS, TERMINATION_REASON } from "../../interfaces/IScenario";
import { RootState } from "../../reducers/rootReducer";
import { deleteScenarioFromRunlength } from "../../slices/scenarioSimulatorSlice";
import ScenarioDialog from "./scenarioDialog";
import { IFeedstock } from "../../interfaces/IFeedstock";


interface IScenarioCardProps {
    scenario: IScenario;
    runlength_id: string;
    feedstocks: IFeedstock[];
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        fuelGases: state.fuelGas.fuelGases,
        convectionsections: state.convectionsection.convectionsections
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteScenarioFromRunlength: (runlength_id: string, scenario: IScenario) => dispatch(deleteScenarioFromRunlength({ runlength_id, scenario })),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IScenarioCardReduxProps = PropsFromRedux & IScenarioCardProps;

type IScenarioCardState = {
    showDialog: boolean;
}

class ScenarioCard extends Component<IScenarioCardReduxProps, IScenarioCardState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false
        }
    }

    handleDeletion() {
        this.props.deleteScenarioFromRunlength(this.props.runlength_id, this.props.scenario);
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
        })
    }

    fireboxTerminationMsg() {
        return this.props.scenario.terminated_firebox !== undefined && this.props.scenario.terminated_firebox !== -1 ? " (BOX " + (this.props.scenario.terminated_firebox + 1 === 1 ? "I" : "II") + " )" : "";
    }


    getFeedStockName(feedstock_id) {
        let name = this.props.feedstocks.forEach(feedstock => {
            if (feedstock.id === feedstock_id) {
                return feedstock.name
            }
        })
        return name;
    }

    getFuelgasName(fuelGas_id) {
        let name = this.props.fuelGases.forEach(fuelGas => {
            if (fuelGas.id === fuelGas_id) {
                return fuelGas.name
            }
        })
        return name;
    }

    getconvectionsectionName(convectionsection_id) {
        let name = this.props.convectionsections.forEach(convectionsection => {
            if (convectionsection.id === convectionsection_id) {
                return convectionsection.name
            }
        })
        return name;
    }

    render() {
        return (
            <div className={"scenario-card"}>
                <Button
                    variant="contained"
                    color="primary"
                    className={"scenario-button " + this.props.scenario.status}
                    onClick={() => this.toggleShowDialog()}
                >
                    {this.props.scenario.name + (this.props.scenario.termination_reason !== TERMINATION_REASON.IDLE ? " -> " + this.props.scenario.termination_reason + this.fireboxTerminationMsg() : "")}
                </Button>
                <ScenarioDialog
                    ok_button_text="Modify"
                    delete_button={true}
                    toggleShowDialog={() => this.toggleShowDialog()}
                    showDialog={this.state.showDialog}
                    scenario={this.props.scenario}
                    scenarioRunlengthId={this.props.runlength_id} />
                {
                    this.props.scenario.status === SCENARIO_STATUS.IS_CHANGED_IN_FEEDSTOCK && (
                        <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                            Feedstock #{this.getFeedStockName(this.props.scenario.firebox[0].feedstock_id)} has been modified
                        </Typography>
                    )
                }

                {
                    this.props.scenario.status === SCENARIO_STATUS.IS_CHANGED_IN_FUELGAS && (
                        <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                            Fuelgas #{this.getFuelgasName(this.props.scenario.fuelGas_id)} has been modified
                        </Typography>
                    )
                }

                {
                    this.props.scenario.status === SCENARIO_STATUS.IS_CHANGED_IN_CONVECTION_SECTION && (
                        <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                            Convectionsection #{this.getconvectionsectionName(this.props.scenario.convectionsection_id)} has been modified
                        </Typography>
                    )
                }

                {
                    this.props.scenario.status === SCENARIO_STATUS.DELETED_FEEDSTOCK && (
                        <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                            Assigned feedstock has been deleted please assign new one
                        </Typography>
                    )
                }

                {
                    this.props.scenario.status === SCENARIO_STATUS.DELETED_FUELGAS && (
                        <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                            Assigned fuel gas has been deleted please assign new one
                        </Typography>
                    )
                }

                {
                    this.props.scenario.status === SCENARIO_STATUS.DELETED_CONVECTION_SECTION && (
                        <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                            Assigned convectionsection has been deleted please assign new one
                        </Typography>
                    )
                }

                {
                    this.props.scenario.status === SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG && (
                        <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                            This scenario has revived from the graveyard previous check of a feedstock or fuelgas ( new one ) will exist
                        </Typography>
                    )
                }

                {
                    this.props.scenario.status === SCENARIO_STATUS.IS_DELETED_SCENARIO_CONFIG_WITH_CONVECTION_SECTION && (
                        <Typography variant="body2" component="span" className={"scenario-button " + SCENARIO_STATUS.ERROR}>
                            This scenario has revived from the graveyard previous check of a feedstock or fuelgas or convectionsection ( new one ) will exist
                        </Typography>
                    )
                }
            </div>
        );
    }

};

export default connector(ScenarioCard);