import { getDefaultShortPionaMethodDistributions, getDefaultHeavyFeedStockMethodDistributions,getTemperatureUnit,getEnumByValue } from "../configuration";

export enum UNIT {
    MOL = "MOL %",
    WT = "Wt %",
    VOL = "VOL %",
    CELSIUS = "°C",
    FAHRENHEIT = "°F"
}

export enum HEAVYFEEDCOMPONENTUNIT {
    HCRATIO = "HCRATIO",
    REFRECTIVEINDEX = "REFRECTIVEINDEX",
    UNKNOWN = "UNKNOWN",
    DENSITY = "DENSITY",
    SPGRAVITY = "SPGRAVITY",
    DESULPGASOIL = "DESULPGASOIL",
    HYDROGASOIL = "HYDROGASOIL",
    STRAIGHTRUN = "STRAIGHTRUN",
    SULFURCONT = "SULFURCONT"
}

export enum ShortPionaMethod {
    simDest = "SIMDEST.",
    TBP = "TBP",
    ASTM = "ASTM"
}

export enum HeavyFeedMethod {
    simDest = "SIMDEST.",
    TBP = "TBP",
    ASTM = "ASTM"
}

export interface IComponent {
    id: string;
    name: string;
    value: number;
    molecular_weight?: number;
}


// Discrete Composition
export class IDiscreteCompositionComponent implements IComponent {
    chemical: string;
    molecular_weight: number;
    feed_type_id: string;
    constructor(public id: string, public name: string, public value: number, chemical: string, molecular_weight: number, feed_type_id: string) {
        this.id = id;
        this.name = name;
        this.value = value;
        this.chemical = chemical;
        this.molecular_weight = molecular_weight;
        this.feed_type_id = feed_type_id;
    }
}


// Deatailed Piona

export class IDetailedPionaComponent implements IComponent {
    N_Paraffins: number;
    I_Paraffins: number;
    Olefins: number;
    Naphthenes: number;
    Aromatics: number;
    constructor(public id: string, public name: string, public value: number, N_Paraffins: number, I_Paraffins: number, Olefins: number, Naphthenes: number, Aromatics: number) {
        this.id = id;
        this.name = name;
        this.N_Paraffins = N_Paraffins;
        this.I_Paraffins = I_Paraffins;
        this.Olefins = Olefins;
        this.Naphthenes = Naphthenes;
        this.Aromatics = Aromatics;
    }
}

//Short Piona

export class IShortPionaComponent implements IComponent {
    constructor(public id: string, public name: string, public value: number) {
        this.id = id;
        this.name = name;
        this.value = value;
    }
}

export class IMethod {
    id: string;
    name: string;
    idUnit: UNIT;
    valueUnit: UNIT;
    distribution: IMethodDistribution[];

    constructor(id: string = ShortPionaMethod.ASTM, name: string = ShortPionaMethod.ASTM, distribution: IMethodDistribution[] = getDefaultShortPionaMethodDistributions(), idUnit: UNIT = UNIT.VOL, valueUnit: UNIT = getEnumByValue(UNIT,getTemperatureUnit())) {
        this.id = id;
        this.name = name;
        this.idUnit = idUnit;
        this.valueUnit = valueUnit;
        this.distribution = distribution;
    }
}

export type IMethodDistribution = {
    id: string;
    value: number;
}

// Heavy
export class IHeavyFeedStockMethod {
    id: string;
    name: string;
    idUnit: UNIT;
    valueUnit: UNIT;
    distribution: IMethodDistribution[];

    constructor(id: string = HeavyFeedMethod.ASTM, name: string = HeavyFeedMethod.ASTM, distribution: IMethodDistribution[] = getDefaultHeavyFeedStockMethodDistributions(), idUnit: UNIT = UNIT.VOL, valueUnit: UNIT = getEnumByValue(UNIT,getTemperatureUnit())) {
        this.id = id;
        this.name = name;
        this.idUnit = idUnit;
        this.valueUnit = valueUnit;
        this.distribution = distribution;
    }
}

export class IHeavyComponent implements IComponent {
    constructor(public id: string, public name: string, public value: number) {
        this.id = id;
        this.name = name;
        this.value = value;
    }
}