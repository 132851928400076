import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, CircularProgress, Typography, Button } from "@material-ui/core";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../reducers/rootReducer";
import { deleteUserById } from "../../../services/userRolesServices";
import { deleteUser } from "../../../slices/userRolesSlice";
import deleteIcon from "../../../assets/images/delete.svg";


interface IDeleteRolesProps {
    id: number
    disabled: boolean
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        deleteUserById: (id: number) => dispatch(deleteUser(id))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IDeleteRolesReduxProps = PropsFromRedux & IDeleteRolesProps;


type IDeleteRolesState = {
    showDialog: boolean;
    id: number;
    showLoading: boolean;
    message: string;
}

class DeleteRoles extends Component<IDeleteRolesReduxProps, IDeleteRolesState> {

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            id: !!this.props.id && this.props.id > 0 ? this.props.id : 0,
            message: "",
            showLoading: false
        }
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
            id: !!this.props.id && this.props.id > 0 ? this.props.id : 0,
        })
    }

    deleteButtonClick = async () => {
        this.setState({
            showLoading: true
        })

        if (this.state.id <= 0) {

            return false;
        } else {

            let idObj = {
                id: this.state.id
            }

            try {
                let delUser = await deleteUserById(idObj);
                if (delUser.status) {
                    this.props.deleteUserById(delUser.id)
                    this.setState({
                        showLoading: false,
                    })

                    setTimeout(() => {
                        this.toggleShowDialog();
                    }, 100);

                }
                else {
                    this.setState({
                        message: "Error while fetching data of user to delete",
                        showLoading: false,
                    })
                }

            } catch (error) {
                this.setState({
                    message: "Error while deleting user " + error,
                    showLoading: false,
                })
            }
        }


    }

    render() {
        return (
            <>
                <div className="control-header element">
                <img src={deleteIcon} className={this.props.disabled ? 'disabled' : ''} style={{height: '20px', width: '20px'}} alt="Delete Icon" onClick={() => this.toggleShowDialog()}/>

                    {/* <button
                        className="button red"
                        onClick={() => this.toggleShowDialog()}
                    >
                        Delete
                    </button> */}
                    <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Delete User Role</DialogTitle>

                        {this.state.showLoading === true && (
                            <div className="loading-dashboard">
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                            </div>
                        )}
                        {this.state.showLoading === false && (
                            <>
                                <DialogContent>

                                    {!!this.state.message && this.state.message !== "" && (
                                        <>
                                            <DialogContentText>
                                                <Typography variant="body1" component="p" color="secondary">
                                                    {this.state.message}
                                                </Typography>
                                            </DialogContentText>
                                        </>
                                    )}

                                    <DialogContentText>
                                        Are you sure you want to delete this user role ?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <button className="button" onClick={() => this.toggleShowDialog()} >
                                        Cancel
                                    </button>
                                    <button className="button" onClick={() => this.deleteButtonClick()} >
                                        Delete
                                    </button>
                                </DialogActions>
                            </>
                        )}

                    </Dialog>
                </div>
            </>
        )
    }
}

export default connector(DeleteRoles);