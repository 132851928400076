import { TerminationI } from "./../interfaces/termination";

export type TerminationState = {
    isVisible: boolean;
    termination: TerminationI[];
};

const initState: TerminationState = {
    isVisible: false,
    termination: [],
};

/**
 * Termination state reducer handles state management of calculation termination that can happen during Feedstock calculatons
 */

const TerminationReducer = (state = initState, action) => {
    switch (action.type) {
        case "ADD_TERMINATION":
            return {
                ...state,
                termination: [...state.termination, action.payload.value],
            };
        case "UPDATE_TERMINATION": {
            const { value } = action.payload;            
            const i = state.termination.findIndex((s) => s.scenarioId === value.scenarioId);
            if (i < 0) console.log("Didn't found scenario of ", value.scenarioId, "update termination status ", value.status, " and reason ", value.reason);
            const newState = { ...state };
            newState.termination[i].status = value.status;
            newState.termination[i].reason = value.reason;
            newState.termination[i].IsRLMaxReached = value.IsRLMaxReached;
            newState.termination[i].IsTMTMaxReached = value.IsTMTMaxReached;
            newState.termination[i].IsCIPMaxReached = value.IsCIPMaxReached;            
            
            return {
                ...newState,
            };
        }        
        case "RESET_TERMINATION":
            return {
                ...state,
                termination: [],
                isVisible: false,
            };
        case "RESET_TERMINATION_STATE":
            return {
                ...state,
                ...initState,
            };
        case "UPDATE_TN_VISIBILITY":
            return {
                ...state,
                isVisible: action.payload.value,
            }
        default:
            return state;
    }
};

export default TerminationReducer;
