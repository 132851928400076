import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ICoilSlice = {
    currentCoil: string,
    coils: string[],
    multipleCurrentCoil: string[],
    coilDropdownDisabled: boolean,
    selectedDate: any
}

const initialState: ICoilSlice = {
    currentCoil: "",
    coils: [],
    multipleCurrentCoil: [],
    coilDropdownDisabled: false,
    selectedDate: ""
}

export const coilSlice = createSlice({
    name: "coilSlice",
    initialState: initialState,
    reducers: {
        setCurrentCoil: (state, action: PayloadAction<string>) => {
            state.currentCoil = action.payload
        },
        setCoils: (state, action: PayloadAction<string[]>) => {
            state.coils = action.payload
        },
        setMultipleCurrentCoil: (state, action: PayloadAction<string[]>) => {
            state.multipleCurrentCoil = action.payload
        },
        resetMultipleCurrentCoil: (state) => {
            state.currentCoil = "";
            state.coils = [];
            state.multipleCurrentCoil = [];
            state.selectedDate = "";
            state.coilDropdownDisabled = false;
        },
        toggleCoilDropdown: (state, action: PayloadAction<boolean>) => {
            state.coilDropdownDisabled = action.payload
        },
        setSelectedDate: (state, action: PayloadAction<any>) => {
            state.selectedDate = action.payload
        }
    }
});

export const {
    setCurrentCoil,
    setCoils,
    setMultipleCurrentCoil,
    resetMultipleCurrentCoil,
    toggleCoilDropdown,
    setSelectedDate
} = coilSlice.actions


export default coilSlice.reducer
