import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IConfigurationSlice = {
    config_version : String
    creation_date: String
    client: String
    plants : []
    furnaces : []
    fuelgas : []
    scenario_simulator: []
    coilgroups : []
    camera_configs : []
    feedstocks : []
    product_predictor : []
}

const initialState: IConfigurationSlice = {
    config_version : '',
    creation_date : '',
    client : '',
    plants : [],
    furnaces : [],
    fuelgas : [],
    scenario_simulator: [],
    coilgroups : [],
    camera_configs : [],
    feedstocks : [],
    product_predictor : []
}

export const configuration = createSlice({
    name: 'configuration',
    initialState: initialState,
    reducers: {
        loadConfigVersion: (state, action: PayloadAction<String>) => {
            state.config_version = action.payload;
        },
        loadConfigCreationdate: (state, action: PayloadAction<String>) => {
            state.creation_date = action.payload;
        },
        loadConfigClient: (state, action: PayloadAction<String>) => {
            state.client = action.payload;
        },
        loadConfigPlants: (state, action: PayloadAction<[]>) => {
            state.plants = action.payload;
        },
        loadConfigFurnaces: (state, action: PayloadAction<[]>) => {
            state.furnaces = action.payload;
        },
        loadConfigFuelgas: (state, action: PayloadAction<[]>) => {
            state.fuelgas = action.payload;
        },
        loadConfigScenariosimulator: (state, action: PayloadAction<[]>) => {
            state.scenario_simulator = action.payload;
        },
        loadConfigCoilgroups: (state, action: PayloadAction<[]>) => {
            state.coilgroups = action.payload;
        },
        loadConfigCameras: (state, action: PayloadAction<[]>) => {
            state.camera_configs = action.payload;
        },
        loadConfigFeedstocks: (state, action: PayloadAction<[]>) => {
            state.feedstocks = action.payload;
        },
        loadConfigProductPredictor: (state, action: PayloadAction<[]>) => {
            state.product_predictor = action.payload;
        },
    }
})

export const {
    loadConfigVersion,
    loadConfigCreationdate,
    loadConfigClient,
    loadConfigPlants,
    loadConfigFurnaces,
    loadConfigFuelgas,
    loadConfigScenariosimulator,
    loadConfigCoilgroups,
    loadConfigCameras,
    loadConfigFeedstocks,
    loadConfigProductPredictor
} = configuration.actions


export default configuration.reducer
