import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Iframe from 'react-iframe'
import "../../assets/styles/_user-guide.scss"
import { USER_GUIDE_URL } from "../../utilities/GlobalConstants";

export const UserGuide = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const pageName = "User Guide"
        if (pageName !== undefined) {
            dispatch({ type: "UPDATE_PAGE_TITLE", payload: { value: pageName } });
        }
    }, [dispatch]);
    return (
        
        <div className="user-guide-parent">
        <Iframe 
        url={`${USER_GUIDE_URL}`}
        width="100%"
        height="100%"
        id="user-guide"
        className="user-guide"
        position="relative"/>
        </div>
    )
}