import React, { Component } from "react";
import { MenuItem, Select, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { IFuelGas } from "../../interfaces/IFuelGas";
import { RootState } from "../../reducers/rootReducer";
import { setCurrentFuelGasId, setNewSelectedFuelGasId } from "../../slices/fuelGasSlice";

interface IFuelGasSelectorProps {
    disabled?: boolean;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFuelGasId: state.fuelGas.current_fuelGas_id,
        fuelGases: state.fuelGas.fuelGases
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentFuelGasId: (newFuelGasId: string) => dispatch(setCurrentFuelGasId(newFuelGasId)),
        setNewSelectedFuelGasId: (newFeedstockId: string) => dispatch(setNewSelectedFuelGasId(newFeedstockId))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFuelGasSelectorReduxProps = PropsFromRedux & IFuelGasSelectorProps;

class FuelGasSelector extends Component<IFuelGasSelectorReduxProps, {}>{

    getSelectedFuelGas(): IFuelGas {
        const fuelGas_index = this.props.fuelGases.findIndex(fuelGas => fuelGas.id === this.props.currentFuelGasId)
        return this.props.fuelGases[fuelGas_index]
    }

    handleSelection(event) {
        const fuelGas_index = this.props.fuelGases.findIndex(feedstock => feedstock.id === this.props.currentFuelGasId);
        if (fuelGas_index > -1) {
            if (this.props.fuelGases[fuelGas_index].is_display_leave_popup) {
                document.getElementById("saveFuelgasButtonHidden")?.click()
                const new_fuelGas_id = event.target.value;
                this.props.setNewSelectedFuelGasId(new_fuelGas_id);
            } else {
                const new_fuelGas_id = event.target.value;
                this.props.setCurrentFuelGasId(new_fuelGas_id);
            }
        } else {
            const new_fuelGas_id = event.target.value;
            this.props.setCurrentFuelGasId(new_fuelGas_id);
        }

    }

    render() {
        return (
            <div className="control-header element">
                <div className="container-typography">
                    <Typography>FuelGas: </Typography>
                </div>
                <Select disabled={this.props.fuelGases.length === 0} autoWidth value={this.props.fuelGases.length === 0 ? "No fuelGas defined" : this.getSelectedFuelGas().id} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.props.fuelGases.length === 0 ?
                        <MenuItem key={`FEEDSTOCK-SELECT-NONE`} value={"No fuelGas defined"}>
                            {"No fuelGas defined"}
                        </MenuItem> : <div />}
                    {this.props.fuelGases.map((fuelGas, i) => (
                        <MenuItem key={`FEEDSTOCK-SELECT-${fuelGas.name}`} value={fuelGas.id}>
                            {fuelGas.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }

};

export default connector(FuelGasSelector);