import { getProductPredictorGroupDetails } from '../configuration';
import { CALCULATION_STATUS, SCENARIO_PROGRESS, SCENARIO_STATUS, TERMINATION_REASON } from './IScenario';

export type IProductPredictor = {
    productpredictor: IProductPredictorScenario[]
}
export type IProductPredictorScenario = {
    id: string;
    name: string;
    fuelgas_id: string;
    pressure: number;
    prediction_time: number;
    calculation_status: CALCULATION_STATUS;
    status: SCENARIO_STATUS;
    case_id: string;
    groups: IProductPredictorGroup[];
    output: IProductPredictorOutput;
    progress: number;
    scenario_progress: SCENARIO_PROGRESS;
    termination_reason: TERMINATION_REASON;
    isExportable?: boolean;
    signature?: string;
    is_public?: boolean;
    is_display_leave_popup?: boolean;
    is_saved?: boolean;

}

export type IProductPredictorGroup = {
    convectionsection_id: string;
    name: string;
    flowrate: number;
    CompiledFlowrate?: number;
    CIT: number;
    DILUT: number;
    SPEC: number;
    SPEC_TYPE: string;
    KEY_COMPONENT: string;
    furnace_number: number;
    feedtype_ids?: number[];
    feedtype_id?: string;
    recyclelabels?: string[];
    furnacetype_id?: string;
    feedstock?: IProductPredictorFeedstockDetail[],
    modified: boolean,
    grouplabel?: string,
    maxFurnace_number?: number
}


export type IProductPredictorOutput = {
    products: IProductPredictorOutputProduct[]
}
export type IProductPredictorOutputProduct = {
    name: string,
    value: number
}
export type IProductPredictorFeedstockDetail = {
    name: string;
    flowrate: number;
    feedstock_id: string;
    feedtype_id: string;
}

function getDefaultProductPredictorFeedstock(element): IProductPredictorFeedstockDetail[] {
    let obj: IProductPredictorFeedstockDetail[] = [];
    element.feedstock.map(f => obj.push(
        {
            name: f,
            flowrate: -1,
            feedstock_id: '',
            feedtype_id: ''
        }));
    return obj;
}
export function getDefaultProductPredictorGroup(element): IProductPredictorGroup {
    return {
        convectionsection_id: '',
        name: element.name,
        flowrate: 0,
        CompiledFlowrate: 0,
        CIT: 0,
        DILUT: 0,
        SPEC: 0,
        SPEC_TYPE: "",
        KEY_COMPONENT: "",
        furnace_number: -1,
        feedstock: getDefaultProductPredictorFeedstock(element),
        feedtype_ids: element.feedtype_ids,
        furnacetype_id: element.furnacetype_id,
        feedtype_id: element.defaultfeedtype_id,
        recyclelabels: element.recyclelabels,
        modified: false,
        grouplabel: element.grouplabel,
        maxFurnace_number: element.max_furnace_number
    }
}

export function getDefaultProductPredictorGroups(): IProductPredictorGroup[] {
    let result: IProductPredictorGroup[] = [];
    getProductPredictorGroupDetails().forEach(element => {
        let newGrpObj = getDefaultProductPredictorGroup(element);
        result.push(newGrpObj)
    });
    return result;
}