import * as React from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { getTemperatureUnit } from '../../configuration';

export interface CTMTSummaryProps {
  selectedFirebox: number;
}

const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICTMTSummaryReduxProps = PropsFromRedux & CTMTSummaryProps;

/**
 * CTMT Specific summary table
 */

const CTMTSummaryTable: React.SFC<ICTMTSummaryReduxProps> = ({ scenarios,selectedFirebox }) => {
  const { referencePositionIndex } = useSelector((state: RootState) => state.coilState);
  const selectedScenario : number = 0;
  const output_dict = scenarios?.[selectedScenario]?.firebox[selectedFirebox]?.["CALCULATIONS"][referencePositionIndex] ? scenarios?.[selectedScenario]?.firebox[selectedFirebox]?.["CALCULATIONS"][referencePositionIndex]["output_dict"] : undefined;

  return (
    <Table className="l-table">
      <TableBody style={{width: "101%"}}>
        <TableRow>
          <TableCell></TableCell>
          <TableCell className="bold">DCS ({getTemperatureUnit()})</TableCell>
          <TableCell className="bold">LVF ({getTemperatureUnit()})</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="bold">TMT Forecast</TableCell>
          <TableCell>N/A</TableCell>
          <TableCell>{(output_dict && !("ERROR_CODE" in output_dict) && Number(output_dict.TMTMAX[0][0]).toFixed(1)) || "N/A"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="bold">TMTmax Current</TableCell>
          <TableCell>N/A</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default connector(CTMTSummaryTable);
