import { IScenario, SCENARIO_TYPE } from "./IScenario";

export type IScenarioRunlength = {
    id: string;
    furnaceId: string;
    plantId: string;
    name: string;
    scenarios: IScenario[];
    isExportable: boolean;
    type:SCENARIO_TYPE
    signature: string;
    is_public?: boolean;
    is_display_leave_popup?: boolean;
    is_saved?: boolean;
    cases_id?: number;
    ts?: string;
}

/**
 * Get scenario run length from SettingsIn
 * If not found return undefined
 * @param input settingsIn
 * @returns ScenarioRunLength | undefined
 */
export function getScenarioRunlengthFromJson(input: string): IScenarioRunlength | undefined {
    let scenarioRunlength: IScenarioRunlength;
    try {
        scenarioRunlength = JSON.parse(input);
        // scenarioRunlength.id = uuidv4();
        return scenarioRunlength;
    } catch (error) {
        return undefined;
    }

}