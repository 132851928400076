import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ICoilGroupSlice = {
    id: string,
    name: string,
    coilGroups: string[]
    selectedCoilgroupImageName: string
}

const initialState: ICoilGroupSlice = {
    id: "",
    name: "",
    coilGroups: [],
    selectedCoilgroupImageName: ""
}

export const coilGroupSlice = createSlice({
    name: "coilGroupSlice",
    initialState: initialState,
    reducers: {
        setCurrentCoilGroup: (state, action: PayloadAction<string>) => {
            state.name = action.payload
        },
        setCurrentCoilGroupId: (state, action: PayloadAction<string>) => {
            state.id = action.payload
        },
        setCoilgroups: (state, action: PayloadAction<string[]>) => {
            state.coilGroups = action.payload
        },
        setSelectedCoilgroupImageName: (state, action: PayloadAction<string>) => {
            state.selectedCoilgroupImageName = action.payload
        },
        resetCoilGroupSlice: (state) => {
            state.id = "";
            state.name = "";
            state.coilGroups = [];
            state.selectedCoilgroupImageName = "";
        },
    }
});

export const {
    setCurrentCoilGroup,
    setCurrentCoilGroupId,
    setCoilgroups,
    setSelectedCoilgroupImageName,
    resetCoilGroupSlice
} = coilGroupSlice.actions


export default coilGroupSlice.reducer
