import React from "react";
import { Grid } from "@material-ui/core";
import LoginState from "../loginState";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../authProvider";
import { REACT_APP_VERSION } from "../../utilities/GlobalConstants";
import { Link } from "react-router-dom";
import { USER_GUIDE_URL } from "../../utilities/GlobalConstants";
import { useAuth } from "react-oidc-context";
import { isPingIDAuth } from "../../utilities/helperFunctions";


const Footer: React.SFC = () => {
  
  const AzureADUserGuide = () => {
    return(
    <AzureAD provider={authProvider} forceLogin={false}>
    {({ login, logout, authenticationState, error, accountInfo }) => {
      if (authenticationState === "Authenticated") {
        return (<>
          {`${USER_GUIDE_URL}` !== "" ?
            (<Grid item>
              <Link to="/user-guide" className="footer-lable">User Guide</Link>
            </Grid>) : (<></>)
          }
  
          <Grid item>
            <LoginState />
          </Grid></>
        )
      } else {
        return <div></div>;
      }
    }}
    </AzureAD>
    )
  }
  
  const PingIDUserGuide = () => {
    const auth = useAuth();
  
    if (auth.isAuthenticated){
      return (
        <>
          {`${USER_GUIDE_URL}` !== "" ?
            (<Grid item>
              <Link to="/user-guide" className="footer-lable">User Guide</Link>
            </Grid>) : (<></>)
          }
  
          <Grid item>
            <LoginState />
          </Grid></>
      )
    }
    else{
      return <div></div>;
    }
  }

  return (
    <footer>
      <Grid container justifyContent="space-between" alignItems="center" className="footer-container">
        <Grid item>
          <p className="copyright">Copyright by Linde Engineering - {REACT_APP_VERSION}</p>
        </Grid>
        <Grid item>
          <Link to="/legal-notice" className="footer-lable">Legal Notice</Link>
        </Grid>
        <Grid item>
          <Link to="/terms-conditions" className="footer-lable">Terms & Conditions</Link>
        </Grid>
        <Grid item>
          <Link to="/privacy-policy" className="footer-lable">Privacy Policy</Link>
        </Grid>
         {/* Ping ID change: Will add condition for authentication type */}
        {isPingIDAuth() ? PingIDUserGuide() : AzureADUserGuide()}
      </Grid>
    </footer>
  );
};

export default Footer;
