import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const PrivacyPolicy = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const pageName = "Privacy Policy"
        if (pageName !== undefined) {
            dispatch({ type: "UPDATE_PAGE_TITLE", payload: { value: pageName } });
        }
    }, [dispatch]);

    return (
        <div className="privacy-policy">
            <div className="row">
                <div className="col-12 col-md-10 offset-md-1"><h1 className="header">Privacy Policy</h1>
                    <div class="post-content">
                        <p>With the following data privacy notice, we would like to inform you about the processing of your personal data by Linde GmbH | Linde Engineering, Dr.-Carl-von-Linde-Strasse 6-14, 82049 Pullach, Germany (in the following “Linde Engineering”) for the purpose of the Linde Virtual Furnace (LVF) Web Application (in the following “LVF Web App”).</p>
                        <p>From time to time, it may be necessary to adapt this privacy policy as whole as well as specific parts of it to comply with the up to date legal requirements or to cover the introduction of new services.</p>
                        <h3>I. General Information regarding Data Protection at Linde Engineering</h3>
                        <h4><strong>1. Controller and Data Protection Officer</strong></h4>
                        <p>The controller responsible for the processing of your personal data in the sense of data protection law is Linde GmbH | Linde Engineering</p>
                        <p>The contact details of the EU Group Data Protection Officer (“EU GDPO”) of Linde GmbH are:<br></br>
                            Linde GmbH<br></br>
                            EU Group Data Protection Officer<br></br>
                            Dr.-Carl-von-Linde-Strasse 6-14, 82049 Pullach, Germany<br></br>
                            Email: <a href="mailto:dataprotection@linde.com">dataprotection@linde.com</a></p>
                        <p>The Linde Engineering Team is also available to answer all your questions regarding data protection at Linde Engineering by E-mail. You can contact the Team under the following address:<br></br>
                            Email: <a href="mailto:LAOS.LVF@PROCORR.LINDE.COM">LAOS.LVF@PROCORR.LINDE.COM</a>
                        </p>
                        <h4><strong>2. Your Data Privacy Rights</strong></h4>
                        <p>In connection with any processing of personal data by Linde Engineering, all data subjects have the following rights pursuant to Articles 15 to 21 EU-GDPR - within the statutory limits of the member states:</p>
                        <ul><li>Right to information</li>
                            <li>Right to correction</li>
                            <li>Right of deletion</li>
                            <li>Right to limitation of processing</li>
                            <li>Right to data transferability</li>
                            <li>Right of objection</li>
                        </ul>
                        <p>Furthermore, you have the right to revoke your consent to the processing of your personal data at any time with effect for the future. Such revocation has no effect for the past, i.e. it does not affect the effectiveness of the data processing carried out up to the revocation. If you are of the opinion that the processing of personal data by Linde Engineering is not in accordance with the data protection regulations or you are not satisfied with the information provided by us, you have the right to file a complaint with the competent supervisory authority (see Art. 77 EU-GDPR).</p>
                        <h4><strong>3. Transfer to Third Party Countries</strong>
                        </h4><p>In international projects, as is the rule in the engineering sector, data within the project teams must also be transferred to so-called third countries (non-EU). Where this is the case, we take appropriate measures to ensure an adequate level of data protection for your personal data.</p>
                        <h4><strong>4. Storage and Retention Periods</strong>
                        </h4><p>We process personal data only as long as it is necessary for the fulfilment of our contract obligations. As soon as the data concerned is no longer required for this, it is generally deleted. However, in order to comply with certain legal requirements, we must store some data beyond the termination of contractual relationships. This includes commercial and tax documentation, evidence and storage obligations. In these cases, we are generally required to safeguard or store data for three to ten years, or in rare instances, e.g. in legal disputes for up to 30 years.</p>
                        <h3>II. Processing of Personal Data by Linde Engineering for the purpose of the LVF Web App</h3>
                        <p>Our Customer or Partners are as diverse as we are. To conduct our complex international business, it is necessary to process personal data.</p>
                        <h4><strong>1. Type of Personal Data processed by Linde Engineering for the purpose of the LVF Web App</strong></h4>
                        <p>We process personal data only to the extent necessary to fulfil our contractual and legal obligations in connection with the business relationship with our Customer or Partners and partners. “Processing” means that we collect, store, delete or transfer personal data, to list a few examples. Personal data processed by us includes:</p>
                        <ul><li>Master and contact data of Customer or Partners, such as name, address, telephone number, e-mail address, etc.</li>
                            <li>Customer or Partner relationship management information, such as order history, etc., as far as it concerns the data of a natural person</li>
                        </ul>
                        <p>We regularly receive the personal data processed by us within the LVF Web App. In some cases, we also receive personal data from affiliated companies of Linde Engineering, e.g. contact data from Customer or Partners within Linde Engineering's Customer or Partner relationship management. In some cases, we also process personal data that we have received within Linde Engineering, in accordance with the applicable data protection laws.</p>                        
                        <h4><strong>2. Purpose for Processing and Legal Basis</strong></h4>
                        <p>We process your personal data only for permitted purposes and in accordance with the applicable legal provisions of the EU-GDPR and relevant national data protection laws.</p>
                        <p><strong>2.1</strong> We process Data to fulfil our Contractual Obligation</p>
                        <p>We process personal data to fulfil our contractual obligations towards our Customer or Partners or to carry out so-called pre-contractual measures, which take place upon a specific request.</p>
                        <p>In these cases, the purpose of data processing is determined by the contract we have concluded with our Customer or Partners and services that are provided under this contract. This also includes, for example, the processing of personal data in the context of sending catalogues, information on our services or the preparation of specific offers.</p>
                        <p><strong>2.2</strong> We process Data to protect Legitimate Interests</p>
                        <p>We also process personal data insofar as it is necessary to safeguard the legitimate interests of Linde Engineering's companies as well as our costumers (and, if applicable, other third parties). Where this is the case, we process personal data only after due consideration of your relevant interests.<br></br>
                            In particular, the following legitimate interests are pursued with the operation of the LVF Web App:</p>
                        <ul><li>Improve the order processing</li>
                            <li>Improve the contract performance</li>
                            <li>Secure the data exchange between Linde Engineering and the Customer or Partner</li>
                            <li>Transfer of personal data within Linde Engineering for internal administrative purposes</li>
                        </ul>
                        <p><strong>2.3</strong> We process Data to fulfil Legal Obligations</p>
                        <p>We are required to process certain data in order to comply with legal obligations. Such obligations may arise from certain provisions of national commercial, trade, tax and social law as well as European legislation, e.g. in connection with regulations to avoid the financing of terrorism. In detail, this may also result in obligations for Linde to safeguard, store, report and collect data, which generally serve control purposes from the respective authorities.</p>
                        <h4><strong>3. Categories of Recipients</strong></h4>
                        <p>Personal data will only be made available to other companies of Linde if and insofar as this is necessary to protect our legal and contractual rights and obligations. This, for example, can be the case for the coordination of our contractual services. Typical examples are centralized supplier and customer management services, centralized IT services and internal shared service center in finance and accounting.</p>
                        <p>We cooperate with external service providers to fulfill certain contractual obligations. This is the case, for example, in connection with specific supplier and customer management services, hosting of IT infrastructure and external shared service center in finance and accounting, payment processing (credit card, direct debiting, purchase on account), logistics and delivery, promotional activities or the transaction of online orders. As far as we involve external service providers, this always takes place within the legal limits and in compliance with the applicable data protection regulations.</p>
                        <p>We only transfer personal data to other recipients outside Linde if we are legally obliged or permitted to do so. In all other cases, we will only transfer your data to other third parties if you have given us your corresponding consent. In the context of the continuous development of our business, we may divest subsidiaries or business parts or merge our business or parts thereof with another company. Such transactions typically entail the transfer of customer information pertaining to the sold or divested subsidiary or business part to the buyer or to the company created by the merger. In the unlikely event of a complete sale of Linde or substantial parts thereof, your personal information will also be transferred to the buyer.</p>
                        <h4><strong>4. Automated Decision-Making and Profiling</strong></h4>
                        <p>We do not use automated decision-making processes for procedures that have legal implications or a similarly significant impact on you. No decision will be made without further human review. Profiling within the meaning of Art. 4 (4) GDPR in general does not take place at Linde. In case of an exception, a separate data protection notice will be provided.</p>
                        <h3>III. Cookies</h3>
                        <p><strong>1.</strong> Use of Technically Necessary Cookies for the Purpose of the LVF Web App</p>
                        <p>The purpose of using technically necessary cookies is to make it easier for you to use the LVF Web App. Some functions of our LVF Web App cannot be offered without the use of cookies. For these functions to work your web browser has to be recognized even after a page change. Your personal data will not be processed in any other way. The data will be erased as soon as the storage is no longer necessary to achieve the purpose for which the data was collected; this happens, in particular, as soon as the relevant session has ended.</p>
                        <p><strong>2.</strong> Marketing and Targeting Cookies</p>
                        <p>The LVF Web App does not use marketing or targeting cookies.</p>
                        <h3>Information regarding your Right of Objection</h3>
                        <p>You have the right to object at any time to certain types of processing of your data for reasons arising from your particular situation. This right applies to data processing in the public interest and to data processing to protect legitimate separate interests.</p>
                        <p>In the event of a contradiction, we will cease processing your personal data. However, this does not apply if we can prove compelling reasons worthy of protection for the processing, that outweigh your interests, rights and freedoms, or if the processing serves to assert, exercise or defend legal claims.</p>
                        <p>Your objection does not require any specific form and can be e-mailed to us at:<br></br>
                            Email: <a href="mailto:LAOS.LVF@PROCORR.LINDE.COM">LAOS.LVF@PROCORR.LINDE.COM</a> with a copy to <a href="mailto:dataprotection@Linde-LE.com">dataprotection@Linde-LE.com</a>
                        </p>
                    </div>
                </div>
            </div >
        </div >
    )
}