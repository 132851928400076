import * as React from "react";
import { useState } from "react";
import { Badge, Dialog, Box, Table, TableHead, TableRow, TableCell, TableBody, DialogContent } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { ErrorI } from "../../interfaces/errors";
import { TerminationI } from "../../interfaces/termination";

export interface TerminationNotificationProps { }

/**
 * Termination Notification component is used to give user information about reason for termination
 */

const TerminationNotification: React.SFC<TerminationNotificationProps> = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const { termination }: { termination: TerminationI[], isVisible: boolean } = useSelector((state: RootState) => state.terminationState);  
  const { errors }: { errors: ErrorI[] } = useSelector((state: RootState) => state.errorsState);
  const [closeNotification, setCloseNotification] = useState(false);  
  const { isVisible } = useSelector((state: RootState) => state.terminationState);  

  let visibleNotification = termination.length === 0 ? false : errors.length > 0 ? false : // hide notification condition
    isVisible ? true : (isVisible && closeNotification === false) ? true : 
    (isVisible === false && closeNotification === false) ? true : // show notification untill once opened condition
      false;
  
  return (
    <React.Fragment>
      <Badge badgeContent={termination.length} style={{ visibility: visibleNotification ? "inherit" : "hidden" }}
        color="primary"
        overlap="rectangular"
        onClick={() => setShowPopUp(true)}>
        <NotificationsIcon style={{ color: "green", visibility: visibleNotification ? "inherit" : "hidden" }} />
      </Badge>
      <Dialog open={showPopUp} onClose={() => setShowPopUp(false)} maxWidth={false} scroll="paper">
        <DialogContent>
          <Box>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {["Scenario Id", "Status", "Reason"].map((header) => (
                    <TableCell key={header} className="bold">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {termination.map((term) => (
                  <TableRow key={JSON.stringify(term)}>
                    <TableCell>{term.scenarioId + 1}</TableCell>
                    <TableCell>{term.status}</TableCell>
                    <TableCell>{term.reason}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <button onClick={() => {
              setShowPopUp(false);              
              isVisible ? setCloseNotification(false) : setCloseNotification(true);
            }}
              className="button is-primary" style={{ width: "100%", marginTop: "16px" }}>
              Close
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default TerminationNotification;
