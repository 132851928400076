import React, { Component } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { IConvectionSection } from "../../interfaces/IConvectionSection";
import { RootState } from "../../reducers/rootReducer";

import { Prompt, RouteComponentProps, withRouter } from "react-router-dom";
import { modifyConvectionSection, setCurrentConvectionSectionId, setNewSelectedConvectionSectionId, updateConvectionSectionSignature } from "../../slices/convectionSectionSlice";
import { getConvectionSectionById, saveNewConvectionSection } from "../../services/convectionsectionServices";
import { updateModifiedconvectionsectionInDbForScenario } from "../../slices/scenarioSimulatorSlice";
import { updateModifiedConvectionSectionIdInPP } from "../../slices/productPredictorSlice";

interface ISaveConvectionSectionProps extends RouteComponentProps {
    convectionsection: IConvectionSection;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        convectionsections: state.convectionsection.convectionsections,
        new_selected_convectionsection_id: state.convectionsection.new_selected_convectionsection_id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateSignature: (convectionSection_id: string) => dispatch(updateConvectionSectionSignature({ convectionSection_id })),
        modifyConvectionSection: (newConvectionSection: IConvectionSection) => dispatch(modifyConvectionSection(newConvectionSection)),
        setCurrentConvectionSectionId: (newConvectionSectionId: string) => dispatch(setCurrentConvectionSectionId(newConvectionSectionId)),
        setNewSelectedConvectionSectionId: (newConvectionSectionId: string) => dispatch(setNewSelectedConvectionSectionId(newConvectionSectionId)),
        updateModifiedconvectionsectionInDbForScenario: (newConvectionSectionId: string) => dispatch(updateModifiedconvectionsectionInDbForScenario(newConvectionSectionId)),
        updateModifiedConvectionSectionIdInPP: (newConvectionSectionId: string) => dispatch(updateModifiedConvectionSectionIdInPP(newConvectionSectionId))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ISaveConvectionSectionReduxProps = PropsFromRedux & ISaveConvectionSectionProps;

type ISaveConvectionSectionState = {
    showDialog: boolean;
    name: string;
    message: string;
    location: string;
    signature: string;
    convectionSection: IConvectionSection;
    showLoading: boolean;
}

class SaveConvectionSection extends Component<ISaveConvectionSectionReduxProps, ISaveConvectionSectionState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            name: "",
            message: "",
            location: "",
            signature: !!this.props.convectionsection.signature ? this.props.convectionsection.signature : "",
            convectionSection: this.props.convectionsection,
            showLoading: false,
        }
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
            name: "",
            message: ""
        })
    }

    componentDidMount() {
        // TO BE USED IN FEATURE 
        // if (this.props.convectionsection.is_display_leave_popup) {
        //     const newConvectionSection: IConvectionSection = {
        //         ...this.props.convectionsection,
        //         is_display_leave_popup: true
        //     }
        //     this.props.modifyConvectionSection(newConvectionSection)
        // }
    }

    async handleSaveNewConvectionSection() {
        if (this.props.convectionsection.signature !== "") {
            this.setState({
                showLoading: true,
            })
            try {
                let save = await saveNewConvectionSection(this.props.convectionsection);
                if (save.status) {
                    let getNewSavedUpdatedById = await getConvectionSectionById(this.props.convectionsection.id);
                    if (getNewSavedUpdatedById.status) {
                        this.setState({
                            message: save.message,
                            signature: !!this.props.convectionsection.signature ? this.props.convectionsection.signature : "",
                            showLoading: false,
                        })
                        let convectionSection = getNewSavedUpdatedById.data[0] as IConvectionSection;
                        const newConvectionSection: IConvectionSection = {
                            ...convectionSection,
                            is_display_leave_popup: false,
                            is_saved: true,
                        }

                        this.props.modifyConvectionSection(newConvectionSection)
                        this.props.updateModifiedconvectionsectionInDbForScenario(newConvectionSection.id)
                        this.props.updateModifiedConvectionSectionIdInPP(newConvectionSection.id)

                        setTimeout(() => {
                            this.toggleShowDialog();
                            if (!!this.state.location && this.state.location !== "") {
                                this.props.history.push(this.state.location)
                            }
                        }, 0);
                    } else {
                        this.setState({
                            message: "Oops... somthing went to wrong!",
                            showLoading: false,
                        })
                    }
                } else {
                    this.setState({
                        message: "Oops... somthing went to wrong!",
                        showLoading: false,
                    })
                }
            } catch (error) {
                this.setState({
                    message: "Oops... somthing went to wrong!",
                    showLoading: false,
                })
            }
        } else {
            this.setState({
                message: "Please Add component value in convectionSection!",
                showLoading: false,
            })
        }

    }

    handleTransition = location => {
        if (this.props.convectionsection.signature !== this.state.signature) {
            this.setState({
                location: location.pathname + location.search
            })
            this.toggleShowDialog();
            return false
        } else {
            return true
        }
    };

    handleCancelConvectionSectionRedirection() {
        this.toggleShowDialog();
        setTimeout(() => {
            this.setState({
                location: "",
            })
            if (this.props.convectionsection.is_display_leave_popup) {
                const newConvectionSection: IConvectionSection = {
                    ...this.props.convectionsection,
                }

                this.props.modifyConvectionSection(newConvectionSection)
            }
        }, 0);
    }

    async handleLeaveConvectionSection() {
        this.toggleShowDialog();
        let getNewSavedUpdatedById = await getConvectionSectionById(this.props.convectionsection.id);
        let convectionSection = getNewSavedUpdatedById.data.length > 0 ? getNewSavedUpdatedById.data[0] as IConvectionSection : this.state.convectionSection;
        const newConvectionSection: IConvectionSection = {
            ...convectionSection,
            is_display_leave_popup: false,
            is_saved: true
        }
        await this.props.modifyConvectionSection(newConvectionSection)
        this.setState({
            signature: !!this.props.convectionsection.signature ? this.props.convectionsection.signature : "",
            convectionSection: this.props.convectionsection
        })
        if (this.props.new_selected_convectionsection_id !== "") {
            await this.props.setCurrentConvectionSectionId(this.props.new_selected_convectionsection_id)
            setTimeout(async () => {
                this.props.setNewSelectedConvectionSectionId("");
            }, 10);
        }
        setTimeout(() => {
            if (!!this.state.location && this.state.location !== "") {
                this.props.history.push(this.state.location)
            }
        }, 0);
    }

    hendalClickOnSavePopup() {
        if (this.props.convectionsection.is_display_leave_popup) {
            const newConvectionSection: IConvectionSection = {
                ...this.props.convectionsection,
                is_display_leave_popup: false,
            }
            this.props.modifyConvectionSection(newConvectionSection)
        }
        this.toggleShowDialog();
    }

    hendalClickOnCancelbButtonInSavePopup() {
        if (this.state.signature !== this.props.convectionsection.signature) {
            const newConvectionSection: IConvectionSection = {
                ...this.props.convectionsection,
                is_display_leave_popup: true,
            }
            this.props.modifyConvectionSection(newConvectionSection)
        }
        this.toggleShowDialog();
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    id="saveButton"
                    className={this.props.convectionsection.is_display_leave_popup === true ? "button red" : "button"}
                    onClick={() => this.hendalClickOnSavePopup()}
                >
                    Save
                </button>
                <button
                    id="saveConvectionsectionsButtonHidden"
                    type="button"
                    style={{ display: "none" }}
                    onClick={() => this.toggleShowDialog()}
                ></button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    {this.props.convectionsection.is_display_leave_popup === false && (
                        <>
                            <DialogTitle id="form-dialog-title">Save this convectionSection</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to save this convectionSection?
                                </DialogContentText>
                                {!!this.state.message && this.state.message !== "" && (
                                    <Typography variant="body1" component="p" color="secondary">
                                        {this.state.message}
                                    </Typography>
                                )}
                            </DialogContent>


                            <DialogActions>
                                <button className="button" onClick={() => this.hendalClickOnCancelbButtonInSavePopup()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.handleSaveNewConvectionSection()} disabled={this.state.showLoading}>
                                    Save {this.state.showLoading === true && (
                                        <Fade
                                            in={this.state.showLoading}
                                            style={{
                                                transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                            }}
                                            unmountOnExit
                                        >
                                            <CircularProgress size={11} />
                                        </Fade>
                                    )}
                                </button>
                            </DialogActions>
                        </>
                    )}
                    {this.props.convectionsection.is_display_leave_popup === true && (
                        <>
                            <DialogTitle id="form-dialog-title1">Leave Page</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Do you want to save ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button className="button" onClick={() => this.handleCancelConvectionSectionRedirection()} >
                                    Cancel
                                </button>
                                <button className="button" onClick={() => this.handleSaveNewConvectionSection()} >
                                    Yes
                                </button>
                                <button className="button" onClick={() => this.handleLeaveConvectionSection()} >
                                    No
                                </button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
                <Prompt when={this.props.convectionsection.is_display_leave_popup} message={this.handleTransition} />
            </div >
        );
    }
};

export default connector(withRouter(SaveConvectionSection));