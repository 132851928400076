
import { v4 as uuidv4 } from 'uuid';

export enum SCENARIO_STATUS {
    UNCOMPUTED = "UNCOMPUTED",
    RUNNING = "RUNNING",
    COMPUTED = "COMPUTED",
    ERROR = "ERROR",
    STOP_CALCULATION = "STOP_CALCULATION",
    RESTORE = "RESTORE",
    IS_CHANGED_IN_FEEDSTOCK = "IS_CHANGED_IN_FEEDSTOCK",
    IS_CHANGED_IN_FUELGAS = "IS_CHANGED_IN_FUELGAS",
    IS_CHANGED_IN_CONVECTION_SECTION = "IS_CHANGED_IN_CONVECTION_SECTION",
    DELETED_FEEDSTOCK = "DELETED_FEEDSTOCK",
    DELETED_FUELGAS = "DELETED_FUELGAS",
    DELETED_CONVECTION_SECTION = "DELETED_CONVECTION_SECTION",
    IS_DELETED_SCENARIO_CONFIG = "IS_DELETED_SCENARIO_CONFIG",
    IS_DELETED_SCENARIO_CONFIG_WITH_CONVECTION_SECTION = "IS_DELETED_SCENARIO_CONFIG_WITH_CONVECTION_SECTION"
}

export enum SCENARIO_PROGRESS {
    VALIDATION = "Validating",
    INIT = "Init",
    Looping = "loop"
}

export enum CALCULATION_STATUS {
    IDLE = "IDLE",
    INIT = "INIT",
    LOOP = "LOOP"
}

export enum SPEC_TYPE {
    COT = "1",
    P_E_RATIO = "3",
    CONVERSION = "5"
}

export enum SCENARIO_TYPE {
    WARM_START = "Warm Start",
    Cold_START = "Cold Start",
}
export enum TERMINATION_REASON {
    RUNLENGTH_MAX = "RUNLENGTH_MAX",
    TMTMAX = "TMTMAX",
    CIPMAX = "CIPMAX",
    IDLE = "IDLE",
    STOPPED_BY_USER = "STOPPED_BY_USER",
    ITERATION_MAX = "ITERATION_MAX",
    UNSTEADY = "UNSTEADY"
}


export enum MODE {
    FULL = "FULL",
    HYBRID = "HYBRID"
}

export type IFirebox =
    {
        feedstock_id: string;
        FLOW_HC: number;
        CIT: number;
        COP: number;
        DILUT: number;
        SPEC: number;
        SPEC_TYPE: string;
        KEY_COMPONENT: string;
        CALCULATIONS: any[];
        P_XOVER: number;
    }

export enum ERROR_TYPE {
    ERROR = "ERROR",
    SERVICEBUS_LISTEN_TIMEOUT = "SERVICEBUS_LISTEN_TIMEOUT",
    MODELSERVER_ERROR = "MODELSERVER_ERROR",
    LOADBALANCER_ERROR = "LOADBALANCER_ERROR",
    AUXSERVER_ERROR = "AUXSERVER_ERROR",
    PRODUCTPREDICTORSERVER_ERROR = "PRODUCTPREDICTORSERVER_ERROR",
    UNKNOWN_ERROR = "UNKNOWN_ERROR"
}

export type IScenario = {
    id: string;
    name: string;
    furnace: string;
    feedstock_id: string;
    fuelGas_id: string;
    mode: MODE;
    firebox: IFirebox[];
    convectionsection_id: string;

    CIT: number;
    COP: number;
    DILUT: number;
    FLOW_HC: number;
    INTERVAL: number;
    KEY_COMPONENT: string;
    LAVAL_RATIO: number;
    P_XOVER: number;
    RUNLENGTH_MAX: number;
    SPEC: number;
    SPEC_TYPE: string;
    TMTMAX: number;

    FROM_DB: boolean;
    CALCULATIONS: any[];
    RTATime?: any[];
    Days?: string[];
    RUN_LENGTH_FINAL: number | null;

    NEXT_RESULT_CALCULATION_ID: string;
    status: SCENARIO_STATUS
    calculation_status: CALCULATION_STATUS
    modifiedDateTime?: Date;
    createdDateTime?: Date;
    last_model_input: any
    termination_reason: TERMINATION_REASON
    terminated_firebox: number

    case_id: string
    scenario_progress: SCENARIO_PROGRESS
    currentScenarioIsRTA: boolean | false;
}

export function getScenarioFromInput(input, name, mode, furnace_id): IScenario {
    let newFirebox: IFirebox[] = [];
    input?.firebox.forEach((input) => {
        let item: IFirebox = {
            feedstock_id: input.feedstock_id,
            // User Input
            CIT: input.CIT,
            COP: input.COP,
            DILUT: input.DILUT,
            FLOW_HC: input.FLOW_HC,
            KEY_COMPONENT: input.KEY_COMPONENT,
            SPEC: input.SPEC,
            SPEC_TYPE: input.SPEC_TYPE,
            CALCULATIONS: [],
            P_XOVER: input.P_XOVER
        };
        newFirebox.push(item);
    })

    const newScenario: IScenario = {
        id: uuidv4(),
        name: name,
        furnace: furnace_id,
        feedstock_id: "",
        fuelGas_id: "",
        convectionsection_id: "",
        RTATime: [],
        // User Input
        CIT: input.CIT,
        COP: input.COP,
        DILUT: input.DILUT,
        FLOW_HC: input.FLOW_HC,
        INTERVAL: input.INTERVAL,
        KEY_COMPONENT: input.KEY_COMPONENT,
        LAVAL_RATIO: input.LAVAL_RATIO,
        P_XOVER: input.P_XOVER,
        RUNLENGTH_MAX: input.RUNLENGTH_MAX,
        SPEC: input.SPEC,
        SPEC_TYPE: input.SPEC_TYPE,
        TMTMAX: input.TMTMAX,

        FROM_DB: false,
        CALCULATIONS: [],
        RUN_LENGTH_FINAL: 0,

        NEXT_RESULT_CALCULATION_ID: "",
        status: SCENARIO_STATUS.UNCOMPUTED,
        calculation_status: CALCULATION_STATUS.IDLE,
        last_model_input: {},
        termination_reason: TERMINATION_REASON.IDLE,
        case_id: "",
        scenario_progress: SCENARIO_PROGRESS.VALIDATION,
        currentScenarioIsRTA: false,
        firebox: newFirebox,
        terminated_firebox: -1,
        mode: mode
    }

    return newScenario;
}