import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducers/rootReducer'

import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table';
import { IProductPredictorScenario } from '../../interfaces/IProductPredictor';
import { Grid, Typography, Paper } from '@material-ui/core';

interface IProductPredictorOutput {
    currentProductPredictorScenairo: IProductPredictorScenario
}

const mapStateToProps = (state: RootState) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);


type PropsFromRedux = ConnectedProps<typeof connector>

type IProductPredictorOutputFromRedux = PropsFromRedux & IProductPredictorOutput

type IProductPredictorOutputState = {

}

class ProductPredictorOutput extends Component<IProductPredictorOutputFromRedux, IProductPredictorOutputState> {


    render() {
        const output = this.props.currentProductPredictorScenairo.output.products;
        return (

            <Grid container xs={6} spacing={0}>
                <Paper className="output paper" elevation={3}>
                    <Grid xs={12} alignContent={'stretch'} className="outputTitle">
                        <Typography><b>{'Expected Plant Product(s) and Recycle(s)'}</b></Typography>
                    </Grid>
                    <Grid xs={12} alignContent={'stretch'} className="outputTable">
                        <Table className="l-table-summary" style={{ width: "100%" }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="bold th">Product(s)</TableCell>
                                    <TableCell className="bold th">Unit</TableCell>
                                    <TableCell className="bold th">Value</TableCell>
                                </TableRow>
                                {output.map((o, i) => {
                                    return (
                                        <TableRow style={i % 2 ? { background: "rgba(210, 225, 235, 0.8)" } : { background: "#f1f6f9" }}>
                                            <TableCell className="td">{o.name}</TableCell>
                                            <TableCell className="td">kg/h</TableCell>
                                            <TableCell className="td">{Number(o.value).toFixed(1)}</TableCell>
                                        </TableRow>)
                                })}

                            </TableBody>
                        </Table>
                    </Grid></Paper>
            </Grid>)
    }
}

export default connector(ProductPredictorOutput)