import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getDefaultFuelGas, IFuelGas } from "../../interfaces/IFuelGas";
import { RootState } from "../../reducers/rootReducer";
import AddFuelGas from "./addFuelGas";
import FuelGasSelector from "./fuelGasSelector";
import FuelGasTable from "./fuelGasTable";
import DeleteFuelGas from "./deleteFuelGas";
import LoadFuelGas from "./loadFuelGas";
import SaveFuelGas from "./saveFuelGas";
import RenameFuelGas from "./renameFuelGas";
import CloneFuelgas from "./cloneFuelgas";

export const FUELGAS_OVERVIEW_DASHBOARD_ID = "_FUELGAS";

interface IFuelGasOverviewProps { }

const mapStateToProps = (state: RootState) => {
    return {
        current_fuelGas_id: state.fuelGas.current_fuelGas_id,
        fuelGases: state.fuelGas.fuelGases
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFuelGasOverviewReduxProps = PropsFromRedux & IFuelGasOverviewProps;

class FuelGasOverview extends Component<IFuelGasOverviewReduxProps, {}> {

    getCurrentFuelGas(): IFuelGas {
        const fuelGas = this.props.fuelGases.find(fuelGas => fuelGas.id === this.props.current_fuelGas_id);
        return (fuelGas !== undefined ? fuelGas : getDefaultFuelGas())
    }

    render() {
        return (
            <div className="feedstock-overview">
                <div className="control-header">
                    <FuelGasSelector />
                    <AddFuelGas fuelGas={this.props.fuelGases.length > 0 ? this.getCurrentFuelGas() : undefined} />
                    <LoadFuelGas />
                    {(this.props.fuelGases.length === 0) ? <div /> :
                        <SaveFuelGas fuelGas={this.getCurrentFuelGas()} />
                    }
                    {(this.props.fuelGases.length === 0) ? <div /> :
                        <RenameFuelGas fuelGas={this.getCurrentFuelGas()} />
                    }
                    {(this.props.fuelGases.length === 0) ? <div /> :
                        <CloneFuelgas fuelGas={this.getCurrentFuelGas()} />
                    }
                    {(this.props.fuelGases.length === 0) ? <div /> :
                        <DeleteFuelGas fuelGas={this.getCurrentFuelGas()} />
                    }
                    {/* // TO BE USED in FUTURE
                     {(this.getCurrentFuelGas().id.startsWith("_DEFAULT") || this.props.fuelGases.length === 0) ? <div /> :

                        <SaveFuelGas fuelGas={this.getCurrentFuelGas()} />
                    }
                    {(this.getCurrentFuelGas().id.startsWith("_DEFAULT") || this.props.fuelGases.length === 0) ? <div /> :

                        <RenameFuelGas fuelGas={this.getCurrentFuelGas()} />
                    }
                    {(this.getCurrentFuelGas().id.startsWith("_DEFAULT") || this.props.fuelGases.length === 0) ? <div /> :

                        <CloneFuelgas fuelGas={this.getCurrentFuelGas()} />
                    }
                    {(this.getCurrentFuelGas().id.startsWith("_DEFAULT") || this.props.fuelGases.length === 0) ? <div /> :

                        <DeleteFuelGas fuelGas={this.getCurrentFuelGas()} />
                    } */}
                </div>
                <div className="feedstock-dashboard">
                    {this.props.fuelGases.length === 0 ? <div /> :
                        <FuelGasTable fuelGas={this.getCurrentFuelGas()} />
                    }
                </div>
            </div>
        )
    }
}

export default connector(FuelGasOverview);