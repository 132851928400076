import { ErrorI } from "./../interfaces/errors";

export type ErrorsState = {
  errors: ErrorI[];
};

const initState: ErrorsState = {
  errors: [],
};

/**
 * Errors state reducer handles state management of errors that can happen during Feedstock calculatons
 */

const ErrosReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_ERROR":
      return {
        ...state,
        errors: [...state.errors, action.payload.value],
      };
    case "RESET_ERRORS":
      return {
        ...state,
        errors: [],
      };
    case "RESET_ERRORS_STATE":
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export default ErrosReducer;
