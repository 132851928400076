import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IFeaturesSlice = {
    features: Array<string>,
    accessibleFeatures: Array<string>
}

const initialState: IFeaturesSlice = {
    features: ['N/A'],
    accessibleFeatures: ['N/A']
}

export const featuresSlice = createSlice({
    name: 'featuresSlice',
    initialState: initialState,
    reducers: {
        loadFeatures: (state, action: PayloadAction<{ features: Array<string>, accessibleFeatures: Array<string> }>) => {
            state.features = action.payload.features;
            state.accessibleFeatures = action.payload.accessibleFeatures;
        },
        resetFeatures: (state) => {
            state.features = ['N/A'];
            state.accessibleFeatures = ['N/A'];
        }
    }
})

export const {
    loadFeatures,
    resetFeatures
} = featuresSlice.actions


export default featuresSlice.reducer
