import React, { useState } from "react";
import { FormControl, Select, MenuItem, Grid, Box } from "@material-ui/core";

export interface OverviewTableProps {
  onYChange: (value: string) => void;
}

/**
 * Y Axis switcher from CIP dashboard
 * @param onYChange {function} props handler for on Y Axis selection change
 */

const CIPYSwitch = ({ onYChange }) => {
  const options = ["Coil Inlet Pressure", "Laval Ratio"];
  const [activeOption, setActiveOption] = useState(options[0]);

  const handleChange = (e) => {
    const newOption = e.target.value;
    setActiveOption(newOption);
    onYChange(newOption);
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs>
          <Box mr={1}>Y-Axis</Box>
        </Grid>
        <Grid item xs>
          <Select autoWidth value={activeOption} onChange={(e) => handleChange(e)} style={{ width: "100%" }}>
            {options.map((option, i) => (
              <MenuItem key={`OPTION-SELECT-${i}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default CIPYSwitch;
