import React, { Component } from "react";
import { FormControl, Select, MenuItem, Grid, Box } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { setCGMode } from "../../slices/crackedGasSlice";

interface ICGModeSelectorProps {
  disabled?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    cgMode: state.crackedGas.cgMode,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCGMode: (cgMode: string) => dispatch(setCGMode(cgMode)),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICGModeSelectorReduxProps = PropsFromRedux & ICGModeSelectorProps;


/**
 * Short/detailed mode selector for Cracked Gas
 */

class CGModeSelector extends Component<ICGModeSelectorReduxProps, {}> {

  handleChange = (e) => {
    const value = e.target.value;
    this.props.setCGMode(value);
  };

  render() {
    return (
      <FormControl style={{ width: "100%" }}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs>
            <Box mr={1}>Selected Mode</Box>
          </Grid>
          <Grid item xs>
            <Select autoWidth disabled={false} value={this.props.cgMode} onChange={(e) => this.handleChange(e)} style={{ width: "100%" }}>
              <MenuItem value="short">Short</MenuItem>
              <MenuItem value="detailed">Detailed</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </FormControl>
    );
  }
};

export default connector(CGModeSelector);
