import * as React from "react";
import { Table, TableBody, TableRow, TableCell, Box } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { getTemperatureUnit } from '../../configuration';

export interface COTSettingsProps { }

const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios,
    currentScenarioFireboxId: state.scenarioSimulator.currentScenarioFireboxId
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICOTSettingsReduxProps = PropsFromRedux & COTSettingsProps;


/**
 * General Coil settings table
 */

const SettingsTable: React.SFC<ICOTSettingsReduxProps> = ({ scenarios, currentScenarioFireboxId }) => {
  const selectedScenario: number = 0;
  return (
    <React.Fragment>
      <Box className="bold">Run Length Criteria</Box>
      <Box>
        <Table className="l-table-setting" style={{ width: "unset" }}>
          <TableBody>
            <TableRow>
              <TableCell align="right" className="bold th">
                TMT Max ({getTemperatureUnit()})
              </TableCell>
              <TableCell className="td">{Number(scenarios?.[selectedScenario]?.TMTMAX).toFixed(1) || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" className="bold th">
                Laval Ratio (-)
              </TableCell>
              <TableCell className="td">{Number(scenarios?.[selectedScenario]?.LAVAL_RATIO).toFixed(3) || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" className="bold th">
                Run Length Max (Days)
              </TableCell>
              <TableCell className="td">{Number(scenarios?.[selectedScenario]?.RUNLENGTH_MAX).toFixed(1) || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" className="bold th">
                Interval (Days)
              </TableCell>
              <TableCell className="td">{Number(scenarios?.[selectedScenario]?.INTERVAL).toFixed(1) || "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" className="bold th">
                Final Run Length (Days)
              </TableCell>
              <TableCell className="td">{scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL?.toFixed(1) || "N/A"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
};

export default connector(SettingsTable);
