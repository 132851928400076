import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IcrackedGasSlice = {
    cgMode: string,
    molPerc: string,
}

const initialState: IcrackedGasSlice = {
    cgMode: "short",
    molPerc: "Wt %"
}

export const crackedGasSlice = createSlice({
    name: 'crackedGasSlice',
    initialState: initialState,
    reducers: {
        setCGMode: (state, action: PayloadAction<string>) => {
            state.cgMode = action.payload;
        },
        setmolPerc: (state, action: PayloadAction<string>) => {
            state.molPerc = action.payload;
        },

    }
})

export const {
    setCGMode,
    setmolPerc

} = crackedGasSlice.actions


export default crackedGasSlice.reducer
