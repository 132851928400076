import * as React from "react";

type StateEC = {
  value: any;
};

type CellDataType = {
  id: string;
  value: any;
  type: string;
  disabled?: boolean;
};

type PropsEC = {
  optionalArgument?: string;
  data?: any;
  cellUpdate: any;
  cellData: CellDataType;
  isHighLightTermination?: boolean;
  ValueInString?: boolean;
};

class EditableCell extends React.Component<PropsEC, StateEC> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.props.ValueInString ? '' : this.props.cellData.value,
    };
  }

  handleChange(e) {
    const mockE = {
      target: {
        id: e.target.id,
        name: e.target.name,
        value: this.props.ValueInString ? ( Number(e.target.value) >= 0 ? e.target.value : '') : isNaN(e.target.value) || !e.target.value ? 0 : e.target.value.includes(".") ? e.target.value : Number(e.target.value),
      },
    };
    this.setState({
      value: mockE.target.value,
    });
    this.props.cellUpdate(mockE);
  }

  render() {
    return (
      <td>
        <input className="man_correction" disabled={this.props.cellData.disabled} type="text" name={this.props.cellData.type} id={this.props.cellData.id} value={this.props.cellData.value} onChange={this.handleChange.bind(this)}
          style={{ backgroundColor: this.props.isHighLightTermination ? "lightgreen" : "inherit" }}
        />
      </td>
    );
  }
}

export default EditableCell;
