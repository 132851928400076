export const FeedstockRef = {
  components: [
    {
      id: '1',
      name: 'Hydrogen',
      chemical: 'H2',
      molecular_weight: 2.016,
      value: 0,
      feed_type_id: '2'
    },
    {
      id: '2',
      name: 'CO',
      chemical: 'CO',
      molecular_weight: 28.01,
      value: 0,
      feed_type_id: '2'
    },
    {
      id: '3',
      name: 'CO2',
      chemical: 'CO2',
      molecular_weight: 44.01,
      value: 0,
      feed_type_id: '2'
    },
    {
      id: '4',
      name: 'H2S',
      chemical: 'H2S',
      molecular_weight: 34.08,
      value: 0,
      feed_type_id: '2'
    },
    {
      id: '5',
      name: 'Methane',
      chemical: 'CH4',
      molecular_weight: 16.04,
      value: 0,
      feed_type_id: '2'
    },
    {
      id: '6',
      name: 'Acetylene',
      chemical: 'C2H2',
      molecular_weight: 26.04,
      value: 0,
      feed_type_id: '2'
    },
    {
      id: '7',
      name: 'Ethylene',
      chemical: 'C2H4',
      molecular_weight: 28.05,
      value: 0,
      feed_type_id: '2'
    },
    {
      id: '8',
      name: 'Ethane',
      chemical: 'C2H6',
      molecular_weight: 30.07,
      value: 100,
      feed_type_id: '2'
    },
    {
      id: '9',
      name: 'Methylacetylene',
      chemical: 'C3H4',
      molecular_weight: 40.06,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '10',
      name: 'Propadiene',
      chemical: 'C3H4',
      molecular_weight: 40.06,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '11',
      name: 'Propylene',
      chemical: 'C3H6',
      molecular_weight: 42.08,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '12',
      name: 'Propane',
      chemical: 'C3H8',
      molecular_weight: 44.1,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '13',
      name: '1,2-Butadiene',
      chemical: 'BUTAD',
      molecular_weight: 54.09,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '14',
      name: 'Vinylacetylene',
      chemical: 'C4H4',
      molecular_weight: 52.08,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '15',
      name: '1,3-Butadiene',
      chemical: 'BUTAD',
      molecular_weight: 54.09,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '16',
      name: '1-Butene',
      chemical: 'B1',
      molecular_weight: 56.11,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '17',
      name: 'trans-2-Butene',
      chemical: 'B2',
      molecular_weight: 56.11,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '18',
      name: 'cis-2-Butene',
      chemical: 'B2',
      molecular_weight: 56.11,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '19',
      name: 'i-Butene',
      chemical: 'IB',
      molecular_weight: 56.11,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '20',
      name: 'n-Butane',
      chemical: 'NBUTA',
      molecular_weight: 58.12,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '21',
      name: 'i-Butane',
      chemical: 'IBUTA',
      molecular_weight: 58.12,
      value: 0,
      feed_type_id: '3'
    },
    {
      id: '22',
      name: '3-Methyl-1-Butene',
      chemical: 'ISAM',
      molecular_weight: 70.14,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '23',
      name: '1,4-Pentadiene',
      chemical: 'PTD',
      molecular_weight: 68.12,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '24',
      name: 'i-Pentane',
      chemical: 'IC5',
      molecular_weight: 72.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '25',
      name: '1-Pentene',
      chemical: 'P1',
      molecular_weight: 70.14,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '26',
      name: '2-Methyl-1-Butene',
      chemical: 'ISAM',
      molecular_weight: 70.14,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '27',
      name: 'Isoprene',
      chemical: 'ISOPR',
      molecular_weight: 68.12,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '28',
      name: 'n-Pentane',
      chemical: 'NC5',
      molecular_weight: 72.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '29',
      name: 'trans-2-Pentene',
      chemical: 'P1',
      molecular_weight: 70.14,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '30',
      name: 'cis-2-Pentene',
      chemical: 'P1',
      molecular_weight: 70.14,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '31',
      name: '3-Methyl-1,2-Butadiene',
      chemical: 'ISOPR',
      molecular_weight: 68.12,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '32',
      name: '2-Methyl-2-Butene',
      chemical: 'ISAM',
      molecular_weight: 70.14,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '33',
      name: 'trans-1,3-Pentadiene',
      chemical: 'PTD',
      molecular_weight: 68.12,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '34',
      name: 'Cyclo-Pentadiene',
      chemical: 'CPTD',
      molecular_weight: 66.1,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '35',
      name: 'cis-1,3-Pentadiene',
      chemical: 'PTD',
      molecular_weight: 68.12,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '36',
      name: '1,2-Pentadiene',
      chemical: 'PTD',
      molecular_weight: 68.12,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '37',
      name: '3-Methyl-1,4-Pentadiene',
      chemical: 'MMPTD',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '38',
      name: '2,3-Pentadiene',
      chemical: 'PTD',
      molecular_weight: 68.12,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '39',
      name: '2,2-Dimethyl-Butane',
      chemical: 'C6ISO',
      molecular_weight: 86.18,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '40',
      name: 'Cyclo-Pentene',
      chemical: 'CC5H8',
      molecular_weight: 68.12,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '41',
      name: '4-Methyl-1-Pentene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '42',
      name: '3-Methyl-1-Pentene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '43',
      name: '4-Methyl-cis-2-Pentene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '44',
      name: '2,3-Dimethyl-1-Butene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '45',
      name: 'Cyclo-Pentane',
      chemical: 'CPTAN',
      molecular_weight: 70.14,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '46',
      name: '4-Methyl-trans-2-Pentene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '47',
      name: '2,3-Dimethyl-Butane',
      chemical: 'C6ISO',
      molecular_weight: 86.18,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '48',
      name: '2-Methyl-Pentane',
      chemical: 'C6ISO',
      molecular_weight: 86.18,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '49',
      name: '2-Methyl-1-Pentene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '50',
      name: '1-Hexene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '51',
      name: '3-Methyl-Pentane',
      chemical: 'C6ISO',
      molecular_weight: 86.18,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '52',
      name: 'cis-1,4-Hexadiene',
      chemical: 'HXDN',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '53',
      name: '3-Hexene/3E-1-Butene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '54',
      name: 'trans-2-Hexene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '55',
      name: '2-Methyl-2-Pentene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '56',
      name: 'n-Hexane',
      chemical: 'NC6',
      molecular_weight: 86.18,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '57',
      name: '2-Methyl-cis-1,3-Pentadiene',
      chemical: 'MMPTD',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '58',
      name: 'cis-2-Hexene',
      chemical: 'AOLC6',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '59',
      name: '2,3-Dimethyl-1,3-Butadiene',
      chemical: 'MMPTD',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '60',
      name: 'C6-Mono-Olefins',
      chemical: 'HXDN',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '61',
      name: 'C6-Di-Olefins',
      chemical: 'HXDN',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '62',
      name: '1-Methyl-1,3-Cyclopentadiene',
      chemical: 'MCPTD',
      molecular_weight: 80.13,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '63',
      name: 'Methyl-Cyclopentane',
      chemical: 'MCPTA',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '64',
      name: '2-Methyl-trans-1,3-Pentadiene',
      chemical: 'MMPTD',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '65',
      name: '4-Methyl-trans-1,3-Pentadiene',
      chemical: 'MMPTD',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '66',
      name: '3-Methyl-cis-1,3-Pentadiene',
      chemical: 'MMPTD',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '67',
      name: '3-Methyl-trans-1,3-Pentadiene',
      chemical: 'MMPTD',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '68',
      name: '1-Methyl-Cyclo-Pentene',
      chemical: 'MCP',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '69',
      name: 'trans-2-trans-4-Hexadiene',
      chemical: 'HXDN',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '70',
      name: 'cis-2-trans-4-Hexadiene',
      chemical: 'HXDN',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '71',
      name: 'Cyclo-Hexane',
      chemical: 'CHXAN',
      molecular_weight: 84.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '72',
      name: 'Methyl-Hexadienes',
      chemical: 'MHXDN',
      molecular_weight: 96.17,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '73',
      name: '2-Methyl-Hexane',
      chemical: 'C7ISO',
      molecular_weight: 100.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '74',
      name: 'Cyclo-Hexene',
      chemical: 'CHXEN',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '75',
      name: '1,1-Dimethyl-Cyclo-Pentane',
      chemical: 'C7NAF',
      molecular_weight: 98.19,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '76',
      name: '2,3-Dimethyl-Pentane',
      chemical: 'C7ISO',
      molecular_weight: 100.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '77',
      name: '3-Methyl-Hexane',
      chemical: 'C7ISO',
      molecular_weight: 100.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '78',
      name: '2-Methyl-1-Hexene',
      chemical: 'AOLC7',
      molecular_weight: 98.19,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '79',
      name: 'cis-1,3-Dimethyl-Cyclo-Pentane',
      chemical: 'C7NAF',
      molecular_weight: 98.19,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '80',
      name: '1-Heptene',
      chemical: 'AOLC7',
      molecular_weight: 98.19,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '81',
      name: 'trans-1,3-Dimethyl-Cyclo-Pentane',
      chemical: 'C7NAF',
      molecular_weight: 98.19,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '82',
      name: 'trans-1,2-Dimethyl-Cyclo-Pentane',
      chemical: 'C7NAF',
      molecular_weight: 98.19,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '83',
      name: 'trans-2-Heptene',
      chemical: 'AOLC7',
      molecular_weight: 98.19,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '84',
      name: 'trans-3-Heptene',
      chemical: 'AOLC7',
      molecular_weight: 98.19,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '85',
      name: 'cis-3-Heptene',
      chemical: 'AOLC7',
      molecular_weight: 98.19,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '86',
      name: 'n-Heptane',
      chemical: 'NC7',
      molecular_weight: 100.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '87',
      name: 'Benzene',
      chemical: 'BENZ',
      molecular_weight: 78.11,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '88',
      name: 'Toluene',
      chemical: 'TOLU',
      molecular_weight: 92.14,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '89',
      name: 'Other C8-iso-Paraffins',
      chemical: 'C8ISO',
      molecular_weight: 114.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '90',
      name: 'Other C8-Olefins',
      chemical: 'AOLC8',
      molecular_weight: 112.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '91',
      name: 'Ethyl-Benzene',
      chemical: 'EBENZ',
      molecular_weight: 106.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '92',
      name: 'p-Xylene',
      chemical: 'XYL',
      molecular_weight: 106.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '93',
      name: 'm-Xylene',
      chemical: 'XYL',
      molecular_weight: 106.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '94',
      name: 'Styrene',
      chemical: 'STYR',
      molecular_weight: 104.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '95',
      name: 'o-Xylene',
      chemical: 'XYL',
      molecular_weight: 106.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '96',
      name: 'Other C9-iso-Paraffins',
      chemical: 'C9ISO',
      molecular_weight: 128.3,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '97',
      name: 'i-Propyl-Benzene',
      chemical: 'C9ARO',
      molecular_weight: 120.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '98',
      name: 'n-Propyl-Benzene',
      chemical: 'C9ARO',
      molecular_weight: 120.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '99',
      name: '1-Methyl-3-Ethyl-Benzene',
      chemical: 'C9ARO',
      molecular_weight: 120.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '100',
      name: '1-Methyl-4-Ethyl-Benzene',
      chemical: 'C9ARO',
      molecular_weight: 120.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '101',
      name: '1-Methyl-2-Ethyl-Benzene',
      chemical: 'C9ARO',
      molecular_weight: 120.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '102',
      name: '1,3,5-Trimethyl-Benzene',
      chemical: 'C9ARO',
      molecular_weight: 120.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '103',
      name: '2-Methyl-Styrene',
      chemical: 'MSTYR',
      molecular_weight: 118.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '104',
      name: '3-Methyl-Styrene',
      chemical: 'MSTYR',
      molecular_weight: 118.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '105',
      name: '1,2,4-Trimethyl-Benzene',
      chemical: 'C9ARO',
      molecular_weight: 120.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '106',
      name: 'Other C10-iso-Paraffins',
      chemical: 'C10IS',
      molecular_weight: 142.3,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '107',
      name: '1,2,3-Trimethyl-Benzene',
      chemical: 'C9ARO',
      molecular_weight: 120.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '108',
      name: 'Indene',
      chemical: 'INDEN',
      molecular_weight: 116.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '109',
      name: '1,4-Dimethyl-2-Styrene',
      chemical: 'DMSTY',
      molecular_weight: 132.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '110',
      name: '1,3-Dimethyl-2-Styrene',
      chemical: 'DMSTY',
      molecular_weight: 132.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '111',
      name: 'n-Octane',
      chemical: 'NC8',
      molecular_weight: 114.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '112',
      name: 'n-Nonane',
      chemical: 'NC9',
      molecular_weight: 128.3,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '113',
      name: 'n-Decane',
      chemical: 'NC10',
      molecular_weight: 142.3,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '114',
      name: 'Nitrogen',
      chemical: 'N2',
      molecular_weight: 28.01,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '115',
      name: '1,5-Hexadiene',
      chemical: 'DIAL',
      molecular_weight: 82.15,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '116',
      name: 'C8-Naphthenes',
      chemical: 'C8NAF',
      molecular_weight: 112.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '117',
      name: 'C9-Naphthenes',
      chemical: 'C9NAF',
      molecular_weight: 126.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '118',
      name: 'Methyl-Cyclohexenes',
      chemical: 'MCHXN',
      molecular_weight: 96.17,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '119',
      name: 'C7-Cyclo-Diolefins',
      chemical: 'C7H10',
      molecular_weight: 94.16,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '120',
      name: 'Dimethyl-Cyclo-Hexadienes',
      chemical: 'DMCHD',
      molecular_weight: 108.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '121',
      name: 'Dimethyl-Cyclo-Hexenes',
      chemical: 'MMCHN',
      molecular_weight: 110.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '122',
      name: 'Naphthalene',
      chemical: 'FUEL1',
      molecular_weight: 128.2,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '123',
      name: 'C16H18-Polyaromatics',
      chemical: 'FUEL2',
      molecular_weight: 210.3,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '124',
      name: 'C16H12-Polyaromatics',
      chemical: 'FUEL3',
      molecular_weight: 204.3,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '125',
      name: 'C22H18-Polyaromatics',
      chemical: 'FUEL4',
      molecular_weight: 282.4,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '126',
      name: 'n-C15',
      chemical: 'NC15',
      molecular_weight: 212.4,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '127',
      name: 'C15-Mono-Cyclo-Naphthenes',
      chemical: 'NAF15',
      molecular_weight: 210.4,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '128',
      name: 'C20-Mono-Cyclo-Naphthenes',
      chemical: 'NAF20',
      molecular_weight: 280.5,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '129',
      name: 'C11-Cyclo-Olefins',
      chemical: 'OLN11',
      molecular_weight: 152.3,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '130',
      name: 'C16-Cyclo-Olefins',
      chemical: 'OLN16',
      molecular_weight: 222.4,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '131',
      name: 'C15-Alkyl-Benzenes',
      chemical: 'C15AR',
      molecular_weight: 204.4,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '132',
      name: 'C20-Alkyl-Benzenes',
      chemical: 'C20AR',
      molecular_weight: 274.5,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '133',
      name: 'C20-Dicyclo-Naphthenes',
      chemical: 'DNA20',
      molecular_weight: 278.5,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '134',
      name: 'n-C20',
      chemical: 'NC20',
      molecular_weight: 282.5,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '135',
      name: 'i-C20-Paraffins',
      chemical: 'ISO20',
      molecular_weight: 282.6,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '136',
      name: 'C15-Dicyclo-Aromatics',
      chemical: 'C15CO',
      molecular_weight: 200.3,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '137',
      name: 'C20-Dicyclo-Aromatics',
      chemical: 'C20CO',
      molecular_weight: 270.5,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '138',
      name: 'i-C15-Paraffins',
      chemical: 'ISO15',
      molecular_weight: 212.4,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '139',
      name: 'C11-Olefins',
      chemical: 'OLC11',
      molecular_weight: 154.3,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '140',
      name: 'C16-Olefins',
      chemical: 'OLC16',
      molecular_weight: 224.4,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '141',
      name: '1,6-Heptadienes',
      chemical: 'DIA7',
      molecular_weight: 96.17,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '142',
      name: '1,13-C14-Diolefins',
      chemical: 'DIA14',
      molecular_weight: 194.4,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '143',
      name: 'C15-Dicyclo-Naphthenes',
      chemical: 'DNA15',
      molecular_weight: 208.4,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '144',
      name: 'C32-Polyaromatics',
      chemical: 'CH32',
      molecular_weight: 416.6,
      value: 0,
      feed_type_id: '4'
    },
    {
      id: '145',
      name: 'C19-Polyaromatics',
      chemical: 'C1924',
      molecular_weight: 252.4,
      value: 0,
      feed_type_id: '4'
    }
  ]
  ,
  feed_type_id: '2',
  status: 'UNCOMPUTED',
  error_message: ''
}

export const FuelGasRef = {
  id: '_DEFAULT_DAHEJ',
  name: 'DEFAULT_DAHEJ',
  unit: 'MOL %',
  components: [
    {
      id: '_Hydrogen',
      name: 'Hydrogen',
      chemical: 'H2',
      molecular_weight: 2.016,
      value: 50.496
    },
    {
      id: '_CO',
      name: 'CO',
      chemical: 'CO',
      molecular_weight: 28.01,
      value: 0.083
    },
    {
      id: '_CO2',
      name: 'CO2',
      chemical: 'CO2',
      molecular_weight: 44.01,
      value: 0
    },
    {
      id: '_Methane',
      name: 'Methane',
      chemical: 'CH4',
      molecular_weight: 16.04,
      value: 49.03
    },
    {
      id: '_Ethylene',
      name: 'Ethylene',
      chemical: 'C2H4',
      molecular_weight: 28.05,
      value: 0.046
    },
    {
      id: '_Ethane',
      name: 'Ethane',
      chemical: 'C2H6',
      molecular_weight: 30.07,
      value: 0
    },
    {
      id: '_Propylene',
      name: 'Propylene',
      chemical: 'C3H6',
      molecular_weight: 42.08,
      value: 0
    },
    {
      id: '_Propane',
      name: 'Propane',
      chemical: 'C3H8',
      molecular_weight: 44.1,
      value: 0
    },
    {
      id: '_n-Butane',
      name: 'n-Butane',
      chemical: 'NBUTA',
      molecular_weight: 58.12,
      value: 0.33
    },
    {
      id: '_n-Pentane',
      name: 'n-Pentane',
      chemical: 'NC5',
      molecular_weight: 72.15,
      value: 0.015
    },
    {
      id: '_n-Hexane',
      name: 'n-Hexane',
      chemical: 'NC6',
      molecular_weight: 86.18,
      value: 0
    },
    {
      id: '_Nitrogen',
      name: 'Nitrogen',
      chemical: 'N2',
      molecular_weight: 28.01,
      value: 0
    },
    {
      id: '_Water',
      name: 'Water',
      chemical: 'H2O',
      molecular_weight: 18.015,
      value: 0
    },
    {
      id: '_Oxygen',
      name: 'Oxygen',
      chemical: 'O2',
      molecular_weight: 15.999,
      value: 0
    }
  ],
  status: 'UNCOMPUTED',
  error_message: ''
}


export const ConvectionSectionRef = {

  id: '_DEFAULT_CS',
  name: '_DEFAULT_CS',
  furnace_id: 1,
  asset_name: 'DAHEJ.FURNACE.01',
  components: [
    {
      asset_name: 'DAHEJ.FURNACE.01',
      BFW_TEMPERATURE: {
        input_tag: 'BFW_TEMPERATURE',
        tag_unit: '°C',
        design: 113
      },
      BFW_PRESSURE: {
        input_tag: 'BFW_PRESSURE',
        tag_unit: 'bar g',
        design: 118.99
      },
      HP_STEAM_TEMPERATURE: {
        input_tag: 'HP_STEAM_TEMPERATURE',
        tag_unit: '°C',
        design: 505
      },
      HP_STEAM_PRESSURE: {
        input_tag: 'HP_STEAM_PRESSURE',
        tag_unit: 'bar g',
        design: 106.99
      },
      FEED_TEMPERATURE: {
        input_tag: 'FEED_TEMPERATURE',
        tag_unit: '°C',
        design: 60
      },
      FEED_PRESSURE: {
        input_tag: 'FEED_PRESSURE',
        tag_unit: 'bar g',
        design: 7.19
      },
      DILUTION_STEAM_TEMPERATURE: {
        input_tag: 'DILUTION_STEAM_TEMPERATURE',
        tag_unit: '°C',
        design: 185
      },
      DILUTION_STEAM_PRESSURE: {
        input_tag: 'DILUTION_STEAM_PRESSURE',
        tag_unit: 'bar g',
        design: 6.79
      },
      FUELGAS_TEMPERATURE: {
        input_tag: 'FUELGAS_TEMPERATURE',
        tag_unit: '°C',
        design: 23
      },
      FUELGAS_PRESSURE: {
        input_tag: 'FUELGAS_PRESSURE',
        tag_unit: 'bar g',
        design: 3.19
      },
      CG_PRESSURE_AT_HEADER: {
        input_tag: 'CG_PRESSURE_AT_HEADER',
        tag_unit: 'bar g',
        design: 0.69
      }
    }
  ]

}

// type FeedstockRef_Type = {
//   component: {
//     id: string;
//     name: string;
//     chemical: string;
//     molecular_weight: number;
//     value: number;
//     feed_type_id: string;
//   }[];
//   feed_type_id: string;
//   status: string;
//   error_message: string;
// }

// type FuelGasRef_Type = {
//   id: string;
//   name: string;
//   unit: string;
//   components: {
//     id: string;
//     name: string;
//     chemical: string;
//     molecular_weight: number;
//     value: number;
//   }[];
//   status: string;
//   error_message: string;
// }


