import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type INotificationLogSlice = {
    notificationLog: Array<any>
    showNotificationButton: boolean
}

const initialState: INotificationLogSlice = {
    notificationLog: [],
    showNotificationButton : true
}

export const notificationLogSlice = createSlice({
    name: 'notificationLogSlice',
    initialState: initialState,
    reducers: {
        setNotificationLog: (state, action: PayloadAction<any[]>) => {
            state.notificationLog = action.payload;
        },
        resetNotificationLog: (state) => {
            state.notificationLog = initialState.notificationLog
        },
        setNotificationButton: (state, action: PayloadAction<boolean>) => {
            state.showNotificationButton = action.payload
        }
    }
})

export const {
    setNotificationLog,
    resetNotificationLog,
    setNotificationButton
} = notificationLogSlice.actions


export default notificationLogSlice.reducer