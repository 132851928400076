import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect, ConnectedProps } from "react-redux";
import { COTDataI, ToFixedI } from "../../interfaces/coil";
import { RootState } from "../../reducers/rootReducer";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import store from "../..";

export interface OverviewTableProps {
  data: Array<COTDataI>;
  id: string;
  keys: Array<string>;
  headers: Array<string>;
  disableHighlight?: boolean;
  className?: string;
  ToFixed?: ToFixedI;
  currentView?: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios,
    currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA

  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IOverViewTableReduxProps = PropsFromRedux & OverviewTableProps;


const OverviewTable: React.SFC<IOverViewTableReduxProps> = ({ scenarios, data, id, keys, currentView, headers, disableHighlight, className = "l-table", ToFixed, currentScenarioIsRTA }) => {
  const { referencePosition } = useSelector((state: RootState) => state.coilState);
  const selectedScenario: number = 0;
  // const maxRunLength = scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL
  const scenario_calc = scenarios?.[selectedScenario]?.firebox[0]?.CALCULATIONS;
  const maxRunLength = scenario_calc?.[scenario_calc?.length ? scenario_calc.length - 1 : 0]?.output_dict?.["RUNLENGTH"][0][0] ?? 0;

  /**
   * Highlight means the changed color for currently selected value, this highlighted value is also visible in the timechart
   */
  const [highlight, setHighlight] = useState(0);
  useEffect(() => {
    const indexOfLarger = data?.findIndex((d) => d.runtime >= referencePosition);
    if (indexOfLarger === 0) {
      setHighlight(0);
    } else if (indexOfLarger === -1) {
      setHighlight(data.length - 1);
    } else {
      if (data) {
        const [smaller, larger] = [...data].splice(indexOfLarger - 1, 2);
        const diff = larger.runtime - referencePosition + (smaller.runtime - referencePosition);
        const refPositionIndex = diff >= 0 ? data.findIndex((d) => d === smaller) : data.findIndex((d) => d === larger);
        setHighlight(refPositionIndex);
      }
    }
  }, [referencePosition, data]);

  /**
   * Changes highlight to different row based on passed index and updates reference line in the chart
   * @param i {number} index
   */

  const handleRowClick = (i) => {
    if (!disableHighlight) {
      let days = store.getState().scenarioSimulator?.scenarioRunlengths?.filter(x => x.id === store.getState().scenarioSimulator.currentScenarioRunLengthId)[0]?.scenarios[0]?.Days;
      let day1Index = days?.lastIndexOf('1') === -1 ? 0 : days?.lastIndexOf('1') ?? 0;
      let day5Index = days?.lastIndexOf('5') === -1 ? day1Index : days?.lastIndexOf('5') ?? 0;
      let startIndex = currentView === "1" ? 0 : (currentView === "5" ? (day1Index > 0 ? day1Index + 1 : 0) : (day5Index > 0 ? day5Index + 1 : 0));

      setHighlight(i);
      dispatch({ type: "UPDATE_REFERENCE_POSITION", payload: { value: Math.round(data[i].runtime) } });
      dispatch({ type: "UPDATE_REFERENCE_POSITION_INDEX", payload: { value: currentScenarioIsRTA ? i + startIndex : i } });
    }
  };

  const dispatch = useDispatch();

  let flagIndex = 0
  return (
    <Table className={`${className}`} id={id}>
      {headers && (
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell align="left" className="bold th" key={header}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {!disableHighlight && maxRunLength && maxRunLength > 0 ?
          data && data.filter(d => d.runtime <= maxRunLength).map((row, i) => (
            <TableRow key={i} id={`overview-${i}`} onClick={() => handleRowClick(i)} style={disableHighlight ? {} : highlight === i ? { background: "#00A0E1" } : {}}>
              {keys.map((key, j) => {
                let toFixed = (ToFixed && ToFixed[key]) ? ToFixed[key] : 2;
                return (
                  <React.Fragment key={j}>
                    <TableCell align="left" className="td" style={disableHighlight ? {} : highlight === i ? { color: "white" } : { color: "black" }}>
                      {row[key] ? (row[key] === "N/A" ? "N/A" : row[key].toFixed(toFixed)) : row[key] === 0 ? (0).toFixed(toFixed) : "N/A"}
                    </TableCell>
                  </React.Fragment>
                )
              })}
            </TableRow>
          ))

          :
          data && data.forEach((row, i) => {
            if (!row['DateTime'] && flagIndex === 0)
              flagIndex = i
            if (flagIndex === 0 || flagIndex === 1 || i > flagIndex) {
              return <TableRow key={i} id={`overview-${i}`} onClick={() => handleRowClick(i)} style={disableHighlight ? {} : highlight === i ? { background: "#00A0E1" } : {}}>
                {keys.map((key, j) => {
                  let toFixed = (ToFixed && ToFixed[key]) ? ToFixed[key] : 2;
                  return (
                    <React.Fragment key={j}>
                      <TableCell align="left" className="td" style={disableHighlight ? {} : highlight === i ? { color: "white" } : { color: "black" }}>
                        {currentScenarioIsRTA ?
                          key !== "DESIGN"
                            ? row[key]
                              ? (row[key] === "N/A" ? "N/A" : row[key]?.toFixed(toFixed))
                              : ((row[key] === 0
                                ? (0).toFixed(toFixed)
                                : ((row["FORECAST"] && row["FORECAST"].toString() === "N/A") || row["DCS"] ? "N/A" : (row["FORECAST"] && row["FORECAST"].toFixed(toFixed)))))
                            : "N/A"
                          :
                          row[key] ? (row[key] === "N/A" ? "N/A" : row[key].toFixed(toFixed)) : row[key] === 0 ? (0).toFixed(toFixed) : "N/A"
                        }
                      </TableCell>
                    </React.Fragment>
                  )
                })}
              </TableRow>
            }
          })
        }
      </TableBody>
    </Table>
  );
};

export default connector(OverviewTable);
