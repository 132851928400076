import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConvectionSection } from "../interfaces/IConvectionSection";
import { getSignature } from "../utilities/helperFunctions";

export type IConvectionSectionSlice = {
    convectionsections: IConvectionSection[],
    current_convectionsection_id: string,
    new_selected_convectionsection_id: string,
    defaultConvectionSectionDatas: any[],
    loadedConvectionSections: IConvectionSection[]
}

const initialState: IConvectionSectionSlice = {
    convectionsections: [],
    current_convectionsection_id: "",
    new_selected_convectionsection_id: "",
    defaultConvectionSectionDatas: [],
    loadedConvectionSections: []
}

export const convectionsectionSlice = createSlice({
    name: 'convectionsectionSlice',
    initialState: initialState,
    reducers: {
        addConvectionSection: (state, action: PayloadAction<IConvectionSection>) => {
            state.convectionsections.push(action.payload);
            state.current_convectionsection_id = action.payload.id;
        },
        deleteConvectionSection: (state, action) => {
            state.convectionsections = state.convectionsections.filter((item) => (item.id !== action.payload));
            if (state.convectionsections.length > 0) {
                state.current_convectionsection_id = state.convectionsections[state.convectionsections.length - 1].id;
            }
        },
        addUpdateComponents: (state, action: PayloadAction<{ convectionid: string, components: any[] }>) => {
            let idx: number = state.convectionsections.findIndex(x => x.id === action.payload.convectionid);
            state.convectionsections[idx].components = action.payload.components;
            state.convectionsections[idx].is_display_leave_popup = true
            state.convectionsections[idx].is_saved = false;

        },
        setCurrentConvectionSectionId: (state, action: PayloadAction<string>) => {
            state.current_convectionsection_id = action.payload;
        },
        addDefaultConvectionSectionData: (state, action: PayloadAction<any[]>) => {
            state.defaultConvectionSectionDatas = action.payload;
        },
        updateDefaultConvectionSectionData: (state, action: PayloadAction<any[]>) => {
            state.defaultConvectionSectionDatas = [...state.defaultConvectionSectionDatas, ...action.payload];
        },
        updateLoadedConvectionSections: (state, action: PayloadAction<IConvectionSection[]>) => {
            state.loadedConvectionSections = action.payload;
        },
        loadAllConvectionSectionData: (state, action: PayloadAction<IConvectionSection[]>) => {
            if (state.convectionsections.length === 0) {
                state.current_convectionsection_id = action.payload[0].id;
            }
            state.convectionsections = [...state.convectionsections, ...action.payload];

        },
        modifyConvectionSection: (state, action: PayloadAction<IConvectionSection>) => {
            const convectionsection_index = state.convectionsections.findIndex(convectionSection => convectionSection.id === action.payload.id);
            state.convectionsections[convectionsection_index] = action.payload;
        },
        updateConvectionSectionSignature: (state, action: PayloadAction<{ convectionSection_id: string }>) => {
            let current_convectionsection = state.convectionsections.filter(f => f.id === action.payload.convectionSection_id)[0];
            current_convectionsection.signature = getSignature(current_convectionsection);
        },
        setNewSelectedConvectionSectionId: (state, action: PayloadAction<string>) => {
            state.new_selected_convectionsection_id = action.payload;
        },
        resetConvectionSections:(state) => {
            state.loadedConvectionSections = []
            state.convectionsections = []
            state.current_convectionsection_id = ""
            state.defaultConvectionSectionDatas = []
        }
    }
})

export const {
    addConvectionSection,
    deleteConvectionSection,
    addUpdateComponents,
    setCurrentConvectionSectionId,
    addDefaultConvectionSectionData,
    updateDefaultConvectionSectionData,
    updateLoadedConvectionSections,
    loadAllConvectionSectionData,
    modifyConvectionSection,
    updateConvectionSectionSignature,
    setNewSelectedConvectionSectionId,
    resetConvectionSections
} = convectionsectionSlice.actions


export default convectionsectionSlice.reducer
