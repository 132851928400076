import { MenuItem, Select, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IConvectionSection } from "../../interfaces/IConvectionSection";
import { RootState } from "../../reducers/rootReducer";
import { setCurrentConvectionSectionId, setNewSelectedConvectionSectionId } from "../../slices/convectionSectionSlice";

interface IConvectionSectionSelectorProps {
    disabled?: boolean;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentConvectionSectionId: state.convectionsection.current_convectionsection_id,
        convectionsections: state.convectionsection.convectionsections
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentConvectionSectionId: (newConvectionSectionId: string) => dispatch(setCurrentConvectionSectionId(newConvectionSectionId)),
        setNewSelectedConvectionSectionId: (newConvectionSectionId: string) => dispatch(setNewSelectedConvectionSectionId(newConvectionSectionId))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IConvectionSectionSelectorReduxProps = PropsFromRedux & IConvectionSectionSelectorProps;

class ConvectionSectionSelector extends Component<IConvectionSectionSelectorReduxProps, {}>{

    getSelectedConvectionSection(): IConvectionSection {
        const convectionsection_index = this.props.convectionsections.findIndex(convectionsection => convectionsection.id === this.props.currentConvectionSectionId)
        return this.props.convectionsections[convectionsection_index]
    }

    handleSelection(event) {
        const convectionsection_index = this.props.convectionsections.findIndex(convectionsection => convectionsection.id === this.props.currentConvectionSectionId)
        if (convectionsection_index > -1) {
            if (this.props.convectionsections[convectionsection_index].is_display_leave_popup) {
                document.getElementById("saveConvectionsectionsButtonHidden")?.click()
                const new_convectionsection_id = event.target.value;
                this.props.setNewSelectedConvectionSectionId(new_convectionsection_id);
            } else {
                const new_convectionsection_id = event.target.value;
                this.props.setCurrentConvectionSectionId(new_convectionsection_id);
            }
        } else {
            const new_convectionsection_id = event.target.value;
            this.props.setCurrentConvectionSectionId(new_convectionsection_id);
        }

    }

    render() {
        return (
            <div className="control-header element">
                <div className="container-typography">
                    <Typography>Convection Section: </Typography>
                </div>
                <Select disabled={this.props.convectionsections.length === 0} autoWidth value={this.props.convectionsections.length === 0 ? "No convection section defined" : this.getSelectedConvectionSection().id} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.props.convectionsections.length === 0 ?
                        <MenuItem key={`CONVECTIONSECTION-SELECT-NONE`} value={"No convection section defined"}>
                            {"No convection section defined"}
                        </MenuItem> : <div />}
                    {this.props.convectionsections.map((convectionsection, i) => (
                        <MenuItem key={`CONVECTIONSECTION-SELECT-${convectionsection.name}`} value={convectionsection.id}>
                            {convectionsection.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }

};

export default connector(ConvectionSectionSelector);