import React, { Component } from 'react';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from '../authProvider';
import { connect, ConnectedProps } from "react-redux";
import { RootState } from '../../reducers/rootReducer';
import queryString from 'query-string'
import { resetFeedstocks } from '../../slices/feedstockSlice';
import { resetFurnace } from '../../slices/furnaceSlice';
import { resetFeatures } from '../../slices/featuresSlice';
import { resetFuelgas } from '../../slices/fuelGasSlice';
import { resetConvectionSections } from '../../slices/convectionSectionSlice';
import { resetScenarios } from '../../slices/scenarioSimulatorSlice';
import { resetProductPredictorScenario } from '../../slices/productPredictorSlice';
import { resetMultipleCurrentCoil } from '../../slices/coilSlice';
import { resetCameraSlice } from '../../slices/cameraSlice';
import { resetCurrentAggregateFunction } from '../../slices/aggregateFunctionSlice';
import { resetCoilGroupSlice } from '../../slices/coilGroupSlice';
import path from '../../assets/customer_specific_data/login_screen.jpg';

import { AuthContextProps, withAuth } from "react-oidc-context";
import { isPingIDAuth } from "../../utilities/helperFunctions";

import { resetNotificationLog } from '../../slices/notificationLogSlice';
import { resetCurrentUserRole } from '../../slices/userRolesSlice';


interface ILoginProps {
    auth: AuthContextProps
}

let mapStateToProps = (state: RootState) => {
    return { };
};
let mapDispatchToProps = (dispatch) => {
    return {
        update_page_title: (pageName) => dispatch({ type: "UPDATE_PAGE_TITLE", payload: { value: pageName } }),
        clearToken: () => dispatch({ type: "RESET_AUTH_STATE" }),
        resetRedux: () => {
            dispatch({ type: "RESET_APP_STATE" });
            dispatch({ type: "RESET_AUTH_STATE" });
            dispatch({ type: "RESET_COIL_STATE" });
            dispatch({ type: "RESET_ERRORS_STATE" });
            dispatch(resetFeedstocks());
            dispatch(resetFurnace());
            dispatch(resetFuelgas());
            dispatch(resetScenarios());
            dispatch({ type: "RESET_SCENARIOS_STATE" });
            dispatch(resetCurrentUserRole());
            dispatch({ type: "RESET_TERMINATION_STATE" });
            dispatch(resetFeatures());
            dispatch(resetConvectionSections());
            dispatch(resetProductPredictorScenario());
            dispatch(resetMultipleCurrentCoil());
            dispatch(resetCameraSlice());
            dispatch(resetCurrentAggregateFunction());
            dispatch(resetCoilGroupSlice());
            dispatch(resetNotificationLog());
        },
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ILoginReduxProps = PropsFromRedux & ILoginProps;
type ILoginState = {
}

class Login extends Component<ILoginReduxProps, ILoginState> {

    constructor(props) {
        super(props);
    }
   
    componentDidMount() {
        
        const pageName = "Home"
        // this.props.resetRedux() // TO BE USED IN FUTURE
        this.props.update_page_title(pageName);
        this.props.clearToken()

        let params = queryString.parse(window.location.search)
        if (!!params && !!params.userLogOut && params.userLogOut === 'true') {
            this.props.resetRedux();
            authProvider.logout();

            // Ping ID change: Will add condition for authentication type
            if(isPingIDAuth()){
                this.props.auth.removeUser();
                this.props.auth.signoutRedirect();
            }else{
                return (
                        <AzureAD provider={authProvider} forceLogin={false}>
                            {
                                ({ login, logout, authenticationState, error, accountInfo }) => {
                                    logout();
                                }}
                        </AzureAD>
                    )
            }

        }

    }

    pingIdLoginButton = () => {
        console.log("In a Login Component: ")
        console.log(this.props.auth)
        if(!this.props.auth.isAuthenticated) {
            return(
                <button onClick={() => void this.props.auth.signinRedirect()} className="button-user">
                    <i className="button-user-logo-pingid" />
                    <span>Login</span>
                </button>
            )
        }
        else{
            return(
                <div> </div>
            )
           
        }
    }

    azureADLoginButton = () => {
        return(
            <AzureAD provider={authProvider} forceLogin={false}>
                {
                    ({ login, logout, authenticationState, error, accountInfo }) => {
                        if (authenticationState === 'Unauthenticated') {
                            return (
                                <button onClick={login} className="button-user">
                                    <i className="button-user-logo" />
                                    <span>Login</span>
                                </button>
                            )
                        } else {
                            return (<div></div>)
                        }
                    }
                }               
            </AzureAD>
        )
    }
        

    render() {
        // const path = require("../../assets/customer_specific_data/login_screen.jpg").default;
        return (
            <div className="login" style={{ backgroundImage: `url(${path})` }}>
                <div className="login-form">
                    <h1>Welcome to Linde Virtual Furnace</h1>
                    {/* Ping ID change: Will add condition for authentication type */}
                   {isPingIDAuth() ? this.pingIdLoginButton() : this.azureADLoginButton()}  

                </div>
            </div >
        );
    }
};
export default withAuth(connector(Login));