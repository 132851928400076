import React, { Component } from "react";
import { MenuItem, Select, Typography } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { setCurrentScenarioFireboxId, setCurrentScenarioRunLengthId } from "../../slices/scenarioSimulatorSlice";

interface IScenarioRunLengthSelectorProps {
    onScenarioChange: any,
    disabled?: boolean;
    scenarioChanged?: boolean
}

const mapStateToProps = (state: RootState) => {


    return {
        currentScenarioRunLengthId: state.scenarioSimulator.currentScenarioRunLengthId,
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths.filter(x => x.scenarios[0]?.CALCULATIONS?.length > 0 || x.scenarios[0]?.firebox[0]?.CALCULATIONS?.length > 0),
        withConvections: state.scenarioSimulator
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentScenarioRunLengthId: (newScenarioRunLengthId: string) => dispatch(setCurrentScenarioRunLengthId(newScenarioRunLengthId)),
        setCurrentScenarioFireboxId: (newScenarioFireboxId: number) => dispatch(setCurrentScenarioFireboxId(newScenarioFireboxId))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFurnaceSelectorReduxProps = PropsFromRedux & IScenarioRunLengthSelectorProps;

class ScenarioRunLengthSelector extends Component<IFurnaceSelectorReduxProps, {}> {

    getSelectedScenarioRunLength = () => {
        return this.props.scenarioRunlengths.find(scenariorunlength => scenariorunlength.id === this.props.currentScenarioRunLengthId)?.id;
    }

    handleSelection = (event) => {
        const new_scenariorunlength_id = event.target.value;
        this.props.setCurrentScenarioRunLengthId(new_scenariorunlength_id);
        let fireboxId = this.props.scenarioRunlengths.find(scenario => scenario.id === new_scenariorunlength_id)?.scenarios[0]?.terminated_firebox;
        fireboxId = fireboxId !== -1 ? fireboxId : 0;
        this.props.setCurrentScenarioFireboxId(Number(fireboxId));
        if (this.props.onScenarioChange) this.props.onScenarioChange(this.props.withConvections.scenarioRunlengths[this.props.currentScenarioRunLengthId]);
    }
    componentDidUpdate(prevProps) {
        if (this.props.scenarioChanged) {
            this.getSelectedScenarioRunLength();
        }
    }
    render() {
        return (
            <div className="control-header element">
                <div className="container-typography">
                    <Typography>Scenario Runlength</Typography>
                </div>
                <Select disabled={this.props.disabled} autoWidth value={this.getSelectedScenarioRunLength()} onChange={(event) => this.handleSelection(event)} style={{ width: "100%" }}>
                    {this.props.scenarioRunlengths
                        .filter(scenarioRunlength =>
                            scenarioRunlength.furnaceId === this.props.currentFurnaceId &&
                            scenarioRunlength.plantId === this.props.currentPlantId)
                        .map((scenarioRunlength, i) => (
                            <MenuItem key={`SCENARIO-SELECT-${scenarioRunlength.name}-${i}`} value={scenarioRunlength.id}>
                                {scenarioRunlength.name}
                            </MenuItem>
                        ))}
                </Select>
            </div>
        );
    }

};

export default connector(ScenarioRunLengthSelector);