import React, { Component } from "react";
import { MenuItem, Select, Typography, InputLabel, FormControl } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { getAssetName, getFurnaceReference, getFurnaceReferences, getFurnaceTypeFeedTypeByFurnaceId, getScenarioInputDefinitions, getFurnaceCameras } from "../../configuration";
import { RootState } from "../../reducers/rootReducer";
import { setAssetName, setFurnaceId } from "../../slices/furnaceSlice";
import { addCalculation, addScenarioRunlength, addScenarioToRunlength, setCurrentFeedTypeId, setCurrentFurnaceId, setCurrentFurnaceTypeId, setCurrentScenarioRunLengthId } from "../../slices/scenarioSimulatorSlice";
import { getRTATilldateData } from "../../utilities/helperFunctions";
import { CALCULATION_STATUS, getScenarioFromInput, IScenario, MODE, SCENARIO_STATUS, SCENARIO_TYPE } from "../../interfaces/IScenario";
import store from "../..";
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { v4 as uuidv4 } from 'uuid';
import "../../assets/styles/_thermalImageInformationBlock.scss";
import { isThermalImage } from "../../utilities/helperFunctions";
import { setCurrentCamera,setCurrentCameraId,ICameras } from '../../slices/cameraSlice'

interface IFurnaceSelectorProps {
    disabled?: boolean;
    setLoading?: (val) => void
    furnaceId?: string;
    fromTMT?: string;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        furnaceState: state.furnace,
        scenarioState: state.scenarioSimulator,
        feedstockState: state.feedstock,
        token: state.authState.token,
        currentScenarioIsRTA: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios[0].currentScenarioIsRTA
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentFurnaceId: (newFurnaceId: string) => dispatch(setCurrentFurnaceId(newFurnaceId)),
        setFurnaceId: (newFurnaceId: string) => dispatch(setFurnaceId(newFurnaceId)),
        setAssetName: (assetname: string) => dispatch(setAssetName(assetname)),
        setCurrentFurnaceTypeId: (furnace_type_id: string) => dispatch(setCurrentFurnaceTypeId(furnace_type_id)),
        setCurrentFeedTypeId: (feed_type_id: string) => dispatch(setCurrentFeedTypeId(feed_type_id)),
        addScenarioRunlength: (newScenarioRunlength: IScenarioRunlength) => dispatch(addScenarioRunlength(newScenarioRunlength)),
        addScenarioToRunlength: (newRunLengthId: string, scenario: IScenario) => dispatch(addScenarioToRunlength({ runlength_id: newRunLengthId, scenario: scenario })),
        addCalculation: (runlength_id: string, scenario_id: string, result: any, timeline: any, days: string[], rta: boolean, fireboxIndex: number) => dispatch(addCalculation({ runlength_id, scenario_id, result, timeline, days, rta, fireboxIndex })),
        setCurrentScenarioRunLengthId: (newScenarioRunLengthId: string) => dispatch(setCurrentScenarioRunLengthId(newScenarioRunLengthId)),
        setCurrentCamera: (name: string) => dispatch(setCurrentCamera(name)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IFurnaceSelectorReduxProps = PropsFromRedux & IFurnaceSelectorProps;

type IFurnaceSelectorState = {
    furnaceId: string
}

class FurnaceSelector extends Component<IFurnaceSelectorReduxProps, IFurnaceSelectorState>{

    constructor(props) {
        super(props);
        this.state = {
            furnaceId: !!this.props.furnaceId && parseInt(this.props.furnaceId) > 0 ? this.props.furnaceId : this.props.currentFurnaceId
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.currentFurnaceId !== this.props.currentFurnaceId){
            this.setState({
                furnaceId: this.props.currentFurnaceId
            })
        }
    }

    getSelectedFurnace() {
        let currentFurnaceId = this.state.furnaceId;
        return getFurnaceReference(this.props.currentPlantId, currentFurnaceId);
    }

    getFurnaces() {
        return getFurnaceReferences(this.props.currentPlantId);
    }
    getDefaultScenarioInputs(new_furnace_id) {
        let scenarioInputs = { firebox: [{}, {}], mode: MODE.FULL };
        const value = getFurnaceTypeFeedTypeByFurnaceId(new_furnace_id);
        getScenarioInputDefinitions(value[0], value[1]).forEach((input) => {
            const id = input.id;
            scenarioInputs.firebox[0][id] = input.design ?? input.selected_value;
            scenarioInputs.firebox[1][id] = input.design ?? input.selected_value;
        });

        return scenarioInputs;
    }
    setRTAData = async (data, furnace_num) => {
        let scenario: IScenario;
        scenario = getScenarioFromInput(this.getDefaultScenarioInputs(furnace_num), "", MODE.HYBRID, 1);
        scenario.name = "Realtime Advisor";
        scenario.status = SCENARIO_STATUS.RUNNING;
        scenario.calculation_status = CALCULATION_STATUS.INIT;
        scenario.currentScenarioIsRTA = true;
        const newScenarioRunlength: IScenarioRunlength = {
            id: uuidv4(),
            furnaceId: furnace_num,
            plantId: this.props.currentPlantId,
            name: "Realtime Advisor " + furnace_num,
            scenarios: [],
            isExportable: false,
            signature: "",
            type: SCENARIO_TYPE.Cold_START
        }
        this.props.addScenarioRunlength(newScenarioRunlength);
        this.props.addScenarioToRunlength(newScenarioRunlength.id, scenario);
        this.props.addCalculation(newScenarioRunlength.id, scenario.id, data.map(x => {
            return x.forecast !== 0 ? (x.forecast) : x.now
        }), data.map(x => x.UTCTimeStamp), data.map(y => y.Day), true, this.props.scenarioState.currentScenarioFireboxId);
        this.props.setCurrentScenarioRunLengthId(newScenarioRunlength.id);
    }

    async handleSelection(event) {
        this.props.setLoading && this.props.setLoading(true) //set loader true
        const new_furnace_id = event.target.value.id;
        const assetname = getAssetName(new_furnace_id);
        this.props.setCurrentFurnaceId(new_furnace_id);
        this.props.setFurnaceId(new_furnace_id);
        this.props.setAssetName(assetname);

        const value = getFurnaceTypeFeedTypeByFurnaceId(new_furnace_id);
        this.props.setCurrentFurnaceTypeId(value[0]);
        this.props.setCurrentFeedTypeId(value[1]);
        if (this.props.currentScenarioIsRTA) {
            const firebox = this.props.scenarioState.currentScenarioFireboxId === 0 ? "A" : "B";
            const furnace = new_furnace_id + "." + firebox;
            const furnaceTypeId = store.getState().scenarioSimulator.current_furnace_type_id;
            const feedTypeId = store.getState().scenarioSimulator.current_feed_type_id;
            const data = await getRTATilldateData(furnace, furnaceTypeId, feedTypeId, store.getState().authState.token)
            if (!store.getState().scenarioSimulator.scenarioRunlengths.find(x => x.name === "Realtime Advisor " + new_furnace_id)) {
                this.setRTAData(data, new_furnace_id);
            }
            else {
                const scenarioRunlength = store.getState().scenarioSimulator.scenarioRunlengths.filter(x => x.name === "Realtime Advisor " + new_furnace_id)[0]
                const scenarioRunlengthId = scenarioRunlength.id;
                const scenarioId = scenarioRunlength.scenarios[0].id;
                this.props.addCalculation(scenarioRunlengthId, scenarioId, data.map(x => {
                    return x.forecast !== 0 ? (x.forecast) : x.now
                }), data.map(x => x.UTCTimeStamp), data.map(y => y.Day), true, this.props.scenarioState.currentScenarioFireboxId);
            }
        }
        this.setState({
            furnaceId: new_furnace_id
        })
        if (isThermalImage()) {
            let furnaceCameras = getFurnaceCameras(new_furnace_id);
            this.props.setCurrentCamera(furnaceCameras[0].display_name);
        }
        // this.props.setLoading && this.props.setLoading(false) //set loader false
    }

    furnaceSelect = () => {
        return <Select disabled={this.props.disabled} autoWidth value={this.getSelectedFurnace()} onChange={(event) => this.handleSelection(event)} style={{ width: isThermalImage() ? "100%" : "" }}>
                {this.getFurnaces().map((furnace, i) => (
                    <MenuItem key={`FURNACE-SELECT-${furnace.name}-${i}`} value={furnace}>
                        {furnace.name}
                    </MenuItem>
                ))}
                </Select>
    }

    render() {
        return (
            <div className="control-header element">
                {!this.props.fromTMT && <div className="container-typography">
                    <Typography>Furnace Number</Typography>
                </div>}
                {!this.props.fromTMT && this.furnaceSelect()}
                {this.props.fromTMT && <FormControl className="sel-furnace">
                    <InputLabel>Select Furnace</InputLabel>
                    {this.furnaceSelect()}
                </FormControl>}
            </div>
        );
    }

};

export default connector(FurnaceSelector);