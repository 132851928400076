import React, { useState } from "react";
import { FormControl, Box, Grid, MenuItem, Select } from "@material-ui/core";

export interface ChartSwitcherProps {
  onChange?: (selectedItem: any) => void;
  style?: any;
  defaultSelection?: string;
}

/**
 * Switching this Component changes data source that is displayed on Coil Dynamics
 * @param onChange {function} props on parameter change handler
 * @param style {object} props style object
 */

const ChartSwitcher: React.SFC<ChartSwitcherProps> = ({ onChange, style, defaultSelection }) => {
  let selection: string = defaultSelection ? defaultSelection : "Ethylene Content";
  const [selectedItem, setSelectedItem] = useState(selection);

  const menuItems = [
    {
      keys: ["ETHYLENE"],
      name: "Ethylene Content",
      name_long: "Ethylene Content",
      unit: "%",
      ToFixed: 2,
      y_axis_adjustment: [2, false]
    },
    {
      keys: ["PROPYLENE"],
      name: "Propylene Content",
      name_long: "Propylene Content",
      unit: "%",
      tofixed: 2,
      y_axis_adjustment: [2, false]
    },
    {
      keys: ["COKETHICKNESS"],
      name: "Coke Thickness",
      name_long: "Coke Thickness",
      unit: "mm",
      tofixed: 1,
      y_axis_adjustment: [0, false]
    },
    {
      keys: ["PE_RATIO"],
      name: "P/E Ratio",
      name_long: "Propylene to Ethylene Ratio",
      unit: "-",
      tofixed: 3,
      y_axis_adjustment: [20, true]
    },
    {
      keys: ["C2_CONVERSION"],
      name: "Conversion",
      name_long: "C2 Conversion",
      unit: "%",
      tofixed: 2,
      y_axis_adjustment: [2, false]
    },
    {
      keys: ["C3_CONVERSION"],
      name: "Conversion",
      name_long: "C3 Conversion",
      unit: "%",
      tofixed: 2,
      y_axis_adjustment: [2, false]
    },
    {
      keys: ["C4_CONVERSION"],
      name: "Conversion",
      name_long: "C4 Conversion",
      unit: "%",
      tofixed: 2,
      y_axis_adjustment: [2, false]
    },
    {
      keys: ["ABSORBED_HEAT_ANA"],
      name: "Qabs",
      name_long: "Heat Required For Cracking",
      unit: "MW",
      tofixed: 2,
      y_axis_adjustment: [2, false]
    },
    {
      keys: ["FEUDIM_FIRED_HEAT"],
      name: "Qfired",
      name_long: "Fired Heat",
      unit: "MW",
      tofixed: 2,
      y_axis_adjustment: [2, false]
    },
  ];

  const handleChange = (e) => {
    const name = e.target.value;
    const selectedMenuItem = menuItems.find((i) => i.name_long === name);

    if (!selectedItem) return;

    setSelectedItem(selectedMenuItem!.name_long);

    if (onChange) onChange(selectedMenuItem);
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs>
          <Box mr={1}>Y-Axis</Box>
        </Grid>
        <Grid item xs>
          <Select autoWidth value={selectedItem} onChange={(e) => handleChange(e)} style={{ width: "100%" }}>
            {menuItems.map((mi) => (
              <MenuItem key={`CHART-SELECT-${mi.name_long}`} value={mi.name_long}>
                {mi.name_long}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default ChartSwitcher;
