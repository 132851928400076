import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../reducers/rootReducer";
import { getAllUsersFromAd, getUserById, saveUser } from "../../../services/userRolesServices";
import { IAdminUser, IAdUsersResponse } from "../../../interfaces/IadminPanel";
import { addUser, editUser, loadAllAdUsers } from "../../../slices/userRolesSlice";
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import editIcon from "../../../assets/images/edit.svg";


interface IAddEditRolesProps {
    popupName: string;
    id: number;
    allRolesData: any;
    disabled: boolean
}

const mapStateToProps = (state: RootState) => {
    return {
        allRoles: state.adminPanel.userRolesResponse,
        allUserData: state.adminPanel.adminPanelUsers,
        allAdUsers: state.adminPanel.adUsersResponse,
        userRole: state.adminPanel.currentUserRoleState.role,
        currentUserRoleIndex: state.adminPanel.currentUserRoleState.roleIndex

    };
};

const mapDispatchToProps = dispatch => {
    return {
        addSaveUser: (newUserWithRole: IAdminUser) => dispatch(addUser(newUserWithRole)),
        editSaveUser: (updateUserWithRole: IAdminUser) => dispatch(editUser(updateUserWithRole)),
        loadAdUsers: (loadAllAdUsersResponse: IAdUsersResponse) => dispatch(loadAllAdUsers(loadAllAdUsersResponse))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IAddEditRolesReduxProps = PropsFromRedux & IAddEditRolesProps;


type IAddEditRolesState = {
    showDialog: boolean;
    name: string;
    email: string;
    id: number;
    adUsersid: string;
    AdUsers: any[];
    roles: any[];
    commonUserValue: any[];
    showLoading: boolean;
    roleId: number;
    roleErrorMessage: string;
    isRoleAvailable: boolean;
    isNameAvailable: boolean;
    nameErrorMessage: string;
    message: string;
}

class AddEditRoles extends Component<IAddEditRolesReduxProps, IAddEditRolesState> {

    constructor(props: any) {
        super(props);

        this.state = {
            showDialog: false,
            name: "",
            email: "",
            id: !!this.props.id && this.props.id > 0 ? this.props.id : 0,
            adUsersid: "",
            AdUsers: [],
            roles: [],
            commonUserValue: [],
            roleId: 0,
            showLoading: false,
            roleErrorMessage: "",
            isRoleAvailable: false,
            isNameAvailable: false,
            nameErrorMessage: "",
            message: "",
        }
    }

    toggleShowDialog = async () => {
        this.setState({
            showDialog: !this.state.showDialog,
            id: !!this.props.id && this.props.id > 0 ? this.props.id : 0,
            roles: [],
            adUsersid: "",
            roleId: 0,
            roleErrorMessage: "",
            isRoleAvailable: false,
            isNameAvailable: false,
            nameErrorMessage: "",
            message: "",
            email: ""
        })

        if (!this.state.showDialog) {
            this.setState({
                showLoading: true
            })
            await this.loadUserDataFromAdOnInit();
            await this.getAllRoles();
            if (this.props.id > 0) { // Edit popup
                let allUsers = this.props.allUserData;
                let user : any = allUsers.filter(x=> x.id == this.state.id)[0];
        
                    this.setState({        
                        adUsersid: user.object_id,
                        email: user.email,
                        name: user.name,
                        roleId: user.role,
                    })
            }

            this.setState({
                showLoading: false
            })
        }
    }

    loadUserDataFromAdOnInit = async () => {
        this.setState({
            showLoading: true
        })

        let getAllUsersFromAdData: IAdUsersResponse = {
            status: false,
            message: "",
            data: []
        };

        if (this.props.allAdUsers.status) {
            getAllUsersFromAdData = this.props.allAdUsers;
            this.refreshState(getAllUsersFromAdData);
        } else {
            this.refreshAdList();
        }

    }

    refreshState = (getAllUsersFromAdData: IAdUsersResponse) => {

        if (getAllUsersFromAdData.status === true) {

            let allUsers = this.props.allUserData;
            let aDUsers = getAllUsersFromAdData.data;
            let AdUsersData, dbUsersData: any[] = [];

            // Use below if condition while allUsers data updated from  " await getAllUserRolesFromDB() " method. 
            // let allUsers = await getAllUserRolesFromDB();
            // if (allUsers.status && allUsers.data.length > 0) {
            //     //allUsers.data.some((e2: any) => e2.email === e1.mail)
            //     // AdUsersData = aDUsers.filter((e1: any) => {
            //     //     return allUsers.data.some((e2: any) => e2.email !== e1.mail)
            //     // })
            //     if (this.props.popupName === 'Add') {
            //         AdUsersData = aDUsers.filter((e1: any) => !allUsers.data.some((e2: any) => e2.object_id === e1.id))
            //         dbUsersData = aDUsers.filter((e1: any) => allUsers.data.some((e2: any) => e2.object_id === e1.id))
            //     }
            //     else if (this.props.popupName === 'Edit') {
            //         let selectedDataDeatils: any = allUsers.data.filter((e2: any) => e2.id === this.state.id)[0];
            //         AdUsersData = aDUsers.filter((e1: any) => e1.id === selectedDataDeatils.object_id)
            //     }
            //     else
            //         AdUsersData = getAllUsersFromAdData.data;
            // } 

            if (allUsers.length > 0) {
                if (this.props.popupName === 'Add') {
                    AdUsersData = aDUsers.filter((e1: any) => !allUsers.some((e2: any) => e2.object_id === e1.id))
                    dbUsersData = aDUsers.filter((e1: any) => allUsers.some((e2: any) => e2.object_id === e1.id))
                }
                else if (this.props.popupName === 'Edit') {
                    let selectedDataDeatils: any = allUsers.filter((e2: any) => e2.id === this.state.id)[0];
                    AdUsersData = aDUsers.filter((e1: any) => e1.id === selectedDataDeatils.object_id)
                }
                else
                    AdUsersData = getAllUsersFromAdData.data;
            }
            else {
                AdUsersData = getAllUsersFromAdData.data;
            }

            this.setState({
                showLoading: false,
                AdUsers: AdUsersData,
                commonUserValue: dbUsersData
            })
        } else {
            this.setState({
                showLoading: false
            })
        }

    }

    refreshAdList = async () => {
        let getAllUsersFromAdData: IAdUsersResponse = {
            status: false,
            message: "",
            data: []
        };

        this.setState({
            showLoading: true
        })

        getAllUsersFromAdData = await getAllUsersFromAd();
        this.props.loadAdUsers(getAllUsersFromAdData);
        this.refreshState(getAllUsersFromAdData);
    }

    getAllRoles = async () => {
        this.setState({
            showLoading: true
        })
        let allRolesData = this.props.allRoles;
        let filteredRoles = allRolesData.data.filter(data => data.id >= this.props.currentUserRoleIndex)
        filteredRoles.sort((a,b) => a.id - b.id);
        if (allRolesData.status === true) {
            this.setState({
                showLoading: false,
                roles: filteredRoles
            })
        } else {
            this.setState({
                showLoading: false
            })
        }
    }

    addEditNewRolesButtonClick = async () => {
        this.setState({
            showLoading: true
        })

        if (this.state.adUsersid === "") {
            this.setState({
                nameErrorMessage: "Please select name",
                isNameAvailable: true,
                showLoading: false
            })

            return false;
        }

        if (this.state.roleId <= 0) {
            this.setState({
                roleErrorMessage: "Please select role",
                isRoleAvailable: true,
                showLoading: false
            })

            return false;
        }

        if (!!this.state.adUsersid && this.state.adUsersid !== "" && !!this.state.roleId && this.state.roleId > 0) {
            let selectedValueObj = this.state.AdUsers.find((x: any) => x.id === this.state.adUsersid);
            let setData = {
                id: this.state.id,
                email: selectedValueObj.mail == null ? selectedValueObj.userPrincipalName : selectedValueObj.mail,
                role: this.state.roleId,
                name: selectedValueObj.displayName,
                is_delete: false,
                object_id: selectedValueObj.id
            }

            try {
                let save = await saveUser(setData);
                if (save.status) {
                    let lastInsertedId = save.data['id'];
                    let lastInsertedData = await getUserById(lastInsertedId);
                    if (lastInsertedData.status) {

                        let statusMessage;
                        if (setData.id <= 0) {
                            this.props.addSaveUser(lastInsertedData.data as IAdminUser)
                            statusMessage = "New role added successfully!"
                        }
                        else {
                            this.props.editSaveUser(lastInsertedData.data as IAdminUser)
                            statusMessage = "Role updated successfully!"
                        }

                        this.setState({
                            message: statusMessage,
                            showLoading: false,
                        })

                        setTimeout(() => {
                            this.toggleShowDialog();
                        }, 100);
                    } else {
                        this.setState({
                            message: "Error while fetching user data",
                            showLoading: false,
                        })
                    }


                } else {
                    this.setState({
                        message: "Error while saving user data",
                        showLoading: false,
                    })
                }

            } catch (error) {
                this.setState({
                    message: "Error while saving user data "+ error,
                    showLoading: false,
                })
            }
        } else {
            this.setState({
                nameErrorMessage: "Error while getting aduser id",
                isNameAvailable: true,
                roleErrorMessage: "Error while getting role id",
                isRoleAvailable: true,
                showLoading: false,
            })
        }


    }

    handleChangeName = (event: any) => {
        let selectedValueObj = this.state.AdUsers.find((x: any) => x.id === event.target.value);
        this.setState({
            adUsersid: event.target.value,
            email: selectedValueObj.mail == null ? selectedValueObj.userPrincipalName : selectedValueObj.mail,
            name: selectedValueObj.displayName
        })

    }

    handleChangeRoles(event: any) {
        let selectedValue = event.target.value;
        this.setState({
            roleId: event.target.value
        })
    }

    render() {
        return (
            <>
                <div className="control-header element">
                {this.props.popupName === 'Edit' ?
                    <img src={editIcon} className={this.props.disabled ? 'disabled' : ''} style={{height: '20px', width: '20px', paddingBottom: '0px'}} alt="Edit Icon" onClick={() => this.toggleShowDialog()}/>
                    :
                    <button
                        className="button"
                        onClick={() => this.toggleShowDialog()}
                    >
                        {this.props.popupName}
                    </button>
                }
                    <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{this.props.popupName} User</DialogTitle>
                        {this.state.showLoading === true && (
                            <div className="loading-dashboard">
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                            </div>
                        )}
                        {this.state.showLoading === false && (
                            <>
                                <DialogContent>
                                    <DialogContentText>
                                        To {this.props.popupName} a new Role, please provide the following information:
                                        {!!this.state.message && this.state.message !== "" && (
                                            <Typography variant="body1" component="p" color="secondary">
                                                {this.state.message}
                                            </Typography>
                                        )}
                                    </DialogContentText>
                                    <FormControl style={{ width: "100%", marginBottom: "20px" }}>

                                        {(this.props.popupName === 'Add') ?
                                            <>
                                                <div style={{ display: "flex" }}>

                                                    <InputLabel id="demo-simple-select-label">Name</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={this.state.adUsersid}
                                                        onChange={this.handleChangeName.bind(this)}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        error={this.state.isNameAvailable} style={{ width: '90%' }}
                                                    >
                                                        <MenuItem value="select" disabled>
                                                            Please Select Name
                                                        </MenuItem>
                                                        {!!this.state.AdUsers && this.state.AdUsers.length > 0 && this.state.AdUsers.map((AdUserValue: any) => {
                                                            return (
                                                                <MenuItem key={'adUser_' + AdUserValue.id} value={AdUserValue.id}>{AdUserValue.displayName}</MenuItem>
                                                            )
                                                        })}
                                                        {this.state.commonUserValue.length > 0 && this.state.commonUserValue.map((commonUserValue: any) => {
                                                            return (
                                                                <MenuItem key={'adUser_' + commonUserValue.id} value={commonUserValue.id} disabled>{commonUserValue.displayName}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>

                                                    <div>
                                                        <IconButton aria-label="refresh" onClick={() => this.refreshAdList()}>
                                                            <RefreshIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </> :
                                            <>
                                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                                <TextField
                                                    disabled
                                                    id="standard-disabled"
                                                    fullWidth
                                                    defaultValue="Name"
                                                    label="Name"
                                                    value={this.state.name}
                                                    style={{ marginBottom: "20px" }}
                                                />
                                            </>}
                                        {this.state.isNameAvailable && (
                                            <FormHelperText>{this.state.nameErrorMessage}</FormHelperText>
                                        )}
                                    </FormControl>
                                    <TextField
                                        disabled
                                        id="standard-disabled"
                                        label="Email"
                                        fullWidth
                                        defaultValue="email"
                                        value={this.state.email}
                                        style={{ marginBottom: "20px" }}
                                    />

                                    <FormControl style={{ width: "100%", marginBottom: "20px" }}>
                                        <InputLabel id="demo-simple-select-label-1">Roles</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label-1"
                                            id="demo-simple-select-1"
                                            value={this.state.roleId}
                                            onChange={this.handleChangeRoles.bind(this)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            error={this.state.isRoleAvailable}
                                        >
                                            <MenuItem value="" disabled>
                                                Please Select Role
                                            </MenuItem>
                                            {!!this.state.roles && this.state.roles.length > 0 && this.state.roles.map((roleValue: any) => {
                                                return (
                                                    <MenuItem key={'role_' + roleValue.id} value={roleValue.id}>{roleValue.display_name}</MenuItem>
                                                )
                                            })}
                                            {/* <MenuItem value={10}>Admin</MenuItem>
                                            <MenuItem value={20}>Engineer</MenuItem>
                                            <MenuItem value={30}>Super Admin</MenuItem> */}
                                        </Select>
                                        {this.state.isRoleAvailable && (
                                            <FormHelperText>{this.state.roleErrorMessage}</FormHelperText>
                                        )}

                                    </FormControl>

                                </DialogContent>
                                <DialogActions>
                                    <button className="button" onClick={() => this.toggleShowDialog()} >
                                        Cancel
                                    </button>
                                    <button className="button" onClick={() => this.addEditNewRolesButtonClick()} >
                                        {this.props.popupName === 'Add' ? 'Add' : 'Update'}
                                    </button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                </div>
            </>
        )
    }
}

export default connector(AddEditRoles);