import React, { Component } from "react";
import { Button } from "@material-ui/core";

import { connect, ConnectedProps } from "react-redux";
import { getFireboxScenarioInputDefinitions, getPlantReferenceById, getScenarioInputDefinitions } from "../../configuration";
import { RootState } from "../../reducers/rootReducer";
import { addScenarioToRunlength } from "../../slices/scenarioSimulatorSlice";
import { getScenarioFromInput, IScenario, MODE } from "../../interfaces/IScenario";
import { } from "../../configuration";
import ScenarioDialog from "./scenarioDialog";

interface IAddScenarioProps {
    scenarioRunlengthId: string;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        currentPlantId: state.scenarioSimulator.currentPlantId,
        current_furnace_type_id: state.scenarioSimulator.current_furnace_type_id,
        current_feed_type_id: state.scenarioSimulator.current_feed_type_id,
        scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addScenarioToRunlength: (runlength_id: string, scenario: IScenario) => dispatch(addScenarioToRunlength({ runlength_id, scenario }))
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IAddScenarioReduxProps = PropsFromRedux & IAddScenarioProps;

type IAddScenarioState = {
    showDialog: boolean;
    scenario: IScenario;
}

class AddScenario extends Component<IAddScenarioReduxProps, IAddScenarioState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            scenario: this.getDefaultScenario(),
        }
    }

    getDefaultScenario(): IScenario {
        let getScenarioRunlength = this.props.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === this.props.scenarioRunlengthId);
        let scenarioCount = getScenarioRunlength?.scenarios.length;
        let newCount = Number(scenarioCount) + 1;
        return getScenarioFromInput(this.getDefaultScenarioInputs(), `CHILD-${newCount}`, MODE.FULL, this.props.currentFurnaceId)
    }

    getDefaultScenarioInputs() {
        const scenario_input_id = getPlantReferenceById(this.props.currentPlantId).id;
        let scenarioInputs = { firebox: [{}, {}], mode: MODE.FULL }

        let inputs = getFireboxScenarioInputDefinitions(scenario_input_id, this.props.current_furnace_type_id, this.props.current_feed_type_id);
        getScenarioInputDefinitions(this.props.current_furnace_type_id, this.props.current_feed_type_id).forEach((input) => {

            const id = input.id;
            if (inputs.map(x => x.id).includes(id)) {
                scenarioInputs.firebox[0][id] = input.design ?? input.selected_value;
                scenarioInputs.firebox[1][id] = input.design ?? input.selected_value;
            }
            else {
                scenarioInputs[id] = input.design;
            }
        });

        return scenarioInputs;
    }

    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog,
            scenario: this.getDefaultScenario()
        });
    }

    render() {
        return (
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    className="add-scenario-runlength-button"
                    onClick={() => this.toggleShowDialog()}
                >
                    +
                </Button>
                <ScenarioDialog
                    scenarioRunlengthId={this.props.scenarioRunlengthId}
                    ok_button_text="Add"
                    showDialog={this.state.showDialog}
                    okBtnDisabled={true}
                    toggleShowDialog={() => this.toggleShowDialog()}

                    scenario={this.state.scenario}
                    delete_button={false} />
            </div>
        );
    }

};

export default connector(AddScenario);