import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";

export interface SummaryTableProps {
  data: any[];
}

const mapStateToProps = (state: RootState) => {
  return {
    scenarios: state.scenarioSimulator.scenarioRunlengths.find(scenarioRunlength => scenarioRunlength.id === state.scenarioSimulator.currentScenarioRunLengthId)?.scenarios
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ISummaryTableReduxProps = PropsFromRedux & SummaryTableProps;

/**
 * Coil Dynamics summary table with information about all values at selected point
 */

const SummaryTable: React.SFC<ISummaryTableReduxProps> = ({ scenarios, data }) => {
  const { referencePositionIndex } = useSelector((state: RootState) => state.coilState);
  const [newReferencePosition, setNewReferencePosition] = useState(0);
  const selectedScenario: number = 0;
  const maxRunLength = scenarios?.[selectedScenario]?.RUN_LENGTH_FINAL

  useEffect(() => {
    setNewReferencePosition(referencePositionIndex >= data.length ? 0 : referencePositionIndex);
  }, [referencePositionIndex, data, maxRunLength]);

  const menuItems = [
    {
      keys: ["ETHYLENE"],
      name: "Ethylene Content",
      name_long: "Ethylene Content",
      unit: "%",
      tofixed: 2,
    },
    {
      keys: ["PROPYLENE"],
      name: "Propylene Content",
      name_long: "Propylene Content",
      unit: "%",
      tofixed: 2,
    },
    {
      keys: ["COKETHICKNESS"],
      name: "Coke Thickness",
      name_long: "Coke Thickness",
      unit: "mm",
      tofixed: 1,
    },
    {
      keys: ["PE_RATIO"],
      name: "P/E Ratio",
      name_long: "Propylene to Ethylene Ratio",
      unit: "-",
      tofixed: 3,
    },
    {
      keys: ["C2_CONVERSION"],
      name: "Conversion",
      name_long: "C2 Conversion",
      unit: "%",
      tofixed: 2,
    },
    {
      keys: ["C3_CONVERSION"],
      name: "Conversion",
      name_long: "C3 Conversion",
      unit: "%",
      tofixed: 2,
    },
    {
      keys: ["C4_CONVERSION"],
      name: "Conversion",
      name_long: "C4 Conversion",
      unit: "%",
      tofixed: 2,
    },
    {
      keys: ["ABSORBED_HEAT_ANA"],
      name: "Qabs",
      name_long: "Heat Required For Cracking",
      unit: "MW",
      tofixed: 2,
    },
    {
      keys: ["FEUDIM_FIRED_HEAT"],
      name: "Qfired",
      name_long: "Fired Heat",
      unit: "MW",
      tofixed: 2,
    },
  ];

  return (
    <React.Fragment>
      {data && data.length > 0 && data[newReferencePosition] && (
        <Table className="l-table-summary" style={{ width: "unset" }} >
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className="bold th">DCS</TableCell>
              <TableCell className="bold th">LVF</TableCell>
            </TableRow>

            {menuItems.map((item) => (
              <React.Fragment key={item.name_long}>
                <TableRow>
                  <TableCell align="right" className="bold td" style={{ minWidth: "150px" }}>
                    {`${item.name_long} (${item.unit})`}
                  </TableCell>
                  <TableCell className="td">N/A</TableCell>
                  <TableCell className="td">{isNaN(data[newReferencePosition][item.keys[0]]) ? "N/A" : maxRunLength && maxRunLength > 0 ? data.filter(d => d.runtime <= maxRunLength)[newReferencePosition][item.keys[0]].toFixed(item.tofixed) : data[newReferencePosition][item.keys[0]].toFixed(item.tofixed)}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      )}
    </React.Fragment>
  );
};

export default connector(SummaryTable);
