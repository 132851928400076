import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IComponent, UNIT } from "../interfaces/IComponent";
import { FUELGAS_STATUS, IFuelGas } from "../interfaces/IFuelGas";
import { getSignature } from "../utilities/helperFunctions";

export type IFuelGasSlice = {
    fuelGases: Array<IFuelGas>,
    loadedFuelGases: Array<IFuelGas>,
    current_fuelGas_id: string,
    new_selected_fuelGas_id: string,
}

const initialState: IFuelGasSlice = {
    fuelGases: [],
    loadedFuelGases: [],
    current_fuelGas_id: "",
    new_selected_fuelGas_id: "",
}

export const fuelGasSlice = createSlice({
    name: 'fuelGasSlice',
    initialState: initialState,
    reducers: {
        addFuelGas: (state, action: PayloadAction<IFuelGas>) => {
            state.fuelGases.push(action.payload);
            state.current_fuelGas_id = action.payload.id;
        },
        deleteFuelGas: (state, action) => {
            state.fuelGases = state.fuelGases.filter((item) => (item.id !== action.payload));
            // state.current_fuelGas_id = state.fuelGases[state.fuelGases.length - 1].id;
            if (state.fuelGases.length > 0) {
                state.current_fuelGas_id = state.fuelGases[state.fuelGases.length - 1].id;
            } else {
                state.current_fuelGas_id = ""
            }
        },
        setCurrentFuelGasId: (state, action: PayloadAction<string>) => {
            state.current_fuelGas_id = action.payload;
        },
        updateComponent: (state, action: PayloadAction<{ fuelGas_id: string, component_id: string, new_value: number }>) => {
            const fuelGas_index = state.fuelGases.findIndex(fuelGas => fuelGas.id === action.payload.fuelGas_id);
            const component_index = state.fuelGases[fuelGas_index].components.findIndex(component => component.id === action.payload.component_id);
            state.fuelGases[fuelGas_index].components[component_index].value = action.payload.new_value;
            state.fuelGases[fuelGas_index].status = FUELGAS_STATUS.UNCOMPUTED;
            state.fuelGases[fuelGas_index].is_display_leave_popup = true;
            state.fuelGases[fuelGas_index].is_saved = false;

        },
        toggleUnit: (state, action: PayloadAction<string>) => {
            const fuelGas_index = state.fuelGases.findIndex(fuelGas => fuelGas.id === action.payload)
            const old_unit = state.fuelGases[fuelGas_index].unit;
            if (old_unit === UNIT.MOL) {
                state.fuelGases[fuelGas_index].unit = UNIT.WT;
            } else {
                state.fuelGases[fuelGas_index].unit = UNIT.MOL;
            }
        },
        normalizeFuelGas: (state, action: PayloadAction<string>) => {
            const fuelGas_index = state.fuelGases.findIndex(fuelGas => fuelGas.id === action.payload);

            if (state.fuelGases[fuelGas_index].status === FUELGAS_STATUS.NORMALIZED) {
                return;
            }
            const value_sum = state.fuelGases[fuelGas_index].components.reduce((accumulator, component) => accumulator + Number(component.value), 0);
            if (value_sum === 0) {
                state.fuelGases[fuelGas_index].status = FUELGAS_STATUS.ERROR;
                state.fuelGases[fuelGas_index].error_message = "Can't update LVF because fuelGas values of scenario are empty.";
                return;
            }

            // Round all components
            let values_computed = state.fuelGases[fuelGas_index].components;
            values_computed = values_computed.map(component => {
                const new_component: IComponent = component;
                new_component.value = Math.round((Number(component.value) / Number(value_sum)) * 10000) / 100;
                return new_component;
            });

            // If sum differs from hundred, apply deviation to first non-zero component
            const computed_value_sum = values_computed.reduce((accumulator, component) => accumulator + Number(component.value), 0);
            if (computed_value_sum !== 100) {
                const deviation = 100 - computed_value_sum;
                const firstNonZeroIndex = values_computed.findIndex((component) => component.value > 0);
                values_computed[firstNonZeroIndex].value = Math.round((values_computed[firstNonZeroIndex].value += deviation) * 100) / 100;
            }

            // Set the status of the fuelGas to NORMALIZED
            state.fuelGases[fuelGas_index].status = FUELGAS_STATUS.NORMALIZED;
        },
        updateLoadedFuelGases: (state, action: PayloadAction<IFuelGas[]>) => {
            state.loadedFuelGases = action.payload;
        },
        updateO2BridgeWall: (state, action: PayloadAction<{ fuelGas_id: string, o2Bridgewall: number }>) => {
            const fuelGas_index = state.fuelGases.findIndex(fuelGas => fuelGas.id === action.payload.fuelGas_id);
            state.fuelGases[fuelGas_index].O2BridgeWall = action.payload.o2Bridgewall;
        },
        updateFuelGasPressure: (state, action: PayloadAction<{ fuelGas_id: string, fuelgasPressure: number }>) => {
            const fuelGas_index = state.fuelGases.findIndex(fuelGas => fuelGas.id === action.payload.fuelGas_id);
            state.fuelGases[fuelGas_index].Pressure = action.payload.fuelgasPressure;
        },
        updateFuelGasTemperature: (state, action: PayloadAction<{ fuelGas_id: string, fuelgasTemperature: number }>) => {
            const fuelGas_index = state.fuelGases.findIndex(fuelGas => fuelGas.id === action.payload.fuelGas_id);
            state.fuelGases[fuelGas_index].Temperature = action.payload.fuelgasTemperature;
        },
        loadAllFuelGasData: (state, action: PayloadAction<IFuelGas[]>) => {
            if (state.fuelGases.length === 0) {
                state.current_fuelGas_id = action.payload[0].id
            }
            state.fuelGases = [...state.fuelGases, ...action.payload]
        },
        modifyFuelGas: (state, action: PayloadAction<IFuelGas>) => {
            const fuelgas_index = state.fuelGases.findIndex(fuelGas => fuelGas.id === action.payload.id);
            state.fuelGases[fuelgas_index] = action.payload;
        },
        updateFualGasSignature: (state, action: PayloadAction<{ fuelgas_id: string }>) => {
            let current_fuelgas = state.fuelGases.filter(f => f.id === action.payload.fuelgas_id)[0];
            current_fuelgas.signature = getSignature(current_fuelgas);
        },
        setNewSelectedFuelGasId: (state, action: PayloadAction<string>) => {
            state.new_selected_fuelGas_id = action.payload;
        },
        resetFuelgas: (state) => {
            state.fuelGases = [];
            state.current_fuelGas_id = "";
            state.loadedFuelGases = [];
        },
    }
})

export const {
    addFuelGas,
    deleteFuelGas,
    setCurrentFuelGasId,
    updateComponent,
    normalizeFuelGas,
    toggleUnit,
    updateLoadedFuelGases,
    updateO2BridgeWall,
    updateFuelGasPressure,
    updateFuelGasTemperature,
    loadAllFuelGasData,
    modifyFuelGas,
    updateFualGasSignature,
    setNewSelectedFuelGasId,
    resetFuelgas
} = fuelGasSlice.actions


export default fuelGasSlice.reducer
