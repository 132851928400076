import { MsalAuthProvider, LoginType } from "react-aad-msal";
 import { CLIENT_ID,OIDC_AUTHORITY_URL , OIDC_AUTHORIZATION_ENDPOINT, OIDC_TOKEN_ENDPOINT, OIDC_ISSUER } from "../utilities/GlobalConstants";
// import { CLIENT_ID } from "../utilities/GlobalConstants";

// Msal Configurations
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: "https://login.microsoftonline.com/f9be925e-f94e-4850-a10d-b459d98a27ca",
      clientId: `${CLIENT_ID}`,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      validateAuthority: true,

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  },
  {
    // scopes: ["openid email profile Group.Read.All User.Read User.ReadBasic.All"],
    scopes: ["openid email profile"],
  },
  {
    loginType: LoginType.Redirect, // Popup
  }
);

export const oidcAuthConfig = {
  authority: `${OIDC_AUTHORITY_URL}`,
  client_id: `${CLIENT_ID}`,
  redirect_uri: window.location.origin+"/callback",
  metadata:{
    issuer: `${OIDC_ISSUER}`,
    authorization_endpoint: `${OIDC_AUTHORIZATION_ENDPOINT}`,
    token_endpoint: `${OIDC_TOKEN_ENDPOINT}`
  },
  scope: "openid profile email"
};
