import { Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@material-ui/core';
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { IProductPredictorFeedstockDetail, IProductPredictorGroup, IProductPredictorScenario } from '../../interfaces/IProductPredictor';
import { RootState } from '../../reducers/rootReducer'
import { addFeedstockDetails, setProgress, updateSignature, setIsExportable } from '../../slices/productPredictorSlice';

interface IProductPredictorFeedstockConfig {
    ProductPredictorElement: IProductPredictorGroup;
    groupIndex: number
}

const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks,
        currproductPredictorScenario: state.productPredictor.productPredictorScenarios.filter(x => x.id === state.productPredictor.currentProductPredictorScenarioId)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addFeedstockDetails: (groupIndex: number, feedstockIndex: number, feedstockdetails: IProductPredictorFeedstockDetail) => dispatch(addFeedstockDetails({ groupIndex, feedstockIndex, feedstockdetails })),
        updateSignature: (obj: IProductPredictorScenario) => dispatch(updateSignature(obj)),
        setProgress: (scenario_id: string) => dispatch(setProgress({ scenario_id: scenario_id, progress: Number(0) })),
        setIsExportable: (scenario_id: string, isTrueOrFalse: boolean) => dispatch(setIsExportable({ scenario_id, isTrueOrFalse }))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProductPredictorFeedstockConfigFromRedux = PropsFromRedux & IProductPredictorFeedstockConfig

type IProductPredictorFeedstockConfigState = {
    feedstockName: string,
    flowrate: number,
    feedstockIndex: number;
    feedstockId: string,
    showDialog: boolean,
    disabledOkBtn: boolean
}

class ProductPredictorFeedstockConfig extends Component<IProductPredictorFeedstockConfigFromRedux, IProductPredictorFeedstockConfigState> {
    initState() {
        return {
            feedstockName: '',
            feedstockIndex: 0,
            flowrate: 0,
            feedstockId: '',
            showDialog: false,
            disabledOkBtn: true
        }
    }

    constructor(props) {
        super(props);
        this.state = this.initState();
    }
    toggleShowDialog() {
        this.setState({
            showDialog: !this.state.showDialog
        })
    }
    addFeedstockDetails() {
        let newObj: IProductPredictorFeedstockDetail = {
            name: this.state.feedstockName,
            flowrate: this.state.flowrate,
            feedstock_id: this.state.feedstockId,
            feedtype_id: this.props.feedstocks.filter(x => x.id === this.state.feedstockId)?.[0]?.feed_type_id
        }
        this.props.addFeedstockDetails(this.props.groupIndex, this.state.feedstockIndex, newObj)
        this.props.updateSignature(this.props.currproductPredictorScenario[0])
        this.props.setProgress(this.props.currproductPredictorScenario[0]?.id)
        this.props.setIsExportable(this.props.currproductPredictorScenario[0].id, false)
        this.toggleShowDialog()
    }
    handleFeedstockChange(event) {
        this.setState({
            feedstockId: event.target.value,
            disabledOkBtn: event.target.value.length >= 0 && this.state.flowrate >= 0 ? false : true
        })
    }
    handleFlowRateInput(event) {
        this.setState({
            flowrate: event.target.value,
            disabledOkBtn: event.target.value >= 0 && this.state.feedstockId.length >= 0 ? false : true
        })
    }
    openFeedstockDetailPopup(name, flowrate, feedstock_id, index) {
        this.setState({
            feedstockName: name,
            flowrate: flowrate,
            feedstockId: feedstock_id,
            feedstockIndex: index,
            disabledOkBtn: flowrate > 0 && feedstock_id.toString().length > 0 ? false : true
        });
        this.toggleShowDialog()
    }

    render() {
        let getcombinaton = this.state.feedstockName === "Butane" ? [3] : this.props.ProductPredictorElement.feedtype_ids;
        let filtered_feed = this.props.feedstocks.filter(x => getcombinaton?.includes(parseInt(x.feed_type_id)));
        return (
            <div>
                <Box display="flex" flexDirection="column" justifyContent="flex-end">
                    {this.props.ProductPredictorElement.feedstock!.map((e, _index) => (
                        <div>
                            <button className="button margin-right feedstockbutton" onClick={() => (this.openFeedstockDetailPopup(e.name, e.flowrate, e.feedstock_id, _index))}>{e.name} Feedstock</button>
                            {e.flowrate > -1 ? 'Total Flow: ' + e.flowrate : ''}
                        </div>
                    ))}
                </Box>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title" >
                    <DialogTitle id="form-dialog-title">Feedstock Configurator</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Flowrate (Kg/h)"
                            type="text"
                            fullWidth
                            value={this.state.flowrate >= 0 ? this.state.flowrate : ''}
                            onChange={(event) => this.handleFlowRateInput(event)}
                        />
                        <Box display="flex" justifyContent="flex-start" >
                            <TextField
                                id={"feedstock"}
                                select
                                margin="dense"
                                style={{ 'width': '100%' }}
                                SelectProps={{ autoWidth: true }}
                                label={"Feedstock"}
                                defaultValue={this.state.feedstockId}
                                onChange={(event) => this.handleFeedstockChange(event)}
                            >
                                {filtered_feed.map((feedstock) => (
                                    <MenuItem key={feedstock.id} value={feedstock.id}>
                                        {feedstock.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" disabled={this.state.disabledOkBtn} onClick={() => this.addFeedstockDetails()}>
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default connector(ProductPredictorFeedstockConfig)