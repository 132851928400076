import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControlLabel, TextField } from "@material-ui/core";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IConvectionSection } from "../../interfaces/IConvectionSection";
import { RootState } from "../../reducers/rootReducer";
import { checkConvectionsectionName } from "../../services/convectionsectionServices";
import { modifyConvectionSection } from "../../slices/convectionSectionSlice";
import FurnaceSelector from "../common/FurnaceSelector";

interface IRenameConvectionSectionProps {
    convectionsection: IConvectionSection;
}

const mapStateToProps = (state: RootState) => {
    return {
        currentConvectionSectionId: state.convectionsection.current_convectionsection_id,
        convectionsections: state.convectionsection.convectionsections
    };
};

const mapDispatchToProps = dispatch => {
    return {
        modifyConvectionSection: (convectionsectiondata: IConvectionSection) => dispatch(modifyConvectionSection(convectionsectiondata)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IRenameConvectionSectionReduxProps = PropsFromRedux & IRenameConvectionSectionProps;

type IRenameConvectionSectionState = {
    showDialog: boolean;
    name: string;
    is_public: boolean;
    isNameAvailable: boolean;
    nameErrorMessage: string;
    showLoading: boolean;
}
class RenameConvectionSection extends Component<IRenameConvectionSectionReduxProps, IRenameConvectionSectionState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            name: this.props.convectionsection.name,
            is_public: this.props.convectionsection.is_public ? this.props.convectionsection.is_public : false,
            isNameAvailable: false,
            nameErrorMessage: "",
            showLoading: false,
        }
    }

    toggleShowDialog() {
        let getName = this.props.convectionsection.is_public === true ? this.props.convectionsection.name.replace('(PUBLIC)', '') : this.props.convectionsection.name;
        this.setState({
            showDialog: !this.state.showDialog,
            name: getName,
            is_public: this.props.convectionsection.is_public ? this.props.convectionsection.is_public : false,
            isNameAvailable: false,
            nameErrorMessage: ""
        })
    }

    handleNameInput(event) {
        this.setState({
            name: event.target.value.toUpperCase()
        })
    }

    handleModifyConvectionSection = async () => {
        if (!!this.state.name?.trim()) {

            this.setState({
                showLoading: true,
            })
            let setName = this.state.is_public === true ? this.state.name.trim() + "(PUBLIC)" : this.state.name.trim();
            let newConvectionSection: IConvectionSection = {
                ...this.props.convectionsection,
                id: this.props.convectionsection.id,
                name: setName,
                furnace_id: this.props.convectionsection.furnace_id,
                asset_name: this.props.convectionsection.asset_name,
                components: this.props.convectionsection.components,
                signature: this.props.convectionsection.signature,
                is_public: this.props.convectionsection.is_public,
                is_display_leave_popup: true,
                is_saved: false
            }

            try {
                let isNameInRedux = this.props.convectionsections.some(convectionsection => convectionsection.name === newConvectionSection.name);

                if (isNameInRedux) {
                    this.setState({
                        isNameAvailable: true,
                        nameErrorMessage: "Name already exist!",
                        showLoading: false,
                    })
                } else {
                    let isNameAvailable = await checkConvectionsectionName(newConvectionSection);
                    if (isNameAvailable.status) {
                        this.setState({
                            isNameAvailable: true,
                            nameErrorMessage: !!isNameAvailable.message ? isNameAvailable.message : "Name already exist!",
                            showLoading: false,
                        })
                    } else {
                        this.props.modifyConvectionSection(newConvectionSection);
                        this.toggleShowDialog();
                        this.setState({
                            showLoading: false,
                        })
                    }
                }
            } catch (error) {
                // Api error hendaling 
                this.setState({
                    isNameAvailable: true,
                    showLoading: false,
                    nameErrorMessage: "Oops somthing went to wrong!"
                })
            }
        } else {
            this.setState({
                isNameAvailable: true,
                nameErrorMessage: "Please enter a name!",
                showLoading: false,
            })
        }
    }

    handleChangeIsPublic(event) {
        this.setState({
            is_public: !this.state.is_public
        })
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                >
                    Rename
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Clone convection section</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Clone convection section, please provide the following information:
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            defaultValue={this.state.name}
                            value={this.state.name}
                            onChange={(event) => this.handleNameInput(event)}
                            helperText={this.state.nameErrorMessage}
                            error={this.state.isNameAvailable}
                        />
                        <FurnaceSelector disabled={true} furnaceId={this.props.convectionsection.furnace_id} />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.is_public}
                                    onChange={(event) => this.handleChangeIsPublic(event)}
                                    name="isPublic"
                                    value={this.state.is_public}
                                    color="primary"
                                    disabled={true}
                                />
                            }
                            label="Is Public?"
                        />
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.handleModifyConvectionSection()} >
                            Modify {this.state.showLoading === true && (
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={11} />
                                </Fade>
                            )}
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

};

export default connector(RenameConvectionSection);