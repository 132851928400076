import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { NavLink, Link, RouteComponentProps } from "react-router-dom";
import Loader from "react-spinners/ClipLoader";
import PlantSelector from "../common/PlantSelector";
import { getDefaultFeedTypeId, getTemperatureUnit } from "../../configuration";
import { RootState } from "../../reducers/rootReducer";

import { addCalculation, addScenarioRunlength, addScenarioToRunlength, setCurrentFeedTypeId, setCurrentFurnaceId, setCurrentFurnaceTypeId, setCurrentPlantId, setCurrentRunLengthId, setCurrentScenarioRunLengthId } from "../../slices/scenarioSimulatorSlice";
import { REACT_APP_APIM_URL_COMPUTE, REACT_APP_APIM_URL_METADATA, REACT_APP_APIM_URL } from "../../utilities/GlobalConstants";
import { authProvider } from "../authProvider";

import { v4 as uuidv4 } from 'uuid';
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";
import { IScenario, SCENARIO_TYPE, getScenarioFromInput, SCENARIO_STATUS, CALCULATION_STATUS, MODE } from "../../interfaces/IScenario";
import store from "../..";
import { getRTATilldateData, isRTA, RTATimeFrequency, isTMTCameraDeviation, isPingIDAuth, ParseTemperatureValue } from "../../utilities/helperFunctions";
import { ErrorI } from "../../interfaces/errors";
import localforage from 'localforage';
import {withRouter} from 'react-router-dom';
import { TI_API_CALL_INTERVAL } from "../../utilities/GlobalConstants";
import { TMTCameraDeviation } from "../../interfaces/tmtCameraDeviation";
import { AuthContextProps, withAuth } from "react-oidc-context";
import { setNotificationLog,setNotificationButton } from "../../slices/notificationLogSlice"
import { getAssetName, getFireboxScenarioInputDefinitions, getFurnaceId, getFurnaceReferenceAssetNames, getPlantReferenceById, getPlantReferences, getScenarioInputDefinitions, getFurnaceReferences } from "../../configuration";
import { setAssetName, setFurnaceId, updateFurnaces, setFurnaceMode, setFurnaceMetrics } from "../../slices/furnaceSlice";

export const HOME_DASHBOARD_ID = "_HOME";

interface IParentDashboardProps extends RouteComponentProps {
  auth?: AuthContextProps
}

const mapStateToProps = (state: RootState) => {
  return {
    token: state.authState.token,
    selectedFurnace: state.furnace.current_furnace_id,
    currentPlantId: state.scenarioSimulator.currentPlantId,
    scenarioRunlengths: state.scenarioSimulator.scenarioRunlengths,
    furnaceState: state.furnace,
    feedstockState: state.feedstock,
    current_furnace_type_id: state.scenarioSimulator.current_furnace_type_id,
    current_feed_type_id: state.scenarioSimulator.current_feed_type_id,
    scenarioState: state.scenarioSimulator,
    accessibleFeatures: state.features.accessibleFeatures,
    config : state.configuration
  };
};
// write to redux
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentFurnaceId: (furnace_id: string) => dispatch(setCurrentFurnaceId(furnace_id)),
    addToken: (data) => dispatch({ type: "ADD_TOKEN", payload: { value: data } }),
    addExpiration: (data) => dispatch({ type: "ADD_EXPIRATION", payload: { value: data } }),
    setCurrentPlantId: (plant_id: string) => dispatch(setCurrentPlantId(plant_id)),
    updateFurnaces: (furnaces: any[]) => dispatch(updateFurnaces(furnaces)),
    setFurnaceId: (furnace_id: string) => dispatch(setFurnaceId(furnace_id)),
    setAssetName: (assetname: string) => dispatch(setAssetName(assetname)),
    setCurrentFurnaceTypeId: (furnace_type_id: string) => dispatch(setCurrentFurnaceTypeId(furnace_type_id)),
    setCurrentFeedTypeId: (feed_type_id: string) => dispatch(setCurrentFeedTypeId(feed_type_id)),
    setCurrentScenarioRunLengthId: (newScenarioRunLengthId: string) => dispatch(setCurrentScenarioRunLengthId(newScenarioRunLengthId)),
    setCurrentRunLengthId: (newRunLengthId: number) => dispatch(setCurrentRunLengthId(newRunLengthId)),
    addScenarioRunlength: (newScenarioRunlength: IScenarioRunlength) => dispatch(addScenarioRunlength(newScenarioRunlength)),
    addScenarioToRunlength: (newRunLengthId: string, scenario: IScenario) => dispatch(addScenarioToRunlength({ runlength_id: newRunLengthId, scenario: scenario })),
    addCalculation: (runlength_id: string, scenario_id: string, result: any, timeline: any, days: string[], rta: boolean, fireboxIndex: number) => dispatch(addCalculation({ runlength_id, scenario_id, result, timeline, days, rta, fireboxIndex })),
    setFurnaceMetrics: (furnaceMetrics: any[]) => dispatch(setFurnaceMetrics(furnaceMetrics)),

    setFurnaceMode: (furnaceMode: any[]) => dispatch(setFurnaceMode(furnaceMode)),
    setNotificationLog: (notificationLog: any[]) => dispatch(setNotificationLog(notificationLog)),
    setNotificationButton: (notificationButton: boolean) => dispatch(setNotificationButton(notificationButton))

  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & IParentDashboardProps;

interface IState {
  assetstate: any[];
  plants: any[];
  furnaces: any[];
  TMTDeviationData: any[];
  loading: boolean;
  maxTMT: any[]; 
  homeFurnaceMetrics:[],
  furnaceNotificationLog: []
}
interface furnaceItem {
  key: string,
  unit: string
}
/**
 * Class component for initial dashboard with furnaces overview
 */
class ParentDashboard extends Component<IProps, IState> {

  bind_furnaces: any = [];
  features: Array<any> = [];
  displayData: Array<furnaceItem> = [];
  displayTMTData: Array<furnaceItem> = [];
  interval;
  constructor(props: any) {
    super(props);

    this.state = {
      assetstate: [],
      plants: getPlantReferences(),
      furnaces: [],
      TMTDeviationData: [],
      loading: false,
      maxTMT:[],
      homeFurnaceMetrics: [],
      furnaceNotificationLog: []
    };

    this.displayData = [];
    this.displayTMTData = [];

    if (isTMTCameraDeviation()) {
      this.displayTMTData.push({ key: 'TMT Max per furnace', unit: getTemperatureUnit() })
      
      this.displayData.push(
        { key: 'Actual Runlength', unit: 'Days' },
        { key: 'Remaining Runlength', unit: 'Days' },
        { key: 'Feed type', unit: '-' },
        { key: 'Coil Outlet Temperature', unit: getTemperatureUnit() })
        // { key: 'Pressure drop', unit: 'PSIG' })
    } else {
      this.displayData.push(
        { key: 'C2-Conversion', unit: 'Wt.-%' },
        { key: 'C3-Conversion', unit: 'Wt.-%' },
        { key: 'C4-Conversion', unit: 'Wt.-%' },
        { key: 'P/E Ratio', unit: '-' },
        { key: 'Tail Gas', unit: 'Wt.-%' },
        { key: 'Ethylene', unit: 'Wt.-%' },
        { key: 'Propylene', unit: 'Wt.-%' },
        { key: 'C4-Fraction', unit: 'Wt.-%' },
        { key: 'C5-Fraction', unit: 'Wt.-%' })
    }
  }
  getDefaultScenarioInputs() {
    let scenarioInputs = { firebox: [{}, {}], mode: MODE.FULL }
    const scenario_input_id = getPlantReferenceById(this.props.currentPlantId).id;
    let inputs = getFireboxScenarioInputDefinitions(scenario_input_id, this.props.current_furnace_type_id, this.props.current_feed_type_id);
    getScenarioInputDefinitions(this.props.current_furnace_type_id, this.props.current_feed_type_id).forEach((input) => {
      const id = input.id;
      if (inputs.map(x => x.id).includes(id)) {
        scenarioInputs.firebox[0][id] = input.design ?? input.selected_value;
        scenarioInputs.firebox[1][id] = input.design ?? input.selected_value;
      }
      else {
        scenarioInputs[id] = input.design;
      }
    });
    return scenarioInputs;
  }
  async componentDidMount() {
    if (isRTA()) {
      //get asset details
      await this.getRTAassetState()
      setInterval(() => this.getRTAassetState(), 60000 * RTATimeFrequency());
      //get RTA dtaa
      const firebox = this.props.scenarioState.currentScenarioFireboxId === 0 ? "A" : "B";
      const furnace = this.props.furnaceState.current_furnace_id + "." + firebox;
      const furnaceTypeId = store.getState().scenarioSimulator.current_furnace_type_id;
      const feedTypeId = store.getState().scenarioSimulator.current_feed_type_id;
      const data = await getRTATilldateData(furnace, furnaceTypeId, feedTypeId, store.getState().authState.token)
      this.setRTAData(data, this.props.furnaceState.current_furnace_id);
    }
    else {
      this.getAssetState();
      if (isTMTCameraDeviation()) {
        let furnaces = getFurnaceReferences(this.props.currentPlantId)
        this.setState({ furnaces: furnaces })
        this.setState({loading:true})
        this.getMaxTMT();
        this.getFurnaceNotificationLog();
        this.getHomeFurnaceMetrics();
        this.props.setNotificationLog([])
        this.getNotificationLogData()

        this.interval = setInterval(() => {
          this.props.setNotificationLog([])
          this.getNotificationLogData()
          this.setState({loading:true})
          this.getMaxTMT();
          this.getFurnaceNotificationLog();
          this.getHomeFurnaceMetrics();          
        },Number(TI_API_CALL_INTERVAL))
        }
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  // componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
  //   if((prevState.maxTMT != this.state.maxTMT) || (prevState.furnaceNotificationLog != this.state.furnaceNotificationLog) || (prevState.homeFurnaceMetrics != this.state.homeFurnaceMetrics)){
  //     if(this.state.maxTMT.length > 0 && this.state.furnaceNotificationLog.length > 0 && this.state.homeFurnaceMetrics.length > 0){
  //       this.bind_furnaces = this.getTMTMaxAndSeverityData(this.state.homeFurnaceMetrics)
  //       this.setState({loading:false})
  //     }
  //   }
  // }

  setRTAData = async (data, furnace_num) => {
    let scenario: IScenario;
    if (!store.getState().scenarioSimulator.scenarioRunlengths.find(x => x.name === "Realtime Advisor " + furnace_num)) {
      scenario = getScenarioFromInput(this.getDefaultScenarioInputs(), "", MODE.HYBRID, 1);
      scenario.name = "Realtime Advisor";
      scenario.status = SCENARIO_STATUS.RUNNING;
      scenario.calculation_status = CALCULATION_STATUS.LOOP;
      scenario.createdDateTime = new Date();
      scenario.modifiedDateTime = new Date();
      scenario.currentScenarioIsRTA = true;
      const newScenarioRunlength: IScenarioRunlength = {
        id: uuidv4(),
        furnaceId: furnace_num,
        plantId: this.props.currentPlantId,
        name: "Realtime Advisor " + furnace_num,
        scenarios: [],
        isExportable: false,
        signature: "",
        type: SCENARIO_TYPE.Cold_START
      }
      this.props.addScenarioRunlength(newScenarioRunlength);
      this.props.addScenarioToRunlength(newScenarioRunlength.id, scenario);
      this.props.addCalculation(newScenarioRunlength.id, scenario.id, data.map(x => {
        return x.forecast !== 0 ? (x.forecast) : x.now
      }), data.map(x => x.UTCTimeStamp), data.map(y => y.Day), true, 0);
    }
  }
  /**
     * refresh token and store into redux
     */
  async refreshAccessToken() {
    // Ping ID change: Will add condition for authentication type
   
    if(isPingIDAuth()){
      console.log("parentDashboard -> refreshAccessToken() : "+this.props.auth);
      const accessToken = this.props.auth?.user?.access_token === undefined ? '' : this.props.auth?.user?.access_token;;
      const expiresOn = this.props.auth?.user?.expires_at;

      this.props.addToken(accessToken);
      this.props.addExpiration(expiresOn);

      return accessToken;
    }
    else{
      const { accessToken, expiresOn } = await authProvider.getAccessToken();
      this.props.addToken(accessToken);
      this.props.addExpiration(expiresOn);

      return accessToken;
      
    }
  }

  redirectToMultiCamera = (furnace,furnaceState) => {
    furnaceState = {'State':furnaceState}
    this.props.setCurrentFurnaceId(furnace.id);
    this.props.setFurnaceId(furnace.id);
    this.props.setFurnaceMode(furnaceState)
  }

  redirectToCameraHealth = (furnace,furnaceState) => {
    furnaceState = {'State':furnaceState}
    this.props.setCurrentFurnaceId(furnace.id);
    this.props.setFurnaceId(furnace.id);
    this.props.setFurnaceMode(furnaceState)
  }

  getRTAassetState = async () => {
    const mappedArray: any[] = [];
    const accessToken = await this.refreshAccessToken();

    fetch(`${REACT_APP_APIM_URL_COMPUTE}/rta/getFurnaceState`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.status || data.message) {
          throw Error(data.message);
        } else {
          // map object to array   
          for (const furnace of Object.entries(data)) {
            if (furnace[0] !== "NullTags") {
              mappedArray.push(furnace[1]);
            }
            else {
              //Reset Errors
              store.dispatch({ type: "RESET_ERRORS" });
              const startDateTime = furnace[1] && new Date(new Date(furnace[1][0]).getTime() - (15 * 60000)).toLocaleString();
              const endDateTime = furnace[1] && new Date(furnace[1][0]).toLocaleString();
              furnace[1] && (furnace[1] as Array<string>).shift();
              if ((furnace[1] as Array<String>).length > 0) {
                const error: ErrorI = {
                  date: new Date().toLocaleString(),
                  source: "NULL TAGS",
                  message: `Warning: ${startDateTime} to ${endDateTime} : Following tags are missing data! ${furnace[1]}`, //update
                  errorCode: "NULLTAGS"
                };
                store.dispatch({ type: "ADD_ERROR", payload: { value: error } });
              }
            }
          }
          this.setState({
            assetstate: mappedArray,
          });
          this.props.updateFurnaces(mappedArray);
          this.props.setAssetName(getAssetName(this.props.selectedFurnace));
          return mappedArray;
        }
      })
      .catch((error) => {
        error = {
          message: "The server could not verify that you are authorized to access the URL requested. You either supplied the wrong credentials (e.g. bad password), or your browser doesn't understand how to supply the credentials required.",
        };
        //The server could not verify -> logout and clear localstorage
        if (error && error.message && error.message.indexOf("The server could not verify") >= 0) {
          window.localStorage.clear();
          localforage.clear();
          authProvider.logout();
        }
      });
  };
  
  getSeverity(furnace){

    let furnaceData = {...furnace}
    furnaceData['severity'] = 'no_severity'
    if(furnaceData['severity'] === 'no_severity'){
      if(this.state.furnaceNotificationLog.length){
      this.state.furnaceNotificationLog.map(event => {
        if((parseInt(event['furnace_id']) === parseInt(furnaceData['id']))){
          if(furnaceData['severity'] !== 'high_severity')
          {
            if(event['rule_id'] == 1 || 2 || 3)
            {
              furnaceData['severity'] = 'high_severity'
            }
          }
        }
      })
    }else{
      furnaceData['severity'] = 'high_severity'
    }
    }

    furnaceData['camera_severity'] = 'no_severity'
    if(furnaceData['camera_severity'] === 'no_severity'){
      if(this.state.furnaceNotificationLog.length){
      this.state.furnaceNotificationLog.map(event => {
        if((parseInt(event['furnace_id']) === parseInt(furnace['id']))){
          if(furnaceData['camera_severity'] !== 'high_severity'){
          
          if(event['rule_id'] == 4 || 5 || 6)
          {
            furnaceData['camera_severity'] = 'high_severity'
          }
        }
        }
        })}else{
          furnaceData['camera_severity'] = 'high_severity'
        }
    }

    return furnaceData
  }

  // getTMTMaxAndSeverityData(furnaces) {
  //   furnaces = furnaces.map(furnace => {
  //     this.state.maxTMT.map(TMTDeviation => {
  //       if (parseInt(TMTDeviation.id) === parseInt(furnace.furnace_id)) {
  //         furnace['DisplayName'] = TMTDeviation['DisplayName']
  //         furnace['TMT Max per furnace'] = TMTDeviation['TMT Max per furnace']
  //         furnace['camera_severity'] = 'no_severity'
  //         furnace['severity'] = 'no_severity'

  //         if(furnace['severity'] === 'no_severity'){
  //           this.state.furnaceNotificationLog.map(event => {
  //             if((parseInt(event['furnace_id']) === parseInt(TMTDeviation['id']))){
  //               if(furnace['severity'] !== 'high_severity')
  //               {
  //                 if(event['rule_id'] == 1 || 2 || 3)
  //                 {
  //                   furnace['severity'] = 'high_severity'
  //                 }
  //               }
  //             }
  //           })
  //         }

  //         if(furnace['camera_severity'] === 'no_severity'){
  //           this.state.furnaceNotificationLog.map(event => {
  //             if((parseInt(event['furnace_id']) === parseInt(TMTDeviation['id']))){
  //               if(furnace['camera_severity'] !== 'high_severity'){
                
  //               if(event['rule_id'] == 4 || 5 || 6)
  //               {
  //                 furnace['camera_severity'] = 'high_severity'
  //               }
  //             }
  //             }
  //             })
  //         }
  //       }
  //     })
  //     return furnace;
  //   })
  //   return furnaces
  // }

  getNotificationLogData = async () => {
    let accessToken = this.props.token;
    accessToken = await this.refreshAccessToken();

    fetch(`${REACT_APP_APIM_URL}/thermalimage/getNotificationLog/home`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((res) => res.json())
      .then(async (data) => {
        this.props.setNotificationLog(data)
        this.props.setNotificationButton(true)
      })
      .catch((error) => {
        console.log(error)
      });
  }

  getMaxTMT = async () => {
    this.bind_furnaces = [];
    let accessToken = this.props.token;
    accessToken = await this.refreshAccessToken();

    fetch(`${REACT_APP_APIM_URL}/thermalimage/getMaxTMT`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((res) => res.json())
      .then(async (data) => {

        

        // this.setState({deviationAndMetaData:data})
        this.props.setAssetName(getAssetName(this.props.selectedFurnace));
        // let devdata = data.map(dev => {

        //   this.state.furnaces.map(furnace => {
        //     if (parseInt(dev.id) === parseInt(furnace.id)) {
        //       dev['DisplayName'] = furnace.name
        //     }
            
        //   })
        //   return dev
        // })
        this.setState({ maxTMT: data })
        // this.setState({ maxTMT: [] })

      })
      .catch((error) => {
        this.setState({ maxTMT: [] })
        console.log(error)
      });
  }

  getFurnaceNotificationLog = async () => {
    this.bind_furnaces = [];
    let accessToken = this.props.token;
    accessToken = await this.refreshAccessToken();

    fetch(`${REACT_APP_APIM_URL}/thermalimage/furnaceNotificationLog`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((res) => res.json())
      .then(async (data) => {     
        this.setState({furnaceNotificationLog:data})          
        // this.setState({ furnaceNotificationLog: [] })
      })
      .catch((error) => {
        this.setState({furnaceNotificationLog:[]})
        console.log(error)
      });
  }

  getHomeFurnaceMetrics = async () => {
    this.bind_furnaces = [];
    let accessToken = this.props.token;
    accessToken = await this.refreshAccessToken();

    fetch(`${REACT_APP_APIM_URL}/thermalimage/getHomeFurnaceMetrics`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((res) => res.json())
      .then(async (data) => {     
        this.setState({homeFurnaceMetrics:data})          
        this.props.setFurnaceMetrics(data)
      })
      .catch((error) => {
        this.setState({homeFurnaceMetrics:[]})
        console.log(error)
      });
  }

  getAssetState = async () => {
    const mappedArray: any[] = [];
    let accessToken = this.props.token;
    accessToken = await this.refreshAccessToken();

    fetch(`${REACT_APP_APIM_URL_METADATA}/asset/assetstatus`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.status || data.message) {
          throw Error(data.message);
        } else {
          // map object to array   
          for (const furnace of Object.entries(data)) {
            mappedArray.push(furnace[1]);
          }
          this.setState({
            assetstate: mappedArray,
          });
          this.props.updateFurnaces(mappedArray);
          this.props.setCurrentFeedTypeId(getDefaultFeedTypeId())
          //this.props.setAssetName(getAssetName(this.props.selectedFurnace));
          return mappedArray;
        }
      })
      .catch((error) => {
        error = {
          message: "The server could not verify that you are authorized to access the URL requested. You either supplied the wrong credentials (e.g. bad password), or your browser doesn't understand how to supply the credentials required.",
        };
        console.log(error.message)
        //The server could not verify -> logout and clear localstorage
        // if (error && error.message && error.message.indexOf("The server could not verify") >= 0) {
        //   window.localStorage.clear();
        //   localforage.clear();
        //   authProvider.logout();
        // }
      });
  };

  /**
     * store selected furnace in redux
     * @param id: string
     */
  setFurnaceName(id, displayName, furnace_type_id, feed_type_id) {
    let furnace_id = getFurnaceId(displayName);
    this.props.setCurrentFurnaceId(furnace_id);
    this.props.setFurnaceId(furnace_id);
    this.props.setAssetName(id);
    this.props.setCurrentFurnaceTypeId(furnace_type_id);
    this.props.setCurrentFeedTypeId(feed_type_id);
  }

  convertToDecimal(val) {
    return typeof val == "number" ? val.toFixed(2) : val;
  }

  getFurnaceBgColorByStatus(status: string) {
    let bgColor = '';
    switch (status) {
      case "Cracking":
        {
          bgColor = "rgb(0, 155, 60)";
          break;
        }
      case "Stand-By":
        {
          bgColor = "rgb(0, 110, 42)";
          break;
        }
      case "Stand-By-Cracking":
        {
          bgColor = "rgb(0, 110, 42)";
          break;
        }
      case "Decoking":
        {
          bgColor = "rgb(255, 165, 0)";
          break;
        }
      case "Stand-By-Decoking":
        {
          bgColor = "rgb(180, 117, 0)";
          break;
        }
      case "ERROR":
        {
          bgColor = "rgb(205, 0, 15)";
          break;
        }
      case "Shutdown":
        {
          bgColor = "rgb(59,59,59)";
          break;
        }
      default:
        {
          bgColor = "rgb(207, 207, 205)";
          break;
        }
    }
    return bgColor;
  }

  render() {
    if(!isTMTCameraDeviation()){
      this.bind_furnaces = [];
      const assetNamesbyPlantId = getFurnaceReferenceAssetNames(this.props.currentPlantId);
      this.bind_furnaces = this.state.assetstate.filter(furnace => assetNamesbyPlantId.includes(furnace.id));
    }
    const {maxTMT,furnaceNotificationLog,homeFurnaceMetrics} = this.state

    const selector = (
      <div className="control-header element" style={{ display: "flex" }}>
        <PlantSelector disabled={this.state.plants.length === 1 ? true : false} />
      </div>);
    return (
      <div>
        {selector}
        {(!isTMTCameraDeviation() && !this.bind_furnaces.length) ? (
          <div className="loader-grid-parent">
            <div className="loader-grid">
              <Loader />
              <p> Loading...</p>
            </div>
          </div>
        ) : (
          <div className="furnace-wrapper">

            <div className="furnace-item furnace-item-head">
              {(this.props.accessibleFeatures.includes("HOME_FURNACE_INFO")) &&
                <div className="furnace-item-defintion">
                  {
                    isTMTCameraDeviation() && this.displayTMTData.map((item) => {
                      return (
                        <div key={item.key}>
                          {item.key} <span className="unit">({item.unit})</span>
                        </div>
                      )
                    })
                  }
                  {
                    this.displayData.map((item) => {
                      return (
                        <div key={item.key}>
                          {item.key} <span className="unit">({item.unit})</span>
                        </div>
                      )
                    })
                  }
                </div>
              }
              {isTMTCameraDeviation() ?
                <div>
                  <div className="furnace-item-state">Furnace Mode</div>

                  <div className="furnace-item-defintion">
                    <div className="tmt_dev">TMT Deviation Status</div>
                  </div>

                  <div className="furnace-item-defintion">
                    <div className="tmt_dev">Thermal Camera Status</div>
                  </div>
                </div>
                : <div className="furnace-item-state"></div>}

              <div className="furnace-item-icon"></div>

              <div className="furnace-item-button furnace-item-button_definition">
                <div className="furnace-item-button-item">Furnace Number</div>
              </div>
            </div>

            {(isTMTCameraDeviation() ? this.state.furnaces : this.bind_furnaces).map((furnace) => {
              let status = ''
              let maxTMT:any,furnaceMetrics:any = []
              let crackingMode = ''
              let furnaceState = ''
              let furnaceSeverity = ''
              let maxTMTAvaialble = false
              let furnaceMetricsAvailable = false
              if(!isTMTCameraDeviation()){

                if (!furnace["State"]) return <div style={{ fontSize: "40px", fontWeight: "bolder" }}>Please refresh the app.</div>;
                status = furnace["State"].replace(" ", "-")
              }else{
                maxTMT = this.state.maxTMT.filter((tmt) => tmt.id === furnace.id)
                furnaceMetrics = this.state.homeFurnaceMetrics.filter((metrics) => parseInt(metrics['furnace_id']) === furnace.id)
                furnaceMetricsAvailable = furnaceMetrics && furnaceMetrics.length
                status = furnaceMetricsAvailable && furnaceMetrics[0]['furnace_mode']
                crackingMode = furnaceMetricsAvailable && furnaceMetrics[0]["Cracking Mode"]
                furnaceState = furnaceMetricsAvailable && furnaceMetrics[0]["State"]
                furnaceSeverity = this.getSeverity(furnace)
                maxTMTAvaialble = maxTMT && maxTMT.length
                
              }

              return (
                <div className="furnace-item furnace-item-body" style={{ marginRight: '50px' }} key={furnace.id}>
                  {isRTA() ? <>
                    <span className="furnace-item-defintion-value "> I</span>
                    <span className="furnace-item-defintion-value "> II</span>
                  </> : <></>}
                  {(this.props.accessibleFeatures.includes("HOME_FURNACE_INFO")) &&
                    <div className={isRTA() ? "furnace-item-defintion no-margin" : "furnace-item-defintion"} key={furnace.id}>
                      {
                        isTMTCameraDeviation() && this.displayTMTData.map((item, index) => {
                          return(
                          <div key={item.key + "-" + furnace.id + "-" + index}>
                              <span key={item.key + "-" + furnace.id + "-" + index} className={ maxTMTAvaialble ? "furnace-item-defintion-value" : "furnace-item-defintion-value placeholder-text"}> {maxTMTAvaialble ? maxTMT[0][item.key] : 'N/A'}</span>
                          </div>
                          )
                        })
                      }
                      {this.displayData.map((item, index) => {
                          return (
                            <div key={item.key + "-" + furnace.id + "-" + index}>
                              {isRTA() ?
                                <><span key={item.key + "-" + furnace.id + "-" + index + "-0"} className="furnace-item-defintion-value "> {furnace[item.key + "0"] ? (Number(furnace[item.key + "0"]) ? Number(furnace[item.key + "0"]).toFixed(2) : furnace[item.key + "0"]) : 'N/A'}</span>
                                  <span key={item.key + "-" + furnace.id + "-" + index + "-1"} className="furnace-item-defintion-value "> {furnace[item.key + "1"] ? (Number(furnace[item.key + "1"]) ? Number(furnace[item.key + "1"]).toFixed(2) : furnace[item.key + "1"]) : 'N/A'}</span>
                                </>
                                : isTMTCameraDeviation() ? <span key={item.key + "-" + furnace.id + "-" + index} className={ furnaceMetricsAvailable ? "furnace-item-defintion-value" : "furnace-item-defintion-value placeholder-text"}> {furnaceMetricsAvailable ? ParseTemperatureValue(furnaceMetrics[0][item.key]) : 'N/A'}</span> : <span key={item.key + "-" + furnace.id + "-" + index} className="furnace-item-defintion-value"> {furnace[item.key] ? furnace[item.key] : 'N/A'}</span> }
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                  <div className={"furnace-item-state furnace-item-state_" + status}>
                  {isTMTCameraDeviation() ? <span className={"furnace-item-state-label"}>{furnaceState ? furnaceState : "n/a"}</span> : <span className="furnace-item-state-label">{furnace["State"] ? furnace["State"] : "n/a"}</span>}
                  {isTMTCameraDeviation() ?  <span className="furnace-item-state-mode">
                      {!crackingMode || crackingMode === "N/A" ?
                        null : status === "Stand-By-Cracking" ? "" : crackingMode}
                    </span> :
                    <span className="furnace-item-state-mode">
                      {!furnace["Cracking Mode"] || furnace["Cracking Mode"] === "N/A" ?
                        null : status === "Stand-By-Cracking" ? "" : furnace["Cracking Mode"]}
                    </span>}
                  </div>
                  {isTMTCameraDeviation() &&
                    <div>
                      <div>
                          <Link className={"camera-severity furnace-item-tmt furnace-item-defintion-body furnace-item-state furnace-item-state_" + furnaceSeverity['severity']} onClick={() => this.redirectToMultiCamera(furnace,furnaceState)} to={'/multi-camera'}> {(furnaceSeverity['severity']) === TMTCameraDeviation.High_Severity ? TMTCameraDeviation.Deviation : TMTCameraDeviation.Normal} </Link>
                          
                        <div className="furnace-underline"></div>
                      </div>

                      <div>
                        <Link className={"camera-severity furnace-item-defintion-body furnace-item-state furnace-item-state_" + furnaceSeverity['camera_severity']} onClick={() => this.redirectToCameraHealth(furnace,furnaceState)} to={'/camera-health'}> {(furnaceSeverity['camera_severity']) === TMTCameraDeviation.High_Severity ? TMTCameraDeviation.Deviation : TMTCameraDeviation.Normal} </Link>
                        <div className="furnace-underline"></div>
                      </div>
                    </div>
                  }
                  <div className="furnace-item-icon">
                    <svg viewBox="0 0 370 440" enableBackground="new 0 0 370 440;" x="0px" y="0px">
                      <polygon
                        className="st0"
                        fill={
                          this.getFurnaceBgColorByStatus(status)
                        }
                        points="263.4,215 263.4,9.1 106.1,9.1 106.1,215 8.2,215 8.2,430.9 106.1,430.9 106.1,360 263.4,
                          360 263.4,430.9 361.8,430.9 361.8,215"
                      />
                      {isRTA() ? <>
                        <text x="50" y="350" text-anchor="middle" fill="white" fontSize="60">I</text>
                        <text x="310" y="350" text-anchor="middle" fill="white" fontSize="60">II</text>
                      </>
                        : <></>}
                    </svg>
                  </div>
                  <div className={this.props.accessibleFeatures.includes('FURNACE_OVERVIEW') ? "furnace-item-button" : "furnace-item-button furnace-button-disable"}>
                    <NavLink
                      to={this.props.accessibleFeatures.includes('FURNACE_OVERVIEW') ? "/furnace-overview" : ""}
                      onClick={this.setFurnaceName.bind(this, furnace.id, furnace.DisplayName, furnace.Furnace_type_id, furnace.Feed_type_id)}
                      className="button furnace-item-button-item">
                      {isTMTCameraDeviation() ? furnace.name : furnace.DisplayName}
                    </NavLink>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withAuth(connector(withRouter(ParentDashboard)));