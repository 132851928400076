import { ROWCOLType } from '../../interfaces/IImportExport';
import { generateCoilDynamicsData, generateDetailTimechartData } from '../../utilities/coilModelShapers'
import { getPlantReferenceById, getFurnaceReferenceName, getTemperatureUnit, temperatureUnitConversion } from "../../configuration";
import { MODE } from '../../interfaces/IScenario';
import store from '../..';



interface ICG {
    plants: any,
    furnaces: any,
    scenarios: any[]
};

/*This function creates an object for all prop. which needed to be exported
  getCalcResult => [{
      furnaceId: 2
      plantId: XYZ
      results: [{…}]
  },...]  

  where results is of type:
  results: [{
  config:{}
  cokethickness: (34) [{…}, ...]
  data: (6) [{…}, ...]
  id: 0
  name: "config3"
  scenarioName: "Scenario3"
  },...]
*/

export function getCalcResult(scenarioRunlengths) {
    const selectedScenario = 0
    var setFurnaceIds = new Set(scenarioRunlengths.map(run => run.furnaceId).sort());
    let sortedCalcsByFurnaces: any[] = []
    setFurnaceIds.forEach(fid => sortedCalcsByFurnaces.push(scenarioRunlengths.filter(run => run.furnaceId === fid)))

    // scenarioRunlengths.filter(run => run.furnaceId == fid)
    const config: string[] = ['fuelGas_id', 'feedstock_id', 'convectionsection_id', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX']

    let CalcResults: any[] = []
    const getScenerios = ((fworker, i) => {
        let CalcScenarios: any[] = [];
        fworker.forEach((scenarios, wid) => {
            if (scenarios.isExportable) {
                let scenarioConfig: any = {};
                let thicknessProfile: any[] = [];
                let scenario = scenarios.scenarios[0]
                //read configuration
                config.forEach((itm, i) => scenarioConfig[itm] = scenario[itm])
                //!!!!scenarioConfig['feedstock_id'] = scenario['feedstock_name']

                //read cokethickness
                scenario.CALCULATIONS.forEach((val, i) => {
                    //item is {output_dict , trans_dict}
                    let runtime = val.output_dict.RUNLENGTH[0][0];
                    thicknessProfile.push(
                        {
                            runlength: runtime,
                            coillength: generateDetailTimechartData(
                                "COKETHICKNESS",
                                scenarios.scenarios,
                                selectedScenario,
                                i,
                                false
                            ).data
                        }
                    )

                });

                CalcScenarios.push({
                    scenarioName: scenarios.name, config: [scenarioConfig], cokethickness: thicknessProfile,
                    ...generateCoilDynamicsData(scenarios.scenarios, selectedScenario, 0)

                })
            }
        })

        if (CalcScenarios.length > 0)
            CalcResults.push({
                furnaceId: getFurnaceReferenceName(fworker[0].plantId, fworker[0].furnaceId),//getAssetName(run[0].furnaceId),
                plantId: getPlantReferenceById(fworker[0].plantId)['name'],
                results: CalcScenarios
            });
    });

    sortedCalcsByFurnaces.forEach(getScenerios);

    return CalcResults


}

export function getCalcResultFromObj(session, scenarioRunlengths) {

    const selectedScenario = 0
    const configSession: string[] = ['FuelGas_Ref', 'Feedstock_Ref', 'convectionsection_Ref', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX']
    const config: string[] = ['fuelGas_id', 'feedstock_id', 'convectionsection_id', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX']
    var setFurnaceIds = new Set(session.Session.Scenarios.map(scenario => scenario.Furnace_Ref).sort());

    let CalcResults: any[] = []

    setFurnaceIds.forEach(fid => {
        let fworker = session.Session.Scenarios.filter(scenario => scenario.Furnace_Ref === fid && !scenario.Config_Ref.includes("Realtime Advisor"))



        let results: any[] = []
        fworker.forEach((scenario, i) => {
            let thicknessProfile: any[] = [];
            let twallouter_maxProfile: any[] = [];
            let scenarioConfigList: any = [];
            let scenarioConfig: any = {};
            let scenarioConfigF2: any = {};
            //read configuration
            config.forEach((itm, k) => {
                scenarioConfig[itm] = scenario[configSession[k]]
            })
            scenarioConfigList.push(scenarioConfig)

            let runlength = scenarioRunlengths.find(run => run.name === scenario['Scenario'])
            //read CD-Data
            let cd_data = generateCoilDynamicsData(runlength.scenarios, selectedScenario, 0)

            // runlength.scenarios[0].CALCULATIONS.forEach((val, i) => {
            if (runlength.scenarios[0].mode === MODE.HYBRID) {
                config.forEach((itm, k) => {
                    scenarioConfigF2[itm] = scenario.scenarioConfigFirebox1[configSession[k]]
                })
                scenarioConfigList.push(scenarioConfigF2)

                let cd_data_firbox2 = generateCoilDynamicsData(runlength.scenarios, selectedScenario, 1)
                cd_data_firbox2.data.forEach(c => {
                    cd_data.data.push(c);
                })
            }
            runlength.scenarios[0].firebox[0].CALCULATIONS.forEach((val, i) => {
                //item is {output_dict , trans_dict}
                let runtime = val.output_dict.RUNLENGTH[0][0];
                //read cokethickness
                thicknessProfile.push({
                    runlength: runtime,
                    coillength: generateDetailTimechartData(
                        "COKETHICKNESS",
                        runlength.scenarios,
                        selectedScenario,
                        i,
                        false
                    ).data,

                });

                twallouter_maxProfile.push(
                    {
                        runlength: runtime,
                        coillength: generateDetailTimechartData(
                            "TWALLOUTER_MAX",
                            runlength.scenarios,
                            selectedScenario,
                            i,
                            false
                        ).data,

                    });


            });
            if (runlength.scenarios[0].mode === MODE.HYBRID) {
                runlength.scenarios[0].firebox[1].CALCULATIONS.forEach((val, i) => {
                    //item is {output_dict , trans_dict}
                    let runtime = val.output_dict.RUNLENGTH[0][0];
                    //read cokethickness
                    thicknessProfile.push({
                        runlength: runtime,
                        coillength: generateDetailTimechartData(
                            "COKETHICKNESS",
                            runlength.scenarios,
                            selectedScenario,
                            i,
                            false
                        ).data,

                    });

                    twallouter_maxProfile.push(
                        {
                            runlength: runtime,
                            coillength: generateDetailTimechartData(
                                "TWALLOUTER_MAX",
                                runlength.scenarios,
                                selectedScenario,
                                i,
                                false
                            ).data,

                        });


                });
            }
            if (cd_data.data.length > 0)
                results.push({
                    scenarioName: scenario['Scenario'],
                    twallouter: twallouter_maxProfile,
                    //name: scenario['Config_Ref'],
                    cokethickness: thicknessProfile,
                    config: scenarioConfigList,
                    ...cd_data
                });

            //sortedCalcsByFurnaces.push(obj);
        });

        let obj = {
            furnaceId: fworker[0].Furnace_Ref,
            plantId: getPlantReferenceById(fworker[0].Plant_Id)['name'],
            results: results
        }

        CalcResults.push(obj)


    })

    return CalcResults


}


function createEmtpyHeaderField(numRows, numCol) {
    return Array.from(Array(numRows), () => new Array(numCol).fill(''));
}

function mapAliases(config) {
    config.tags.forEach((key, i) => {
        config.map[key] = config.alias[i]
    })
}

//AOO to AOA
function ArrayOfObjToArrayOfArray(AOO: {}[], headerOrder: string[]) {
    var result: any[] = [];
    // var header: string[] = Object.keys(AOO[0]);

    /*if (headerOrder.length > 0)
        var Indexes = headerOrder.map(h => header.findIndex((elem) => elem === h))*/

    AOO.forEach(o => {
        var ordered: any = []
        //var values = Object.values(o);
        for (let h of headerOrder) {
            let isNumber = /^-?[\d.]+(?:e-?\d+)?$/.test(o[h]);
            if (isNumber)
                ordered.push(parseFloat(o[h]))
            else
                ordered.push(o[h])
        }

        result.push(ordered)

    })

    return result;
}

function fillArray(arr, value, len) {
    //var arr = [];
    for (var i = 0; i < len; i++) {
        arr.push(value);
    }
    return arr;
}

export function prepareFeedstock(session) {
    let aoa_list: any[] = []
    let feedStockSet = new Set();

    let sp_feedstock = session.Session.Feedstocks.filter(feedstock => feedstock.Type === "SHORT_PIONA");
    let dp_feedstock = session.Session.Feedstocks.filter(feedstock => feedstock.Type === "DETAILED_PIONA");
    let dc_feedstock = session.Session.Feedstocks.filter(feedstock => feedstock.Type === "DISCRETE_COMPOSITION");
    let sp = prepare_shortPiona(sp_feedstock, feedStockSet);
    let dp = prepare_detailedPiona(dp_feedstock, feedStockSet);
    let dc = prepare_discretComp(dc_feedstock, feedStockSet);
    if (sp !== null) aoa_list.push(sp);
    if (dp !== null) aoa_list.push(dp);
    if (dc !== null) aoa_list.push(dc);

    return aoa_list;
}

function prepare_shortPiona(sp_feedstock: any, feedStockSet: Set<unknown>) {
    let aoa: any[] = [];
    let tags = ["name", "value"]
    let sheetName = 'Feedstock SP';
    sp_feedstock.forEach((feedstock, i) => {
        let aoa_partial: any[] = [];

        let components = feedstock.Components;



        if (feedstock.Type === "SHORT_PIONA" && !feedStockSet.has(feedstock.Name)) {

            aoa_partial = ArrayOfObjToArrayOfArray(components, tags);
            let aoa2 = ArrayOfObjToArrayOfArray(feedstock.Method.distribution, ["id", "value"]);
            fillArray(aoa_partial, ["", ""], Math.abs(aoa_partial.length - aoa2.length));
            aoa_partial.forEach((v, i) => { v.push(...aoa2[i]); });

            let headerWithUnits = [...tags];
            headerWithUnits[0] = "Components";
            headerWithUnits[1] = "Composition [" + feedstock.Unit + "]";
            headerWithUnits[2] = "Value [" + feedstock.Method.idUnit + "]";
            headerWithUnits[3] = "BP [" + feedstock.Method.valueUnit + "]";

            aoa_partial.unshift(headerWithUnits);
            aoa_partial.unshift(['Method', feedstock.Method.name, '', '']);
            aoa_partial.unshift(['Ref. Name', feedstock.Name, '', '']);

            if (aoa.length > 0) {
                aoa.forEach((v, i) => { v.push(""); v.push(...aoa_partial[i]); });
            }
            else
                aoa = aoa_partial;

            feedStockSet.add(feedstock.Name);

        }

    });
    return aoa.length > 0 ? {
        sheetName: sheetName,
        data: aoa,
        status: ""
    } : null
}


function prepare_detailedPiona(dp_feedstock: any, feedStockSet: Set<unknown>) {
    let aoa: any[] = [];
    let sheetName = 'Feedstock DP';
    dp_feedstock.forEach((feedstock, i) => {
        let aoa_partial: any[] = [];
        // session.Session.Feedstocks.forEach((feedstock, i) => {

        let components = feedstock.Components;

        if (feedstock.Type === "DETAILED_PIONA" && !feedStockSet.has(feedstock.Name)) {
            let tags = ["name", "Aromatics", "I_Paraffins", "N_Paraffins", "Naphthenes", "Olefins"]

            aoa_partial = ArrayOfObjToArrayOfArray(components, tags);



            let header = [...tags];
            header[0] = "Components";
            header[1] = header[1] + " [" + feedstock.Unit + "]";
            header[2] = header[2] + " [" + feedstock.Unit + "]";
            header[3] = header[3] + " [" + feedstock.Unit + "]";
            header[4] = header[4] + " [" + feedstock.Unit + "]";
            header[5] = header[5] + " [" + feedstock.Unit + "]";


            aoa_partial.unshift(header);
            aoa_partial.unshift(['Ref. Name', feedstock.Name, '', '', '', '']);

            if (aoa.length > 0) {
                aoa.forEach((v, i) => { v.push(""); v.push(...aoa_partial[i]); });
            }
            else
                aoa = aoa_partial;



            feedStockSet.add(feedstock.Name);

        }

    });
    return aoa.length > 0 ? {
        sheetName: sheetName,
        data: aoa,
        status: ""
    } : null
}

function prepare_discretComp(dc_feedstock: any, feedStockSet: Set<unknown>) {
    let aoa: any[] = [];
    let sheetName = 'Feedstock DC';
    let tags = ['name', 'molecular_Weight', 'value']
    dc_feedstock.forEach((feedstock, i) => {
        let aoa_partial: any[] = [];
        let components = feedstock.Components;

        //set first coloum and header
        if (i === 0) {

            if (feedstock.Type === "DISCRETE_COMPOSITION") {
                aoa_partial = ArrayOfObjToArrayOfArray(components, tags);
                let headerWithUnits = [...tags];
                headerWithUnits[0] = "Components";
                headerWithUnits[1] = "Molecular Weight [g/mol]";
                headerWithUnits[2] = "Value [" + feedstock.Unit + "]";
                aoa_partial.unshift(headerWithUnits);
                aoa_partial.unshift(['Ref. Name', '', feedstock.Name]);
                aoa = aoa_partial;
            }
            feedStockSet.add(feedstock.Name);

        }
        else {

            if (!feedStockSet.has(feedstock.Name)) {
                aoa_partial = ArrayOfObjToArrayOfArray(components, [tags[2]]);
                let headerWithUnits = [...tags]
                headerWithUnits[2] = "Value [" + feedstock.Unit + "]"
                aoa_partial.unshift([headerWithUnits[2]])
                aoa_partial.unshift([feedstock.Name])

                aoa = aoa.map((elm, i) => [...elm, ...aoa_partial[i]])
                feedStockSet.add(feedstock.Name)
            }


        }
    });
    return aoa.length > 0 ? {
        sheetName: sheetName,
        data: aoa,
        status: ""
    } : null
}

export function prepareFuelGas(session, header) {
    let aoa: any[] = []
    let fuelgasSet = new Set();

    session.Session.FuelGases.forEach((fuelgas, i) => {
        let aoa_partial: any[] = []
        let components = fuelgas.Components;

        if (i === 0) {
            aoa_partial = ArrayOfObjToArrayOfArray(components, header);
            let headerWithUnits = [...header]
            headerWithUnits[0] = "Components"
            headerWithUnits[1] = "Molecular Weight [g/mol]"
            headerWithUnits[2] = "Value [" + fuelgas.Unit + "]"
            aoa_partial.unshift(headerWithUnits)
            aoa_partial.unshift(['Ref. Name', '', fuelgas.Name])

            aoa = aoa_partial;
            fuelgasSet.add(fuelgas.Name)

        }
        else {

            if (!fuelgasSet.has(fuelgas.Name)) {
                aoa_partial = ArrayOfObjToArrayOfArray(components, [header[2]]);
                let headerWithUnits = [...header]
                headerWithUnits[2] = "Value [" + fuelgas.Unit + "]"
                aoa_partial.unshift([headerWithUnits[2]])
                aoa_partial.unshift([fuelgas.Name])

                aoa = aoa.map((elm, i) => [...elm, ...aoa_partial[i]])
                fuelgasSet.add(fuelgas.Name)
            }


        }


    })



    return {
        sheetName: 'Fuelgas',//'Cracked Gas',
        data: aoa,
        status: ""
    }
}

function upDateHeadersWithAliases(config) {
    config.headers.forEach(h => h[1].forEach((t, i) => {
        if (config.map[t] !== undefined)
            h[1][i] = config['map'][t]
    }))
}

function check_wsData(ws_data: any[], sheet: any, prevCheck: boolean = true, checkLength: boolean = true, errorMsg: string = "Error: " + sheet + "-File preparation failed") {

    let check = true;
    if (checkLength) {
        check = ws_data.every((arr, i) => {
            if (arr.length > 0) {
                if (i > 0 && ws_data[i - 1].length !== arr.length)
                    return false;
                return true;
            }

            else
                return false;
        });
    }

    return (check && prevCheck) ? {
        sheetName: sheet,
        data: ws_data,
        status: ""
    } :
        {
            sheetName: sheet,
            data: null,
            status: errorMsg
        };
}

export async function prepareCG(state: any) {
    const { furnaces, scenarios }: ICG = await state.exportData.getCG();
    var ws_data: any[] = [];
    var cg_headers: any[] = [];
    var scenario_headers: any[] = [];
    var scenario_headers_val: any[] = [];
    var firebox_headers: any[] = [];
    var firebox_headers_val: any[] = [];

    //Order Scenarios by Furnaces
    let scenarioOrdered = [...furnaces].map((fname, fid) => {
        return scenarios.filter(s => s.furnace === fname && !s.scenario.includes("Realtime Advisor"));
    }).flat();

    //Variables used to remove duplicated coloums from scenario results with same funace number
    let prevPlant = "";
    // let prevFurnace = "";
    // let reduceHeader = true;
    //create for all scenarios an array with header and cg-result
    let cg_header: string[];
    let check = scenarioOrdered.every((scenario, fid) => {
        if (scenario['cg'] == null)
            return false;

        //prepare header
        let sc_header = Object.keys(scenario);
        sc_header.splice(-2); //remove cg

        let currentFurnace = scenario.furnace;//getFurnaceReferenceName(scenario.plant,scenario.furnace);
        let currentPlant = getPlantReferenceById(scenario.plant)['name'];
        let sc_header_val = [currentPlant, currentFurnace, scenario.scenario];


        if (prevPlant !== currentPlant) {
            cg_header = [...Object.keys(scenario['cg'][0])];
            cg_header.splice(2, 0, "Unit");


        }
        else {
            cg_header = [...Object.keys(scenario['cg'][0])];

        }
        if (fid > 0) {
            cg_header = cg_header.slice(2);
            sc_header = sc_header.slice(2);
            sc_header_val = sc_header_val.slice(2);
            // reduceHeader = true;
        }

        let N = cg_header.length - sc_header.length;
        sc_header = sc_header.concat(Array.from({ length: N }, _ => ''));


        ws_data = scenario.cg.map((insert2Row, idx) => {
            let row: any = ws_data.length > 0 ? ws_data[idx] : [];
            let col: any = [];

            let newRowEntry: any[] = Object.values(insert2Row);

            if (fid === 0) {
                newRowEntry.splice(2, 0, store?.getState()?.crackedGas?.molPerc);
            }
            if (fid > 0) {
                newRowEntry = newRowEntry.slice(2);
            }

            row = row.concat(newRowEntry);
            col.push(row.flat());
            return col;

        });


        cg_headers.push(cg_header);
        scenario_headers.push(sc_header);

        scenario_headers_val.push(sc_header_val.concat(Array.from({ length: N }, _ => '')));
        if (fid === 0 && scenario.mode === MODE.HYBRID) {
            let f1Index = cg_headers[0].findIndex(x => x === "0 [d] ");
            firebox_headers = ['', '', '', 'Firebox 0'];
            firebox_headers = firebox_headers.concat(Array.from({ length: Number(f1Index) - 4 }, _ => ''));
            let f1_header = 'Firebox 1';
            firebox_headers.push(f1_header);
        }
        else if (scenario.mode === MODE.HYBRID) {
            firebox_headers = ['', '', '', 'Firebox 0'];
            let f1Index = cg_headers[1].findIndex(x => x === "0 [d] ");
            firebox_headers = firebox_headers.concat(Array.from({ length: Number(f1Index) - 1 }, _ => ''));
            let f1_header = 'Firebox 1';
            firebox_headers.push(f1_header);
        }
        else {
            firebox_headers = [];
        }
        firebox_headers_val.push(firebox_headers.concat(Array.from({ length: N }, _ => '')));
        ws_data = ws_data.flat();

        // assign merges to sheet
        // https://stackoverflow.com/questions/53516403/sheetjs-xlsx-how-to-write-merged-cells
        /*const ids = [...new Set(prep.map(obj => obj.id))];
        const merges = ids.reduce((acc, curr, idx) => {
          acc.push({
            s: {r: 0, c: 1 + (2 *idx)},
            e: {r: 0, c: 1 + (2 *idx) + 1}
          });
          return acc;
        }, []);
        ws["!merges"] = merges;*/
        prevPlant = currentPlant;
        // prevFurnace = currentFurnace;
        return true;

    });


    if (check) {
        // prepend the headers        
        ws_data.unshift(cg_headers.flat());
        ws_data.unshift(firebox_headers_val.flat());
        ws_data.unshift(scenario_headers_val.flat());
        ws_data.unshift(scenario_headers.flat());

        /*  return {
              sheetName: 'CG',//'Cracked Gas',
              data: ws_data,
              status: ""
          }*/
    }
    /*else return {
        sheetName: 'Cracked Gas',
        data: null,
        status: "Error: CG-File preparation failed"
    }*/

    return check_wsData(ws_data, 'CG', check, false)
}


export async function prepareCOT(config) {

    var ws_data: any[] = [];
    var AOAs: any;

    //const { plants, furnaces, scenarios }: ICG = await state.exportData.getXYItems(['RUNLENGTH','COT']);
    const data: any = await config.getXYItemsbyType(config.tags, config.types);

    //Variables used to remove duplicated coloums from scenario results with same funace number
    // let prevPlant = "";
    // let prevFurnace = "";

    //Order Scenarios by Furnaces
    let scenarioOrdered = Array.from(data.furnaces).map((fname) => {
        return data.scenarios.filter(s => s.furnace === fname);
    }).flat();

    let scenarioColLength: number[] = [];
    let scenarioColInfo: any[] = [];


    let check = scenarioOrdered.every((scenario, sId) => {
        if (scenario.values == null)
            return false;

        // let currentFurnace = scenario.furnace;
        // let currentPlant = getPlantReferenceById(scenario.plant)['name'];

        let newColTag = scenario['order'].concat(Array.from({ length: 1 }, _ => ''));

        scenarioColInfo.push(...newColTag);

        ws_data = scenario.values.map((insert2Row, idx) => {
            let row: any = ws_data.length > 0 ? ws_data[idx] : [];
            let col: any = [];

            //copy last element
            //insert2Row.push(insert2Row.at(-1))
            let newRowEntry: any[] = insert2Row.concat(Array.from({ length: 1 }, _ => ''));
            scenarioColLength.push(newRowEntry.length)


            /* if (sId === 0) {
                 newRowEntry.splice(2, 0, "Wt%");
             }
             if (sId > 0) {
                 newRowEntry = newRowEntry.slice(2);
             }*/

            row = row.concat(newRowEntry);
            col.push(row.flat());
            return col;

        });

        ws_data = ws_data.flat();


        // prevPlant = currentPlant;
        // prevFurnace = currentFurnace;
        return true;
    });

    // ({ cg_headers, cg_header, scenario_headers, sc_header, scenario_headers_val, sc_header_val } = 
    check = setHeader(config, scenarioOrdered, AOAs, scenarioColLength, ws_data);

    return check_wsData(ws_data, config.sheet, check, false)
}


export async function prepareSheetFromObj(config, calcResults, data = 'data') {
    let sheet = config.sheet;
    // let tags = config.tags;
    // let types = config.types;
    let transposed = config.transposed;
    var ws_data: any[] = [];
    ws_data.length = 0;

    mapAliases(config);
    upDateHeadersWithAliases(config);
    //replace furnaceid by Assetname --> getAssetName(id)

    let cases: any[] = [];
    calcResults.forEach((fworker, wIdx) => {
        // var value = ''; // by default
        //Loop over all scenarios and find the one with max entries (Runlength)
        // let res= calc.results.map((sc, idx) => sc.data.length)
        // let maxLength= Math.max(...res);

        let runTimeData: any[] = [];

        fworker.results.forEach((rcase, rIdx) => {

            let isNested = config.tags.some(v => v.indexOf('.') > -1)
            //Add Header
            let maxColLength = rcase[data].length + 2
            let maxRowLength = config.headers[0][1].length
            if (isNested) {
                let path = config.refTag.split(".")
                let numRow = rcase[data][0][path[0]].length

                maxRowLength = maxRowLength + numRow + 2
                // maxColLength = maxColLength//rIdx > 0 ? maxColLength +1 :  maxColLength
            }



            var grid = [...Array(maxRowLength)].map(e => Array(maxColLength));
            let startY = 4;
            let startX = 2;

            if (wIdx === 0 && rIdx === 0) {
                config.headers.forEach(header => addCells2Grid(header, grid, true))
                startY = 4;
                startX = 2;
            }

            //Add Scenario Info
            // let caseId = [[0, startX], [fworker.plantId, fworker.furnaceId, rcase.scenarioName], true]
            // addCells2Grid(caseId, grid, true);
            let hybridIndex = -1;

            for (let i = 0; i < rcase.data.length - 1; i++) {
                if (rcase.data[i].runtime > rcase.data[i + 1].runtime) {
                    hybridIndex = i;
                    break;
                }
            }
            //Add Data Coloum
            if (isNested) {
                config.tags.forEach((tag, i) => {
                    let path = tag.split(".")
                    let line = [""];
                    if (path.length === 2) {
                        //loop over reference tag e.g coillength and set pos in Col A1:A#
                        if (tag === config.refTag && (wIdx === 0 && rIdx === 0)) {
                            let a = rcase[data][0]

                            line = a[path[0]].map(b => b[path[1]])
                            line = modifyValues(line, config, i);
                            line.unshift(config.map[config.refTag])
                            let newinsert = [[4, 0], line, config.types[i] === ROWCOLType.VarCol]
                            addCells2Grid(newinsert, grid, true);
                        }
                        else {

                            startX = 2;
                            //loop over data
                            rcase[data].forEach((a, t) => {
                                line = a[path[0]].map(b => b[path[1]])
                                line = modifyValues(line, config, i);
                                line.unshift(a['runlength'] + '[d]')
                                let newinsert = [[startY, startX + t], line, config.types[i] === ROWCOLType.VarCol]
                                addCells2Grid(newinsert, grid, true);


                            });
                            //startY = 3;
                            //startX = 1;

                        }

                    }





                });
            }

            else {
                config.tags.forEach((tag, i) => {
                    let line = rcase[data].map(a => a[tag]);

                    line = modifyValues(line, config, i);
                    let newinsert = [[startY + i, startX], line, config.types[i] === ROWCOLType.VarCol]
                    runTimeData.push(newinsert)

                    addCells2Grid(newinsert, grid, true);
                });
            }
            if (hybridIndex > 0) {
                let caseId = [[0, startX], [fworker.plantId, fworker.furnaceId, rcase.scenarioName, 'Firebox 0'], true]
                addCells2Grid(caseId, grid, true);
                if (transposed || sheet === 'CD' || sheet === "Cokethickness") {
                    let fb2index = Number(startX) + Number(hybridIndex) + 1
                    let caseId2 = [[0, fb2index], ['', '', '', 'Firebox 1'], true]
                    addCells2Grid(caseId2, grid, true);
                }
                else {
                    let caseId3 = [[3, Number(startX) + 1], ['Firebox 1'], true]
                    addCells2Grid(caseId3, grid, true);
                }
            }
            else {
                let caseId = [[0, startX], [fworker.plantId, fworker.furnaceId, rcase.scenarioName], true]
                addCells2Grid(caseId, grid, true);
            }
            cases.push(grid)
        })


    })

    cases.forEach((c, i) => {
        if (i === 0)
            ws_data = [...ws_data, ...c]
        else {

            c.forEach((el, i) => {
                ws_data[i] = [...ws_data[i], ...el]
            })

        }
    })

    //Check if all rows have the same length
    return check_wsData(ws_data, sheet)

}


export async function prepareConvectionSection(config, session) {
    let sheet = config.sheet;
    // let tags = config.tags;
    // let types = config.types;

    var ws_data: any[] = [];
    ws_data.length = 0;

    mapAliases(config);
    upDateHeadersWithAliases(config);
    let maxColLength = 10//fgrp[data].length + 2
    let maxRowLength = config.headers[0][1].length
    var grid = [...Array(maxRowLength)].map(e => Array(maxColLength));
    // let startY = 4;
    // let startX = 2;
    //replace furnaceid by Assetname --> getAssetName(id)


    let cases: any[] = [];
    // let EmptyHeader = ["", "", "", "", ""];
    //["Process Media","Process Value","Unit","Default"]["FF7CONV(PUBLIC)"]
    let CS_Session = [
        {
            "Name": "11-FF-101-CONV(PUBLIC)",
            "Components": [
                {
                    "Name": "BFW_TEMPERATURE",
                    "Value": temperatureUnitConversion(113),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "BFW_PRESSURE",
                    "Value": 118.99,
                    "Unit": "bar g"
                },
                {
                    "Name": "HP_STEAM_TEMPERATURE",
                    "Value": temperatureUnitConversion(505),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "HP_STEAM_PRESSURE",
                    "Value": 106.99,
                    "Unit": "bar g"
                },
                {
                    "Name": "FEED_TEMPERATURE",
                    "Value": temperatureUnitConversion(60),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "FEED_PRESSURE",
                    "Value": 7.19,
                    "Unit": "bar g"
                },
                {
                    "Name": "DILUTION_STEAM_TEMPERATURE",
                    "Value": temperatureUnitConversion(185),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "DILUTION_STEAM_PRESSURE",
                    "Value": 6.79,
                    "Unit": "bar g"
                },
                {
                    "Name": "FUELGAS_TEMPERATURE",
                    "Value": temperatureUnitConversion(23),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "FUELGAS_PRESSURE",
                    "Value": 3.19,
                    "Unit": "bar g"
                },
                {
                    "Name": "CG_PRESSURE_AT_HEADER",
                    "Value": 0.69,
                    "Unit": "bar g"
                }
            ],
            "Furnace_Id": 1,
            "Furnace_Ref": "11-FF-102",
            "Asset": "DAHEJ.FURNACE.01"
        },
        {
            "Name": "11-FF-102-CONV(PUBLIC)",
            "Components": [
                {
                    "Name": "BFW_TEMPERATURE",
                    "Value": temperatureUnitConversion(136),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "BFW_PRESSURE",
                    "Value": 0.00,
                    "Unit": "bar g"
                },
                {
                    "Name": "HP_STEAM_TEMPERATURE",
                    //TODO: check temperature value is correct or not
                    "Value": temperatureUnitConversion(12356.0),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "HP_STEAM_PRESSURE",
                    "Value": 2569,
                    "Unit": "bar g"
                },
                {
                    "Name": "FEED_TEMPERATURE",
                    "Value": temperatureUnitConversion(60),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "FEED_PRESSURE",
                    "Value": 7.19,
                    "Unit": "bar g"
                },
                {
                    "Name": "DILUTION_STEAM_TEMPERATURE",
                    "Value": temperatureUnitConversion(185),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "DILUTION_STEAM_PRESSURE",
                    "Value": 6.79,
                    "Unit": "bar g"
                },
                {
                    "Name": "FUELGAS_TEMPERATURE",
                    "Value": temperatureUnitConversion(23),
                    "Unit": getTemperatureUnit()
                },
                {
                    "Name": "FUELGAS_PRESSURE",
                    "Value": 3.19,
                    "Unit": "bar g"
                },
                {
                    "Name": "CG_PRESSURE_AT_HEADER",
                    "Value": 0.69,
                    "Unit": "bar g"
                }
            ],
            "Furnace_Id": 2,
            "Furnace_Ref": "11-FF-103",
            "Asset": "DAHEJ.FURNACE.02"
        }
    ];
    /*session.Session.ConvectionSections: [
        {
            "Name": "11-FF-101-CONV(PUBLIC)",
            "Components": [
                {
                    "Name": "BFW_TEMPERATURE",
                    "Value": 113,
                    "Unit": "°C"
                },
                ...

            ],
            "Furnace_Id": 2,
            "Furnace_Ref": "11-FF-103",
            "Asset": "DAHEJ.FURNACE.02"
        }
    ]*/
    CS_Session = session.Session.ConvectionSections
    if (CS_Session.length === 0)
        return {}
    let startRow = 1
    let startCol = 0

    /*Horizonal Header Layer-----------------------------*/
    let header = [[startRow, startCol], ["Unit", "Default"], false]
    addCells2Grid(header, grid, true)
    /*Vertical Header Layer--for renamed Process Media Names-------------------------*/

    let sideheader = [[startRow - 1, startCol], ["Furnace", "Process Media", ...config.alias], true]
    addCells2Grid(sideheader, grid, true)
    /*Vertical Header Layer for Units --------------------------*/
    let nextRow = startRow
    CS_Session.forEach((cs, csIdx) => {
        if (csIdx === 0) {

            cs["Components"].forEach((comp, compIdx) => {
                if (comp.Name === config.tags[compIdx])
                    addCells2Grid([[nextRow + 1, startCol + 1], [comp.Unit], false], grid, true)
                nextRow = startRow + compIdx
            })
        }

        /*Vertical Value Layer--------------------------*/
        //reset Row Counter

        cs["Components"].forEach((comp, compIdx) => {
            if (compIdx === 0) {
                addCells2Grid([[startRow - 1, startCol + 2], [cs.Furnace_Ref], false], grid, true)
                addCells2Grid([[startRow, startCol + 2], [cs.Name], false], grid, true)
            }
            nextRow = startRow + 1
            if (comp.Name === config.tags[compIdx])
                addCells2Grid([[nextRow + compIdx, startCol + 2], [comp.Value], false], grid, true)

        })

        startCol = startCol + 2

    })

    cases.push(grid)


    cases.forEach((c, i) => {
        if (i === 0)
            ws_data = [...ws_data, ...c]
        else {

            c.forEach((el, i) => {
                ws_data[i] = [...ws_data[i], ...el]
            })

        }
    })

    //Check if all rows have the same length
    return check_wsData(ws_data, sheet)



}


export async function preparePPSheetFromObj(config, session, data = 'data') {
    let sheet = config.sheet;
    var ws_data: any[] = [];
    ws_data.length = 0;

    let yieldPredictionScen = session['Session'][data].filter(sc => sc.isExportable === true)
    upDateHeadersWithAliases(config);
    let maxColLength = 7//fgrp[data].length + 2
    let maxRowLength = config.headers[0][1].length


    //replace furnaceid by Assetname --> getAssetName(id)

    let cases: any[] = [];
    let EmptyHeader = ["", "", "", "", ""];
    yieldPredictionScen.forEach((scenario, scenarioIdx) => {

        let startX = 0;
        var grid = [...Array(maxRowLength)].map(e => Array(maxColLength).fill(""));
        const furnaceGrps = Object.keys(scenario).filter(word => word.includes('FurnaceGrp_'));
        furnaceGrps.forEach((fgrpName, fgrIdx) => {


            //Write for each Scenario an Identifier header  but not for Furnace Grp Coloums
            let Entry = EmptyHeader
            if (fgrIdx === 0) {

                if (scenarioIdx === 0) {

                    config.headers.forEach(header => addCells2Grid(header, grid, true))
                    startX = 1;
                }

                Entry = [scenario['Scenario'], scenario['Pressure'], scenario['PredictionTime'], scenario['FuelGas_Ref'], ""]


            }
            startX = startX + 1
            //Each FurnaceGro can run with different num of feedtypes
            let FEED_FLOWS: any[] = []
            scenario[fgrpName]['FEED_FLOWS'].forEach((comp, compIdx) => {
                let cId = compIdx + 1
                FEED_FLOWS.push(comp['COMPONENT-' + cId])
                FEED_FLOWS.push(comp['COMPONENT-' + cId + '-FLOWRATE'])

                let Feed_Ref = comp['COMPONENT-' + cId + '-FEEDSTOCK']
                let Feed_RefIdx = scenario["Feedstocks"].findIndex(itm => itm['Feedstock_id'] === Feed_Ref);
                if (Feed_RefIdx !== -1)
                    FEED_FLOWS.push(scenario["Feedstocks"][Feed_RefIdx]["Feedstock_Ref"])
            })

            //Find Convection-Section Reference Name
            let conv_ref = scenario["ConvSections"].find(csr => csr.ConvSection_id === scenario[fgrpName]['Conv_Ref'])
            if (conv_ref)
                scenario[fgrpName]['Conv_Ref'] = conv_ref.ConvSection_Ref

            //Add Furnace-grp Column with config. values
            let ConfigField = Entry.concat(
                [scenario[fgrpName]['FGroupLabel'],
                scenario[fgrpName]['NUM_FURNACES'],
                scenario[fgrpName]['HydroCarbon_Flow_Per_Furnace'],
                scenario[fgrpName]['Conv_Ref'],
                scenario[fgrpName]['CIT'],
                scenario[fgrpName]['DILUT'],
                scenario[fgrpName]['KEY_COMPONENT'],
                scenario[fgrpName]['SPEC'],
                scenario[fgrpName]['SPEC_TYPE'],

                ],
                FEED_FLOWS
            )

            //Fill Cells with Scenario and FurnaceGro Infos
            let caseId = [[0, startX], ConfigField, true]
            addCells2Grid(caseId, grid, true);



        })
        //Fill Cells with Scenario Results
        startX = startX + 1
        let PoductLabelField = EmptyHeader.concat(["Product/Recycles"], scenario["products"].map(comp => comp["name"]));
        let productLabels = [[0, startX], PoductLabelField, true]
        addCells2Grid(productLabels, grid, true);

        startX = startX + 1
        let PoductValuesField = EmptyHeader.concat(["Values [kg/h]"], scenario["products"].map(comp => parseFloat(comp["value"]).toFixed(1)));
        let productValues = [[0, startX], PoductValuesField, true]
        addCells2Grid(productValues, grid, true);

        cases.push(grid)

    })

    cases.forEach((c, i) => {

        if (i === 0)
            ws_data = [...ws_data, ...c]
        else {

            c.forEach((el, i) => {
                ws_data[i] = [...ws_data[i], ...el]
            })

        }
    })

    //Check if all rows have the same length
    return check_wsData(ws_data, sheet);

}



function modifyValues(line, config, tagNum) {
    return line.map(l => {

        if (typeof (l) === 'number') {
            if (isNaN(l))
                l = l.toString();

            else if (!('decimal_places' in config))
                l = parseFloat(l.toFixed(3))
            else
                l = parseFloat(l.toFixed(config.decimal_places[tagNum]))
        }


        return l;

    })

}

function addCells2Grid(field, grid, cond) {

    if (cond) {
        //read  [pos, entry, isCol]
        let x, y;
        [y, x] = field[0];
        let isCol = field[2];

        if (isCol)
            field[1].forEach((elm, i) => grid[y + i][x] = elm)

        else
            field[1].forEach((elm, i) => grid[y][x + i] = elm)

    }

}

export async function prepareSheetFromAOA(config) {
    let sheet = config.sheet;
    let tags = config.tags;
    let types = config.types;

    mapAliases(config);
    upDateHeadersWithAliases(config);
    //const { plants, furnaces, scenarios }: ICG = await state.exportData.getXYItems(['RUNLENGTH','COT']);
    const data: any = await config.getXYItemsbyType(tags, types);

    //Order Scenarios by Furnaces and filter for scenarios with values
    let scenarioOrdered = Array.from(data.furnaces).map((fname) => {
        return data.scenarios.filter(s => s.furnace === fname && s.values.length > 0 && s.values[0].length > 0 && !s.scenario.includes("Realtime Advisor"));
    }).flat();

    var ws_data: any[] = [];
    ws_data.length = 0;
    let scenarioColLength: number[] = [];
    let AOAs: any = {};

    //Loop over all scenarios and find the one with max entries (Runlength)
    let res = scenarioOrdered.map((scenario, idx) => scenario.values.length)
    let maxLength = Math.max(...res);

    let check = scenarioOrdered.every((scenario, sId) => {
        if (scenario.values === null || scenario.values[0] === undefined)
            return false;

        if (config.transposed) {

            let scenarioFlat = scenario.values.map((scenarioField, idx) => {
                if (idx === 0)
                    AOAs = findAOA(tags, types, scenarioField);
                //copy last element
                // scenarioField.push(scenarioField[scenarioField.length-1])


                return scenarioField.flat();
            });

            let data_t: any[] = [];
            let hybridScenarioIndex = -1;
            for (let k in scenarioFlat[0]) {
                let spalte: any[] = [];
                for (let l in scenarioFlat) {
                    if (Number(k) === 0 && scenario.mode === MODE.HYBRID && hybridScenarioIndex === -1 && ((Number(l) + 1) !== scenarioFlat.length) && scenarioFlat[l][k] > scenarioFlat[Number(l) + 1][k]) {
                        hybridScenarioIndex = Number(l);
                    }
                    spalte.push(scenarioFlat[l][k])
                    if (Number(l) === hybridScenarioIndex) {
                        spalte.push('-')
                    }
                    // spalte.push(scenarioFlat[l][k])
                }
                data_t.push(spalte.concat(''))
                if (data_t.length === 0) {
                    scenarioColLength.push(spalte.length + 1)
                }
            }

            if (ws_data.length > 0) {
                for (let l in data_t) {
                    ws_data[l].push(...data_t[l])
                }
            }
            else
                ws_data = data_t
        }
        else {
            //Add empty rows to have for all scenarios the same length
            let addRow = maxLength - scenario.values.length;
            if (addRow > 0)
                scenario.values.push(...createEmtpyHeaderField(addRow, scenario.values[0].flat().length))
            let hybridIndex = -1
            if (scenario.mode === MODE.HYBRID) {

                for (var i = 0; i < scenario.values.length - 1; i++) {
                    if ((scenario.values[i]?.[0] > scenario.values[i + 1]?.[0])) {
                        hybridIndex = i;
                        break;
                    }
                }
            }
            // let col: any = [];

            ws_data = scenario.values.map((scenarioField: string[], idx) => {

                let row: any = ws_data.length > 0 ? ws_data[idx] : [];
                let col: any = [];

                //In case a cell contains an array, flat the whole field
                let newRowEntry: string[] = scenarioField.flat()

                if (scenario.mode === MODE.HYBRID) {
                    if (idx <= hybridIndex || hybridIndex === -1) {

                        let newFirebox2Entry = scenario.values[hybridIndex + idx + 1];
                        //Add empty cell bewteen scenarios in each row
                        newRowEntry = newRowEntry.concat(Array.from({ length: 1 }, _ => '-'));
                        newRowEntry = newRowEntry.concat(newFirebox2Entry)
                    }
                    else {
                        newRowEntry = [];
                        newRowEntry = newRowEntry.concat(Array.from({ length: 1 }, _ => ''));
                        newRowEntry = newRowEntry.concat(Array.from({ length: 1 }, _ => ''));
                    }
                }
                //Add empty cell bewteen scenarios in each row
                newRowEntry = newRowEntry.concat(Array.from({ length: 1 }, _ => ''));
                if (idx === 0) {
                    AOAs = findAOA(tags, types, scenarioField);
                    scenarioColLength.push(newRowEntry.length);
                }
                row = row?.concat(newRowEntry);
                col.push(row?.flat());
                return col;

            });
            ws_data = ws_data.flat();

        }


        return true;
    });
    if (check)
        check = setHeader(config, scenarioOrdered, AOAs, scenarioColLength, ws_data);
    return check_wsData(ws_data, sheet, check, false)


}

export async function prepareCOT2(config) {
    let sheet = config.sheet;
    let tags = config.tags;
    let types = config.types;

    const data: any = await config.getXYItemsbyType(tags, types);

    //Variables used to remove duplicated coloums from scenario results with same funace number
    // let prevPlant = "";
    // let prevFurnace = "";
    // let prevScenarioId = -1;

    //Order Scenarios by Furnaces
    let scenarioOrdered = Array.from(data.furnaces).map((fname) => {
        return data.scenarios.filter(s => s.furnace === fname);
    }).flat();

    var ws_data: any[] = [];
    ws_data.length = 0;
    let scenarioColLength: number[] = [];
    // let scenarioInfo: any = {};
    let AOAs: any = {};

    //Loop over all scenarios and find the one with max entries (Runlength)
    let res = scenarioOrdered.map((scenario, idx) => scenario.values.length)
    let maxLength = Math.max(...res);

    let check = scenarioOrdered.every((scenario, sId) => {
        if (scenario.values == null)
            return false;

        // let currentFurnace = scenario.furnace;
        // let currentPlant = getPlantReferenceById(scenario.plant)['name'];
        // let currentScenarioId = sId;
        
        if (config.transposed) {

            let scenarioFlat = scenario.values.map((scenarioField, idx) => {
                if (idx === 0)
                    AOAs = findAOA(tags, types, scenarioField);
                //copy last element
                // scenarioField.push(scenarioField[scenarioField.length-1])


                return scenarioField.flat();
            });

            let data_t: any[] = [];
            let hybridScenarioIndex = -1;
            for (let k in scenarioFlat[0]) {
                let spalte: any[] = [];

                for (let l in scenarioFlat) {
                    // spalte.push(scenarioFlat[l][k])
                    if (scenario.mode === MODE.HYBRID && hybridScenarioIndex === -1 && scenarioFlat[l][k] > scenarioFlat[Number(l) + 1][k]) {
                        hybridScenarioIndex = Number(l);
                    }
                    spalte.push(scenarioFlat[l][k])
                    if (Number(l) === hybridScenarioIndex) {
                        spalte.push('-')
                    }
                }


                data_t.push(spalte.concat(''))
                if (data_t.length === 0) {
                    scenarioColLength.push(spalte.length + 1)
                }


            }

            if (ws_data.length > 0) {
                for (let l in data_t) {
                    ws_data[l].push(...data_t[l])
                }
            }
            else
                ws_data = data_t
        }
        else {
            //Add empty rows to have for all scenarios the same length
            let addCol = maxLength - scenario.values.length;
            if (addCol > 0)
                scenario.values.push(...createEmtpyHeaderField(addCol, scenario.values[0].flat().length))

            ws_data = scenario.values.map((scenarioField: string[], idx) => {
                let row: any = ws_data.length > 0 ? ws_data[idx] : [];
                let col: any = [];

                let newRowEntry: string[] = scenarioField.flat()
                //copy last element, no data extension/manipulation should not occure here!
                //No idea what the last element can be,number,sting,aoa?!
                newRowEntry.push(newRowEntry[newRowEntry.length - 1])
                //Add empty cell bewteen scenarios in each row
                newRowEntry = newRowEntry.concat(Array.from({ length: 1 }, _ => ''));



                if (idx === 0) {
                    AOAs = findAOA(tags, types, scenarioField);


                    scenarioColLength.push(newRowEntry.length)
                }

                row = row.concat(newRowEntry);
                col.push(row.flat());
                return col;

            });
            ws_data = ws_data.flat();
        }
        // Merge of Cell Not Yiet Implemented***************
        /*const ids = [...new Set(prep.map(obj => obj.id))];
        const merges = ids.reduce((acc, curr, idx) => {
          acc.push({
            s: {r: 0, c: 1 + (2 *idx)},
            e: {r: 0, c: 1 + (2 *idx) + 1}
          });
          return acc;
        }, []);
        ws["!merges"] = merges;*/
        // prevPlant = currentPlant;
        // prevFurnace = currentFurnace;
        // prevScenarioId = currentScenarioId;

        return true;
    });

    check = setHeader(config, scenarioOrdered, AOAs, scenarioColLength, ws_data);
    return check_wsData(ws_data, sheet, check, false)

}


function addColHeader(currentPlant: any, currentFurnace: any, scenario: any, prevPlant: string, sId: number) {
    var cg_headers: any[] = [];
    var scenario_headers: any[] = [];
    var scenario_headers_val: any[] = [];
    //create for all scenarios an array with header and cg-result
    let cg_header: string[];
    //prepare header
    let sc_header_val = [currentPlant, currentFurnace, scenario.scenario];
    let sc_header = ['plant', 'furnace', 'scenario'];

    if (prevPlant !== currentPlant) {
        cg_header = [...scenario.order];
        //cg_header.splice(2, 0, "Unit");
    }
    else {
        cg_header = [...scenario.order];

    }

    if (sId > 0) {
        cg_header = cg_header.slice(2);
        sc_header = sc_header.slice(2);
        sc_header_val = sc_header_val.slice(2);
    }

    let N = (cg_header.length - sc_header.length);

    if (N > 0) {
        sc_header = sc_header.concat(Array.from({ length: N }, _ => ''));
        sc_header_val = sc_header_val.concat(Array.from({ length: N }, _ => ''));
    }
    if (N < 0) {
        N = Math.abs(N);
        cg_header = cg_header.concat(Array.from({ length: N }, _ => ''));
    }


    cg_headers.push(cg_header);
    scenario_headers.push(sc_header);

    scenario_headers_val.push(sc_header_val);

    return { cg_headers, cg_header, scenario_headers, sc_header, scenario_headers_val, sc_header_val };
}

function getAllIndexes(arr, val) {
    var indexes: number[] = [];

    for (let i = 0; i < arr.length; i++)
        if (arr[i] === val)
            indexes.push(i);
    return indexes;

}

//Find Array of Array
function findAOA(tags: string[], types: ROWCOLType[], scenario,) {
    let items1: any[] = [];
    let items2: any[] = [];
    let newDict = {};
    let foundIdx1 = getAllIndexes(types, ROWCOLType.StaticRow);
    let foundIdx2 = getAllIndexes(types, ROWCOLType.VarRow);
    foundIdx1.forEach(i => { items1.push(scenario[i]) });
    foundIdx2.forEach(i => { items2.push(scenario[i]) });

    foundIdx1.forEach(i => {
        newDict[tags[i]] = {
            values: scenario[i],
            type: ROWCOLType.StaticRow
        }
    })
    foundIdx2.forEach(i => {
        newDict[tags[i]] = {
            values: scenario[i],
            type: ROWCOLType.VarRow
        }
    })


    return newDict
}

/*Adds Headers on A1
['plant','1','','1','','','1','','']
['furnace', '11-FF-101', '', '11-FF-101', '', '', '11-FF-102', '', '']
['scenario', 'test1', '', 'test2', '', '', 'test3','','']
*/

function setHeader(config, scenarioCaseInfo, AOAs, scenarioColLength, ws_data) {
    let sc_header = ['plant', 'furnace', 'scenario'];
    let numRows = sc_header.length + 3;
    let transposed = config.transposed;

    let row_headers = createEmtpyHeaderField(numRows, ws_data[0].length)
    let indexes1 = getAllIndexes(ws_data[0], '');
    let indexes2 = getAllIndexes(ws_data[0], '-');
    let scenarioColInfo: any[] = []

    //Prepare Coillength
    let coilDefaultHeader: any[] = [];
    let coillength_header: any[] = []

    if (AOAs['TWALLOUTER_MAX']) {
        var COILLENGTH = config.map2Range(0, AOAs['TWALLOUTER_MAX'].values.length, 0, 100)


        if (transposed) {
            coilDefaultHeader = [...['', ''], ...COILLENGTH, ...['']]
            ws_data.forEach((it, idx) => it.unshift(coilDefaultHeader[idx]))
        }
        else
            coilDefaultHeader = [...['', ''], ...COILLENGTH, ...['']]

    }
    let hybridSceIndex = -1;
    //Repead headers 
    scenarioCaseInfo.forEach((scenario, sId) => {

        let scenarioDesc = [getPlantReferenceById(scenario.plant)['name'], scenario.furnace, scenario.scenario]

        let idx = sId === 0 ? 0 : (config.sheet === "TMT" ? indexes1[sId - 1] + 2 : indexes1[sId - 1] + 1);
        let idx2 = 0;
        if (scenario.mode === MODE.HYBRID) {
            hybridSceIndex = hybridSceIndex + 1;
            idx2 = (config.sheet === "TMT" ? indexes2[hybridSceIndex] + 2 : indexes2[hybridSceIndex] + 1);
        }
        //Insert the values for plant, furnace, scenario at colIdx : idx
        insertField(row_headers, idx, 0, scenarioDesc, true, true);


        if (transposed === true) {
            let refTag = config.map[config.refTag]
            insertField(row_headers, idx, 0, [...scenarioDesc, '', refTag], true, true);
            if (scenario.mode === MODE.HYBRID) {
                insertField(row_headers, idx, 0, [...scenarioDesc, '', refTag, 'Firebox 0'], true, true);
                insertField(row_headers, idx2, 0, ['', '', '', '', '', 'Firebox 1'], true, true);
            }
            else {
                insertField(row_headers, idx, 0, [...scenarioDesc, '', refTag], true, true);
            }
        }
        else {
            if (scenario.mode === MODE.HYBRID) {
                insertField(row_headers, idx, 0, [...scenarioDesc, '', 'Firebox 0'], true, true);
                insertField(row_headers, idx2, 0, ['', '', '', '', 'Firebox 1'], true, true);
            }
            if (coilDefaultHeader.length > 0 && transposed === false)
                coillength_header.push(...coilDefaultHeader)

            let N2 = scenarioColLength[sId] - scenario['order'].length;
            let renamedTags = scenario['order'].map(tag => config.map[tag])
            if (scenario.mode === MODE.HYBRID) {
                renamedTags.push('')
                renamedTags = renamedTags.concat(renamedTags)
                N2 = N2 - 4
            }

            let newColTag = renamedTags.concat(Array.from({ length: N2 }, _ => ''));

            scenarioColInfo.push(...newColTag);
        }

    })

    //scenarioColInfo row for coloum names e.g Runlength [d], CIP [%]
    if (scenarioColInfo.length > 0) {
        row_headers.push(scenarioColInfo)
    }
    if (coillength_header.length > 0) {
        row_headers.push(coillength_header)
    }

    //set colIter=true and write in A1/A1-A1/A3,...
    row_headers.reverse();
    //place header
    row_headers.map(h => ws_data.unshift(h))


    if (true) {

        let sideheader = createEmtpyHeaderField(ws_data.length, 2)

        for (let h of config.headers) {
            insertField(sideheader, h[0][0], h[0][1], h[1], h[2], true)
        }

        sideheader.map((h, i) => ws_data[i].unshift(...h))
        sideheader.reverse()
    }

    return true;

    function insertField(headers, xIter = 0, y = 0, entries = ['plant', 'furnace', 'scenario'], Coloum = true, condition) {

        if (condition) {
            //extend fields by certain rows # if entries do not fit
            let dataLength = Coloum ? ws_data.length : ws_data[0].length

            let y1 = (entries.length + y) - dataLength;

            if (y < 0 || y1 > 0) { //extend num rows first

                let newlength = y <= 0 ? entries.length : y1
                let headers2 = createEmtpyHeaderField(newlength, ws_data[0].length)
                y = headers.length
                headers.push(...headers2)

            }
            if (Coloum) { //insert into coloum


                for (var i = 0; i < entries.length; i++) {
                    headers[i + y][xIter] = entries[i];

                }
            }
            else { //insert into row
                for (var j = 0; j < entries.length; j++)
                    headers[xIter + y][j] = entries[j];
            }

        }

    }


}

//Productpredictor

export function getPPConfigFromObj(session, scenarioRunlengths) {

    // const selectedScenario = 0
    // const configSession: string[] = ['FuelGas_Ref', 'Feedstock_Ref', 'convectionsection_Ref', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX']
    // const config: string[] = ['fuelGas_id', 'feedstock_id', 'convectionsection_id', 'CIT', 'COP', 'DILUT', 'FLOW_HC', 'SPEC_TYPE', 'SPEC', 'KEY_COMPONENT', 'LAVAL_RATIO', 'P_XOVER', 'TMTMAX', 'INTERVAL', 'RUNLENGTH_MAX']
    //var setFurnaceIds = new Set(session.Session.Scenarios.map(scenario => scenario.Furnace_Ref).sort());
    let CalcResults = session['Session']['YieldPredictor']
    /*  setFurnaceIds.forEach(fid => {
          let sortedCalcsByFurnaces: any[] = []
          let fworker = session.Session.Scenarios.filter(scenario => scenario.Furnace_Ref === fid)
  
          let scenarioConfig: any = {};
          let thicknessProfile: any[] = [];
          let twallouter_maxProfile: any[] = [];
          let results: any[] = []
          fworker.forEach((scenario, i) => {
              //read configuration
              config.forEach((itm, k) => { scenarioConfig[itm] = scenario[configSession[k]] })
              let runlength = scenarioRunlengths.find(run => run.name === scenario['Scenario'])
              //read CD-Data
              let cd_data = generateCoilDynamicsData(runlength.scenarios, selectedScenario)
  
              runlength.scenarios[0].CALCULATIONS.forEach((val, i) => {
                  //item is {output_dict , trans_dict}
                  let runtime = val.output_dict.RUNLENGTH[0][0];
                  //read cokethickness
                  thicknessProfile.push({
                      runlength: runtime,
                      coillength: generateDetailTimechartData(
                          "COKETHICKNESS",
                          runlength.scenarios,
                          selectedScenario,
                          i,
                          false
                      ).data,
  
                  });
  
                  twallouter_maxProfile.push(
                      {
                          runlength: runtime,
                          coillength: generateDetailTimechartData(
                              "TWALLOUTER_MAX",
                              runlength.scenarios,
                              selectedScenario,
                              i,
                              false
                          ).data,
  
                      });
  
  
              });
  
              if (cd_data.data.length > 0)
                  results.push({
                      scenarioName: scenario['Scenario'],
                      twallouter: twallouter_maxProfile,
                      //name: scenario['Config_Ref'],
                      cokethickness: thicknessProfile,
                      config: [scenarioConfig],
                      ...cd_data
                  });
  
              //sortedCalcsByFurnaces.push(obj);
          });
  
          let obj = {
              furnaceId: fworker[0].Furnace_Ref,
              plantId: getPlantReferenceById(fworker[0].Plant_Id)['name'],
              results: results
          }
  
          CalcResults.push(obj)
  
  
      })*/

    return CalcResults


}