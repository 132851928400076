import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const LegalNotice = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const pageName = "Legal Notice"
        if (pageName !== undefined) {
            dispatch({ type: "UPDATE_PAGE_TITLE", payload: { value: pageName } });
        }
    }, [dispatch]);

    return (
        <div className="legal-notice">
            <div className="row">
                <div className="col-12 col-md-10 offset-md-1"><h1 className="header">Legal Notice</h1>
                    <div className="post-content">
                        <p>Linde GmbH <br></br>
                            Linde Engineering<br></br>
                            Dr.-Carl-von-Linde-Str. 6-14<br></br>
                            82049 Pullach<br></br>
                            Germany</p>
                        <p>Phone: +49 89 7445-0<br></br>
                            Fax: +49 89 7445-4908<br></br>
                            Email: <a href="mailto:info@linde-le.com">info@linde-le.com</a>
                        </p>
                        <p>Registered Office: Pullach/Germany, Court of Registration: Munich, HRB<br></br>
                            256407<br></br>
                            Supervisory Board: Christoph Hammerl (Chairman)<br></br>
                            Management Board: Sean Durbin, Jürgen Nowicki, Matthias von Plotho</p>
                        <p>Value-added tax identification number<br></br>
                            DE 322012334</p>
                        <p>Linde is a company name used by Linde plc and its affiliates. The Linde logo, the Linde word and all mentioned trademarks (®/™) on this website are trademarks or registered trademarks of Linde plc or its affiliates. Copyright © 2021. Linde plc.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}