import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IAggregateFunctionSlice = {
    name: string,
    aggregateFunctions: string[]
}

const initialState: IAggregateFunctionSlice = {
    name: "Select",
    aggregateFunctions: ["Select", "avg TMT of coil group", "avg TMT of all coils", "min and max TMT of coil group", "min and max TMT of all coils"]
}

export const aggregateFunctionSlice = createSlice({
    name: "aggregateFunctionSlice",
    initialState: initialState,
    reducers: {
        setCurrentAggregateFunction: (state, action: PayloadAction<string>) => {
            state.name = action.payload
        },
        resetCurrentAggregateFunction: (state) => {
            state.name = "Select";
            state.aggregateFunctions = ["Select", "avg TMT of coil group", "avg TMT of all coils", "min and max TMT of coil group", "min and max TMT of all coils"];
        },
    }
});

export const {
    setCurrentAggregateFunction,
    resetCurrentAggregateFunction

} = aggregateFunctionSlice.actions


export default aggregateFunctionSlice.reducer
