import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getDefaultFeedTypeId,
  getDefaultFurnaceReferenceId,
  getDefaultFurnaceTypeId,
  getDefaultPlantReferenceId,
} from "../configuration";
import {
  CALCULATION_STATUS,
  IScenario,
  SCENARIO_PROGRESS,
  SCENARIO_STATUS,
  TERMINATION_REASON,
} from "../interfaces/IScenario";
import { IScenarioRunlength } from "../interfaces/IScenarioRunlength";
import { getSignature } from "../utilities/helperFunctions";

export type IScenarioSimulatorSlice = {
  scenarioRunlengths: Array<IScenarioRunlength>;
  loadedScenarioRunlengths: Array<IScenarioRunlength>;
  currentRunLengthId: number;
  currentScenarioRunLengthId: string;
  currentScenarioFireboxId: number;
  currentFurnaceId: string;
  currentPlantId: string;
  current_furnace_type_id: string;
  current_feed_type_id: string;
  furnace_feed_combination: any[];
  convectionSections: Array<any>;
  withConvections: Array<any>;
};

const initialState: IScenarioSimulatorSlice = {
  scenarioRunlengths: [],
  loadedScenarioRunlengths: [],
  currentRunLengthId: 0,
  currentScenarioRunLengthId: "",
  currentScenarioFireboxId: 0,
  currentFurnaceId: '',
  currentPlantId: '',
  current_furnace_type_id: '',
  current_feed_type_id: '',
  furnace_feed_combination: [],
  convectionSections: [],
  withConvections: [],
};

export const scenarioSimulatorSlice = createSlice({
  name: "scenarioSimulatorSlice",
  initialState: initialState,
  reducers: {
    addScenarioRunlength: (
      state,
      action: PayloadAction<IScenarioRunlength>
    ) => {
      state.scenarioRunlengths.push(action.payload);
    },
    deleteScenarioRunlength: (state, action) => {
      state.scenarioRunlengths = state.scenarioRunlengths.filter(
        (item) => item.id !== action.payload.id
      );
    },
    updateScenarioRunlength: (state, action) => {
      state.scenarioRunlengths[
        state.scenarioRunlengths.findIndex(
          (item) => item.id === action.payload.scenarioRunlengthId
        )
      ] = action.payload.updatedScenarioRunlength;
    },
    addScenarioToRunlength: (
      state,
      action: PayloadAction<{ runlength_id: string; scenario: IScenario }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      // const scenario_index = state.scenarioRunlengths[runlength_index].scenarios.findIndex((item) => (item.id === action.payload.scenario.id));

      state.scenarioRunlengths[runlength_index].signature = getSignature(
        action.payload.scenario
      );
      state.scenarioRunlengths[runlength_index].is_saved = false;
      state.scenarioRunlengths[runlength_index].isExportable = false;
      if (state.scenarioRunlengths[runlength_index].scenarios.length > 0) {
        state.scenarioRunlengths[runlength_index].scenarios[0] =
          action.payload.scenario;
      } else {
        state.scenarioRunlengths[runlength_index].scenarios.push(
          action.payload.scenario
        );
      }
    },
    deleteScenarioFromRunlength: (
      state,
      action: PayloadAction<{ runlength_id: string; scenario: IScenario }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      state.scenarioRunlengths[runlength_index].scenarios =
        state.scenarioRunlengths[runlength_index].scenarios.filter(
          (scenario_old) => scenario_old.id !== action.payload.scenario.id
        );
    },
    setStatusScenarioInRunlength: (
      state,
      action: PayloadAction<{
        runlength_id;
        scenario_id;
        status: SCENARIO_STATUS;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].status = action.payload.status;
    },
    setCalculationStatusInScenarioInRunlength: (
      state,
      action: PayloadAction<{
        runlength_id;
        scenario_id;
        status: CALCULATION_STATUS;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].calculation_status = action.payload.status;
    },
    setTerminatedFirebox: (
      state,
      action: PayloadAction<{
        runlength_id;
        scenario_id;
        terminated_firebox: number;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].terminated_firebox = action.payload.terminated_firebox;
    },
    resetFireboxTerminationProp: (
      state,
      action: PayloadAction<{ runlength_id; scenario_id }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].terminated_firebox = -1;
    },
    setScenarioProgress: (
      state,
      action: PayloadAction<{
        runlength_id;
        scenario_id;
        status: SCENARIO_PROGRESS;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].scenario_progress = action.payload.status;
    },
    setTerminationReasonInScenarioInRunlength: (
      state,
      action: PayloadAction<{
        runlength_id;
        scenario_id;
        reason: TERMINATION_REASON;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].termination_reason = action.payload.reason;
    },
    setModelInputInScenarioInRunlength: (
      state,
      action: PayloadAction<{ runlength_id; scenario_id; modelInput: {} }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].last_model_input = action.payload.modelInput;
    },
    setFinalRunlengthInScenarioInRunlength: (
      state,
      action: PayloadAction<{ runlength_id; scenario_id }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      const scenario_calc =
        state.scenarioRunlengths[runlength_index].scenarios[scenario_index]
          ?.firebox[0].CALCULATIONS;
      const last_runlength =
        scenario_calc[scenario_calc?.length - 1]?.output_dict?.[
          "RUNLENGTH"
        ][0][0] ?? 0;
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].RUN_LENGTH_FINAL = last_runlength;
    },
    setCurrentRunLengthId: (state, action: PayloadAction<number>) => {
      state.currentRunLengthId = action.payload;
    },
    setCurrentScenarioRunLengthId: (state, action: PayloadAction<string>) => {
      state.currentScenarioRunLengthId = action.payload;
    },
    setCurrentScenarioFireboxId: (state, action: PayloadAction<number>) => {
      state.currentScenarioFireboxId = action.payload;
    },
    setCurrentFurnaceId: (state, action: PayloadAction<string>) => {
      state.currentFurnaceId = action.payload;
    },
    setCurrentPlantId: (state, action: PayloadAction<string>) => {
      state.currentPlantId = action.payload;
    },
    setCurrentFurnaceTypeId: (state, action: PayloadAction<string>) => {
      state.current_furnace_type_id = action.payload;
    },
    setCurrentFeedTypeId: (state, action: PayloadAction<string>) => {
      state.current_feed_type_id = action.payload;
    },
    updateDeletedFeedstockId: (state, action: PayloadAction<string>) => {
      state.scenarioRunlengths = state.scenarioRunlengths.map((runlength) => {
        let new_runlength: IScenarioRunlength = runlength;
        new_runlength.scenarios = runlength.scenarios.map((scenario) => {
          if (
            scenario.firebox.findIndex(
              (scene) => scene.feedstock_id === action.payload
            ) > 0
          ) {
            let new_scenario: IScenario = scenario;
            scenario.firebox.map((input, index) => {
              if (input.feedstock_id === action.payload) {
                new_scenario.firebox[index].feedstock_id = "";
                new_scenario.status = SCENARIO_STATUS.DELETED_FEEDSTOCK;
              }
              return new_scenario;
            });
            return scenario;
          } else {
            return scenario;
          }
        });
        new_runlength.isExportable = false;
        return new_runlength;
      });
      //state.
      //setIsExportable(id,false)
    },
    updateDeletedFuelGasId: (state, action: PayloadAction<string>) => {
      state.scenarioRunlengths = state.scenarioRunlengths.map((runlength) => {
        let new_runlength: IScenarioRunlength = runlength;
        new_runlength.scenarios = runlength.scenarios.map((scenario) => {
          if (scenario.fuelGas_id === action.payload) {
            let new_scenario: IScenario = scenario;
            new_scenario.fuelGas_id = "";
            new_scenario.status = SCENARIO_STATUS.DELETED_FUELGAS;
            return new_scenario;
          } else {
            return scenario;
          }
        });
        new_runlength.isExportable = false;
        return new_runlength;
      });
    },
    updateDeletedConvectionSectionId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.scenarioRunlengths = state.scenarioRunlengths.map((runlength) => {
        let new_runlength: IScenarioRunlength = runlength;
        new_runlength.scenarios = runlength.scenarios.map((scenario) => {
          if (scenario.convectionsection_id === action.payload) {
            let new_scenario: IScenario = scenario;
            new_scenario.convectionsection_id = "";
            new_scenario.status = SCENARIO_STATUS.DELETED_CONVECTION_SECTION;
            return new_scenario;
          } else {
            return scenario;
          }
        });
        new_runlength.isExportable = false;
        return new_runlength;
      });
    },
    resetCalculations: (
      state,
      action: PayloadAction<{ runlength_id: string; scenario_id }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].firebox.map((f) => (f.CALCULATIONS = []));
    },
    updateCalculation: (
      state,
      action: PayloadAction<{
        runlength_id: string;
        scenarioId: string;
        data: any;
        time: any;
        day?: any;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenarioId);
      let days =
        state.scenarioRunlengths[runlength_index].scenarios[scenario_index]
          ?.Days;
      let curr_firebox = state.currentScenarioFireboxId;
      let day1Index: any =
        days?.lastIndexOf("1") === -1 ? 0 : days && days.lastIndexOf("1");
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].firebox[curr_firebox].CALCULATIONS.shift();
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].firebox[curr_firebox].CALCULATIONS.splice(
        day1Index,
        0,
        action.payload.data[0]
      );
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].firebox[curr_firebox].CALCULATIONS.join();
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].RTATime?.shift();
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].RTATime?.splice(day1Index, 0, action.payload.time[0]);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].RTATime?.join();
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].Days?.shift();
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].Days?.splice(day1Index, 0, action.payload.day[0]);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].Days?.join();
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].modifiedDateTime = new Date();
    },
    addCalculation: (
      state,
      action: PayloadAction<{
        runlength_id: string;
        scenario_id: string;
        result: any;
        timeline?: any;
        days?: string[];
        rta?: boolean;
        fireboxIndex?: number;
      }>
    ) => {
      if (action.payload.rta) {
        const runlength_index = state.scenarioRunlengths.findIndex(
          (item) => item.id === action.payload.runlength_id
        );
        const scenario_index = state.scenarioRunlengths[
          runlength_index
        ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
        const fireboxIndex = action.payload.fireboxIndex ?? 0;
        state.scenarioRunlengths[runlength_index].scenarios[
          scenario_index
        ].firebox[fireboxIndex].CALCULATIONS = action.payload.result;
        state.currentScenarioRunLengthId = action.payload.runlength_id;
        state.scenarioRunlengths[runlength_index].scenarios[
          scenario_index
        ].RTATime = action.payload.timeline;
        state.scenarioRunlengths[runlength_index].scenarios[
          scenario_index
        ].Days = action.payload.days;
        state.currentRunLengthId = 0;
      } else {
        const fireboxIndex = action.payload.fireboxIndex ?? 0;
        const runlength_index = state.scenarioRunlengths.findIndex(
          (item) => item.id === action.payload.runlength_id
        );
        const scenario_index = state.scenarioRunlengths[
          runlength_index
        ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
        const result_run = action.payload.result.output_dict.RUNLENGTH[0][0];
        const slice_index = state.scenarioRunlengths[runlength_index].scenarios[
          scenario_index
        ].firebox[fireboxIndex].CALCULATIONS.findIndex(
          (calculation) => calculation.output_dict.RUNLENGTH[0][0] >= result_run
        );
        if (slice_index >= 0) {
          state.scenarioRunlengths[runlength_index].scenarios[
            scenario_index
          ].firebox[fireboxIndex].CALCULATIONS = state.scenarioRunlengths[
            runlength_index
          ].scenarios[scenario_index].firebox[fireboxIndex].CALCULATIONS.slice(
            0,
            slice_index
          );
        }
        state.scenarioRunlengths[runlength_index].scenarios[
          scenario_index
        ].firebox[fireboxIndex].CALCULATIONS.push(action.payload.result);
        state.currentScenarioRunLengthId = action.payload.runlength_id;
        state.currentRunLengthId =
          state.scenarioRunlengths[runlength_index].scenarios[scenario_index]
            .firebox[fireboxIndex].CALCULATIONS.length - 1;
      }
    },
    removeCalculation: (
      state,
      action: PayloadAction<{
        runlength_id: string;
        scenario_id: string;
        fireboxIndex?: number;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      const fireboxIndex = action.payload.fireboxIndex ?? 0;
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].firebox[fireboxIndex].CALCULATIONS.pop();
      state.currentScenarioRunLengthId = action.payload.runlength_id;
      state.currentRunLengthId =
        state.scenarioRunlengths[runlength_index].scenarios[scenario_index]
          .firebox[fireboxIndex].CALCULATIONS.length - 1;
    },
    updateRTAScenarioRunlength: (
      state,
      action: PayloadAction<{
        runlengthId: string;
        scenarioId: string;
        data: any;
        timeline: any;
        days: any;
        fireboxIndex?: number;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlengthId
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenarioId);
      const fireboxIndex = action.payload.fireboxIndex ?? 0;
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].firebox[fireboxIndex].CALCULATIONS = action.payload.data;
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].createdDateTime = new Date();
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].modifiedDateTime = new Date();
      // state.currentScenarioRunLengthId = action.payload.runlengthId;
      state.currentRunLengthId =
        state.scenarioRunlengths[runlength_index].scenarios[scenario_index]
          .firebox[fireboxIndex].CALCULATIONS.length - 1;
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].RTATime = action.payload.timeline;
      state.scenarioRunlengths[runlength_index].scenarios[scenario_index].Days =
        action.payload.days;
    },
    updateNextResultCalculationId: (
      state,
      action: PayloadAction<{
        runlength_id: string;
        scenario_id: string;
        calculation_id: string;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].NEXT_RESULT_CALCULATION_ID = action.payload.calculation_id;
    },
    updateLoadedScenarioRunlengths: (
      state,
      action: PayloadAction<IScenarioRunlength[]>
    ) => {
      state.loadedScenarioRunlengths = action.payload;
    },
    updateScenarioCaseId: (
      state,
      action: PayloadAction<{
        runlength_id: string;
        scenario_id: string;
        case_id: string;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      const scenario_index = state.scenarioRunlengths[
        runlength_index
      ].scenarios.findIndex((item) => item.id === action.payload.scenario_id);
      state.scenarioRunlengths[runlength_index].scenarios[
        scenario_index
      ].case_id = action.payload.case_id;
    },
    setFurnaceFeedCombination: (
      state,
      action: PayloadAction<{ furnacefeed: any[] }>
    ) => {
      state.furnace_feed_combination = action.payload.furnacefeed;
    },
    updateModifiedFeedstockInDbForScenario: (
      state,
      action: PayloadAction<string>
    ) => {
      state.scenarioRunlengths = state.scenarioRunlengths.map((runlength) => {
        let new_runlength: IScenarioRunlength = runlength;
        new_runlength.scenarios = runlength.scenarios.map((scenario) => {
          if (scenario.firebox[0].feedstock_id === action.payload) {
            let new_scenario: IScenario = scenario;
            new_scenario.status = SCENARIO_STATUS.IS_CHANGED_IN_FEEDSTOCK;
            return new_scenario;
          } else {
            return scenario;
          }
        });
        new_runlength.isExportable = false;
        return new_runlength;
      });
    },
    updateModifiedFuelGasInDbForScenario: (
      state,
      action: PayloadAction<string>
    ) => {
      state.scenarioRunlengths = state.scenarioRunlengths.map((runlength) => {
        let new_runlength: IScenarioRunlength = runlength;
        new_runlength.scenarios = runlength.scenarios.map((scenario) => {
          if (scenario.fuelGas_id === action.payload) {
            let new_scenario: IScenario = scenario;
            new_scenario.status = SCENARIO_STATUS.IS_CHANGED_IN_FUELGAS;
            return new_scenario;
          } else {
            return scenario;
          }
        });
        new_runlength.isExportable = false;
        return new_runlength;
      });
    },
    updateModifiedconvectionsectionInDbForScenario: (
      state,
      action: PayloadAction<string>
    ) => {
      state.scenarioRunlengths = state.scenarioRunlengths.map((runlength) => {
        let new_runlength: IScenarioRunlength = runlength;
        new_runlength.scenarios = runlength.scenarios.map((scenario) => {
          if (scenario.convectionsection_id === action.payload) {
            let new_scenario: IScenario = scenario;
            new_scenario.status =
              SCENARIO_STATUS.IS_CHANGED_IN_CONVECTION_SECTION;
            return new_scenario;
          } else {
            return scenario;
          }
        });
        new_runlength.isExportable = false;
        return new_runlength;
      });
    },
    updateExportable: (
      state,
      action: PayloadAction<{
        runlength_id: string;
        scenario_id: string;
        isExportable: boolean;
      }>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.runlength_id
      );
      state.scenarioRunlengths[runlength_index].isExportable =
        action.payload.isExportable;
    },
    updateExportableAll: (
      state,
      action: PayloadAction<{
        furnace_id: string;
        plant_id: string;
        exportable: boolean;
      }>
    ) => {
      state.scenarioRunlengths
        .filter(
          (s) =>
            s.furnaceId === action.payload.furnace_id &&
            s.plantId === action.payload.plant_id
        )
        .forEach((x) => {
          if (x.scenarios[0]) {
            x.isExportable = action.payload.exportable;
          }
        });
    },
    loadAllScenarioRunlengthData: (state, action: PayloadAction<any[]>) => {
      //converted into firebox ( hybrid mode) redux structure for legacy
      let scenarioRunlength = action.payload;
      const scenario = scenarioRunlength[0].scenarios[0];
      if (!scenario.mode) {
        scenarioRunlength[0].scenarios[0] = {
          ...scenario,
          firebox: [
            {
              feedstock_id: scenario.feedstock_id,
              CALCULATIONS: scenario.CALCULATIONS,
              FLOW_HC: scenario.FLOW_HC,
              CIT: scenario.CIT,
              COP: scenario.COP,
              DILUT: scenario.DILUT,
              SPEC: scenario.SPEC,
              SPEC_TYPE: scenario.SPEC_TYPE,
              P_XOVER: scenario.P_XOVER,
              KEY_COMPONENT: scenario.KEY_COMPONENT,
            },
          ],
        };
      }
      state.scenarioRunlengths = [
        ...state.scenarioRunlengths,
        ...scenarioRunlength,
      ];
    },
    modifyScenarioRunlength: (
      state,
      action: PayloadAction<IScenarioRunlength>
    ) => {
      const runlength_index = state.scenarioRunlengths.findIndex(
        (item) => item.id === action.payload.id
      );
      state.scenarioRunlengths[runlength_index] = action.payload;
    },
    resetScenarios: (state) => {
      state.scenarioRunlengths = [];
      state.loadedScenarioRunlengths = [];
      state.furnace_feed_combination = [];
    },
  },
});

export const {
  addScenarioRunlength,
  deleteScenarioRunlength,
  updateScenarioRunlength,
  addScenarioToRunlength,
  deleteScenarioFromRunlength,
  setStatusScenarioInRunlength,
  setCalculationStatusInScenarioInRunlength,
  setTerminationReasonInScenarioInRunlength,
  resetFireboxTerminationProp,
  setTerminatedFirebox,
  setModelInputInScenarioInRunlength,
  setFinalRunlengthInScenarioInRunlength,
  updateDeletedFeedstockId,
  updateDeletedFuelGasId,
  updateDeletedConvectionSectionId,
  setCurrentRunLengthId,
  setCurrentScenarioRunLengthId,
  setCurrentScenarioFireboxId,
  setCurrentFurnaceId,
  setCurrentPlantId,
  setCurrentFurnaceTypeId,
  setCurrentFeedTypeId,
  resetCalculations,
  resetScenarios,
  addCalculation,
  updateCalculation,
  updateRTAScenarioRunlength,
  removeCalculation,
  updateNextResultCalculationId,
  updateLoadedScenarioRunlengths,
  updateScenarioCaseId,
  setFurnaceFeedCombination,
  updateExportable,
  updateExportableAll,
  setScenarioProgress,
  updateModifiedFeedstockInDbForScenario,
  updateModifiedFuelGasInDbForScenario,
  updateModifiedconvectionsectionInDbForScenario,
  loadAllScenarioRunlengthData,
  modifyScenarioRunlength,
} = scenarioSimulatorSlice.actions;

export default scenarioSimulatorSlice.reducer;
