import { v4 as uuidv4 } from 'uuid';
import { getConfigFuelGasById, getDefaultFuelGasComposition } from '../configuration';
import { IComponent, UNIT } from './IComponent';

export enum FUELGAS_STATUS {
    UNCOMPUTED = "UNCOMPUTED",
    RUNNING = "RUNNING",
    NORMALIZED = "NORMALIZED",
    COMPUTED = "COMPUTED",
    ERROR = "ERROR"
}

export type IFuelGas = {
    id: string;
    name: string;
    unit: UNIT;
    components: IComponent[];
    status: FUELGAS_STATUS;
    error_message: string;
    O2BridgeWall: number;
    Pressure: number;
    Temperature: number;
    signature?: string;
    is_public?: boolean;
    is_display_leave_popup?: boolean;
    is_saved?: boolean;
}

export function getDefaultFuelGas(): IFuelGas {
    return {
        id: uuidv4(),
        name: "default",
        unit: UNIT.MOL,
        components: getDefaultFuelGasComposition(),
        status: FUELGAS_STATUS.UNCOMPUTED,
        error_message: "",
        O2BridgeWall: -1,
        Pressure: -1,
        Temperature: -1,
        signature: "",
        is_public: true,
        is_display_leave_popup: false,
        is_saved: false
    }
}

export function getFuelGasById(plant_id: string): IFuelGas {
    return {
        id: uuidv4(),
        name: "default",
        unit: UNIT.MOL,
        components: getConfigFuelGasById(plant_id),
        status: FUELGAS_STATUS.UNCOMPUTED,
        error_message: "",
        O2BridgeWall: -1,
        Pressure: -1,
        Temperature: -1,
        signature: ""
    }
}