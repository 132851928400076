import React, { Component } from "react";
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControlLabel, TextField } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { IFuelGas } from "../../interfaces/IFuelGas";
import { RootState } from "../../reducers/rootReducer";
import { addFuelGas, updateFualGasSignature } from "../../slices/fuelGasSlice";
import { checkFuelgasName } from "../../services/fuelgasServices";

interface ICloneFuelgasProps {
    fuelGas: IFuelGas
}

const mapStateToProps = (state: RootState) => {
    return {
        currentFurnaceId: state.scenarioSimulator.currentFurnaceId,
        fuelGases: state.fuelGas.fuelGases
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addFuelGas: (newFuelGas: IFuelGas) => dispatch(addFuelGas(newFuelGas)),
        updateSignature: (fuelgas_id: string) => dispatch(updateFualGasSignature({ fuelgas_id: fuelgas_id })),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ICloneFuelgasReduxProps = PropsFromRedux & ICloneFuelgasProps;

type ICloneFuelgasState = {
    showDialog: boolean;
    name: string;
    is_public: boolean;
    isNameAvailable: boolean;
    nameErrorMessage: string;
    showLoading: boolean;
}
class CloneFuelgas extends Component<ICloneFuelgasReduxProps, ICloneFuelgasState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            name: this.props.fuelGas.name,
            is_public: this.props.fuelGas.is_public ? this.props.fuelGas.is_public : false,
            isNameAvailable: false,
            nameErrorMessage: "",
            showLoading: false,
        }
    }

    toggleShowDialog() {
        let getName = this.props.fuelGas.is_public === true ? this.props.fuelGas.name.replace('(PUBLIC)', '') : this.props.fuelGas.name;
        this.setState({
            showDialog: !this.state.showDialog,
            name: getName + "-COPY",
            is_public: this.props.fuelGas.is_public ? this.props.fuelGas.is_public : false,
            isNameAvailable: false,
            nameErrorMessage: ""
        })
    }

    handleNameInput(event) {
        this.setState({
            name: event.target.value.toUpperCase()
        })
    }

    cloneNewFuelGas = async () => {
        if (!!this.state.name?.trim() && this.state.name !== "DEFAULT_DEMO") {
            this.setState({
                showLoading: true,
            })

            let setName = this.state.is_public === true ? this.state.name.trim() + "(PUBLIC)" : this.state.name.trim();

            let newFuelGas: IFuelGas = {
                id: uuidv4(),
                name: setName,
                unit: this.props.fuelGas.unit,
                components: this.props.fuelGas.components,
                status: this.props.fuelGas.status,
                error_message: this.props.fuelGas.error_message,
                O2BridgeWall: this.props.fuelGas.O2BridgeWall,
                Pressure: this.props.fuelGas.Pressure,
                Temperature: this.props.fuelGas.Temperature,
                signature: this.props.fuelGas.signature,
                is_public: this.state.is_public,
                is_display_leave_popup: true,
                is_saved: false
            }
            newFuelGas = JSON.parse(JSON.stringify(newFuelGas));
            try {
                let isNameInRedux = this.props.fuelGases.some(fuelgas => fuelgas.name === newFuelGas.name);
                if (isNameInRedux) {
                    this.setState({
                        isNameAvailable: true,
                        nameErrorMessage: "Name already exist!",
                        showLoading: false,
                    })
                } else {
                    let isNameAvailable = await checkFuelgasName(newFuelGas);

                    if (isNameAvailable.status) {
                        this.setState({
                            isNameAvailable: true,
                            nameErrorMessage: !!isNameAvailable.message ? isNameAvailable.message : "Name already exist!",
                            showLoading: false,
                        })
                    } else {
                        this.props.addFuelGas(newFuelGas);
                        this.props.updateSignature(this.props.fuelGas.id);
                        this.toggleShowDialog();
                        this.setState({
                            showLoading: false,
                        })
                    }
                }
            } catch (error) {
                // Api error hendaling 
                this.setState({
                    isNameAvailable: true,
                    showLoading: false,
                    nameErrorMessage: "Oops somthing went to wrong!"
                })
            }

        } else {
            this.setState({
                isNameAvailable: true,
                nameErrorMessage: "Please enter a name!",
                showLoading: false,
            })
        }
    }

    handleChangeIsPublic(event) {
        this.setState({
            is_public: !this.state.is_public
        })
    }

    render() {
        return (
            <div className="control-header element">
                <button
                    className="button"
                    onClick={() => this.toggleShowDialog()}
                    disabled={!!this.props.fuelGas && !this.props.fuelGas.is_saved && !this.props.fuelGas.id.startsWith("_DEFAULT")}
                >
                    Clone
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Clone fuel gas</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To clone fuel gas, please provide the following information:
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            defaultValue={this.state.name}
                            value={this.state.name}
                            onChange={(event) => this.handleNameInput(event)}
                            helperText={this.state.nameErrorMessage}
                            error={this.state.isNameAvailable}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.is_public}
                                    onChange={(event) => this.handleChangeIsPublic(event)}
                                    name="isPublic"
                                    value={this.state.is_public}
                                    color="primary"
                                />
                            }
                            label="Is Public?"
                        />
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()}>
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.cloneNewFuelGas()}>
                            Clone {this.state.showLoading === true && (
                                <Fade
                                    in={this.state.showLoading}
                                    style={{
                                        transitionDelay: this.state.showLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={11} />
                                </Fade>
                            )}
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

};

export default connector(CloneFuelgas);