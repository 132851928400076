import React, { Component } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../reducers/rootReducer";

import { deleteScenarioRunlength } from "../../slices/scenarioSimulatorSlice";
import { IScenarioRunlength } from "../../interfaces/IScenarioRunlength";

interface IDeleteScenarioProps {
    scenarioRunlength: IScenarioRunlength;
}

const mapStateToProps = (state: RootState) => {
    return {
        feedstocks: state.feedstock.feedstocks
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteScenarioRunlength: (scenarioRunlength: IScenarioRunlength) => dispatch(deleteScenarioRunlength(scenarioRunlength)),
    };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IDeleteScenarioReduxProps = PropsFromRedux & IDeleteScenarioProps;

type IDeleteScenarioState = {
    showDialog: boolean;
}
class DeleteScenario extends Component<IDeleteScenarioReduxProps, IDeleteScenarioState>{

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false
        }
    }

    toggleShowDialog = () => {
        this.setState({
            showDialog: !this.state.showDialog,
        })
    }

    deleteScenario = () => {
        this.props.deleteScenarioRunlength(this.props.scenarioRunlength);
        this.toggleShowDialog()
    }

    render() {
        return (
            <>
                <button
                    className="button "
                    onClick={() => this.toggleShowDialog()}
                >
                    Close
                </button>
                <Dialog open={this.state.showDialog} onClose={() => this.toggleShowDialog()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Close Scenario</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to close this scenario?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button className="button" onClick={() => this.toggleShowDialog()} >
                            Cancel
                        </button>
                        <button className="button" onClick={() => this.deleteScenario()} >
                            Ok
                        </button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

};

export default connector(DeleteScenario);